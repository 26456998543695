import React from 'react';
import './pill.scss';
import AvatarList from '../avatar-list/avatar-list';
import { IAvatarProps } from '../avatar/avatar';
import Emoji from 'react-emoji-render';

/**
 * Pill colors defined as enums
 *
 * @export
 * @enum {number}
 */
export enum PillColor {
    Pink = 1,
    Blue = 2,
    Grey = 3
}

/**
 * Pill props
 *
 * @interface IPillProps
 */
interface IPillProps {
    users: IAvatarProps[];
    msg: string;
    color?: PillColor;
    delay?: number;
}

/**
 * Pill component for display a notification with message and avatar
 *
 * @param {*} props
 * @returns
 */
const Pill: React.FC<IPillProps> = (props) => {
    let pillColorClasses: string = "b-pill";
    switch(props.color) {
        case PillColor.Pink:
            pillColorClasses += ' m-pink';
            break;
        case PillColor.Blue:
            pillColorClasses += ' m-blue';
            break;
        case PillColor.Grey:
            pillColorClasses += ' m-grey';
            break;
        default:
            pillColorClasses += ' m-grey';
            break;
    }
    return (
        <div className="b-pill-wrapper">
            <div className={pillColorClasses}
                style={{
                    animationDelay: props.delay + 'ms'
                }}>
                <div className="e-avatars">
                    <AvatarList users={props.users} />
                </div>
                <div className="e-msg">
                    <Emoji text={props.msg} />
                </div>
            </div>
        </div>
    )
};

/**
 * Default pill animation is 0 for when pills are rendered by themselves instead of a list
 */
Pill.defaultProps = {
    delay: 0
}

export default Pill;