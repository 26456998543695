import React from "react";

import "./header.scss";

import logo from "../../images/kin-logo.svg";
import { Link } from "react-router-dom";

/**
 * Header props
 *
 * @interface IHeaderProps
 */
interface IHeaderProps {
  className?: string;
  hideAccount?: boolean;
}

/**
 * Header component
 */
const Header: React.FC<IHeaderProps> = props => (
  <header className={props.className}>
    <Link to="/">
      <img src={logo} alt="Kin" />
    </Link>
  </header>
);

export default Header;
