import React from "react";
import Avatar from "../avatar/avatar";

import "./person.scss";
import BalanceSummary from "../balance-summary/balance-summary";
import { IAmount } from "../../stores/peopleStore";

/**
 * Person tile props
 *
 * @interface IPersonTileProps
 */
interface IPersonTileProps {
  image: string;
  name: string;
  amountIOwe: IAmount[];
  amountOwed: IAmount[];
  onClick?: () => void;
}

/**
 * Component to render the group tile
 *
 * @param {*} props
 * @returns
 */
const PersonTile: React.FC<IPersonTileProps> = props => {
  let tileClasses: string = "b-person-tile";
  if (props.onClick) {
    tileClasses += " m-linked";
  }
  return (
    <div
      className={tileClasses}
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      <div className="e-person-tile-column">
        <Avatar image={props.image} name={props.name} size={48} />
        <div className="e-person-details">
          <div className="e-person-name">{props.name}</div>
        </div>
      </div>
      <div className="l-summary-cards:horizontal in-container e-person-tile-column">
        <BalanceSummary
          amounts={props.amountIOwe}
          type="danger"
          label="You owe"
          spacing="tight"
        />
        <BalanceSummary amounts={props.amountOwed} spacing="tight" />
      </div>
    </div>
  );
};

export default PersonTile;
