import React from "react";

import "./reminders.scss";
import Button, { ButtonColors } from "../button/button";

import { IKinStore } from "../../stores/kinStore";
import { inject, observer } from "mobx-react";
import Lottie from "react-lottie";

import animationData from "../../images/kin_success_check_lottie.json";
import { ILayoutSidebarStore } from "../../stores/layoutSidebarStore";
import { IProfileSettingsStore } from "../../stores/profileSettingsStore";

/**
 * Reminder sent props
 *
 * @interface IReminderSentProps
 */
interface IReminderSentProps {
  name: string;
  kinStore?: IKinStore;
  layoutSidebarStore?: ILayoutSidebarStore;
  profileSettingsStore?: IProfileSettingsStore;
}

/**
 * Component modal for payment confirmation
 *
 * @class ReminderSent
 * @extends {React.Component<IReminderSentProps, {}>}
 */
@inject("kinStore", "layoutSidebarStore", "profileSettingsStore")
@observer
class ReminderSent extends React.Component<IReminderSentProps, {}> {
  /**
   * Render modal actions based on whether the user has any bank account details stored
   *
   * @returns
   * @memberof ReminderSent
   */
  renderActions() {
    // Check for stored bank account details
    let hasNoBankAccountDetails: boolean =
      this.props.profileSettingsStore!.user.receivingAccounts.length === 0;
    // If the user has no bank account details
    if (hasNoBankAccountDetails) {
      return (
        <>
          <div className="g-h6 e-reminder-description">
            Make sure you've added your banking details if you want {this.props.name} to pay you back using
            Kin.
          </div>
          <Button
            className="e-reminder-btn"
            color={ButtonColors.Secondary}
            small={true}
            onClick={() => {
              this.props.kinStore!.setReminderModal(false);
              this.props.layoutSidebarStore!.setProfileSettings(true);
            }}
          >
            Add my bank details
          </Button>
          <div onClick={() => this.props.kinStore!.setReminderModal(false)} className="e-reminder-cancel">
            Done
          </div>
        </>
      );
    } else {
      // if the user already has bank account details
      return (
        <>
          <div className="g-h6 e-reminder-description">
            Make sure your banking details are correct if you want {this.props.name} to pay you back using
            Kin.
          </div>
          <Button
            className="e-reminder-btn"
            color={ButtonColors.Secondary}
            small={true}
            onClick={() => {
              this.props.kinStore!.setReminderModal(false);
            }}
          >
            Done
          </Button>
        </>
      );
    }
  }
  /**
   * Default render method
   *
   * @returns
   * @memberof ReminderSent
   */
  render() {
    return (
      <div className="b-reminder-modal">
        <div className="e-img-icon m-reminder m-small">
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            height={200}
            width={200}
          />
        </div>
        <div className="g-h5">Reminder sent!</div>
        {this.renderActions()}
      </div>
    );
  }
}

export default ReminderSent;
