import React from 'react';
import '../global.scss';

/**
 * Content Loader component props for showing and hiding the loader
 *
 * @interface ILoaderContentProps
 */
interface ILoaderContentProps {
    active: boolean;
    style?: any;
}

/**
 * Content Loader component for displaying while a query is executing in the browser
 *
 * @param {*} props
 */
const LoaderContent: React.FC<ILoaderContentProps> = (props) => (
    <div style={props.style}>
        { props.active ? 
            <div className="g-loader-gradient">
                {props.children}
            </div>
            : null
        }
    </div>
);

/**
 * Default prop state for the loader is always false
 */
LoaderContent.defaultProps = {
    active: false
}

export default LoaderContent;