import React from "react";

import "./footer.scss";

/**
 * Footer links are exported for sharing on other pages, e.g Modal component
 */
export const privacyPolicyLink: string = "https://www.kin.me/privacy-policy";
export const termsOfUseLink: string = "https://www.kin.me/terms-of-use";
export const talentLink: string = "https://www.kin.me/talent";

/**
 * Footer component
 */
const Footer: React.FC = () => (
  <footer className="b-footer">
    <a className="e-link" rel="noopener noreferrer" target="_blank" href={privacyPolicyLink}>
      Privacy Policy
    </a>
    <a className="e-link" rel="noopener noreferrer" target="_blank" href={termsOfUseLink}>
      Terms of Use
    </a>
    <a className="e-link" rel="noopener noreferrer" target="_blank" href={talentLink}>
      Talent
    </a>
  </footer>
);

export default Footer;
