import { observable, action } from "mobx";
import { GroupMembersStatus } from "./kinsStore";
import Notices from "../common/snackbars";
import { Subscription, Observable } from "rxjs";
import _ from "lodash";
import { queryAuthenticatedWithCache } from "../common/graphql-utils";
import { last, map } from "rxjs/operators";

/**
 * Query to fetch people balances
 *
 */
export const peopleQuery = `
  query {
    userBalances {
      balances {
        currencyIso
        summary {
          balance {
    cents
            currencyIso
            formatted
  }
          currencyIso
      totalIAmOwed {
            cents
            currencyIso
            formatted
      }
      totalOwe {
            cents
            currencyIso
            formatted
      }
  }
      }
      connectionBalances {
    balances {
          currencyIso
          summary {
            balance {
              cents
              currencyIso
              formatted
      }
            currencyIso
            totalIAmOwed {
              cents
              currencyIso
              formatted
            }
            totalOwe {
              cents
              currencyIso
              formatted
            }
          }
        }
        connectionId
        email
        groups {
        balanceSummary {
            balance {
              cents
              currencyIso
              formatted
        }
            currencyIso
            totalIAmOwed {
              cents
              currencyIso
              formatted
          }
            totalOwe {
              cents
              currencyIso
              formatted
            }
          }
          group {
            createDate
            hasTransactions
            id
            image
            members {
              image
            name
              updateDate
          }
            name
            purpose
            type
            updateDate
          }
          member {
            connectionId
            hasReceivingAccount
            id
            image
            isNotificationEnabled
            name
            status
            summary {
              balance {
                cents
                currencyIso
                formatted
              }
              totalIAmOwed {
                cents
                currencyIso
                formatted
              }
              totalOwe {
                cents
                currencyIso
                formatted
              }
              totalSpent {
                cents
                currencyIso
                formatted
              }
            }
            updateDate
            userId
          }
          myMemberId
        }
        image
        isHidden
        name
        status
        updateDate
        userId
      }
    }
  }
`;

/**
 * People balance query interface
 *
 * @export
 * @interface IPoepleQuery
 */
export interface IPoepleQuery {
  balances: IPeopleQueryBalances;
}

/**
 * People balance query balances interface
 *
 * @export
 * @interface IPoepleQuery
 */
export interface IPeopleQueryBalances {
  balances: IPeopleBalanceSummaryWithIsoCodePair[];
  connectionBalances: IPeopleConnectionBalance[];
}

/**
 * Interface for PeopleBalanceSummary
 *
 * @exports
 * @interface IPeopleBalanceSummary
 */
export interface IPeopleBalanceSummaryWithIsoCodePair {
  currencyIso: string;
  summary: IPeopleBalanceSummary;
}

export interface IPeopleBalanceSummary {
  balance: IAmount;
  currencyIso: string;
  totalIAmOwed: IAmount;
  totalOwe: IAmount;
}

/**
 * Interface for PeopleConnectionBalance
 *
 * @exports
 * @interface IPeopleConnectionBalance
 */
export interface IPeopleConnectionBalance extends IConnectionIdOrUserId {
  balances: IPeopleBalanceSummaryWithIsoCodePair[];
  groups: IPeopleConnectionGroupBalance[];
  userId: string;
  image: string;
  isHidden: boolean;
  name: string;
  updateDate: string;
  connectionId: string;
  status: GroupMembersStatus;
  email: string;
}

/**
 * Interface for PeopleConnectionGroupBalance
 *
 * @exports
 * @interface IPeopleConnectionGroupBalance
 */
export interface IPeopleConnectionGroupBalance {
  balances: IPeopleBalanceSummaryWithIsoCodePair[];
  balanceSummary: IPeopleBalanceSummary;
  group: IPeopleGroupReference;
  member: IPeopleGroupMember;
  myMemberId: string;
}

/**
 * Interface for PeopleGroupReference
 *
 * @exports
 * @interface IPeopleGroupReference
 */
export interface IPeopleGroupReference {
  createDate: string;
  hasTransactions: boolean;
  id: string;
  image: string;
  members: IGroupMemberReference[];
  name: string;
  purpose: string;
  type: GroupTypes;
  updateDate: string;
}

/**
 * Interface for GroupMemberReference
 *
 * @exports
 * @interface IGroupMemberReference
 */
export interface IGroupMemberReference {
  image: string;
  name: string;
  updateDate: string;
}
export interface IConnectionIdOrUserId {
  connectionId: string;
  userId: string;
}
/**
 * Interface for PeopleGroupMember
 *
 * @exports
 * @interface IPeopleGroupMember
 */
export interface IPeopleGroupMember extends IConnectionIdOrUserId {
  hasReceivingAccount: boolean;
  image: string;
  isNotificationEnabled: boolean;
  name: string;
  id: string;
  status: GroupMembersStatus;
  summary: IPeopleGroupMemberSummary;
  updateDate: string;
}

/**
 * Interface for PeopleGroupMemberSummary
 *
 * @exports
 * @interface IPeopleGroupMemberSummary
 */
export interface IPeopleGroupMemberSummary {
  balance: IAmount;
  totalIAmOwed: IAmount;
  totalOwe: IAmount;
  totalSpent: IAmount;
}

export interface IPeopleSidebarBalance extends IConnectionIdOrUserId {
  name: string;
  image: string;
  amountIOwe: IAmount[];
  amountOwed: IAmount[];
  status: GroupMembersStatus;
  email: string;
  balances: IPeopleBalanceSummaryWithIsoCodePair[];
}

/**
 * Interface for Amount
 *
 * @exports
 * @interface IAmount
 */
export interface IAmount {
  cents: number;
  currencyIso: string;
  formatted: string;
}

export interface IUIAmount extends IAmount {
  constant: boolean;
}

/**
 * Enum for group types
 *
 * @export
 * @enum {number}
 */
export enum GroupTypes {
  STANDARD = "STANDARD",
  AD_HOC = "AD_HOC"
}

export function matchOnConnectionIdOrUserId(
  connectionBalance: IConnectionIdOrUserId,
  personId: string
): boolean {
  if (personId) {
    return (
      connectionBalance.connectionId === personId ||
      connectionBalance.userId === personId
    );
  }
  return false;
}

export function getPersonId(person: IConnectionIdOrUserId): string {
  return person.connectionId || person.userId;
}

export function sumAmount(amounts: IAmount[]): number {
  return amounts.reduce((total, amount) => (total += amount.cents), 0);
}

/**
 * People Balances store interface
 *
 * @export
 * @interface IPeopleStore
 */
export interface IPeopleStore {
  loading: boolean;
  error: boolean;
  peopleBalances: IPeopleQueryBalances | undefined;
  peopleSidebarBalances: IPeopleSidebarBalance[] | [];
  fetchPeople(): Observable<any>;
  refreshPeople(): Promise<IPeopleQueryBalances>;
  fetchPersonGroupBalances(personId: string): IPeopleConnectionGroupBalance[];
  setLoading(state: boolean): void;
  setPeopleBalances(balances: IPeopleQueryBalances): void;
}

/**
 * Class for storing and handling people balances
 *
 * @export
 * @class PeopleStore
 * @implements {IPeopleStore}
 */
export class PeopleStore implements IPeopleStore {
  /**
   * Subscription for fetching people balances data
   *
   * @private
   * @type {Subscription}
   * @memberof PeopleStore
   */
  private peopleBalanceFetchSubscription: Subscription;
  /**
   * UI loading state
   *
   * @type {boolean}
   * @memberof PeopleStore
   */
  @observable loading: boolean;
  /**
   * UI error state
   *
   * @type {boolean}
   * @memberof PeopleStore
   */
  @observable error: boolean;
  /**
   * People balances with current user
   *
   * @type {IPoepleQuery}
   * @memberof PeopleStore
   */
  @observable peopleBalances: IPeopleQueryBalances | undefined;
  /**
   * People balances formatted for sidebar
   *
   * @type {(IPeopleSidebarBalance[] | undefined)}
   * @memberof PeopleStore
   */
  @observable peopleSidebarBalances: IPeopleSidebarBalance[] | [];

  /**
   * Creates an instance of PeopleStore.
   * @memberof PeopleStore
   */
  constructor() {
    this.loading = false;
    this.error = false;
    this.peopleBalances = undefined;
    this.peopleSidebarBalances = [];
    this.peopleBalanceFetchSubscription = new Subscription();
  }

  /**
   * Format people balances into grouped by user for side bar display
   *
   * @returns {IPeopleSidebarBalance[]}
   * @memberof PeopleStore
   */
  formatSideBalances(): IPeopleSidebarBalance[] {
    // New array of formatted people balances
    let peopleSidebarBalances: IPeopleSidebarBalance[] = [];
    // Loop through connections balances to collect member amounts
    this.peopleBalances!.connectionBalances.forEach(connectionBalance => {
      // Assign member details for side bar data
      peopleSidebarBalances.push({
        name: connectionBalance.name,
        image: connectionBalance.image,
        amountIOwe: connectionBalance.balances.map(
          balance => balance.summary.totalOwe
        ),
        amountOwed: connectionBalance.balances.map(
          balance => balance.summary.totalIAmOwed
        ),
        connectionId: connectionBalance.connectionId,
        status: connectionBalance.status,
        userId: connectionBalance.userId,
        email: connectionBalance.email,
        balances: connectionBalance.balances
      });
    });

    // Seperate people the user owes and sort by most owed (reversed)
    let amountIOwe: IPeopleSidebarBalance[] = _.sortBy(
      peopleSidebarBalances.filter(
        p => sumAmount(p.amountOwed) - sumAmount(p.amountIOwe) < 0
      ),
      person => person.amountIOwe
    ).reverse();
    // Seperate people the user is owed and sort by most owed (reversed)
    let amountOwed: IPeopleSidebarBalance[] = _.sortBy(
      peopleSidebarBalances.filter(
        p => sumAmount(p.amountOwed) - sumAmount(p.amountIOwe) > 0
      ),
      person => person.amountOwed
    ).reverse();
    // Seperate people the user is settled with and sort by alphabetically
    let settled: IPeopleSidebarBalance[] = _.sortBy(
      peopleSidebarBalances.filter(
        p => sumAmount(p.amountOwed) - sumAmount(p.amountIOwe) === 0
      ),
      person => person.name
    );
    // New people sidebar balances returned for assignment
    return [...amountIOwe, ...amountOwed, ...settled];
  }

  /**
   * Fetch people balances from server
   *
   * @param {string} query
   * @memberof PeopleStore
   */
  fetchPeople(): Observable<any> {
    this.setLoading(true);
    const observable = queryAuthenticatedWithCache(peopleQuery);
    observable.subscribe({
      next: result => {
        // Update people balances data to result
        this.setPeopleBalances(result.data.data.userBalances);
        // Execute if callback defined
        this.setLoading(false);
      },
      error: () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
      }
    });
    return observable;
  }

  refreshPeople(): Promise<IPeopleQueryBalances> {
    return this.fetchPeople()
      .pipe(last())
      .pipe(map(x => x.data.data.userBalances))
      .toPromise();
  }

  /**
   * Fetch group balances by person ID
   *
   * @param {string} personId
   * @returns {IPeopleConnectionGroupBalance[]}
   * @memberof PeopleStore
   */
  fetchPersonGroupBalances(personId: string): IPeopleConnectionGroupBalance[] {
    let personGroupBalances: IPeopleConnectionGroupBalance[] = [];
    this.peopleBalances!.connectionBalances.forEach(connectionBalance => {
      if (matchOnConnectionIdOrUserId(connectionBalance, personId)) {
        personGroupBalances = connectionBalance.groups;
      }
    });
    return personGroupBalances;
  }

  /**
   * Setting state values
   */
  @action setLoading(state: boolean): void {
    this.loading = state;
  }
  @action setError(state: boolean): void {
    this.error = state;
  }
  @action setPeopleBalances(balances: IPeopleQueryBalances): void {
    this.peopleBalances = balances;
    this.peopleSidebarBalances = this.formatSideBalances();
    this.loading = false;
  }
}
