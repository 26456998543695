import React from "react";
import "./settle-empty.scss";

/**
 * Empty settle feed SVG
 *
 * @export
 */
export const SettleSVG: React.FC = () => (
  <svg
    className="b-settle-svg"
    xmlns="http://www.w3.org/2000/svg"
    width="233"
    height="232"
    viewBox="0 0 233 232"
  >
    <defs>
      <linearGradient id="a" x1="23.522%" x2="88.244%" y1="6.659%" y2="93.341%">
        <stop offset="0%" stopColor="#E8DD92" />
        <stop offset="100%" stopColor="#CCB557" />
      </linearGradient>
      <linearGradient id="b" x1="13.997%" x2="79.443%" y1="30.6%" y2="71.179%">
        <stop offset="0%" stopColor="#57A290" />
        <stop offset="100%" stopColor="#428071" />
      </linearGradient>
      <linearGradient id="c" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFD4C1" />
        <stop offset="100%" stopColor="#EFB198" />
      </linearGradient>
      <linearGradient id="d" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FAEB85" />
        <stop offset="100%" stopColor="#F2D24D" />
      </linearGradient>
      <linearGradient id="e" x1="50%" x2="50%" y1="109.096%" y2="1.892%">
        <stop offset="0%" stopColor="#C86362" />
        <stop offset="100%" stopColor="#E78D8D" />
      </linearGradient>
      <linearGradient id="f" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFD4C1" />
        <stop offset="100%" stopColor="#EFB198" />
      </linearGradient>
      <linearGradient id="g" x1="50%" x2="50%" y1="100%" y2="-9.974%">
        <stop offset="0%" stopColor="#8D5633" />
        <stop offset="100%" stopColor="#BD7F56" />
      </linearGradient>
      <linearGradient id="h" x1="50%" x2="50%" y1="109.096%" y2="1.892%">
        <stop offset="0%" stopColor="#CC713C" />
        <stop offset="100%" stopColor="#FF9566" />
      </linearGradient>
      <linearGradient id="i" x1="50%" x2="50%" y1="104.301%" y2="2.127%">
        <stop offset="0%" stopColor="#14345A" />
        <stop offset="100%" stopColor="#1E4777" />
      </linearGradient>
      <linearGradient id="j" x1="50%" x2="50%" y1="0%" y2="199.529%">
        <stop offset="0%" stopColor="#FAEFE9" />
        <stop offset="100%" stopColor="#DAC5B9" />
      </linearGradient>
      <linearGradient id="k" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#1A181C" />
        <stop offset="100%" stopColor="#130017" />
      </linearGradient>
      <linearGradient id="l" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFD4C1" />
        <stop offset="100%" stopColor="#EFB198" />
      </linearGradient>
      <linearGradient id="m" x1="50%" x2="50%" y1="0%" y2="98.085%">
        <stop offset="0%" stopColor="#FAEFE9" />
        <stop offset="100%" stopColor="#DAC5B9" />
      </linearGradient>
      <linearGradient id="n" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#E6864E" />
        <stop offset="100%" stopColor="#EB965E" />
      </linearGradient>
      <linearGradient id="o" x1="50%" x2="50%" y1="109.096%" y2="1.892%">
        <stop offset="0%" stopColor="#C86362" />
        <stop offset="100%" stopColor="#E78D8D" />
      </linearGradient>
      <linearGradient id="p" x1="50%" x2="50%" y1="-96.236%" y2="98.085%">
        <stop offset="0%" stopColor="#C9AEAC" />
        <stop offset="100%" stopColor="#765F5B" />
      </linearGradient>
      <linearGradient id="q" x1="50%" x2="50%" y1="0%" y2="98.085%">
        <stop offset="0%" stopColor="#B8ACAB" />
        <stop offset="100%" stopColor="#756A68" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M-35-10h305v250H-35z" opacity=".204" />
      <path
        fill="#173D6A"
        d="M205.466 30.365c0 6.708-5.438 12.145-12.146 12.145-6.708 0-12.146-5.437-12.146-12.145 0-6.708 5.438-12.146 12.146-12.146 6.708 0 12.146 5.438 12.146 12.146"
      />
      <path
        fill="url(#a)"
        d="M209.946 128.543c-8.602 0-15.462 7.008-14.455 15.274.842 6.907 7.278 12.16 14.68 12.052 7.904-.116 12.734-6.338 14.328-13.662 1.79-8.232-6.515-13.664-14.553-13.664"
      />
      <path fill="url(#b)" d="M175.101 198.766l-57.36 33.015-8.43-14.555 57.36-33.015z" />
      <path fill="#EBE0DA" d="M201.799 94.298l-8.17-30.489 30.347-8.131 8.17 30.488-17.778 4.764z" />
      <path
        fill="url(#c)"
        d="M154.834 13.072c.307-1.897-2.255-3.77-8.76-5.842C139.566 5.157 119.79.02 113.241 0c-6.548-.02-11.145 1.705-14.462 8.907-3.318 7.202-8.462 27.134-9.225 34.053-.763 6.919-.863 8.27 1.459 9.42 2.321 1.148 4.17-1.833 5.326-4.755 1.157-2.924 8.496-25.683 16.894-30.855 8.398-5.173 31.843-1.189 35.198-1.044 3.355.144 5.937.212 6.402-2.654"
      />
      <path
        fill="url(#d)"
        d="M59.547 163.968c-1.057 4.7-4.182 8.718-9.276 9.082-5.334.382-10.28-3.406-10.756-8.244-.533-5.408 4.148-9.948 9.996-9.948 5.542 0 11.27 3.621 10.036 9.11"
      />
      <path
        fill="url(#e)"
        d="M72.874 29.352c0 7.268-5.89 13.158-13.157 13.158s-13.158-5.89-13.158-13.158c0-7.267 5.89-13.158 13.158-13.158 7.266 0 13.157 5.89 13.157 13.158"
      />
      <path
        fill="url(#f)"
        d="M44.534 88.057L0 44.029 44.534 0 89.07 44.029 62.979 69.82z"
        transform="translate(0 51.62)"
      />
      <path
        fill="url(#g)"
        d="M35.311 38.797c-2.697 2.855-2.697 5.619.714 10.04.028 6.523 0 13.548 0 14.947 0 .493 2.539 1.188 7.615 2.087 5.077-1.046 7.615-1.742 7.615-2.087v-5.236h4.878c3.808 0 2.827-4.914 2.737-6.425-.15-2.505-.705-8.375-1.666-17.61-12.798.952-20.095 2.38-21.893 4.284"
        transform="translate(0 51.62)"
      />
      <path
        fill="url(#h)"
        d="M44.546 88.045h-.022l-18.795-18.58c2.267-1.162 5.064-2.445 8.392-3.849l19.038-.002c3.924 1.657 7.11 3.146 9.558 4.466L44.546 88.045z"
        transform="translate(0 51.62)"
      />
      <path
        fill="url(#i)"
        d="M46.239 86.372l-1.705 1.685-3.41-3.372.85-17.434c.74-.884 1.296-1.326 1.666-1.326.37 0 .925.442 1.666 1.326l.933 19.12z"
        transform="translate(0 51.62)"
      />
      <path
        fill="url(#j)"
        d="M43.64 65.407l-7.615-2.813-2.142 3.093 7.377 4.522 2.38-4.286 2.38 4.286 7.377-4.522-2.142-3.093z"
        transform="translate(0 51.62)"
      />
      <path
        fill="url(#k)"
        d="M31.885 38.518c.332 5.633 4.14 11.82 4.14 14.319 3.178-1.566 3.724-3.937 2.534-3.896-3.448.12-5.494-6.051-2.412-7.652 1.3-.675 2.22-.237 2.976.412 1.897 2.692 1.855 8.346 2.613 11.85.952 4.402 5.887 7.853 11.954 7.853 3.57 0 6.965-4.522 5.18-9.281-.119.476-1.142 1.268-3.094 1.547-2.498.357-10.47-.12-11.898-3.57-1.118-2.701-3.175-11.07.402-11.07 7.45 0 13.868.617 14.82-1.94 2.95-7.913-5.532-11.954-13.97-11.954-8.802 0-17.56 4.397-13.245 13.382"
        transform="translate(0 51.62)"
      />
      <path
        fill="url(#l)"
        d="M42.401 3.853C27.381-3.427 10.233-1.37 2.198 18.528-3.516 32.68 2.197 49.07 16.02 55.92c14.72 7.293 30.399.58 39.103-13.118C63.652 29.377 57.05 10.953 42.4 3.853"
        transform="matrix(-1 0 0 1 175.987 42.095)"
      />
      <path
        fill="url(#m)"
        d="M11.757 51.873c.313-1.517.663-2.649 1.053-3.392 1.484-2.834 7.692-6.073 18.623-9.716 10.931 3.643 17.14 6.882 18.624 9.716.232.444.45 1.025.655 1.745a28.57 28.57 0 0 1-7.827 5.694c-10.93 5.415-22.39 3.108-31.128-4.047z"
        transform="translate(117.081 42.095)"
      />
      <path fill="#12345B" d="M148.267 93.212l2.227-2.636h-4.858z" />
      <path
        fill="url(#n)"
        d="M22.241 41.865V29.72c-5.065-.076-4.077-8.853 1.012-6.174.911.404 3.24-.709 2.43-3.442 5.465 4.049 13.081-3.057 13.562 1.316.947 8.605-.885 15.678-5.74 15.679-.477 0-.942.016-1.547-.092v4.858c-1.585.945-3.17 7.49-4.756 7.49-1.585 0-3.238-6.545-4.96-7.49z"
        transform="matrix(-1 0 0 1 175.47 42.095)"
      />
      <path
        fill="#DB6F3D"
        d="M143.611 79.139v1.62c3.037 0 5.398-1.451 7.085-4.353-2.024 1.35-4.386 2.26-7.085 2.733z"
      />
      <path fill="#12335A" d="M153.024 83.289l-4.757 8.162 6.883-.722z" />
      <path fill="#113157" d="M143.308 83.289l-2.126 7.498 7.085.673z" />
      <path
        fill="#190020"
        d="M171.537 55.908c.248 7.413-4.316 14.857-12.77 17.28-6.137 1.76-10.496-5.013-15.15-8.081-3.33-2.196-7.34-.107-9.768-3.821-3.746-5.728-2.778-11.568 1.082-15.962 13.393-5.943 28.19-4.38 36.606 10.584z"
      />
      <path
        fill="#F38887"
        fillRule="nonzero"
        d="M169.709 139.775a.966.966 0 0 1 1.676 0l10.662 18.25c.388.666-.082 1.507-.84 1.507h-21.322c-.758 0-1.226-.84-.84-1.506l10.664-18.251z"
      />
      <path
        fill="#376F70"
        fillRule="nonzero"
        d="M92.146 80.888a.626.626 0 0 1-1.094 0L84.1 68.811c-.253-.44.052-.997.546-.997h13.908c.494 0 .8.556.546.997l-6.953 12.077z"
      />
      <path fill="url(#o)" d="M166.136 121.964L86.304 207.49l-26.587-72.2z" />
      <path
        fill="#FFD7B9"
        d="M103.733 178.39v5.694c0 1.096-16.194.728-16.194-.853 0-1.487.03-8.231 0-15.167-3.627-4.702-3.627-7.639-.76-10.675 1.912-2.025 9.672-3.543 23.28-4.555 1.021 9.819 1.611 16.06 1.771 18.725.096 1.607 1.139 6.832-2.91 6.832h-5.187z"
      />
      <path
        fill="url(#p)"
        d="M86.032 184.755c.198-1.91.535-3 1.013-3.273.716-.41 17.365-.41 18.218 0 .439.21.744.799.908 1.98.824 1.343 1.235 1.773 1.235 1.293 0-.028-7.034 7.55-21.102 22.735l-7.225-19.62a153.463 153.463 0 0 1 6.953-3.115z"
      />
      <path
        fill="url(#q)"
        d="M87.902 158.774c-3.278 1.704-1.102 8.265 2.565 8.137 1.264-.044.387 2.546-2.993 4.21 0-2.656-4.105-6.882-4.105-15.293-4.075-1.517-3.062-7.586-.264-7.586-.448-5.565 8.462-8.439 11.912-5.246 3.508-4.205 12.364-2.054 12.899 1.594 6.09-3.251 10.093 6.094 4.39 9.72-2.346 1.493-7.834 2.062-15.758 2.062-3.802 0-3.465 5.703-5.482 2.841-.805-.692-1.783-1.156-3.164-.439z"
      />
      <path
        fill="#000"
        fillOpacity=".065"
        d="M103.923 178.158v3.056c-4.889-.87-8.007-2.907-9.356-6.113 2.526 1.617 5.646 2.634 9.356 3.057z"
      />
    </g>
  </svg>
);
