import React from "react";
import ReactList from "react-list";
import * as Scroll from "react-scroll";
import LayoutSidebar from "../../hoc/layout-sidebar";
import { menuItems } from "../../common/menu";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { PageModal, PageModalHead, PageModalBody } from "../../hoc/page-modal";
import _ from "lodash";
import moment from "moment";
import querystring from "query-string";

import "../../components/tabs/tabs.scss";
import "./kin.scss";
import TransactionTile from "../../components/tiles/transaction";
import SettleTile from "../../components/tiles/settle";
import { RouteComponentProps } from "react-router";
import KinLoader from "./kin-loader";
import { SettleSVG } from "../../components/svgs/settle-empty";
import { TransactionsSVG } from "../../components/svgs/transactions-empty";
import Segment, { SegmentEvent } from "../../common/segment";
import {
  IGroupQueryActivityData,
  IGroupQueryTransactionData,
  IGroupQuerySettleData,
  IGroupMember,
  DateTypes,
  TransactionType,
  TileType,
  IMemberPaid,
  SettleType,
  IKinStore,
  groupQuery,
  IPaymentProps,
  IReminderUser,
  IExpenseSplitUser
} from "../../stores/kinStore";
import { ICurrenciesStore } from "../../stores/currenciesStore";
import { inject, observer } from "mobx-react";
import { computed } from "mobx";
import Modal from "../../components/modal/modal";
import Button, { ButtonColors } from "../../components/button/button";
import { ILayoutSidebarStore } from "../../stores/layoutSidebarStore";
import { IProfileSettingsStore } from "../../stores/profileSettingsStore";
import PaymentModalContent from "../../components/modal/payment";
import { IAvatarProps } from "../../components/avatar/avatar";
import ReminderOptions from "../../components/modal/reminder-options";
import ReminderShareGhost from "../../components/modal/reminder-share-ghost";
import ReminderShare from "../../components/modal/reminder-share";
import { GroupMembersStatus } from "../../stores/kinsStore";
import PaymentAdded from "../../components/modal/payment-added";
import ReminderSent from "../../components/modal/reminder-sent";
import ExpenseModalContent from "../../components/modal/expense";
import Utilities from "../../common/utilities";

import plusIcon from "../../images/icon-plus.svg";
import { SplitType, IGroupSplitPortion, IExpense } from "../../stores/kinStore";
import { IAmount } from "../../stores/peopleStore";
import SplitCalculator from "../../modules/split-calculator";

/**
 * Tab names
 */
export const tabNames: string[] = ["Transactions", "Settle"];

/**
 * Kin props form stores
 *
 * @interface IKinProps
 * @extends {RouteComponentProps}
 */
interface IKinProps extends RouteComponentProps {
  kinStore?: IKinStore;
  layoutSidebarStore?: ILayoutSidebarStore;
  profileSettingsStore?: IProfileSettingsStore;
  currenciesStore?: ICurrenciesStore;
}

@inject(
  "kinStore",
  "layoutSidebarStore",
  "profileSettingsStore",
  "currenciesStore"
)
@observer
/**
 * Group class for displaying transactional data for a single group
 *
 * @class Group
 * @extends {React.Component<IKinProps, {}>}
 */
class Kin extends React.Component<IKinProps, {}> {
  /**
   * Default tab index
   *
   * @private
   * @type {number}
   * @memberof Kin
   */
  private defaultTab: number = 0;

  /**
   * Timer that executes scrolling to transaction
   *
   * @type {*}
   * @memberof Kin
   */
  public scrollRunner: NodeJS.Timeout | undefined = undefined;

  @computed get loading(): boolean {
    return this.props.kinStore!.loading;
  }
  @computed get error(): boolean {
    return this.props.kinStore!.error;
  }
  @computed get expenseModal(): boolean {
    return this.props.kinStore!.expenseModal;
  }
  @computed get expenseSplitMode(): boolean {
    return this.props.kinStore!.expenseSplitMode;
  }
  @computed get paymentModal(): boolean {
    return this.props.kinStore!.paymentModal;
  }
  @computed get removingExpense(): boolean {
    return this.props.kinStore!.removingExpense;
  }
  @computed get payment(): IPaymentProps | undefined {
    return this.props.kinStore!.payment;
  }
  @computed get confirmedPayment(): boolean {
    return this.props.kinStore!.confirmedPayment;
  }
  @computed get confirmedReminder(): boolean {
    return this.props.kinStore!.confirmedReminder;
  }
  @computed get reminderModal(): boolean {
    return this.props.kinStore!.reminderModal;
  }
  @computed get reminderOptionsModal(): boolean {
    return this.props.kinStore!.reminderOptionsModal;
  }
  @computed get reminderShareGhostModal(): boolean {
    return this.props.kinStore!.reminderShareGhostModal;
  }
  @computed get reminderShareUserModal(): boolean {
    return this.props.kinStore!.reminderShareUserModal;
  }
  @computed get reminderUser(): IReminderUser {
    return this.props.kinStore!.reminderUser;
  }
  @computed get groupName(): string {
    return this.props.kinStore!.groupName;
  }
  @computed get groupImage(): string {
    return this.props.kinStore!.groupImage;
  }
  @computed get activityData(): IGroupQueryActivityData[] {
    return this.props.kinStore!.activityData;
  }
  @computed get transactionData(): IGroupQueryTransactionData[] {
    return this.props.kinStore!.transactionData;
  }
  @computed get settleData(): IGroupQuerySettleData {
    return this.props.kinStore!.settleData;
  }
  @computed get currentUserId(): string {
    return this.props.kinStore!.currentUserId;
  }
  @computed get members(): IGroupMember[] {
    return this.props.kinStore!.members;
  }
  @computed get leaveOutstandingBalance(): boolean {
    return this.props.kinStore!.leaveOutstandingBalance;
  }
  @computed get leaveOnlyMember(): boolean {
    return this.props.kinStore!.leaveOnlyMember;
  }
  @computed get leaveOkay(): boolean {
    return this.props.kinStore!.leaveOkay;
  }
  @computed get leaving(): boolean {
    return this.props.kinStore!.leaving;
  }
  @computed get noticeState(): boolean {
    return this.props.layoutSidebarStore!.noticeState;
  }
  @computed get noticeMsg(): string {
    return this.props.layoutSidebarStore!.noticeMsg;
  }
  @computed get errorMsg(): string | undefined {
    return this.props.kinStore!.errorMsg;
  }
  @computed get defaultKinCurrency(): string {
    return this.props.kinStore!.currencyIsoCode;
  }
  @computed get defaultKinCurrencySybmol(): string {
    return this.props.currenciesStore!.currencySybmolByIsoCode(
      this.defaultKinCurrency
    );
  }

  /**
   *Creates an instance of Kin.
   * @param {IKinProps} props
   * @memberof Kin
   */
  constructor(props: IKinProps) {
    super(props);
    this.updateDefaultTab();
    this.checkOpenKinSettings();
  }

  /**
   * Fetch group info based on group ID
   *
   * @memberof Group
   */
  componentDidMount() {
    this.props
      .kinStore!.fetchKinData(
        groupQuery((this.props.match.params as any).groupid)
      )
      .then(() => {
        this.scrollToTransaction();
        this.checkOpenExpenseModal();
      });
    new Segment().Page(SegmentEvent.PAGE_KIN);
  }

  /**
   * Check if the default tab needs to change
   *
   * @memberof Kin
   */
  updateDefaultTab() {
    let newTab: string | undefined = querystring.parse(
      this.props.location.search
    ).tab as string;
    // Only if defined update the default tab
    if (newTab) {
      let tabIndex: number = tabNames
        .map(str => str.toLowerCase())
        .indexOf(newTab.toLowerCase());
      if (tabIndex !== -1) {
        // Update index if the tab name exists
        this.defaultTab = tabIndex;
      }
    }
  }

  /**
   * Check if the Kin settings need to be opened form query string
   *
   * @memberof Kin
   */
  checkOpenKinSettings() {
    let settingsPanelOpen: string | undefined = querystring.parse(
      this.props.location.search
    ).settings as string;
    if (settingsPanelOpen === "true") {
      this.props.layoutSidebarStore!.setKinSettings(true);
    }
  }

  /**
   * Directly start adding an expense via query string
   *
   * @memberof Kin
   */
  checkOpenExpenseModal() {
    let expenseModalOpen: string | undefined = querystring.parse(
      this.props.location.search
    ).expense as string;
    if (expenseModalOpen === "add") {
      this.addExpense();
    }
  }

  /**
   * Check if the page needs to scroll to a transaction
   *
   * @memberof Kin
   */
  scrollToTransaction() {
    // Get transaction ID from query string
    let transactionScrollId: string | undefined = querystring.parse(
      this.props.location.search
    ).transaction as string;
    // Only if there is an ID start scrolling
    if (transactionScrollId) {
      // How long the scroll needs to be between points
      const scrollDuration: number = 500;
      // Starting scroll point
      let scrollPosition: number = 0;
      // Amount to scroll at once
      const scrollOffsetAdded: number = document.getElementById("kin-body")!
        .offsetHeight;

      // Start scroll runner to bottom
      this.scrollRunner = setInterval(() => {
        // Increment scroll offset
        scrollPosition += scrollOffsetAdded;
        // Execute scroll animation to move down the page
        Scroll.animateScroll.scrollTo(scrollPosition, {
          containerId: "kin-body",
          duration: scrollDuration,
          smooth: "linear"
        });

        // Only start observing if the element is on the page
        if (document.getElementById(transactionScrollId!)) {
          // Cancel scrolling
          clearInterval(this.scrollRunner as NodeJS.Timeout);
          // Smooth scroll to element aligned at the top
          Scroll.animateScroll.scrollTo(
            document.getElementById(transactionScrollId!)!.offsetTop - 16,
            {
              containerId: "kin-body",
              duration: scrollDuration,
              smooth: "easeOutQuad"
            }
          );
        }

        // Check if at the bottom of scroll page
        if (
          document.getElementById("kin-body")!.scrollTop +
            document.getElementById("kin-body")!.offsetHeight >=
          document.getElementById("kin-body")!.scrollHeight
        ) {
          // Cancel scrolling
          clearInterval(this.scrollRunner as NodeJS.Timeout);
        }
      }, scrollDuration);
    }
  }

  /**
   * Reset data on component unmount
   *
   * @memberof Kin
   */
  componentWillUnmount() {
    this.props.kinStore!.resetData();
    // Cleanup timer on unmount
    if (this.scrollRunner !== undefined) {
      clearInterval(this.scrollRunner as NodeJS.Timeout);
    }
  }

  /**
   * Formats date from GraphQL to human readable version
   *
   * @param {string} date
   * @returns Returns date "DD MMMM" e.g "15 March"
   * @memberof Group
   */
  getFormattedDate(date: string, isUpcoming: boolean = false) {
    let dateString: string = "";
    if (moment(date).isBefore(moment(), "day")) {
      dateString = moment(date).format("DD MMMM");
    } else {
      dateString = DateTypes.Today;
    }
    if (isUpcoming) {
      dateString = DateTypes.Upcoming;
    }
    return dateString;
  }

  /**
   * Get the payment data for the payment modal
   *
   * @param {string} memberId
   * @param {IAmount} amount
   * @memberof Kin
   */
  setPayment(memberId: string, amount: IAmount) {
    let currentUser:
      | IGroupMember
      | IMemberPaid = new KinHelper().findGroupMemberByUserId(
      this.members,
      this.currentUserId
    );
    let otherMember:
      | IGroupMember
      | IMemberPaid = new KinHelper().findGroupMemberByUserId(
      this.members,
      memberId
    );
    let users: IAvatarProps[] = [
      {
        name: otherMember.name,
        image: otherMember.image,
        size: 32
      },
      {
        name: currentUser.name,
        image: currentUser.image,
        size: 32
      }
    ];
    this.props.kinStore!.setPayment({
      users: users,
      transaction: {
        amount: amount,
        repayAmount: amount,
        group: this.groupName,
        user: otherMember.name,
        userId: memberId,
        paying: true
      }
    });
    this.props.kinStore!.setPaymentModal(true);
  }

  /**
   * Get reminder data for reminder modal
   *
   * @param {string} memberId
   * @param {number} amount
   * @memberof Kin
   */
  remindPayment(memberId: string, amount: IAmount) {
    let member: IGroupMember = new KinHelper().findGroupMemberByUserId(
      this.members,
      memberId
    ) as IGroupMember;
    let ghost: boolean = false;
    if (
      member.status === GroupMembersStatus.InActive ||
      member.status === GroupMembersStatus.Invited ||
      member.status === GroupMembersStatus.NotInvited
    ) {
      ghost = true;
    }
    this.setPayment(memberId, amount);
    this.props.kinStore!.setReminderUser({
      currentUser: new KinHelper().findGroupMemberByUserId(
        this.members,
        this.props.kinStore!.currentUserId
      ).name,
      name: member.name,
      id: memberId,
      amount: amount,
      ghost: ghost
    });
    this.props.kinStore!.setReminderModal(true);
    this.props.kinStore!.setReminderOptionsModal(true);
  }

  /**
   * Opens the expense modal
   *
   * @memberof Kin
   */
  addExpense() {
    this.props.kinStore!.setExpenseModal(true, this.defaultKinCurrency);
  }

  markAsPaid(
    transaction: IGroupQueryTransactionData,
    callback: any = null
  ): void {
    if (callback) {
      callback();
    }

    // 1. Set TransactionRecordId
    this.props.kinStore!.setTransactionRecordId(transaction.id);

    // 2. Create temporary expense
    const expense: IExpense = this.mapToExpense(transaction);

    // 3. Update the props that will change
    expense.future = false;
    expense.responsibleMemberId = expense.responsibleMemberId
      ? expense.responsibleMemberId
      : this.props.kinStore!.currentUserId;

    // 4. If the transaction isn't split yet, do so.
    const transactionHasNoPortions = transaction.split.portions.length === 0;
    const transactionHasUnassignedSplit =
      transaction.split.portions.length === 1 &&
      transaction.split.portions[0].size === 0;
    if (transactionHasNoPortions || transactionHasUnassignedSplit) {
      const activeMembers = this.members.filter(
        member => member.status !== GroupMembersStatus.InActive
      );
      expense.split.portions = SplitCalculator.split(
        Math.abs(transaction.amount.cents),
        activeMembers.map(member => ({ cents: 0, constant: false }))
      ).map((split, index) => ({
        memberId: activeMembers[index].id,
        size: split.cents
      }));
    } else {
      // 5. ...just clean the existing data
      expense.split.portions = expense.split.portions.map(portion => ({
        memberId: portion.memberId,
        size: portion.size
      }));
    }

    // 5. Set expense in global state
    this.props.kinStore!.setExpense(expense);

    // FIN: Update the transaction.
    this.props.kinStore!.addUpdateExpense(true);
  }

  editExpense(transaction: IGroupQueryTransactionData) {
    // Update expense object for expense modal
    const expense: IExpense = this.mapToExpense(transaction);
    this.props.kinStore!.setTransactionRecordId(transaction.id);
    this.props.kinStore!.setExpense(expense);
    // Map expense split members
    let portions = transaction.split?.portions
      ? transaction.split.portions
      : [];
    this.props.kinStore!.setExpenseSplitMembers(
      [...this.mapToExpenseSplitMember(portions)],
      true
    );
    // Open expense modal
    this.props.kinStore!.setExpenseModal(true, this.defaultKinCurrency);
    this.props.kinStore!.setEditingExpense(true);
  }

  private mapToExpense(transaction: IGroupQueryTransactionData): IExpense {
    const expense: IExpense = {
      future: transaction.type === TransactionType.Planned,
      amount: transaction.amount,
      description: transaction.description,
      currencyIso: this.defaultKinCurrency,
      split: transaction.split,
      responsibleMemberId: transaction.responsibleMemberId,
      date: transaction.date ? transaction.date : moment.utc().format()
    };
    if (!expense.split?.portions.some(x => x.memberId)) {
      let portions = this.props
        .kinStore!.members.filter(x => x.status !== GroupMembersStatus.InActive)
        .map(x => {
          return {
            memberId: x.id,
            size: 1
          };
        });
      const split = {
        type: SplitType.Ratio,
        portions: portions
      };
      expense.split = split;
    }
    return expense;
  }
  mapToExpenseSplitMember(split: IGroupSplitPortion[]): IExpenseSplitUser[] {
    // First map users to expense split user
    let expenseSplitUsers: IExpenseSplitUser[] = [];
    this.members
      .filter(member => member.status !== GroupMembersStatus.InActive)
      .forEach(member => {
        expenseSplitUsers.push({
          id: member.id,
          name: new KinHelper().findGroupMemberByUserId(this.members, member.id)
            .name,
          selected: false,
          value: 0,
          edited: true
        });
      });
    // Update expense split user list with transaction values
    split.forEach(member => {
      if (member.memberId.length !== 0) {
        expenseSplitUsers.forEach((user, index) => {
          if (user.id === member.memberId) {
            expenseSplitUsers[index] = {
              ...expenseSplitUsers[index],
              selected: member.size !== 0,
              value: Math.abs(member.amount.cents)
            };
          }
        });
      }
    });
    return expenseSplitUsers;
  }

  /**
   * Removing a transaction from a kin
   *
   * @param {string} transactionId
   * @memberof Kin
   */
  removeExpense(transactionId: string, planned: boolean) {
    if (window.confirm("You are about to delete this transaction. Delete?")) {
      this.props.kinStore!.removeExpense(transactionId, planned);
    }
  }

  /**
   * Map Kin members to be displayed as a list of avatars
   *
   * @param {IGroupMember[]} members
   * @returns
   * @memberof Kin
   */
  mapMembersToAvatarList(members: IGroupMember[]): IAvatarProps[] {
    let memberList: IAvatarProps[] = [];
    members.forEach(member => {
      // Filter out InActive members
      if (member.status !== GroupMembersStatus.InActive) {
        memberList.push({
          size: 24,
          borderWidth: 0,
          name: member.name,
          image: member.image,
          initialSize: 12
        });
      }
    });
    return memberList;
  }

  /**
   * Render transaction Planned tile with correct colours
   *
   * @param {IGroupQueryTransactionData} transaction
   * @param {number} index
   * @returns
   * @memberof Group
   */
  renderPlannedTransactionTile(
    transaction: IGroupQueryTransactionData,
    index: number | string,
    expandable: boolean = false
  ) {
    // Default amount if not current user
    let amountToPay: number = Math.abs(transaction.amount.cents);
    let portions: IGroupSplitPortion[] = [];
    // Handle split data when null
    if (transaction.split !== null) {
      portions = transaction.split.portions;
    }
    return (
      <Scroll.Element className="b-tile" name={transaction.id}>
        <TransactionTile
          id={transaction.id}
          key={index}
          responsibleMemberId={transaction.responsibleMemberId}
          currentUser={this.currentUserId}
          description={transaction.description}
          portions={portions}
          members={this.members}
          amount={amountToPay}
          type={TileType.Future}
          expanded={
            (querystring.parse(this.props.location.search)
              .transaction as string) === transaction.id
          }
          expandable={expandable}
          removing={this.removingExpense}
          loading={this.props.kinStore!.addingExpense}
          removeExpense={() => this.removeExpense(transaction.id, true)}
          editExpense={() => this.editExpense(transaction)}
          markAsPaid={callback => this.markAsPaid(transaction, callback)}
          currency={transaction.amount.currencyIso}
        />
      </Scroll.Element>
    );
  }

  /**
   * Render transaction Paid tile with correct colours
   *
   * @param {IGroupQueryTransactionData} transaction
   * @param {number} index
   * @returns
   * @memberof Group
   */
  renderPaidTransactionTile(
    transaction: IGroupQueryTransactionData,
    index: number | string,
    expandable: boolean = false
  ) {
    // Default amount and type if not current user
    let amount: number = 0;
    let type: number = TileType.NotInvolved;
    transaction.split.portions.forEach(portion => {
      // Check through portions to see if id matches current member id
      if (portion.memberId === this.currentUserId) {
        amount = portion.responsible.cents;
        // Check if current user is owed or not
        if (portion.amount.cents === 0) {
          type = TileType.Owed;
          if (portion.responsible.cents === 0) {
            type = TileType.NotInvolved;
          }
        } else {
          type = TileType.Owe;
          if (portion.responsible.cents > 0) {
            type = TileType.Owed;
          }
        }
      }
    });
    return (
      <Scroll.Element className="b-tile" name={transaction.id}>
        <TransactionTile
          id={transaction.id}
          key={index}
          responsibleMemberId={transaction.responsibleMemberId}
          currentUser={this.currentUserId}
          description={transaction.description}
          portions={transaction.split.portions}
          members={this.members}
          amount={amount}
          type={type}
          images={transaction.images}
          expanded={
            (querystring.parse(this.props.location.search)
              .transaction as string) === transaction.id
          }
          expandable={expandable}
          removing={this.removingExpense}
          removeExpense={() => this.removeExpense(transaction.id, false)}
          editExpense={() => this.editExpense(transaction)}
          currency={transaction.amount.currencyIso}
        />
      </Scroll.Element>
    );
  }

  /**
   * Render transaction settle tile with correct colours
   *
   * @param {IGroupQueryTransactionData} transaction
   * @param {number} index
   * @returns
   * @memberof Group
   */
  renderSettleTransactionTile(
    transaction: IGroupQueryTransactionData,
    index: number | string
  ) {
    let users: IMemberPaid[] = [];
    // Create simplified users object to pass to transaction tile
    // to display when one user paid another.
    transaction.split.portions.forEach(portion => {
      let user = new KinHelper().findGroupMemberByUserId(
        this.members,
        portion.memberId
      );
      if (portion.size !== 0) {
        users.push({
          name: user.name,
          image: user.image,
          size: 26,
          initialSize: 12,
          paid: Math.abs(portion.responsible.cents)
        });
      } else {
        users.push({
          name: user.name,
          image: user.image,
          size: 26,
          initialSize: 12
        });
      }
    });
    // Sort users so paying user is first
    let sortedUsers = _.sortBy(users, ["paid"]);
    return (
      <Scroll.Element className="b-tile" name={transaction.id}>
        <TransactionTile
          id={transaction.id}
          key={index}
          users={sortedUsers}
          payment={transaction.type === TransactionType.Settle}
          currency={transaction.amount.currencyIso}
        />
      </Scroll.Element>
    );
  }

  /**
   * Render Transaction tile base on transaction type
   *
   * @param {string} dateFilter
   * @param {(IGroupQueryTransactionData[] | undefined)} transactionData
   * @returns Transaction Tile
   * @memberof Group
   */
  renderTransactionTile(
    dateFilter: string,
    transactionData: IGroupQueryTransactionData[]
  ) {
    return transactionData.map((transaction, index) => {
      // Show upcoming transaction first
      if (
        transaction.type === TransactionType.Planned &&
        dateFilter === DateTypes.Upcoming
      ) {
        return this.renderPlannedTransactionTile(
          transaction,
          transaction.id,
          true
        );
      }
      // Show remaining transactions
      if (
        moment(transaction.date).format("DD MMMM") === dateFilter ||
        this.getFormattedDate(transaction.date) === dateFilter
      ) {
        switch (transaction.type) {
          case TransactionType.Paid:
            return this.renderPaidTransactionTile(
              transaction,
              transaction.id,
              true
            );
          case TransactionType.Settle:
            return this.renderSettleTransactionTile(
              transaction,
              transaction.id
            );
        }
      }
      return true;
    });
  }

  /**
   * Safe way to determine next index for section totals that only
   * have paid or future expenses
   *
   * @param {number} listLength
   * @param {number} index
   * @returns
   * @memberof Kin
   */
  nextIndex(listLength: number, index: number) {
    // In some instances the next item holds the total value
    let nextIndex: number = index + 1;
    if (listLength === 1) {
      return 0;
    } else {
      if (nextIndex <= listLength) {
        return nextIndex;
      } else {
        return 0;
      }
    }
  }

  /**
   * Render the Transaction Feed tab.
   * Tiles are also grouped by date
   *
   * @returns
   * @memberof Group
   */
  renderTransactionFeed(index: number) {
    let dividerClasses: string = "b-tile-divider";
    let dividerLabel: string = "";
    if (this.transactionData[index].dateLabelPrefix) {
      // Only show for planned transactions and if there is a prefix
      if (
        this.transactionData[index].dateLabelPrefix!.length !== 0 &&
        this.transactionData[index].type === TransactionType.Planned
      ) {
        dividerLabel = this.transactionData[index].dateLabelPrefix + " • ";
        dividerClasses += " m-due";
      }
    }
    return (
      <React.Fragment key={"transactionFeed_" + index}>
        {// Show Future expense total label if needed
        index === 0 &&
        this.transactionData[0].type === TransactionType.Planned ? (
          // Only show if there is a sections total
          <div className="b-type-divider">
            <div className="e-type">Future</div>
            {// Don't display total if there is only one month
            this.transactionData[0].sectionTotal !==
            this.transactionData[0].monthTotal ? (
              <div className="e-estimate">
                Total estimate{" "}
                <strong>
                  {this.transactionData[0].sectionTotal
                    ? new Utilities().ThousandSeparator({
                        centsAmount: this.transactionData[0].sectionTotal!,
                        convertToRands: true
                      })
                    : 0}
                </strong>
              </div>
            ) : null}
          </div>
        ) : null}
        {// Show total month expenses
        this.transactionData[index].monthTotal &&
        this.transactionData[index].type === TransactionType.Planned ? (
          <div className="b-tile-month-divider">
            <div className="e-month">
              {this.transactionData[index].date
                ? moment(this.transactionData[index].date).format("MMMM")
                : DateTypes.Upcoming}
            </div>
            <div className="e-estimate">
              Estimate{" "}
              <strong>
                {this.defaultKinCurrencySybmol}
                {this.transactionData[index].monthTotal
                  ? new Utilities().ThousandSeparator({
                      centsAmount: this.transactionData[index].monthTotal!
                    })
                  : 0}
              </strong>
            </div>
          </div>
        ) : null}
        {// Show upcoming transaction first
        this.transactionData[index].type === TransactionType.Planned ? (
          <>
            {this.transactionData[index].dateLabel ? (
              <div key={index + "_label"} className={dividerClasses}>
                {dividerLabel}
                {this.transactionData[index].dateLabel}
              </div>
            ) : null}
            {this.renderPlannedTransactionTile(
              this.transactionData[index],
              this.transactionData[index].id,
              true
            )}
          </>
        ) : null}
        {// Show Paid expenses total label
        (index ===
          _.findLastIndex(this.transactionData, [
            "type",
            TransactionType.Planned
          ]) ||
          (index === 0 &&
            _.findLastIndex(this.transactionData, [
              "type",
              TransactionType.Planned
            ]) === -1)) &&
        (_.findLastIndex(this.transactionData, [
          "type",
          TransactionType.Paid
        ]) !== -1 ||
          _.findLastIndex(this.transactionData, [
            "type",
            TransactionType.Settle
          ]) !== -1) ? (
          <div className="b-type-divider">
            <div className="e-type">Paid</div>
            {// Don't display total if there is only one month
            this.transactionData[
              this.nextIndex(this.transactionData.length, index)
            ].sectionTotal !==
            this.transactionData[
              this.nextIndex(this.transactionData.length, index)
            ].monthTotal ? (
              <div className="e-estimate">
                Total spent by this Kin{" "}
                <strong>
                  {this.defaultKinCurrencySybmol}
                  {this.transactionData[
                    this.nextIndex(this.transactionData.length, index)
                  ].sectionTotal
                    ? new Utilities().ThousandSeparator({
                        centsAmount: this.transactionData[
                          this.nextIndex(this.transactionData.length, index)
                        ].sectionTotal!,
                        convertToRands: true
                      })
                    : 0}
                </strong>
              </div>
            ) : null}
          </div>
        ) : null}
        {// Show total month expenses for paid and settle transactions
        this.transactionData[index].monthTotal !== undefined &&
        (this.transactionData[index].type === TransactionType.Paid ||
          this.transactionData[index].type === TransactionType.Settle) ? (
          <div className="b-tile-month-divider">
            <div className="e-month">
              {this.transactionData[index].date
                ? moment(this.transactionData[index].date).format("MMMM")
                : DateTypes.Upcoming}
            </div>
            <div className="e-estimate">
              Spent by this Kin{" "}
              <strong>
                {this.defaultKinCurrencySybmol}
                {this.transactionData[index].monthTotal
                  ? new Utilities().ThousandSeparator({
                      centsAmount: this.transactionData[index].monthTotal!
                    })
                  : 0}
              </strong>
            </div>
          </div>
        ) : null}
        {// Show remaining transactions
        this.transactionData[index].type === TransactionType.Paid ? (
          <>
            {this.transactionData[index].dateLabel ? (
              <div key={index + "_label"} className={dividerClasses}>
                {this.transactionData[index].dateLabel}
              </div>
            ) : null}
            {this.renderPaidTransactionTile(
              this.transactionData[index],
              index,
              true
            )}
          </>
        ) : null}
        {this.transactionData[index].type === TransactionType.Settle ? (
          <>
            {this.transactionData[index].dateLabel ? (
              <div key={index + "_label"} className={dividerClasses}>
                {this.transactionData[index].dateLabel}
              </div>
            ) : null}
            {this.renderSettleTransactionTile(
              this.transactionData[index],
              index
            )}
          </>
        ) : null}
      </React.Fragment>
    );
  }

  /**
   * Render Settle tiles of remaining members with no interaction with current user
   *
   * @param {string[]} interactedMembers
   * @returns
   * @memberof Group
   */
  renderRemainingSettleTiles(
    interactedMembers: string[],
    noLabel: boolean,
    parentIndex: number
  ) {
    // List all member IDs
    let nonInteractedMembers: string[] = this.members
      .filter(member => member.status !== GroupMembersStatus.InActive)
      .map(member => {
        return member.id;
      });
    // Remove interacted with member IDs
    interactedMembers.forEach(memberId => {
      _.pull(nonInteractedMembers, memberId);
    });
    // Remove current user ID
    _.pull(nonInteractedMembers, this.currentUserId);
    return nonInteractedMembers.map((member, index) => (
      <React.Fragment key={"remainingSettle_" + parentIndex}>
        {nonInteractedMembers.length !== 0 && index === 0 && !noLabel ? (
          <div className="b-tile-divider">{SettleType.Settled}</div>
        ) : null}
        <SettleTile
          key={parentIndex + "_" + index}
          user={{
            ...new KinHelper().findGroupMemberByUserId(this.members, member),
            size: 44,
            paid: "Settled"
          }}
          type={TileType.NotInvolved}
        />
      </React.Fragment>
    ));
  }

  /**
   * Render Settle feed tile
   *
   * @param {string} dataFilter
   * @param {(IGroupQuerySettleData | undefined)} settleData
   * @returns
   * @memberof Group
   */
  renderSettleTile(dataFilter: string, settleData: IGroupQuerySettleData) {
    let interactedMembers: string[] = [];
    let settledLabelDisplayed: boolean = false;
    // Loop through transaction and collect any member that have interacted with current user
    settleData.memberToMemberBalance.forEach(entry => {
      switch (this.currentUserId) {
        case entry.toMemberId:
          // Add member as has been interacted with
          interactedMembers.push(entry.fromMemberId);
          break;
        case entry.fromMemberId:
          // Add member as has been interacted with
          interactedMembers.push(entry.toMemberId);
          break;
      }
    });
    // Remove any duplicate member transaction IDs
    interactedMembers = _.uniq(interactedMembers);
    let settleDataLength = settleData.memberToMemberBalance.length;
    return settleData.memberToMemberBalance.map((entry, index) => {
      // If the current user needs to pay
      if (
        entry.fromMemberId === this.currentUserId &&
        dataFilter === SettleType.Owe &&
        entry.amount.cents !== 0
      ) {
        return (
          <SettleTile
            key={"settleTile_" + index}
            user={{
              ...new KinHelper().findGroupMemberByUserId(
                this.members,
                entry.toMemberId
              ),
              size: 44,
              paid: this.props.currenciesStore!.createAmount(entry.amount)
                .formatted
            }}
            onClick={() => this.setPayment(entry.toMemberId, entry.amount)}
            type={TileType.Owe}
          />
        );
      }
      // If the current user is owed
      if (
        entry.toMemberId === this.currentUserId &&
        dataFilter === SettleType.Owed &&
        entry.amount.cents !== 0
      ) {
        return (
          <SettleTile
            key={"settleTile_" + index}
            user={{
              ...new KinHelper().findGroupMemberByUserId(
                this.members,
                entry.fromMemberId
              ),
              size: 44,
              paid: this.props.currenciesStore!.createAmount(entry.amount)
                .formatted
            }}
            onClick={() => this.remindPayment(entry.fromMemberId, entry.amount)}
            type={TileType.Owed}
          />
        );
      }
      // If the current user is settled
      if (
        (entry.toMemberId === this.currentUserId ||
          entry.fromMemberId === this.currentUserId) &&
        dataFilter === SettleType.Settled &&
        entry.amount.cents === 0
      ) {
        let user: IMemberPaid = {
          name: "",
          image: "",
          size: 44
        };
        switch (this.currentUserId) {
          case entry.toMemberId:
            user = {
              ...new KinHelper().findGroupMemberByUserId(
                this.members,
                entry.fromMemberId
              ),
              size: 44,
              paid: "Settled"
            };
            break;
          case entry.fromMemberId:
            user = {
              ...new KinHelper().findGroupMemberByUserId(
                this.members,
                entry.toMemberId
              ),
              size: 44,
              paid: "Settled"
            };
            break;
        }
        return (
          <React.Fragment key={"settleTile_" + index}>
            {!settledLabelDisplayed ? (
              <div className="b-tile-divider">{SettleType.Settled}</div>
            ) : null}
            {(settledLabelDisplayed = true)}
            <SettleTile user={user} type={TileType.NotInvolved} />
          </React.Fragment>
        );
      }
      if (dataFilter === SettleType.Settled && index === settleDataLength - 1) {
        return this.renderRemainingSettleTiles(
          interactedMembers,
          settledLabelDisplayed,
          index
        );
      }
      return true;
    });
  }

  /**
   * Render the Settle feed tab
   *
   * @returns
   * @memberof Group
   */
  renderSettleFeed(settleData: IGroupQuerySettleData) {
    let settleTypes: string[] = [];
    // Create labels for settle tab
    settleData.memberToMemberBalance.forEach(entry => {
      // If the current user needs to pay
      if (entry.fromMemberId === this.currentUserId) {
        if (entry.amount.cents === 0) {
          // If the amount has already been settled
          settleTypes[2] = SettleType.Settled;
        } else {
          settleTypes[1] = SettleType.Owe;
        }
      }
      // If the current user is owed
      if (entry.toMemberId === this.currentUserId) {
        if (entry.amount.cents === 0) {
          // If the amount has already been settled
          settleTypes[2] = SettleType.Settled;
        } else {
          settleTypes[0] = SettleType.Owed;
        }
      }
    });
    // All settled, Owes you, You owe
    // Remove empty array items labels
    settleTypes = _.remove(settleTypes, entry => entry);
    // If there are only 1 or 2 labels it means "Net Settle" feature
    // is active, if there are 0 labels, then the user is new to a group.
    // Then manually add the "All Settled" label
    if (
      (settleTypes.length === 1 && settleTypes[0] !== SettleType.Settled) ||
      (settleTypes.length === 2 && settleTypes[1] !== SettleType.Settled) ||
      (settleTypes.length === 0 &&
        settleData.memberToMemberBalance.length !== 0)
    ) {
      settleTypes.push(SettleType.Settled);
    }
    // Render date headings with tiles underneath
    return settleTypes.map((label, index) => {
      return (
        <React.Fragment key={"settleFeed_" + index}>
          {label !== SettleType.Settled ? (
            <div className="b-tile-divider">{label}</div>
          ) : null}
          {this.renderSettleTile(label, settleData)}
        </React.Fragment>
      );
    });
  }

  /**
   * Renders the final component markup
   *
   * @returns
   * @memberof Group
   */
  render() {
    return (
      <LayoutSidebar
        showNavBar={false}
        menuItems={menuItems}
        menuActiveItem={menuItems[0].title}
        noticeState={this.noticeState}
        noticeMsg={this.noticeMsg}
        errorState={this.error}
        errorMsg={this.errorMsg}
      >
        <div className="b-group-page">
          <Modal
            center={true}
            onClick={() => {
              this.expenseSplitMode
                ? this.props.kinStore!.setExpenseSplitMode(false)
                : this.props.kinStore!.setExpenseModal(
                    false,
                    this.defaultKinCurrency
                  );
            }}
            visible={this.expenseModal}
            className={`m-modal-short ${
              this.expenseSplitMode ? "m-modal-back" : ""
            }`}
          >
            {this.expenseModal ? <ExpenseModalContent /> : null}
          </Modal>
          <Modal
            center={true}
            onClick={() => this.props.kinStore!.setPaymentModal(false)}
            visible={this.paymentModal}
          >
            {this.payment !== undefined && !this.confirmedPayment ? (
              <PaymentModalContent
                users={this.payment.users}
                transaction={this.payment.transaction}
              />
            ) : null}
            {this.confirmedPayment ? (
              <PaymentAdded paying={this.payment!.transaction!.paying} />
            ) : null}
          </Modal>
          <Modal
            center={true}
            onClick={() => this.props.kinStore!.setReminderModal(false)}
            visible={this.reminderModal}
          >
            {this.reminderOptionsModal ? (
              <ReminderOptions user={this.reminderUser} />
            ) : null}
            {this.reminderShareGhostModal ? (
              <ReminderShareGhost user={this.reminderUser} />
            ) : null}
            {this.reminderShareUserModal ? (
              <ReminderShare user={this.reminderUser} />
            ) : null}
            {this.confirmedReminder ? (
              <ReminderSent name={this.reminderUser.name} />
            ) : null}
          </Modal>
          <Modal
            className="e-group-modal"
            onClick={() => this.props.kinStore!.setLeaveOkay(false)}
            visible={this.leaveOkay}
          >
            <h3>Leave this Kin?</h3>
            <p className="m-red">Are you sure?</p>
            <p>
              You won't be able to join this Kin again unless someone invites
              you.
            </p>
            <Button
              color={ButtonColors.Black}
              className="e-modal-btn"
              loading={this.leaving}
              onClick={() => this.props.kinStore!.leaveKin()}
              small={true}
              inline={true}
            >
              Yes, leave
            </Button>
            <p
              className="g-a m-no-underline"
              onClick={() => this.props.kinStore!.setLeaveOkay(false)}
            >
              Cancel
            </p>
          </Modal>
          <Modal
            className="e-group-modal"
            onClick={() => this.props.kinStore!.setLeaveOnlyMember(false)}
            visible={this.leaveOnlyMember}
          >
            <h3>Leave this Kin?</h3>
            <p className="m-red">Are you sure?</p>
            <p>
              You're the last member of this Kin, so if you leave it means
              nobody will be able to access it again.
            </p>
            <Button
              color={ButtonColors.Black}
              className="e-modal-btn"
              loading={this.leaving}
              onClick={() => this.props.kinStore!.leaveKin()}
              small={true}
              inline={true}
            >
              Yes, leave
            </Button>
            <p
              className="g-a m-no-underline"
              onClick={() => this.props.kinStore!.setLeaveOnlyMember(false)}
            >
              Cancel
            </p>
          </Modal>
          <Modal
            className="e-group-modal"
            onClick={() =>
              this.props.kinStore!.setLeaveOutstandingBalance(false)
            }
            visible={this.leaveOutstandingBalance}
          >
            <h3>Unfortunately you can't leave this Kin right now</h3>
            <p className="m-red">You still have outstanding balances.</p>
            <p>
              You can only leave a Kin if you don't have outstanding balances or
              expenses.
            </p>
            <Button
              color={ButtonColors.Black}
              className="e-modal-btn"
              loading={this.leaving}
              onClick={() =>
                this.props.kinStore!.setLeaveOutstandingBalance(false)
              }
              small={true}
              inline={true}
            >
              Okay
            </Button>
          </Modal>
          {this.loading ? (
            <KinLoader
              style={{
                maxWidth: "500px",
                margin: "24px auto",
                padding: "0 24px"
              }}
              active={this.loading}
            />
          ) : (
            <PageModal>
              <Button
                className="e-expense-button"
                onClick={() => this.addExpense()}
                color={ButtonColors.Black}
                small={true}
                inline={true}
                icon={plusIcon}
                iconCircle={false}
              >
                New expense
              </Button>
              <Tabs
                defaultIndex={this.defaultTab}
                className="b-tabs"
                onSelect={index =>
                  new Segment().Track(
                    `kin_${new Segment().FormatEventName(tabNames[index])}`
                  )
                }
              >
                <PageModalHead
                  membersList={this.mapMembersToAvatarList(this.members)}
                  image={this.groupImage}
                  title={this.groupName}
                >
                  <div className="e-container">
                    <TabList className="e-tabs-list">
                      <Tab
                        className="e-tab"
                        disabledClassName="m-disabled"
                        selectedClassName="m-selected"
                      >
                        {tabNames[0]}
                      </Tab>
                      <Tab
                        className="e-tab"
                        disabledClassName="m-disabled"
                        selectedClassName="m-selected"
                      >
                        {tabNames[1]}
                      </Tab>
                    </TabList>
                  </div>
                </PageModalHead>
                <PageModalBody id="kin-body">
                  <TabPanel
                    className="e-tabs-panel e-container"
                    selectedClassName="m-selected"
                  >
                    <ReactList
                      itemRenderer={index => this.renderTransactionFeed(index)}
                      length={this.transactionData.length}
                      type="simple"
                      threshold={200}
                      useTranslate3d={true}
                    />
                    {this.transactionData.length === 0 ? (
                      <>
                        <TransactionsSVG />
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "40px",
                            marginBottom: "10px",
                            fontSize: "14px"
                          }}
                        >
                          Track what you've spent or plan to spend
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "0",
                            fontSize: "20px"
                          }}
                        >
                          <strong>Rent? Meals? Holidays?</strong>
                        </p>
                      </>
                    ) : null}
                  </TabPanel>
                  <TabPanel
                    className="e-tabs-panel e-container"
                    selectedClassName="m-selected"
                  >
                    {this.renderSettleFeed(this.settleData)}
                    {this.settleData.memberToMemberBalance.length === 0 ? (
                      <>
                        <SettleSVG />
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "40px",
                            marginBottom: "10px",
                            fontSize: "14px"
                          }}
                        >
                          Keep a running balance
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "0",
                            fontSize: "20px"
                          }}
                        >
                          <strong>Settle in or out of app</strong>
                        </p>
                      </>
                    ) : null}
                  </TabPanel>
                </PageModalBody>
              </Tabs>
            </PageModal>
          )}
        </div>
      </LayoutSidebar>
    );
  }
}

export default Kin;

/**
 * Kin helper function to return kin user details by ID
 *
 * @export
 * @class KinHelper
 */
export class KinHelper {
  /**
   * Return user object based on ID
   *
   * @param {(string | undefined)} userId
   * @returns {IGroupMember} User object
   * @memberof Group
   */
  public findGroupMemberByUserId(
    members: IGroupMember[] | undefined,
    userId: string | undefined
  ): IGroupMember | IMemberPaid {
    // Set default member data
    let member: IGroupMember = {
      id: "",
      name: "",
      image: ""
    };
    // Assign member data to matched user ID
    (members as IGroupMember[]).forEach(user => {
      if (user.id === userId) {
        member = user;
      }
    });
    return member;
  }
}
