import React from "react";
import Avatar from "../avatar/avatar";
import AvatarList from "../avatar-list/avatar-list";

import "./group.scss";
import { IGroupMembers } from "../../stores/kinsStore";
import { stores } from "../../stores";

/**
 * Group tile balance interface
 *
 * @interface IGroupTileBalance
 */
interface IGroupTileBalance {
  cents: number;
  currencyIso: string;
  formatted: string;
}

/**
 * Group tile props
 *
 * @interface IGroupTileProps
 */
interface IGroupTileProps {
  image: string;
  name: string;
  hidden: boolean;
  balance: IGroupTileBalance | boolean;
  members: IGroupMembers[];
  onClick?: () => void;
}

/**
 * Adds the avatar size to the group being displayed
 *
 * @param {any[]} members
 * @returns
 */
const formattedUsers = (members: any[]) => {
  members.forEach(member => {
    member.size = 22;
  });
  return members;
};

/**
 * Format string of amount owed/owing on a kin
 *
 * @param {number} cents
 * @returns
 */
const formatAmount = (balance: IGroupTileBalance) => {
  if (balance.cents > 0) {
    return (
      <>
        <span className="e-balance-type m-owed">
          {stores.currenciesStore!.formatAmountWithSymbol(balance)}
        </span>{" "}
        is owed to you
      </>
    );
  }
  if (balance.cents < 0) {
    return (
      <>
        you owe{" "}
        <span className="e-balance-type m-owe">
          {stores.currenciesStore!.formatAmountWithSymbol(balance)}
        </span>
      </>
    );
  }
  if (balance.cents === 0) {
    return `you're all square`;
  }
};

/**
 * Component to render the group tile
 *
 * @param {*} props
 * @returns
 */
const GroupTile: React.FC<IGroupTileProps> = props => {
  let tileClasses: string = "b-group-tile";
  if (props.onClick) {
    tileClasses += " m-linked";
  }
  return (
    <div
      className={tileClasses}
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      <Avatar image={props.image} name={props.name} size={80} />
      <div className="e-group-details">
        <div className="e-group-name">{props.name}</div>
        <AvatarList
          maxAvatars={5}
          spaced={true}
          initialSize={12}
          users={formattedUsers(props.members)}
        />
        <div className="e-group-balance">
          {!props.balance
            ? "No one else here yet..."
            : formatAmount(props.balance as IGroupTileBalance)}
        </div>
      </div>
    </div>
  );
};

export default GroupTile;
