import React from "react";

import "./autocomplete.scss";
import Input from "./input";

import arrowIcon from "../../images/chevron-down.svg";

/**
 * Autocomplete item interface
 *
 * @export
 * @interface IAutocompleteList
 */
export interface IAutocompleteList {
  value: string;
  title: string;
}

/**
 * Autocomplete props interface
 *
 * @interface IAutocompleteProps
 */
interface IAutocompleteProps {
  value?: string;
  placeholder?: string;
  className?: string;
  error?: boolean;
  errorMsg?: string;
  list: IAutocompleteList[];
  onChange?: (value: string) => void;
}

/**
 * Autocomplete open state and value. Including current selected item in dropdown
 *
 * @interface IAutocompleteState
 */
interface IAutocompleteState {
  open?: boolean;
  value?: string;
  update?: boolean;
  active?: string;
}

/**
 * Component for creating the dropdown element
 *
 * @class Autocomplete
 * @extends {React.Component<IAutocompleteProps, IAutocompleteState>}
 */
class Autocomplete extends React.Component<IAutocompleteProps, IAutocompleteState> {
  /**
   *Creates an instance of Autocomplete.
   * @param {IAutocompleteProps} props
   * @memberof Autocomplete
   */
  constructor(props: IAutocompleteProps) {
    super(props);
    // Set the default state. If there is a value prop, update otherwise display 'fetching....'
    this.state = {
      open: false,
      value: this.props.value ? this.findTitleById(this.props.value) : "Fetching...",
      update: true,
      active: undefined
    };
  }

  /**
   * Find the readible title by item value, e.g the id
   *
   * @param {string} id
   * @returns
   * @memberof Autocomplete
   */
  findTitleById(id: string) {
    let title: string = "";
    this.props.list.forEach(item => {
      if (item.value === id) {
        title = item.title;
      }
    });
    return title;
  }

  /**
   * Find the item value (ID) by using the readible title.
   * !!!THIS ONLY WORKS WITH NON-DUPLICATE ITEM TITLES!!!
   *
   * @param {string} title
   * @returns
   * @memberof Autocomplete
   */
  findIdByTitle(title: string) {
    let id: string = "";
    this.props.list.forEach(item => {
      if (item.title === title) {
        id = item.value;
      }
    });
    return id;
  }

  /**
   * update component state when prop changes.
   * This is a workaround for having an internal state that gets out of sync
   *
   * @param {IAutocompleteProps} prevProps
   * @memberof Autocomplete
   */
  componentDidUpdate(prevProps: IAutocompleteProps) {
    if (this.findTitleById(prevProps.value!) !== "" || this.props.list.length !== 0) {
      if (this.state.update) {
        this.setState({
          value: this.findTitleById(this.props.value!),
          update: false
        });
      }
    }
  }

  /**
   * Set state value to selected item and close dropdown
   *
   * @param {string} id
   * @memberof Autocomplete
   */
  updateValue(id: string) {
    if (this.props.onChange) {
      this.props.onChange(id);
    }
    this.setState({
      open: false,
      value: this.findTitleById(id)
    });
  }

  /**
   * Keep dropdown open while searching for a value
   *
   * @param {string} value
   * @memberof Autocomplete
   */
  searchValue(value: string) {
    if (this.props.onChange) {
      this.props.onChange(this.findIdByTitle(value));
    }
    this.setState({
      open: true,
      value: value,
      active: undefined
    });
  }

  /**
   * Keep dropdown open for a few miliseconds so click event on item can register
   * when focus is lost form the input
   *
   * @param {boolean} state
   * @memberof Autocomplete
   */
  showAutocomplete(state: boolean) {
    // Delay hack to wait for focus to trigger
    setTimeout(() => {
      this.setState({
        open: state
      });
    }, 10);
  }

  /**
   * Filter the dropdown list to what matches the searching value
   *
   * @returns {IAutocompleteList[]}
   * @memberof Autocomplete
   */
  getFilteredResults(): IAutocompleteList[] {
    return this.props.list.filter(item => this.matchesItem(item));
  }

  /**
   * Check if searching value in lowercase to matches any part of the
   * dropdown list values in lowercase
   *
   * @param {IAutocompleteList} item
   * @returns
   * @memberof Autocomplete
   */
  matchesItem(item: IAutocompleteList) {
    return item.title.toLowerCase().includes(this.state.value!.toLowerCase());
  }

  /**
   * Get the index relative to the dropdown list of the current value
   *
   * @param {string} value
   * @returns {number}
   * @memberof Autocomplete
   */
  getIndexByValue(value: string): number {
    let itemIndex: number = 0;
    this.getFilteredResults().forEach((item, index) => {
      if (item.value === value) {
        itemIndex = index;
      }
    });
    return itemIndex;
  }

  /**
   * Render the dropdown item
   *
   * @param {IAutocompleteList[]} filteredResults
   * @param {IAutocompleteList} item
   * @param {number} index
   * @param {boolean} [viewAll=false]
   * @returns
   * @memberof Autocomplete
   */
  renderAutocompleteItem(
    filteredResults: IAutocompleteList[],
    item: IAutocompleteList,
    index: number,
    viewAll: boolean = false
  ) {
    let itemClasses: string = "e-dropdown-item";
    // Only add modifier classes when you are viewing a filtered list
    if (!viewAll) {
      // IF the value is a single item or matches a searching value, add the
      // active class for visual reference
      if (filteredResults.length === 1 || item.value === this.state.active) {
        itemClasses += " m-active";
      }
    }
    return (
      <div key={index} onClick={() => this.updateValue(item.value)} className={itemClasses}>
        {item.title}
      </div>
    );
  }

  /**
   * Render the dropdown items list
   *
   * @returns
   * @memberof Autocomplete
   */
  renderAutocompleteItems() {
    let filteredResults: IAutocompleteList[] = this.getFilteredResults();
    let hasResults: boolean = filteredResults.length >= 1;
    // Show entire dropdown list if no filtered results are found
    if ((this.state.open && this.props.value !== "") || !hasResults) {
      return this.props.list.map((item, index) => {
        return this.renderAutocompleteItem(filteredResults, item, index, true);
      });
    }
    // Show only the filtered results matching the search value
    if (hasResults) {
      return this.props.list.map((item, index) => {
        if (this.matchesItem(item)) {
          return this.renderAutocompleteItem(filteredResults, item, index);
        }
        return null;
      });
    }
    // Show fetching when there are no dropdown list items,
    // Used when the dropdown list is async
    if (this.props.list.length === 0) {
      return <div className="e-dropdown-item">Fetching...</div>;
    }
  }

  /**
   * Handle pressing enter events on the input
   *
   * @param {React.KeyboardEvent} event
   * @memberof Autocomplete
   */
  handleEnter(event: React.KeyboardEvent) {
    if (event.charCode === 13) {
      // If there is only one result, select that value
      if (this.getFilteredResults().length === 1) {
        let itemValue: string = this.getFilteredResults()[0].value;
        this.updateValue(itemValue);
      }
      // Select the item navigated to by the arrow keys
      if (this.state.active !== undefined) {
        this.updateValue(this.state.active);
      }
    }
  }

  /**
   * Move highlighted item down
   *
   * @returns
   * @memberof Autocomplete
   */
  moveDownActiveItem() {
    let lastItemIndex: number = this.getFilteredResults().length - 1;
    //If no selection is made yet
    if (this.state.active === undefined) {
      //Go to first item
      return this.getFilteredResults()[0].value;
    } else {
      let nextItemIndex: number = this.getIndexByValue(this.state.active);
      //Check if you can go next, then go next
      if (nextItemIndex + 1 <= lastItemIndex) {
        return this.getFilteredResults()[nextItemIndex + 1].value;
      } else {
        //If you can't go next make last item active
        return this.getFilteredResults()[nextItemIndex].value;
      }
    }
  }

  /**
   * Move highlighted item up
   *
   * @returns
   * @memberof Autocomplete
   */
  moveUpActiveItem() {
    let lastItemIndex: number = this.getFilteredResults().length - 1;
    //If no selection is made yet
    if (this.state.active === undefined) {
      //Go to last item
      return this.getFilteredResults()[lastItemIndex].value;
    } else {
      //Check if you can go previous, then go previous
      let prevItemIndex: number = this.getIndexByValue(this.state.active);
      if (prevItemIndex - 1 >= 0) {
        return this.getFilteredResults()[prevItemIndex - 1].value;
      } else {
        //If you can't go previous make first item active
        return this.getFilteredResults()[0].value;
      }
    }
  }

  /**
   * Smoothly scroll to highlighted item when it's out of view in the dropdown list
   *
   * @memberof Autocomplete
   */
  scrollToActiveItem() {
    document.getElementsByClassName("e-dropdown-item m-active")[0].scrollIntoView({
      behavior: "smooth"
    });
  }

  /**
   * Handle arrow key (up and down) events on the input
   *
   * @param {React.KeyboardEvent} event
   * @memberof Autocomplete
   */
  handleArrows(event: React.KeyboardEvent) {
    if (this.getFilteredResults().length > 1) {
      // Pressing the Down arrow
      if (event.keyCode === 40) {
        event.preventDefault();
        this.setState(
          {
            active: this.moveDownActiveItem()
          },
          () => {
            this.scrollToActiveItem();
          }
        );
      }
      // Pressing the Up arrow
      if (event.keyCode === 38) {
        event.preventDefault();
        this.setState(
          {
            active: this.moveUpActiveItem()
          },
          () => {
            this.scrollToActiveItem();
          }
        );
      }
    }
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof Autocomplete
   */
  render() {
    let dropdownClasses: string = "b-autocomplete";
    if (this.props.className) {
      dropdownClasses += ` ${this.props.className}`;
    }
    return (
      <div className={dropdownClasses} tabIndex={-1}>
        <Input
          type="text"
          onChange={event => this.searchValue(event)}
          onFocus={() => this.showAutocomplete(true)}
          disabled={this.state.update}
          placeholder={this.props.placeholder}
          value={this.state.value!}
          small={true}
          className="e-value"
          onKeyPress={event => this.handleEnter(event)}
          onKeyDown={event => this.handleArrows(event)}
          error={this.props.error}
          errorMsg={this.props.errorMsg}
          noBorderStyle={true}
        />
        <img
          onClick={() => this.showAutocomplete(!this.state.open)}
          className={this.state.open ? "e-arrow m-open" : "e-arrow"}
          src={arrowIcon}
          alt="Autocomplete Arrow"
        />
        <div className={this.state.open ? "e-dropdown m-open" : "e-dropdown"}>
          {this.renderAutocompleteItems()}
        </div>
      </div>
    );
  }
}

export default Autocomplete;
