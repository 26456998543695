import React from "react";
import Avatar, { IAvatarProps } from "../avatar/avatar";
import "./avatar-list.scss";

/**
 * Avatar list props
 *
 * @interface IAvatarListProps
 */
interface IAvatarListProps {
  users: IAvatarProps[];
  spaced?: boolean;
  initialSize?: number;
  maxAvatars?: number;
  borderWidth?: number;
}

/**
 * Avatar list component for display a group of avatars next to each other
 *
 * @param {*} props
 * @returns
 */
const AvatarList: React.FC<IAvatarListProps> = props => {
  // This makes sure that when you only want to display 5
  // and there are only 5 members, it's doesn't display 4
  // with +1
  let totalAvatarsDisplayed: number = props.maxAvatars as number;
  if (props.users.length > (props.maxAvatars as number)) {
    totalAvatarsDisplayed = (props.maxAvatars as number) - 1;
  }

  const remainingAvatars: number = props.users.length - totalAvatarsDisplayed;
  let avatarClass: string = "e-avatar";
  if (props.spaced) {
    avatarClass += " m-spaced";
  }
  return (
    <ul className="b-avatar-list">
      {props.users.map((user, index) => {
        let userElement = null;
        if (index < totalAvatarsDisplayed) {
          userElement = (
            <li className={avatarClass} key={index}>
              <Avatar
                image={user.image}
                initial={user.initial}
                name={user.name}
                size={user.size}
                initialSize={props.initialSize}
                borderWidth={props.borderWidth}
              />
            </li>
          );
        }
        return userElement;
      })}
      {remainingAvatars > 0 ? (
        <li className={avatarClass + " m-pushed"}>
          <Avatar
            size={props.users[0].size}
            moreCount={remainingAvatars}
            name={`+${remainingAvatars.toString()}`}
            initialSize={props.initialSize}
            borderWidth={props.borderWidth}
            isMore={true}
          />
        </li>
      ) : null}
    </ul>
  );
};

AvatarList.defaultProps = {
  maxAvatars: 3
};

export default AvatarList;
