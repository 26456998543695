import React from "react";

import "./new-group.scss";

/**
 * New group tile props
 *
 * @interface INewGroup
 */
interface INewGroup {
  title: string;
  ctaTitle: string;
  imageUrl: string;
  onClick?: () => void;
}

/**
 * Creates a new kin tile component
 *
 * @param {*} props
 * @returns
 */
const NewGroupTile: React.FC<INewGroup> = props => {
  return (
    <div
      className="b-new-group-tile"
      style={{
        backgroundImage: `url(${props.imageUrl})`
      }}
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      <div className="e-title">{props.title}</div>
      <div className="e-cta">{props.ctaTitle}</div>
    </div>
  );
};

export default NewGroupTile;
