import React from "react";

import "./reminders.scss";
import Button, { ButtonColors } from "../button/button";

import { IKinStore } from "../../stores/kinStore";
import { inject, observer } from "mobx-react";
import Lottie from "react-lottie";

import animationData from "../../images/kin_success_check_lottie.json";

/**
 * Payment added props
 *
 * @interface IPaymentAddedProps
 */
interface IPaymentAddedProps {
  paying?: boolean;
  kinStore?: IKinStore;
}

/**
 * Component modal for payment confirmation
 *
 * @class PaymentAdded
 * @extends {React.Component<IPaymentAddedProps, {}>}
 */
@inject("kinStore")
@observer
class PaymentAdded extends React.Component<IPaymentAddedProps, {}> {
  /**
   * Default render method
   *
   * @returns
   * @memberof PaymentAdded
   */
  render() {
    return (
      <div className="b-reminder-modal">
        <div className={this.props.paying ? "e-img-icon m-small" : "e-img-icon m-reminder m-small"}>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            height={200}
            width={200}
          />
        </div>
        <div className="g-h5">Payment added</div>
        <div className="g-h6 e-reminder-description">We've updated your balances.</div>
        <Button
          className="e-reminder-btn"
          color={this.props.paying ? ButtonColors.Primary : ButtonColors.Secondary}
          small={true}
          onClick={() => this.props.kinStore!.setPaymentModal(false)}
        >
          Done
        </Button>
      </div>
    );
  }
}

export default PaymentAdded;
