import React from "react";
import Checkbox from "../form/checkbox";

import "./expense-user.scss";

import editIcon from "../../images/edit.svg";
import Input from "../form/input";
import { IAmount } from "../../stores/peopleStore";
import { inject } from "mobx-react";
import { ICurrenciesStore } from "../../stores/currenciesStore";

/**
 * Expense user props interface
 *
 * @interface IExpenseUserProps
 */
interface IExpenseUserProps {
  name: string;
  amount: IAmount;
  checked?: boolean;
  edited?: boolean;
  disabled?: boolean;
  onSelect: (selection?: boolean) => void;
  onChange: (value: string) => void;
  currencySymbol: string;
  currenciesStore?: ICurrenciesStore;
}

/**
 * Internal User state for setting focus on the input
 *
 * @interface IExpenseUserState
 */
interface IExpenseUserState {
  editing: boolean;
}

@inject("currenciesStore")
class ExpenseUser extends React.Component<
  IExpenseUserProps,
  IExpenseUserState
> {
  /**
   * Default Expense user props
   *
   * @static
   * @memberof ExpenseUser
   */
  static defaultProps = {
    checked: false,
    edited: false
  };

  /**
   * Creates an instance of ExpenseUser.
   * @param {IExpenseUserProps} props
   * @memberof ExpenseUser
   */
  constructor(props: IExpenseUserProps) {
    super(props);
    this.state = {
      editing: false
    };
  }

  /**
   *
   *
   * @memberof ExpenseUser
   */
  editInputMode(value: string) {
    const cents = this.props
      .currenciesStore!.formattedStringToCents(value)
      .toString();

    // TODO: next
    this.props.onChange(cents);
    this.props.onSelect(true);
  }
  /**
   * Default render method
   *
   * @returns
   * @memberof ExpenseUser
   */
  render() {
    let expenseUserClasses: string = "b-expense-user";
    if (this.props.disabled) {
      expenseUserClasses += " m-disabled";
    }
    return (
      <div className={expenseUserClasses}>
        <div className="e-user-check">
          <Checkbox
            checked={this.props.checked}
            onClick={() => this.props.onSelect()}
          />
        </div>
        <div className="e-user-name">{this.props.name}</div>
        <div className="e-user-amount">
          <Input
            focus={this.state.editing}
            onBlur={() => this.setState({ editing: false })}
            type="text"
            small={true}
            className={
              this.props.edited ? "e-user-input m-edited" : "e-user-input"
            }
            noBorderStyle={true}
            value={this.props.currenciesStore!.formatAmountWithSymbol(
              this.props.amount
            )}
            onChange={value => this.editInputMode(value)}
            pattern="[0-9]*"
          />
        </div>
        <div
          className="e-user-edit"
          onClick={() =>
            this.setState({
              editing: true
            })
          }
        >
          <img src={editIcon} alt="Edit" />
        </div>
      </div>
    );
  }
}

export default ExpenseUser;
