import React from "react";
import KinGraphQL from "../../common/graphql";
import Layout from "../../hoc/layout";
import { RouteComponentProps } from "react-router";
import * as firebase from "firebase/app";
import "firebase/auth";

import "./profile-setup.scss";

import Input from "../../components/form/input";
import Button, { ButtonColors } from "../../components/button/button";
import ProfileSetupLoader from "./profile-setup-loader";
import { InviteType } from "../invite/invite";
import Zendesk from "../../common/zendesk";
import UrlHelper from "../../common/url-helper";
import { updatePageTitle } from "../../common/history";
import { Subscription } from "rxjs";
import Authentication from "../../common/authentication";
import Segment, { SegmentEvent } from "../../common/segment";
import Notices from "../../common/snackbars";
import {
  IProfileSetupStore,
  queryHasSignedUp,
  queryToSignUp,
  queryConnectionInvite,
  queryGroupInvite
} from "../../stores/profileSetupStore";
import { computed } from "mobx";
import { observer, inject } from "mobx-react";
import { queryAuthenticated } from "../../common/graphql-utils";

/**
 * Profile setup local state
 *
 * @interface IHasSignedUp
 */
interface IHasSignedUp {
  hasSignedUp: boolean;
  name: string;
  image: string;
  email: string;
}

/**
 * Profile setup store props
 *
 * @interface IProfileSetupProps
 * @extends {RouteComponentProps}
 */
interface IProfileSetupProps extends RouteComponentProps {
  profileSetupStore?: IProfileSetupStore;
}

@inject("profileSetupStore")
@observer
/**
 * Profile setup component for allowing the user to assign themselves a name when signing up through email
 *
 * @class ProfileSetup
 * @extends {React.Component<IProfileSetupProps, {}>}
 */
class ProfileSetup extends React.Component<IProfileSetupProps, {}> {
  /**
   * Subscription variable for checking if signed up
   *
   * @private
   * @type {Subscription}
   * @memberof ProfileSetup
   */
  private checkSignedUp: Subscription;

  /**
   * Subscription variable for signing up
   *
   * @private
   * @type {Subscription}
   * @memberof ProfileSetup
   */
  private signingUp: Subscription;

  /**
   * Storing invite id
   *
   * @private
   * @type {string}
   * @memberof ProfileSetup
   */
  private inviteIdScoped: string = "";

  /**
   * Creates an instance of ProfileSetup.
   * @param {RouteComponentProps} props
   * @memberof ProfileSetup
   */
  constructor(props: RouteComponentProps) {
    super(props);
    this.checkSignedUp = new Subscription();
    this.signingUp = new Subscription();
  }

  @computed get loading(): boolean {
    return this.props.profileSetupStore!.loading;
  }
  @computed get name(): string {
    return this.props.profileSetupStore!.name;
  }
  @computed get inviteId(): string {
    return this.props.profileSetupStore!.inviteId;
  }
  @computed get token(): string {
    return this.props.profileSetupStore!.token!;
  }
  @computed get signingUpUser(): boolean {
    return this.props.profileSetupStore!.signingUpUser;
  }
  @computed get signingUpError(): boolean {
    return this.props.profileSetupStore!.signingUpError;
  }
  @computed get bypassInvite(): boolean {
    return this.props.profileSetupStore!.bypassInvite;
  }
  @computed get errorState(): boolean {
    return this.props.profileSetupStore!.errorState;
  }

  /**
   * Shared error trigger error function
   *
   * @param {*} error
   * @memberof ProfileSetup
   */
  errorTrigger(error: any) {
    new Notices().GlobalTrigger(() => {
      this.props.profileSetupStore!.setErrorState(!this.errorState);
    });
    console.error(error);
  }

  /**
   * Fetch Token from Firebase
   *
   * @memberof ProfileSetup
   */
  componentDidMount() {
    // Get invite ID form URL
    this.inviteIdScoped = (this.props.match.params as any).inviteid;

    // Update page title
    updatePageTitle("Setup Profile");
    new Segment().Page(SegmentEvent.PAGE_PROFILE_SETUP);

    // Handle invite ID = 0 to bypass invite accept process
    if (parseFloat(this.inviteIdScoped) === 0) {
      this.props.profileSetupStore!.setBypassInvite(true);
    } else {
      this.props.profileSetupStore!.setInviteId(this.inviteIdScoped);
    }

    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("kin_emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(email as string, window.location.href)
        .then((result: any) => {
          // Clear email from storage.
          window.localStorage.removeItem("kin_emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          result.user
            .getIdToken(/* forceRefresh */ true)
            .then((idToken: any) => {
              // Send token to your backend via HTTPS
              this.props.profileSetupStore!.setToken(idToken);
              this.checkHasSignUp();
            })
            .catch((error: any) => {
              // Handle error
              this.errorTrigger(error);
            });
        })
        .catch((error: any) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          const ERROR_MSG: string =
            "Your login link has expired. Please log in again.";
          this.errorTrigger(error);
          this.props.history.push({
            pathname: "/sign-in",
            search: `?error=${ERROR_MSG}`
          });
        });
    } else {
      new Authentication().getToken().then(token => {
        let name: any = window.localStorage.getItem("kin_name");
        this.props.profileSetupStore!.setToken(token);
        this.props.profileSetupStore!.setName(name);
        this.checkHasSignUp();
      });
    }
  }

  /**
   * Clean up subscriptions
   *
   * @memberof ProfileSetup
   */
  componentWillUnmount() {
    this.checkSignedUp.unsubscribe();
    this.signingUp.unsubscribe();
  }

  /**
   * Check first if the user has been signed up before.
   *
   * @memberof ProfileSetup
   */
  checkHasSignUp() {
    let graphQuery = new KinGraphQL();
    this.checkSignedUp = graphQuery
      .queryFetchAuth(queryHasSignedUp(), this.token as string)
      .subscribe({
        next: result => {
          let userSignUp: IHasSignedUp = result.data.data.me;
          if (userSignUp != null) {
            if (!userSignUp.hasSignedUp) {
              // User has not yet signed up and wait for user name input
              this.props.profileSetupStore!.setLoading(false);
            } else {
              // Accept the invite automatically
              this.acceptInvite();
            }
          } else {
            this.errorTrigger(result);
          }
        },
        error: error => {
          this.errorTrigger(error);
        }
      });
  }

  /**
   * If a user has not been signed up before with email, sign them up now
   *
   * @memberof ProfileSetup
   */
  signUpUser(): Promise<any> {
    // Disable input and button loading
    this.props.profileSetupStore!.setSigningUpUser(true);
    const promise = queryAuthenticated(queryToSignUp(this.inviteId, this.name));
    // Submit name from input field and sign up the user
    promise.then(
      () => {
        // Send add_name event to Segment
        new Segment().Track(SegmentEvent.ADD_NAME);
        // Accept the invite automatically
        this.acceptInvite();
      },
      error => {
        this.props.profileSetupStore!.setSigningUpUser(false);
        this.props.profileSetupStore!.setSigningUpError(true);
        this.errorTrigger(error);
      }
    );
    return promise;
  }

  /**
   * After sign up automatically accept the invite or connection for the user
   *
   * @memberof ProfileSetup
   */
  acceptInvite() {
    if (this.bypassInvite) {
      this.redirect();
    } else {
      let inviteType: InviteType = window.localStorage.getItem(
        "kin_inviteType"
      ) as any;
      let queryType: string = "";

      // Handle group vs connection invite acceptance by updating the query first
      switch (inviteType) {
        case InviteType.Connection:
          queryType = queryConnectionInvite(this.inviteId);
          break;
        case InviteType.GroupLink:
          queryType = queryGroupInvite(this.inviteId);
          break;
        case InviteType.Member:
          queryType = queryGroupInvite(this.inviteId);
          break;
        default:
          queryType = queryConnectionInvite(this.inviteId);
      }
      // Accept invite query
      queryAuthenticated(queryType).then(
        () => this.redirect(),
        e => this.errorTrigger(e)
      );
    }
  }

  /**
   * Redirect user to Zendesk when signing up
   *
   * @memberof ProfileSetup
   */
  redirect() {
    const zendesk = new Zendesk();
    const queryParams = UrlHelper.getAppParametersFromCurrentUrl();
    if (zendesk.isZendeskAppId(queryParams.AppId)) {
      zendesk.authorize(
        this.token as string,
        queryParams.Redirect,
        (successData: any) => window.location.replace(successData),
        (failureData: any) => {
          this.errorTrigger(failureData);
        }
      );
    } else {
      // Redirect to groups dashboard
      this.props.history.push(`/home/kins`);
    }
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof ProfileSetup
   */
  render() {
    return (
      <Layout className="b-profile-setup" errorState={this.errorState}>
        <ProfileSetupLoader active={this.loading} />
        {!this.loading ? (
          <>
            <h1
              style={{
                marginBottom: "52px"
              }}
            >
              Set up your profile
            </h1>
            <Input
              style={{
                maxWidth: "437px",
                marginBottom: "24px"
              }}
              type="text"
              placeholder="Name"
              onChange={event => {
                this.props.profileSetupStore!.setName(event);
                this.props.profileSetupStore!.setSigningUpError(false);
              }}
              value={this.name}
              label="Enter your name"
              disabled={this.signingUpUser}
              error={this.signingUpError}
              errorMsg="Unable to sign if you user, please try again."
              focus={true}
            />
            <Button
              color={ButtonColors.Black}
              disabled={this.name === ""}
              onClick={() => this.signUpUser()}
              loading={this.signingUpUser}
            >
              Continue
            </Button>
          </>
        ) : null}
      </Layout>
    );
  }
}

export default ProfileSetup;
