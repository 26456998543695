import React from "react";
import "./transactions-empty.scss";

/**
 * Empty transaction feed SVG
 *
 * @export
 */
export const TransactionsSVG: React.FC = () => (
  <svg
    className="b-transactions-svg"
    xmlSpace="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="237"
    height="145"
    viewBox="0 0 237 145"
  >
    <defs>
      <linearGradient id="a" x1="13.997%" x2="85.033%" y1="13.866%" y2="89.291%">
        <stop offset="0%" stopColor="#173D6A" />
        <stop offset="100%" stopColor="#0D294A" />
      </linearGradient>
      <linearGradient id="b" x1="44.884%" x2="44.884%" y1="0%" y2="240.277%">
        <stop offset="0%" stopColor="#FFD7B9" />
        <stop offset="100%" stopColor="#C57D5E" />
      </linearGradient>
      <linearGradient id="c" x1="81.058%" x2="46.418%" y1="35.715%" y2="76.981%">
        <stop offset="0%" stopOpacity=".105" />
        <stop offset="100%" stopColor="#EB9154" />
      </linearGradient>
      <linearGradient id="d" x1="50%" x2="50%" y1="0%" y2="98.085%">
        <stop offset="0%" stopColor="#C9AEAC" />
        <stop offset="100%" stopColor="#765F5B" />
      </linearGradient>
      <linearGradient id="e" x1="50%" x2="50%" y1="109.096%" y2="0%">
        <stop offset="0%" stopColor="#C86362" />
        <stop offset="100%" stopColor="#E78D8D" />
      </linearGradient>
      <linearGradient id="f" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFD4C1" />
        <stop offset="100%" stopColor="#EFB198" />
      </linearGradient>
      <linearGradient id="g" x1="13.997%" x2="85.033%" y1="39.277%" y2="61.659%">
        <stop offset="0%" stopColor="#173D6A" />
        <stop offset="100%" stopColor="#0D294A" />
      </linearGradient>
      <path
        id="h"
        d="M86.75 139.796c.28-.861 2.195-3.53-1.213-6.072-.612-.456-1.624 0-2.501-.289-.66-.217-.55-1.41-1.638-1.367-1.087.043-1.64.627-2.526.556-.543-.043-.267-.6-1.947-.992-.99-.231-1.927.928-2.913.994-.572.038-1.235-.941-1.802-.993-1.381-.126-1.91-.098-3.856.768-1.945.866-2.713 3.593-3.586 4.056-20.38 10.801-9.42 26.015-7.345 28.092 2.839 2.843 8.52 3.444 9.128 3.304 8.35-1.916 7.703-11.476 11.48-14.912 2.777-2.526 6.978-7.809 8.719-13.145z"
      />
      <linearGradient id="i" x1="50%" x2="50%" y1="100%" y2="-9.974%">
        <stop offset="0%" stopColor="#8D5633" />
        <stop offset="0%" stopColor="#875739" />
        <stop offset="100%" stopColor="#BD7F56" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-32 -60)">
      <path d="M0 0h305v250H0z" opacity=".204" />
      <rect width="119" height="38" x="150" y="134" fill="#DFD4D4" rx="19" />
      <rect width="173" height="38" x="32" y="68" fill="#DFD4D4" opacity=".481" rx="19" />
      <rect width="86" height="22" x="119" y="183" fill="#DFD4D4" rx="11" />
      <path
        fill="url(#a)"
        d="M26.5 52.303C11.865 52.303 0 40.593 0 26.15S11.865 0 26.5 0 53 11.709 53 26.151c0 14.443-11.865 26.152-26.5 26.152z"
        transform="translate(216 60)"
      />
      <path
        fill="url(#b)"
        d="M30.38 30.43v2.092c0 1.86 1.354 3.196 4.063 4.01-3.12 2.324-6.3 3.486-9.54 3.486-3.239 0-6.419-1.162-9.54-3.486 2.73-.814 4.085-2.15 4.064-4.01-.032-2.79-.011-4.428-.032-9.208-2.532-3.238-2.37-5.412-.985-6.94 3.092-3.415 8.849-4.54 17.27-3.379.786 8.368.285 13.773-1.502 16.214-.765 1.046-2.031 1.453-3.798 1.22z"
        transform="matrix(-1 0 0 1 267.403 60)"
      />
      <path
        fill="url(#c)"
        d="M27.207 30.336c-2.591-.291-4.653-.988-6.184-2.093 1.296 2.15 3.357 3.545 6.184 4.185v-2.092z"
        opacity=".6"
        style={{ mixBlendMode: "multiply" }}
        transform="matrix(-1 0 0 1 264.23 60)"
      />
      <path
        fill="url(#d)"
        fillRule="nonzero"
        d="M24.019 18.12c-.562-.477-1.245-.797-2.21-.302-2.288 1.173-.556 5.67 1.475 5.67.53 0 .885 3.608-1.475 4.755-2.268 1.993-10.041-14.331-4.741-17.782C16.143 5.962 40.388 0 38.45 13.91c-.246 1.771-5.073 2.251-10.605 2.251-.835 0-1.302.205-1.07 1.224.453 1.974-.596 3.218-2.757.734z"
        transform="matrix(-1 0 0 1 269.814 60)"
      />
      <path
        fill="url(#e)"
        d="M18.935 35.197c-6.532 2.13-10.293 4.086-11.399 6.342-.37.756-.608 1.484-.922 2.569 4.679 5.098 12.123 8.243 19.518 8.195 7.951-.052 14.878-3.213 19.7-8.717-.222-.61-.955-1.812-1.196-2.289-1.137-2.242-5.343-4.21-11.907-6.254-1.787 3.848-11.876 3.977-13.794.154z"
        transform="matrix(-1 0 0 1 268.445 60)"
      />
      <path
        fill="#F38887"
        fillRule="nonzero"
        d="M129.23 137.238l16.66-14.994a4.832 4.832 0 0 1 6.86.392 4.924 4.924 0 0 1-.388 6.914l-19.787 17.807a4.83 4.83 0 0 1-6.346.109l-8.48-7.123a4.924 4.924 0 0 1-.623-6.897 4.832 4.832 0 0 1 6.843-.627l5.26 4.419z"
      />
      <path
        fill="url(#f)"
        fillRule="nonzero"
        d="M71.5 203.947c-21.815 0-39.5-17.673-39.5-39.473S49.685 125 71.5 125s39.5 17.674 39.5 39.474c0 21.8-17.685 39.473-39.5 39.473z"
        transform="matrix(-1 0 0 1 143 0)"
      />
      <path
        fill="url(#g)"
        d="M95.71 189.21c-1.93-3.684-10.007-7.894-24.227-12.631-14.22 4.737-22.295 8.947-24.227 12.632-.587 1.123-1.109 2.932-1.563 5.411 6.922 5.97 15.93 9.589 25.79 9.589s17.99-3.613 25.61-9.589c-.53-2.859-.99-4.663-1.383-5.411z"
      />
      <use fill="#14345A" transform="rotate(24 70.081 149.725)" xlinkHref="#h" />
      <path
        fill="url(#i)"
        d="M64.783 179.059v-15.79c-6.589-.098-5.304-11.508 1.317-8.026 1.339.048 2.415 1.149 5.4-2.07 3.95-3.66 14.777-6.378 15.403-.693 1.231 11.187-1.151 20.382-7.468 20.383-.62 0-1.224.02-2.012-.12v6.316c-2.062 1.228-4.124 1.842-6.186 1.842-2.062 0-4.213-.614-6.454-1.842z"
      />
      <path
        fill="#1D4778"
        d="M52.312 156.86l.66 1.098s5.99-4.6 19.329-9.772c5.897-2.287 11.23-5.04 15.998-8.258v-1.247c-.722-.863-1.681-1.133-2.878-.812-1.794.482-6.249 4.781-14.149 7.94-5.267 2.104-11.587 5.789-18.96 11.052z"
      />
      <path
        fill="#000"
        fillOpacity=".185"
        d="M77.03 172.763v2.105c-3.95 0-7.022-1.886-9.217-5.657 2.634 1.754 5.706 2.938 9.217 3.552z"
      />
      <ellipse cx="65.838" cy="187.237" fill="#1D4778" rx="2.238" ry="2.237" />
      <ellipse cx="62.678" cy="184.079" fill="#1D4778" rx="2.238" ry="2.237" />
      <ellipse cx="71.105" cy="188.289" fill="#1D4778" rx="2.238" ry="2.237" />
      <ellipse cx="76.372" cy="187.237" fill="#1D4778" rx="2.238" ry="2.237" />
      <ellipse cx="80.585" cy="184.079" fill="#1D4778" rx="2.238" ry="2.237" />
    </g>
  </svg>
);
