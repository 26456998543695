import React from 'react';
import '../../global.scss';
import LoaderContent from '../../hoc/content-loader';

/**
 * Invite loading component props for showing and hiding the loader
 *
 * @interface IProfileSetupLoaderProps
 */
interface IProfileSetupLoaderProps {
    active: boolean;
}

/**
 * Invite Loader component for displaying while a query is executing in the browser
 *
 * @param {*} props
 */
const ProfileSetupLoader: React.FC<IProfileSetupLoaderProps> = (props) => (
    <LoaderContent active={props.active}>
        <div className="g-loader" style={{
                    height: "35px",
                    width: "250px",
                    marginBottom: "30px"
                }}></div>
            <div style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "30px",
                    width: "500px",
                    maxWidth: "100%"
                }}></div>
            <div className="g-loader" style={{
                            width: "80px",
                            height: "15px",
                            marginBottom: "10px"
                        }}></div>
            <div className="g-loader" style={{
                    width: "440px",
                    maxWidth: "100%",
                    height: "56px",
                    marginBottom: "20px"
            }}></div>
            <div className="g-loader m-btn" style={{
                    width: "300px",
                    maxWidth: "100%"
                }}></div>
    </LoaderContent>
);

/**
 * Default prop state for the loader is always false
 */
ProfileSetupLoader.defaultProps = {
    active: false
}

export default ProfileSetupLoader;