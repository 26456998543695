import React from "react";

import "./profile-settings-name.scss";
import { IProfileSettingsStore } from "../../stores/profileSettingsStore";
import { inject, observer } from "mobx-react";
import { computed } from "mobx";
import Input from "../form/input";
import Button, { ButtonColors } from "../button/button";

/**
 * Profile settings name props and store
 *
 * @interface IProfileSettingsNameProps
 */
interface IProfileSettingsNameProps {
  name: string;
  profileSettingsStore?: IProfileSettingsStore;
}

/**
 * Component to render the user name and edit it
 *
 * @class ProfileSettingsName
 * @extends {React.Component<IProfileSettingsNameProps, {}>}
 */
@inject("profileSettingsStore")
@observer
class ProfileSettingsName extends React.Component<IProfileSettingsNameProps, {}> {
  @computed get editing(): boolean {
    return this.props.profileSettingsStore!.editing;
  }
  @computed get saving(): boolean {
    return this.props.profileSettingsStore!.savingName;
  }
  @computed get name(): string {
    return this.props.profileSettingsStore!.name;
  }

  /**
   * Go into edit more for the name component
   *
   * @memberof ProfileSettingsName
   */
  editName() {
    this.props.profileSettingsStore!.setEditing(true);
  }

  /**
   * Update the user's name
   *
   * @memberof ProfileSettingsName
   */
  saveName() {
    this.props.profileSettingsStore!.updateUserName(this.name);
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof ProfileSettingsName
   */
  render() {
    return (
      <div className="b-profile-settings-name">
        {// If in editing mode
        this.editing ? (
          <div className="b-grid m-vertical">
            <Input
              disabled={this.saving}
              type="text"
              small={true}
              noBorderStyle={true}
              onChange={event => this.props.profileSettingsStore!.setName(event)}
              value={this.name}
            />
            <div className="e-col e-name-save">
              <Button
                loading={this.saving}
                color={ButtonColors.Black}
                small={true}
                inline={true}
                onClick={() => this.saveName()}
              >
                Save
              </Button>
            </div>
          </div>
        ) : (
          <>
            {
              // Non-editing mode
            }
            <div className="b-grid m-align-center g-text-center">
              <div className="e-col m-auto e-settings-name" onClick={() => this.editName()}>
                {this.props.name}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ProfileSettingsName;
