import ABTesting from "./testing-engine";

/**
 * Experiment names are listed in an enum to prevent spelling mistakes
 *
 * @export
 * @enum {number}
 */
export enum Experiment {
  AppVsWebJoin = "Join Web or Download"
}

/**
 * Experiment class is a list of all initiated and running experiments
 *
 * @class Experiments
 */
class Experiments {
  /**
   * Creates an instance of Experiments.
   * @memberof Experiments
   */
  constructor() {
    /**
     * A/B test between web or app focus sign up
     */
    new ABTesting().Setup({
      name: Experiment.AppVsWebJoin,
      id: "1_app_vs_web_join",
      variations: [
        {
          name: "Join Download",
          id: "1_original_var",
          split: 50
        },
        {
          name: "Join Web",
          id: "1_b_var",
          split: 50
        }
      ]
    });
  }
}

export default Experiments;
