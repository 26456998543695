/// <reference path="../../../types/react-images/index.d.ts" />

import React from "react";
import { inject } from "mobx-react";
import { computed } from "mobx";
import Emoji from "react-emoji-render";
import { TweenMax, Power4 } from "gsap";
import Carousel, { Modal, ModalGateway } from "react-images";

import "./tiles.scss";
import {
  TileType,
  IMemberPaid,
  IGroupMember,
  IGroupSplitPortion
} from "../../stores/kinStore";
import { KinHelper } from "../../pages/home/kin";
import AvatarList from "../avatar-list/avatar-list";

import chevronUp from "../../images/chevron-up.svg";
import chevronDown from "../../images/chevron-down.svg";
import trashIcon from "../../images/trash.svg";
import Utilities from "../../common/utilities";
import Button, { ButtonColors } from "../button/button";
import { isMobile, isTablet } from "react-device-detect";
import Avatar from "../avatar/avatar";
import { ICurrenciesStore } from "../../stores/currenciesStore";

/**
 * Tile image props
 *
 * @interface ITransactionImage
 */
interface ITransactionImage {
  date: string;
  key: string;
  thumbnailUrl: string;
  url: string;
}

/**
 * Transaction tile props
 *
 * @interface ITransactionTileProps
 */
interface ITransactionTileProps {
  id?: string;
  noAnimation?: boolean;
  style?: any;
  payment?: boolean;
  type?: TileType;
  users?: IMemberPaid[];
  portions?: IGroupSplitPortion[];
  responsibleMemberId?: string;
  currentUser?: string;
  members?: IGroupMember[];
  description?: string;
  amount?: number;
  currency: string;
  images?: ITransactionImage[];
  expanded?: boolean;
  expandable?: boolean;
  removing?: boolean;
  loading?: boolean;
  currenciesStore?: ICurrenciesStore;
  removeExpense?: () => void;
  editExpense?: () => void;
  markAsPaid?: (callback: any) => void;
}

/**
 * Transaction tile state
 *
 * @interface ITransactionTileState
 */
interface ITransactionTileState {
  expanded: boolean;
  modalIsOpen: boolean;
  selectedIndex: number;
  transactionCurrency: string;
  isLoading: boolean;
}

/**
 * Tile image gallery props
 *
 * @interface ITransactionGalleryImage
 */
interface ITransactionGalleryImage {
  source: string;
}

/**
 * Component for rendering the transaction feed tile
 *
 * @param {*} props
 * @returns
 */
@inject("currenciesStore")
class TransactionTile extends React.Component<
  ITransactionTileProps,
  ITransactionTileState
> {
  /**
   * React ref to reference the tile content
   *
   * @type {*}
   * @memberof TransactionTile
   */
  tileContent: any;

  /**
   * Max amount of image thumbnails shown on a transaction tile
   *
   * @private
   * @type {number}
   * @memberof TransactionTile
   */
  private maxImages: number = 6;

  /**
   * Default tile props
   *
   * @static
   * @memberof TransactionTile
   */
  static defaultProps = {
    payment: false,
    users: [],
    images: [],
    expandable: false
  };

  /**
   *Creates an instance of TransactionTile.
   * @param {ITransactionTileProps} props
   * @memberof TransactionTile
   */
  constructor(props: ITransactionTileProps) {
    super(props);
    this.state = {
      expanded: false,
      modalIsOpen: false,
      selectedIndex: 0,
      transactionCurrency: this.currencySymbol,
      isLoading: false
    };
    this.tileContent = React.createRef();
    if (isMobile && !isTablet) {
      this.maxImages = 4;
    }
  }

  componentDidMount() {
    if (this.props.expanded) {
      this.expandTile();
    }
  }

  @computed get currencySymbol(): string {
    return this.props.currenciesStore!.currencySybmolByIsoCode(
      this.props.currency
    );
  }

  /**
   * Sort paid transaction tile users
   *
   * @param {IMemberPaid[]} users
   * @returns
   * @memberof TransactionTile
   */
  sortUsers(users: IMemberPaid[]) {
    // Get user that's paying
    let payingUser = users.filter(user => user.paid === undefined)[0];
    // Get user that got paid
    let paidUser = users.filter(user => user.paid !== undefined)[0];
    return [payingUser, paidUser];
  }

  /**
   * Payment transaction is build into a string of who paid who
   *
   * @param {(IMemberPaid[] | undefined)} users
   * @returns
   */
  formatUserPortions(users: IMemberPaid[]) {
    let sortedUsers: IMemberPaid[] = this.sortUsers(users);
    return (
      <>
        <strong>{sortedUsers[0].name}</strong> paid{" "}
        <strong>
          {sortedUsers[1].name} {this.currencySymbol}
          {new Utilities().ThousandSeparator({
            centsAmount: sortedUsers[1].paid as number
          })}
        </strong>
      </>
    );
  }

  /**
   * Format the transaction amount to Rands and to positive value
   *
   * @param {(number | undefined)} amount
   * @returns {string}
   * @memberof TransactionTile
   */
  formatAmount(amount: number | undefined): string {
    // Convert to positive amount
    amount = Math.abs(amount!);
    return new Utilities().ThousandSeparator({
      centsAmount: amount
    });
  }

  /**
   * Format image data into new image object
   *
   * @returns
   * @memberof TransactionTile
   */
  formatImages() {
    let images: ITransactionGalleryImage[] = [];
    (this.props.images as ITransactionImage[]).forEach(image => {
      images.push({
        source: image.url
      });
    });
    return images;
  }

  /**
   * Get the amount paid by user ID
   *
   * @returns
   * @memberof TransactionTile
   */
  fetchAmountByMemberId() {
    return (this.props.portions as IGroupSplitPortion[]).map(portion => {
      if (portion.memberId === this.props.responsibleMemberId) {
        return this.formatAmount(
          Math.abs(portion.amount.cents) + portion.responsible.cents
        );
      }
      return null;
    });
  }

  /**
   * Render the current user share
   *
   * @returns
   * @memberof TransactionTile
   */
  renderCurrentUserShare() {
    return (this.props.portions as IGroupSplitPortion[]).map(
      (portion, index) => {
        if (
          portion.memberId === this.props.currentUser &&
          portion.amount?.cents !== 0
        ) {
          return (
            <div className="e-share" key={index}>
              <div className="e-user m-current-user">Your share</div>
              <div className="e-user-share m-current-user">
                {this.currencySymbol}
                {this.formatAmount(portion.amount.cents)}
              </div>
            </div>
          );
        }
        return null;
      }
    );
  }

  /**
   * Render planned shares
   *
   * @returns
   * @memberof TransactionTile
   */
  renderPlannedShare() {
    if (this.props.type === TileType.Future) {
      let firstSplitUser: any = (this.props
        .portions as IGroupSplitPortion[])[0];
      if (firstSplitUser !== undefined) {
        const responsibleMemberName = new KinHelper().findGroupMemberByUserId(
          this.props.members,
          firstSplitUser.memberId
        ).name;
        return (
          <div className="e-transaction">
            To be paid by{" "}
            <strong>
              {firstSplitUser.memberId === this.props.currentUser
                ? "YOU"
                : responsibleMemberName}
            </strong>
          </div>
        );
      } else {
        return (
          <div className="e-transaction">
            To be paid by <strong>not sure yet</strong>
          </div>
        );
      }
    }
  }

  /**
   * Render responsible shares
   *
   * @returns
   * @memberof TransactionTile
   */
  renderResponsibleUserShare() {
    if (
      this.props.type === TileType.Owe ||
      this.props.type === TileType.NotInvolved ||
      this.props.type === TileType.Owed
    ) {
      return (
        <div className="e-transaction">
          {this.props.currentUser === this.props.responsibleMemberId ? (
            <>
              You paid{" "}
              <strong>
                {this.currencySymbol}
                {this.fetchAmountByMemberId()}
              </strong>
            </>
          ) : (
            <>
              {
                new KinHelper().findGroupMemberByUserId(
                  this.props.members,
                  this.props.responsibleMemberId
                ).name
              }{" "}
              Paid{" "}
              <strong>
                {this.currencySymbol}
                {this.fetchAmountByMemberId()}
              </strong>
            </>
          )}
        </div>
      );
    }
    return null;
  }

  /**
   * Render avatar of user responsible for expense
   *
   * @memberof TransactionTile
   */
  renderTransactionUserAvatar() {
    let user: IMemberPaid | IGroupMember;

    // Assign right user to avatar object
    if (this.props.type === TileType.Future) {
      let firstSplitUser: any = (this.props
        .portions as IGroupSplitPortion[])[0];
      // If no users return null
      if (firstSplitUser !== undefined) {
        user = new KinHelper().findGroupMemberByUserId(
          this.props.members,
          (this.props.portions as IGroupSplitPortion[])[0].memberId
        );
      } else {
        return null;
      }
    } else {
      user = new KinHelper().findGroupMemberByUserId(
        this.props.members,
        this.props.responsibleMemberId
      );
    }
    return (
      <Avatar
        className="e-tile-user-avatar"
        initialSize={12}
        name={user!.name}
        image={user!.image}
        borderWidth={0}
        size={24}
      />
    );
  }

  /**
   * Render shares of each user
   *
   * @returns
   * @memberof TransactionTile
   */
  renderShares() {
    return (this.props.portions as IGroupSplitPortion[]).map(
      (portion, index) => {
        if (
          portion.memberId !== this.props.currentUser &&
          portion.amount.cents !== 0
        ) {
          return (
            <div className="e-share" key={index}>
              <div className="e-user">
                {
                  new KinHelper().findGroupMemberByUserId(
                    this.props.members,
                    portion.memberId
                  ).name
                }
                's share
              </div>
              <div className="e-user-share">
                {this.currencySymbol}
                {this.formatAmount(portion.amount.cents)}
              </div>
            </div>
          );
        }
        return null;
      }
    );
  }

  /**
   * Expand the tile with GSAP animations
   *
   * @memberof TransactionTile
   */
  expandTile() {
    if (this.props.expandable) {
      this.setState({ expanded: !this.state.expanded }, () => {
        // Render expanding animation
        if (this.state.expanded) {
          TweenMax.set(this.tileContent, {
            css: {
              opacity: 1,
              height: "auto"
            }
          });
          TweenMax.from(this.tileContent, 0.3, {
            ease: Power4.easeOut,
            opacity: 0,
            height: 0
          });
        } else {
          // Render collapsing animation
          TweenMax.set(this.tileContent, {
            css: {
              opacity: 1,
              height: "auto"
            }
          });
          TweenMax.to(this.tileContent, 0.3, {
            ease: Power4.easeOut,
            opacity: 0,
            height: 0
          });
        }
      });
    }
  }

  /**
   * Toggle image gallery modal
   *
   * @param {number} index
   * @memberof TransactionTile
   */
  toggleModal(index: number) {
    this.setState({
      selectedIndex: index,
      modalIsOpen: !this.state.modalIsOpen
    });
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof TransactionTile
   */
  render() {
    let tileClasses: string = "b-tile";
    let blockClasses: string = "e-tile-block m-activity";
    let tileDetail: string | undefined;

    let removeClasses: string = "e-remove";

    // User portion amount
    let userPortion: string | undefined = undefined;

    // Only assign value if not payment tile
    if (!this.props.payment) {
      userPortion = this.formatAmount(this.props.amount);
    }
    // If the user portion is 0 always display the following
    if (this.props.amount === 0 && userPortion !== undefined) {
      if (this.props.currentUser === this.props.responsibleMemberId) {
        blockClasses += " m-not-involved";
        tileDetail = "You Get Back";
      } else {
        blockClasses += " m-not-involved";
        tileDetail = "Your share";
      }
    } else {
      // Otherwise check tile type for correct labels
      switch (this.props.type) {
        case TileType.Future:
          blockClasses += " m-future";
          tileDetail = "Estimate";
          break;
        case TileType.Owe:
          blockClasses += " m-owe";
          tileDetail = "Your Share";
          break;
        case TileType.Owed:
          blockClasses += " m-owed";
          tileDetail = "You Get Back";
          break;
        case TileType.NotInvolved:
          blockClasses += " m-not-involved";
          tileDetail = "Your share";
          break;
      }
    }

    if (this.state.expanded) {
      tileClasses += " m-open";
    }

    if (this.props.expandable) {
      tileClasses += " m-expandable";
    }

    if (this.props.noAnimation) {
      tileClasses += " m-no-animation";
    }

    if (this.props.removing) {
      removeClasses += " m-disabled";
    }
    return (
      <div id={this.props.id} className={tileClasses} style={this.props.style}>
        {this.props.payment ? (
          <div className="e-tile-payment">
            <AvatarList
              users={this.sortUsers(this.props.users as IMemberPaid[])}
              initialSize={
                this.sortUsers(this.props.users as IMemberPaid[])[0].initialSize
              }
            />
            <div className="e-payment-text">
              {this.formatUserPortions(this.props.users as IMemberPaid[])}
            </div>
          </div>
        ) : (
          <>
            <div id={this.props.id} className={blockClasses}>
              <div
                className="b-grid m-full-width m-space-between"
                onClick={() => this.expandTile()}
              >
                <div className="e-col">
                  {this.renderTransactionUserAvatar()}
                </div>
                <div className="e-col m-auto">
                  <Emoji text={this.props.description} />
                  {this.renderPlannedShare()}
                  {this.renderResponsibleUserShare()}
                </div>
                <div className="e-col e-tile-amount">
                  {this.currencySymbol}
                  {userPortion}
                  <span className="e-tile-type">{tileDetail}</span>
                </div>
              </div>
              <div
                ref={e => (this.tileContent = e)}
                className={
                  this.state.expanded
                    ? "e-tile-content m-open"
                    : "e-tile-content"
                }
              >
                {this.state.expanded
                  ? (this.renderCurrentUserShare(), this.renderShares())
                  : null}
                {(this.props.images as ITransactionImage[]).length !== 0 &&
                this.props.expandable ? (
                  <div className="e-images">
                    {(this.props.images as ITransactionImage[]).map(
                      (image, index) => {
                        // Check if the loop hasn't reached the max amount of images to show
                        let totalImages: number = (this.props
                          .images as ITransactionImage[]).length;
                        if (index < this.maxImages) {
                          // If on the max amount of images and there is more than 1 left
                          if (
                            index === this.maxImages - 1 &&
                            totalImages - this.maxImages !== 0
                          ) {
                            // If max is reached show count of remaining images + 1 for last tile
                            return (
                              <div
                                key={index}
                                className="e-image-obj m-count"
                                onClick={() => this.toggleModal(3)}
                              >
                                <span>
                                  +
                                  <span className="e-count-number">
                                    {totalImages - this.maxImages + 1}
                                  </span>
                                </span>
                              </div>
                            );
                          }
                          // Display image from the loop
                          return (
                            <div key={index} className="e-image-obj">
                              <div className="e-image">
                                <img
                                  onClick={() => this.toggleModal(index)}
                                  src={image.thumbnailUrl}
                                  alt={this.props.description}
                                />
                              </div>
                            </div>
                          );
                        }
                        return true;
                      }
                    )}
                  </div>
                ) : null}
                {this.state.expanded ? (
                  <div className="e-actions">
                    <img
                      src={trashIcon}
                      onClick={() =>
                        this.props.removeExpense && !this.props.removing
                          ? this.props.removeExpense()
                          : {}
                      }
                      className={removeClasses}
                      alt="Remove Expense"
                    />
                    <div className="e-action-btns">
                      <Button
                        small={true}
                        inline={true}
                        disabled={this.props.removing}
                        className="e-edit"
                        onClick={() =>
                          this.props.editExpense && !this.props.removing
                            ? this.props.editExpense()
                            : {}
                        }
                        color={ButtonColors.Black}
                        outline={true}
                      >
                        Edit
                      </Button>
                      {this.props.type === TileType.Future ? (
                        <Button
                          small={true}
                          inline={true}
                          loading={this.state.isLoading}
                          disabled={this.props.removing}
                          className="e-paid"
                          onClick={() =>
                            this.props.markAsPaid
                              ? this.props.markAsPaid(() => {
                                  this.setState(state => ({
                                    isLoading: !state.isLoading
                                  }));
                                })
                              : {}
                          }
                          color={ButtonColors.Primary}
                        >
                          This is paid
                        </Button>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {this.props.expandable ? (
              <div className="e-expander" onClick={() => this.expandTile()}>
                <img
                  className={
                    this.state.expanded
                      ? "e-expand m-up m-closed"
                      : "e-expand m-up"
                  }
                  src={chevronDown}
                  alt=""
                />
                <img
                  className={
                    this.state.expanded
                      ? "e-expand m-down m-closed"
                      : "e-expand m-down"
                  }
                  src={chevronUp}
                  alt=""
                />
              </div>
            ) : null}
          </>
        )}
        {this.state.expanded ? (
          <ModalGateway>
            {this.state.modalIsOpen ? (
              <Modal
                styles={{
                  blanket: base => ({
                    ...base,
                    zIndex: 9999999
                  }),
                  positioner: positioner => ({
                    ...positioner,
                    zIndex: 9999999
                  })
                }}
                allowFullscreen={false}
                onClose={() => this.toggleModal(this.state.selectedIndex)}
              >
                <Carousel
                  currentIndex={this.state.selectedIndex}
                  views={this.formatImages()}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        ) : null}
      </div>
    );
  }
}

export default TransactionTile;
