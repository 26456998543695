import React from "react";
import { RouteComponentProps } from "react-router";
import Dotdotdot from "react-dotdotdot";

import PillList from "../../components/pill-list/pill-list";
import Avatar from "../../components/avatar/avatar";
import AvatarList from "../../components/avatar-list/avatar-list";
import Button, { ButtonColors } from "../../components/button/button";
import { privacyPolicyLink, termsOfUseLink } from "../../components/footer/footer";
import Modal from "../../components/modal/modal";
import Input from "../../components/form/input";
import Layout from "../../hoc/layout";
import InviteLoader from "./invite-loader";

import "../../grid.scss";
import "../../global.scss";
import "../../typography.scss";
import "../../fonts/cerebri.css";
import "./invite.scss";

import googleIcon from "../../images/icon-google.svg";
import googleBlackIcon from "../../images/icon-google-black.svg";
import facebookIcon from "../../images/icon-facebook.svg";
import facebookBlackIcon from "../../images/icon-facebook-black.svg";
import emailIcon from "../../images/icon-email-black.svg";
import loaderSpinner from "../../images/loader-spinner.svg";
import check from "../../images/check.svg";
import animationData from "../../images/kin_success_check_lottie.json";

import Authentication from "../../common/authentication";
import UrlHelper from "../../common/url-helper";
import Lottie from "react-lottie";
import { updatePageTitle } from "../../common/history";
import Segment, { SegmentEvent } from "../../common/segment";
import AppstoreButton from "../../components/stores/appstore";
import PlaystoreButton from "../../components/stores/playstore";
import { inject, observer } from "mobx-react";
import { IInviteStore, IInviteToGroup, IUser, inviteQuery } from "../../stores/inviteStore";
import { computed } from "mobx";

/**
 * Invite type enum coming from server.
 *
 * @export
 * @enum {number}
 */
export enum InviteType {
  GroupLink = "GROUP_LINK",
  Member = "MEMBER",
  Connection = "CONNECTION"
}

/**
 * Type definitions of the Invite GraphQL query
 *
 * @interface IInviteProps
 */
interface IInviteProps extends RouteComponentProps {
  inviteStore?: IInviteStore;
}

/**
 * Interface for updating the button label
 *
 * @interface IInviteState
 */
interface IInviteState {
  buttonLabel: string;
}

@inject("inviteStore")
@observer
/**
 * Class for creating the Invite page
 *
 * @class Invite
 * @extends {React.Component<IInviteProps, IInviteState>}
 */
class Invite extends React.Component<IInviteProps, IInviteState> {
  /**
   * Invite Id variable
   *
   * @private
   * @type {string}
   * @memberof Invite
   */
  private inviteId: string = "";

  /**
   * Experiment user active bucket
   *
   * @private
   * @type {number}
   * @memberof SignIn
   */
  private experimentBucket: number = 0;

  @computed get id(): string {
    return this.props.inviteStore!.id;
  }
  @computed get type(): string {
    return this.props.inviteStore!.type;
  }
  @computed get name(): string {
    return this.props.inviteStore!.name;
  }
  @computed get inviteToGroup(): IInviteToGroup {
    return this.props.inviteStore!.inviteToGroup;
  }
  @computed get isExpired(): boolean {
    return this.props.inviteStore!.isExpired;
  }
  @computed get expiredReason(): string {
    return this.props.inviteStore!.expiredReason;
  }
  @computed get invitingUser(): IUser {
    return this.props.inviteStore!.invitingUser;
  }
  @computed get showModal(): boolean {
    return this.props.inviteStore!.showModal;
  }
  @computed get loading(): boolean {
    return this.props.inviteStore!.loading;
  }
  @computed get inviteError(): boolean {
    return this.props.inviteStore!.inviteError;
  }
  @computed get signingUpEmail(): boolean {
    return this.props.inviteStore!.signingUpEmail;
  }
  @computed get signingUpGoogle(): boolean {
    return this.props.inviteStore!.signingUpGoogle;
  }
  @computed get signingUpFacebook(): boolean {
    return this.props.inviteStore!.signingUpFacebook;
  }
  @computed get signUpRequestSent(): boolean {
    return this.props.inviteStore!.signUpRequestSent;
  }
  @computed get signUpRequestSending(): boolean {
    return this.props.inviteStore!.signUpRequestSending;
  }
  @computed get sighUpRequestResent(): boolean {
    return this.props.inviteStore!.sighUpRequestResent;
  }
  @computed get signUpEmailView(): boolean {
    return this.props.inviteStore!.signUpEmailView;
  }
  @computed get emailAddress(): string {
    return this.props.inviteStore!.emailAddress;
  }
  @computed get emailError(): boolean {
    return this.props.inviteStore!.emailError;
  }
  @computed get errorState(): boolean {
    return this.props.inviteStore!.errorState;
  }

  constructor(props: IInviteProps) {
    super(props);
    this.state = {
      buttonLabel: ""
    };
  }

  /**
   * Performs the GraphQL query after the component mounts to fetch the invite data
   *
   * @memberof Invite
   */
  componentDidMount() {
    // Assign invite ID from URL
    this.inviteId = (this.props.match.params as any).id;

    // Update page title
    updatePageTitle("Invite");
    new Segment().Page(SegmentEvent.PAGE_INVITE);

    // Start fetching the data
    this.props.inviteStore!.fetchInvite(inviteQuery(this.inviteId), () => {
      if (this.type === InviteType.Connection) {
        this.setState({
          buttonLabel: "Connect"
        });
      }
    });
  }

  /**
   * Adds an additional user avatar size to the users data for display purposes
   *
   * @param {any[]} users
   * @returns The manipulated user data
   * @memberof Invite
   */
  usersList(users: any[]) {
    users.forEach(user => {
      user.size = 42;
    });
    return users;
  }

  /**
   * Set the state for opening the Modal component
   *
   * @memberof Invite
   */
  openModal() {
    new Segment().Track(SegmentEvent.SIGN_UP_MODAL);
    this.props.inviteStore!.setShowModal(true);
  }

  /**
   * Set the state for closing the Modal component
   *
   * @memberof Invite
   */
  closeModal() {
    this.props.inviteStore!.setShowModal(false);
  }

  /**
   * Method for sending the sign up link with email through Firebase
   *
   * @memberof Invite
   */
  emailSignUp(resending: boolean = false) {
    new Segment().Track(SegmentEvent.SIGN_IN_EMAIL);
    const urlParams = UrlHelper.getAppParametersFromCurrentUrl();
    new Authentication().emailSignUp(
      () => {
        // Set state first to queue loading animations
        this.props.inviteStore!.setSigningUpEmail(true);
        this.props.inviteStore!.setSignUpRequestSending(true);
        this.props.inviteStore!.setSighUpRequestResent(resending);
      },
      () => {
        // Email was sent successfully
        this.props.inviteStore!.setSigningUpEmail(false);
        this.props.inviteStore!.setSignUpRequestSent(true);
        this.props.inviteStore!.setSignUpRequestSending(false);
        this.props.inviteStore!.setSighUpRequestResent(resending);
      },
      this.emailAddress,
      this.inviteId,
      urlParams.AppId,
      urlParams.Redirect,
      () => {
        // Email failed to send
        // Restore state to stop loading animations
        this.props.inviteStore!.setEmailError(true);
        this.props.inviteStore!.setSigningUpEmail(false);
        this.props.inviteStore!.setSignUpRequestSent(false);
        this.props.inviteStore!.setSignUpRequestSending(false);
      }
    );
  }

  /**
   * Method for authorizing the user with Google Auth
   *
   * @memberof Invite
   */
  googleSignUp() {
    new Segment().Track(SegmentEvent.SIGN_IN_GOOGLE);
    new Authentication().googleSignUp(
      () => {
        // Set state first to queue loading animations
        this.props.inviteStore!.setSigningUpGoogle(true);
      },
      () => {
        this.props.inviteStore!.setSigningUpGoogle(false);
        this.props.inviteStore!.setSignUpRequestSent(false);
      },
      () => {
        this.props.history.push(`/profile/setup/${this.inviteId}`);
      }
    );
  }

  /**
   * Method for authorizing the user with Facebook Auth
   *
   * @memberof Invite
   */
  facebookSignUp() {
    new Segment().Track(SegmentEvent.SIGN_IN_FACEBOOK);
    new Authentication().facebookSignUp(
      () => {
        // Set state first to queue loading animations
        this.props.inviteStore!.setSigningUpFacebook(true);
      },
      () => {
        this.props.inviteStore!.setSigningUpFacebook(false);
        this.props.inviteStore!.setSignUpRequestSent(false);
      },
      () => {
        this.props.history.push(`/profile/setup/${this.inviteId}`);
      }
    );
  }

  /**
   * Render the group info section if the invite is a group invite
   *
   * @returns
   * @memberof Invite
   */
  renderGroupInfo() {
    return (
      <div className="e-group">
        <div className="e-group-info">
          <h2 className="m-big">
            <Dotdotdot clamp={2}>{this.inviteToGroup.name}</Dotdotdot>
          </h2>
          <div className="e-group-members">
            <span>with</span>{" "}
            <AvatarList borderWidth={3} users={this.usersList(this.inviteToGroup.members)} />
          </div>
        </div>
        <Avatar image={this.inviteToGroup.image} name={this.inviteToGroup.name} size={112} />
      </div>
    );
  }

  /**
   * Modal variation A
   *
   * @param {string} modalTitle
   * @returns
   * @memberof Invite
   */
  renderModalA(modalTitle: string) {
    return (
      <Modal visible={this.showModal} center={true} onClick={() => this.closeModal()}>
        <h1
          style={{
            marginBottom: "17px"
          }}
        >
          Join {modalTitle}
        </h1>
        <form
          onSubmit={event => {
            event.preventDefault();
            this.emailSignUp();
          }}
        >
          <Input
            style={{
              maxWidth: "430px"
            }}
            type="email"
            placeholder="Email address"
            value={this.emailAddress}
            onChange={event => {
              this.props.inviteStore!.setEmailAddress(event);
              this.props.inviteStore!.setEmailError(false);
            }}
            disabled={this.signingUpEmail}
            error={this.emailError}
            errorMsg="Unable to send email, please check your email address for mistakes."
            focus={true}
          />
          <Button
            style={{
              marginTop: "32px",
              marginBottom: "40px",
              width: "100%",
              maxWidth: "393px"
            }}
            color={ButtonColors.Black}
            onClick={() => {}}
            loading={this.signingUpEmail}
            disabled={this.signingUpGoogle || this.signingUpFacebook}
          >
            {this.state.buttonLabel}
          </Button>
        </form>
        <p>or sign in with</p>
        <Button
          style={{
            width: "100%",
            maxWidth: "328px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto"
          }}
          onClick={() => this.googleSignUp()}
          color={ButtonColors.Black}
          outline={true}
          loading={this.signingUpGoogle}
          disabled={this.signingUpEmail || this.signingUpFacebook}
          icon={googleIcon}
        >
          Google
        </Button>
        <Button
          style={{
            width: "100%",
            maxWidth: "328px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto"
          }}
          onClick={() => this.facebookSignUp()}
          color={ButtonColors.Black}
          className="g-margin-top"
          outline={true}
          loading={this.signingUpFacebook}
          disabled={this.signingUpEmail || this.signingUpGoogle}
          icon={facebookIcon}
        >
          Facebook
        </Button>
        <p
          style={{
            maxWidth: "296px",
            margin: "75px auto 0",
            lineHeight: "1.79"
          }}
        >
          By signing in, you're accepting the Kin
          <br />{" "}
          <a href={termsOfUseLink} rel="noopener noreferrer" target="_blank">
            <strong>Terms of Use</strong>
          </a>{" "}
          and{" "}
          <a href={termsOfUseLink} rel="noopener noreferrer" target="_blank">
            <strong>Privacy Policy</strong>
          </a>
        </p>
      </Modal>
    );
  }

  /**
   * Modal variation B
   *
   * @param {string} modalTitle
   * @returns
   * @memberof Invite
   */
  renderModalB(modalTitle: string) {
    return (
      <Modal visible={this.showModal} center={true} onClick={() => this.closeModal()}>
        <h1
          style={{
            marginBottom: "17px"
          }}
        >
          Join {modalTitle}
        </h1>
        <Button
          style={{
            width: "100%",
            maxWidth: "393px",
            marginTop: "50px"
          }}
          onClick={() => this.googleSignUp()}
          color={ButtonColors.Black}
          loading={this.signingUpGoogle}
          disabled={this.signingUpEmail || this.signingUpFacebook}
          icon={googleBlackIcon}
        >
          Google
        </Button>
        <Button
          style={{
            width: "100%",
            maxWidth: "393px"
          }}
          onClick={() => this.facebookSignUp()}
          color={ButtonColors.Black}
          className="g-margin-top-lg"
          loading={this.signingUpFacebook}
          disabled={this.signingUpEmail || this.signingUpGoogle}
          icon={facebookBlackIcon}
        >
          Facebook
        </Button>
        <Button
          style={{
            width: "100%",
            maxWidth: "393px"
          }}
          onClick={() => this.props.inviteStore!.setSignUpEmailView(true)}
          color={ButtonColors.Black}
          className="g-margin-top-lg"
          icon={emailIcon}
          loading={this.signingUpEmail}
          disabled={this.signingUpGoogle || this.signingUpFacebook}
        >
          Email
        </Button>
        <p
          style={{
            maxWidth: "296px",
            margin: "75px auto 0",
            lineHeight: "1.79"
          }}
        >
          By signing in, you're accepting the Kin
          <br />{" "}
          <a href={termsOfUseLink} rel="noopener noreferrer" target="_blank">
            <strong>Terms of Use</strong>
          </a>{" "}
          and{" "}
          <a href={privacyPolicyLink} rel="noopener noreferrer" target="_blank">
            <strong>Privacy Policy</strong>
          </a>
        </p>
      </Modal>
    );
  }

  /**
   * Rendering Modal content when signing up with email request has been sent
   *
   * @param {string} email
   * @returns
   * @memberof Invite
   */
  renderModalEmail(email: string) {
    return (
      <Modal visible={this.showModal} center={true} onClick={() => this.closeModal()}>
        <div className="e-img-icon">
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            height={370}
            width={370}
          />
        </div>
        <h1
          style={{
            marginBottom: "32px"
          }}
        >
          Please check your email
        </h1>
        <p
          style={{
            marginBottom: "23px"
          }}
        >
          We've emailed a link to {email}. <br />
          Click the link to sign in.
        </p>
        <p>
          Wrong email address?{" "}
          <span className="g-a" onClick={() => this.props.inviteStore!.setSignUpRequestSent(false)}>
            <strong>Change it</strong>
          </span>
        </p>
        <p>
          Didn't receive an email?{" "}
          <span className="g-a" onClick={() => this.emailSignUp(true)}>
            <strong>Resend email</strong>
            {this.signUpRequestSending ? (
              <img
                src={loaderSpinner}
                className="g-spinner"
                style={{
                  verticalAlign: "middle",
                  marginLeft: "10px"
                }}
                alt="Resending email"
              />
            ) : null}
            {!this.signUpRequestSending && this.sighUpRequestResent ? (
              <img
                src={check}
                style={{
                  verticalAlign: "middle",
                  width: "27px",
                  marginLeft: "10px"
                }}
                alt="Email resent"
              />
            ) : null}
          </span>
        </p>
      </Modal>
    );
  }

  /**
   * Choosing what modal markup to render
   *
   * @param {string} modalTitle
   * @returns
   * @memberof Invite
   */
  renderModal(modalTitle: string) {
    if (this.signUpRequestSent) {
      return this.renderModalEmail(this.emailAddress);
    }
    if (this.signUpEmailView) {
      return this.renderModalA(modalTitle);
    }
    return this.renderModalB(modalTitle);
  }

  /**
   * Rendering the error content if an invite has expired, been accepted or the url is broken
   *
   * @param {string} errorMsg
   * @returns
   * @memberof Invite
   */
  renderInviteError(errorMsg: string, notifyMsg: boolean = false) {
    new Segment().Track(SegmentEvent.INVITE_EXPIRED);
    return (
      <>
        <h1
          style={
            !notifyMsg
              ? {
                  marginBottom: "82px"
                }
              : {}
          }
        >
          {errorMsg}
        </h1>
        {notifyMsg ? (
          <h1
            style={{
              marginBottom: "82px"
            }}
          >
            Please let {this.invitingUser.name} know.
          </h1>
        ) : null}
        <div
          style={{
            marginBottom: "54px"
          }}
          className="e-purpose g-h1 m-regular"
        >
          But you can download the Kin app to track, split and settle shared expenses with others.
        </div>
      </>
    );
  }

  /**
   * Renders a successful (error free) invite
   *
   * @param {string} modalTitle
   * @returns
   * @memberof Invite
   */
  renderInvite() {
    return (
      <>
        {this.type === InviteType.Connection || this.type === InviteType.Member ? (
          <h1>Hey {this.name}!</h1>
        ) : (
          <h1>Hi there!</h1>
        )}
        <div className="e-user">
          <div className="e-user-avatar">
            <Avatar image={this.invitingUser.image} name={this.invitingUser.name} size={64} />
          </div>
          <div className="e-user-info">
            <p>{this.invitingUser.name}</p>
            <p>has invited you</p>
          </div>
        </div>
        {this.type === InviteType.GroupLink || this.type === InviteType.Member
          ? this.renderGroupInfo()
          : null}
        <>
          <div className="e-purpose g-h1 m-regular">
            <strong>Join Kin</strong> to share expenses, track balances and get paid.
          </div>
          <div className="e-center g-margin-bottom-lg g-padding-bottom">
            <Button
              color={ButtonColors.Black}
              onClick={() => {
                this.setState({ buttonLabel: "Join Kin" });
                this.openModal();
              }}
            >
              Join now
            </Button>
          </div>
        </>
      </>
    );
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof Invite
   */
  render() {
    // Class changes for fading int he content once the query is done
    let fadeClasses: string = "e-content g-fade";

    if (!this.loading) {
      fadeClasses += " m-in";
    }

    // Change button label and modal title depending on the invite type
    let modalTitle: string = "";

    if (this.type === InviteType.GroupLink || this.type === InviteType.Member) {
      modalTitle = `${this.inviteToGroup.name} on Kin`;
    }

    if (this.type === InviteType.Connection) {
      modalTitle = `${this.invitingUser.name} on Kin`;
    }

    return (
      <Layout className="b-invite-page" errorState={this.errorState}>
        <InviteLoader active={this.loading} />
        <div className={fadeClasses}>
          {// When the invite has expired
          this.isExpired ? this.renderInviteError("Sorry, this invite has already been used.", true) : null}
          {// If the url is broken (i.e the invite ID does not exist)
          this.inviteError ? this.renderInviteError("Sorry, something went wrong.") : null}
          {// When the invite ID successfully returns data
          !this.isExpired && !this.inviteError ? this.renderInvite() : null}
          <div className="e-app-links">
            <AppstoreButton eventLocation={this.inviteError || this.isExpired ? "Invite Error" : undefined} />
            <PlaystoreButton
              eventLocation={this.inviteError || this.isExpired ? "Invite Error" : undefined}
            />
          </div>
        </div>
        {this.renderModal(modalTitle)}
        <PillList />
      </Layout>
    );
  }
}

export default Invite;
