import React from "react";

import "./dropdown.scss";

import arrowIcon from "../../images/chevron-down.svg";

export interface IDropdownItem {
  value: string;
  id: string | null;
}

interface IDropdownProps {
  className?: string;
  value: string;
  displayValue?: string;
  prefix: string | null;
  list: IDropdownItem[];
  onChange: (valueId: string | null) => void;
}

interface IDropdownState {
  open: boolean;
}

class Dropdown extends React.Component<IDropdownProps, IDropdownState> {
  constructor(props: IDropdownProps) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggleDropdown() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    let dropdownClasses: string = "b-dropdown";
    if (this.props.className) {
      dropdownClasses += ` ${this.props.className}`;
    }
    if (this.state.open) {
      dropdownClasses += " m-open";
    }
    let dropdownLabel: string = this.props.value;
    if (this.props.displayValue) {
      dropdownLabel = this.props.displayValue;
    }
    return (
      <div className={dropdownClasses}>
        <div className="e-label" onClick={() => this.toggleDropdown()}>
          <span>
            {this.props.prefix} {this.props.prefix ? <strong>{dropdownLabel}</strong> : dropdownLabel}
          </span>
          <img
            className={this.state.open ? "e-arrow m-open" : "e-arrow"}
            src={arrowIcon}
            alt="Expense Split"
          />
        </div>
        <div className={this.state.open ? "e-list m-open" : "e-list"}>
          {this.props.list.map((item, index) => {
            return (
              <div
                key={index}
                className="e-item"
                onClick={() => {
                  this.props.onChange(item.id);
                  this.toggleDropdown();
                }}
              >
                {item.value}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default Dropdown;
