import React from "react";

import "./error.scss";

/**
 * Error notice props
 *
 * @interface IErrorSnackbarProps
 */
interface IErrorSnackbarProps {
  msg?: string;
  show?: boolean;
}

/**
 * Creates error notice snackbar component
 *
 * @param {*} props
 * @returns
 */
const ErrorSnackbar: React.FC<IErrorSnackbarProps> = props => {
  let bodyClasses: string = "b-error-snackbar";
  if (props.show) {
    bodyClasses += " m-active";
  }
  return <div className={bodyClasses}>{props.msg}</div>;
};

/**
 * Error notice snackbar default props
 */
ErrorSnackbar.defaultProps = {
  msg: "Sorry, something went wrong.",
  show: true
};

export default ErrorSnackbar;
