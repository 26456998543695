import divideEquallyWithRemainder from "./divide-equally-with-remainder";

export interface ISplitAmount {
  cents: number;
  constant: boolean;
}

const calculator = (
  totalAmount: number,
  splits: ISplitAmount[]
): ISplitAmount[] => {
  if (totalAmount < 0) {
    throw new Error("Total amount should be greater than zero.");
  }

  let variableAmountsPortions = splits.filter(amount => !amount.constant)
    .length;
  let constantAmountTotal = splits
    .filter(amount => amount.constant)
    .reduce((acc, amount) => {
      return acc + amount.cents;
    }, 0);

  let variableAmount = Math.max(totalAmount - constantAmountTotal, 0);
  let dividedVariableAmount = divideEquallyWithRemainder(
    variableAmount,
    variableAmountsPortions
  );

  let hasAssignedRemainder = false;

  return splits.map(amount => {
    const [dividedAmount, remainder] = dividedVariableAmount;
    if (amount.constant) {
      return amount;
    } else {
      if (hasAssignedRemainder) {
        return {
          ...amount,
          cents: dividedAmount
        };
      } else {
        hasAssignedRemainder = true;
        return {
          ...amount,
          cents: dividedAmount + remainder
        };
      }
    }
  });
};

export default calculator;
