import React from "react";

import "./reminders.scss";
import Button, { ButtonColors } from "../button/button";

import { IKinStore, IReminderUser, reminderQuery } from "../../stores/kinStore";
import { inject, observer } from "mobx-react";

/**
 * Reminder share interfaces
 *
 * @interface IReminderShare
 */
interface IReminderShare {
  user?: IReminderUser;
  kinStore?: IKinStore;
  remindPerson?: () => void;
}

/**
 * Component modal for displaying reminder sharing with a kin user
 *
 * @class ReminderShare
 * @extends {React.Component<IReminderShare, {}>}
 */
@inject("kinStore")
@observer
class ReminderShare extends React.Component<IReminderShare, {}> {
  /**
   * Default render method
   *
   * @returns
   * @memberof ReminderShare
   */
  render() {
    return (
      <div className="b-reminder-modal">
        <div className="g-h5">Send reminder</div>
        <div className="e-reminder-title">
          {this.props.user!.name} will get this message
        </div>
        <div className="g-h6 e-reminder-description">
          Just a little reminder about the {this.props.user!.amount.formatted}{" "}
          owed to {this.props.user!.currentUser} for{" "}
          {this.props.kinStore!.groupName}. Once you settle you’ll be all square
          :)
        </div>
        <Button
          loading={this.props.kinStore!.sendingReminder}
          className="e-reminder-btn"
          color={ButtonColors.Secondary}
          small={true}
          onClick={() =>
            this.props.remindPerson
              ? this.props.remindPerson()
              : this.props.kinStore!.remindPayment(
                  reminderQuery(
                    this.props.kinStore!.groupId,
                    this.props.user!.id
                  )
                )
          }
        >
          Send
        </Button>
        <div
          onClick={() => this.props.kinStore!.setReminderModal(false)}
          className="e-reminder-cancel"
        >
          Cancel
        </div>
      </div>
    );
  }
}

export default ReminderShare;
