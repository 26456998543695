import { KinsStore } from "./kinsStore";
import { KinStore } from "./kinStore";
import { SignInStore } from "./signInStore";
import { InviteStore } from "./inviteStore";
import { ProfileSetupStore } from "./profileSetupStore";
import { LayoutSidebarStore } from "./layoutSidebarStore";
import { ProfileSettingsStore } from "./profileSettingsStore";
import { ActivityStore } from "./activityStore";
import { PeopleStore } from "./peopleStore";
import { PersonStore } from "./personStore";
import CurrenciesStore from "./currenciesStore";

/**
 * Merged MobX stores
 */
export const stores = {
  kinsStore: new KinsStore(),
  kinStore: new KinStore(),
  signInStore: new SignInStore(),
  inviteStore: new InviteStore(),
  profileSetupStore: new ProfileSetupStore(),
  profileSettingsStore: new ProfileSettingsStore(),
  layoutSidebarStore: new LayoutSidebarStore(),
  activityStore: new ActivityStore(),
  peopleStore: new PeopleStore(),
  personStore: new PersonStore(),
  currenciesStore: new CurrenciesStore()
};
