import React from "react";

import appstoreBtn from "../../images/appstore_new.svg";
import Segment, { SegmentEvent } from "../../common/segment";
import DynamicLinkBuilder from "../../common/dynamic-link-builder";
import { isIOS, isBrowser } from "react-device-detect";

/**
 * Appstore link
 *
 * @private
 * @type {string}
 * @memberof Invite
 */
const appstoreLink: string = "https://itunes.apple.com/us/app/kin/id1437611153";

interface IAppstoreButtonProps {
  white?: boolean;
  style?: any;
  eventLocation?: string;
}

/**
 * Renders the App store button
 *
 * @returns
 */
const AppstoreButton: React.FC<IAppstoreButtonProps> = props => {
  /**
   * Segment event to fire on button click
   *
   */
  const fireEvent = () => {
    // Override with custom event name if applicable
    let eventName: string = SegmentEvent.DOWNLOAD_IOS;
    if (props.eventLocation) {
      eventName += `_${new Segment().FormatEventName(props.eventLocation)}`;
    }
    new Segment().Track(eventName);
  };

  return isIOS || isBrowser ? (
    <a
      href={DynamicLinkBuilder.buildLink(appstoreLink)}
      onClick={() => fireEvent()}
      style={props.style}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={appstoreBtn} alt="App Store" />
    </a>
  ) : null;
};

export default AppstoreButton;
