import React from 'react';
import '../../global.scss';
import LoaderContent from '../../hoc/content-loader';

/**
 * Invite loading component props for showing and hiding the loader
 *
 * @interface IInviteLoaderProps
 */
interface IInviteLoaderProps {
    active: boolean;
}

/**
 * Invite Loader component for displaying while a query is executing in the browser
 *
 * @param {*} props
 */
const InviteLoader: React.FC<IInviteLoaderProps> = (props) => (
    <LoaderContent active={props.active}>
        <div className="g-loader" style={{
                    height: "35px",
                    width: "250px",
                    marginBottom: "30px"
                }}></div>
            <div style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "30px",
                    width: "500px",
                    maxWidth: "100%"
                }}>
                    <div className="g-loader m-round" style={{
                            width: "64px",
                            height: "64px",
                            minWidth: "64px",
                            marginRight: "15px"
                        }}></div>
                    <div style={{
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <div className="g-loader" style={{
                                    width: "80px",
                                    height: "15px",
                                    marginBottom: "10px"
                                }}></div>
                            <div className="g-loader" style={{
                                    width: "130px",
                                    height: "15px"
                                }}></div>
                    </div>
            </div>
            <div className="g-loader" style={{
                    width: "450px",
                    maxWidth: "100%",
                    height: "15px",
                    marginBottom: "20px"
                }}></div>
            <div className="g-loader" style={{
                    width: "500px",
                    maxWidth: "100%",
                    height: "15px",
                    marginBottom: "20px"
                }}></div>
            <div className="g-loader" style={{
                    width: "400px",
                    maxWidth: "100%",
                    height: "15px",
                    marginBottom: "30px"
                }}></div>
            <div className="g-loader m-btn" style={{
                    width: "300px",
                    maxWidth: "100%"
                }}></div>
    </LoaderContent>
);

/**
 * Default prop state for the loader is always false
 */
InviteLoader.defaultProps = {
    active: false
}

export default InviteLoader;