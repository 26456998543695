import axios, { AxiosResponse } from "axios";
import { Observable, from } from "rxjs";
import _ from "lodash";
import moment from "moment";

/**
 * Set the base GraphQL path depending on the build environment
 */
const kinGraphQLPath = axios.create({
  baseURL: process.env.REACT_APP_GRAPHQL_PATH
});

const kinApiPath = axios.create({
  baseURL: process.env.REACT_APP_API_PATH
});

/**
 * KinGraphQL query class for making requests in the app
 *
 * @export
 * @class KinGraphQL
 */
class KinGraphQL {
  constructor() {
    // Initialize cache interceptor for query requests
    this.cacheInitializer();
  }
  /**
   * Fetch query without Headers (public query)
   *
   * @param {string} query Insert the GraphQL query you would like to execute
   * @returns {Promise<any>} Returns the promise to be able to handle errors on a component level
   * @memberof KinGraphQL
   */
  public queryFetch(query: string): Observable<any> {
    return from(kinGraphQLPath.post("", { query: query }));
  }

  /**
   * Fetch query with authentication token
   *
   * @param {string} query Insert the GraphQL query you would like to execute
   * @param {string} token Authorization Bearer token
   * @param {string} variables Variables for the query
   * @returns {Promise<any>} Returns the promise to be able to handle errors on a component level
   * @memberof KinGraphQL
   */
  public queryWithToken(
    query: string,
    token: string,
    variables: any = null
  ): Promise<AxiosResponse<any>> {
    return kinGraphQLPath.post(
      "",
      { query: query, ...(variables && { variables: variables }) },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }

  /**
   * Fetch query with authentication token
   *
   * @param {string} query Insert the GraphQL query you would like to execute
   * @param {string} token Authorization Bearer token
   * @returns {Observable<any>} Returns the promise to be able to handle errors on a component level
   * @memberof KinGraphQL
   */
  public queryFetchAuth(
    query: string,
    token: string,
    variables: any = null
  ): Observable<any> {
    return from(
      kinGraphQLPath.post(
        "",
        { query: query, ...(variables && { variables: variables }) },
        { headers: { Authorization: `Bearer ${token}` } }
      )
    );
  }

  /**
   * Uploading user photos
   *
   * @param {*} file
   * @param {string} userId
   * @returns {Observable<any>}
   * @memberof KinGraphQL
   */
  public uploadUserPhoto(
    file: any,
    userId: string,
    token: string
  ): Observable<any> {
    let formData: FormData = new FormData();
    formData.append("image", file);
    return from(
      kinApiPath.post(`/users/${userId}/image`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: "application/json"
        }
      })
    );
  }

  /**
   * Uploading kin photos
   *
   * @param {*} file
   * @param {string} groupId
   * @returns {Observable<any>}
   * @memberof KinGraphQL
   */
  public uploadKinPhoto(
    file: any,
    groupId: string,
    token: string
  ): Observable<any> {
    let formData: FormData = new FormData();
    formData.append("image", file);
    return from(
      kinApiPath.post(`/groups/${groupId}/image`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: "application/json"
        }
      })
    );
  }

  /**
   * Stored the Kin Query into localStorage.
   * Key: query used to make request
   * Value: query result
   *
   * @private
   * @param {*} response
   * @memberof KinGraphQL
   */
  private storeRequest(response: any) {
    let key: string = JSON.stringify(JSON.parse(response.config.data).query);
    // Prefix 'kin_' to easily find later when clearing the cache
    key = `kin_${key}`;
    let value: string = JSON.stringify(response);
    window.localStorage.setItem(key, value);
  }

  /**
   * Fetches the stored query result by using the key value of query
   *
   * @param {string} query
   * @returns {Promise<any>} Returned value could be null in the case a query not yet stored
   * @memberof KinGraphQL
   */
  public fetchStoredRequest(query: string): Promise<any> {
    return new Promise(resolve => {
      let key: string = JSON.stringify(query);
      // Prefix 'kin_' to easily find later when clearing the cache
      key = `kin_${key}`;
      let value = window.localStorage.getItem(key);
      if (value !== null) {
        value = JSON.parse(value);
      }
      resolve(value);
    });
  }

  /**
   * Initializes the cache to intercept all Axios queries to store the key/query and value/result
   *
   * @private
   * @memberof KinGraphQL
   */
  private cacheInitializer() {
    // Add a response interceptor
    kinGraphQLPath.interceptors.response.use(
      response => {
        // Do something with response data
        this.storeRequest(response);
        return response;
      },
      function(error) {
        // Do something with response error
        return Promise.reject(error);
      }
    );
  }

  /**
   * Get all the localStorage keys starting with "kin_"
   *
   * @private
   * @returns
   * @memberof KinGraphQL
   */
  private getKinStorageKeys() {
    // Fetch all localStorage key values
    let storageKeys: string[] = Object.keys(localStorage);
    // Reduce key values to only 'kin_' values
    let kinStorageKeys = _.filter(storageKeys, key => {
      return _.includes(key, "kin_");
    });
    return kinStorageKeys;
  }

  /**
   * Clears all 'kin_' key values in localstorage.
   * Mainly used to clean up after signing out
   *
   * @memberof KinGraphQL
   */
  public clearCache() {
    // Remove all 'kin_' localStorage data
    _.forEach(this.getKinStorageKeys(), key => {
      window.localStorage.removeItem(key);
    });
  }

  /**
   * Clean up any requests that resulted in errors
   * to avoid storage form filling up.
   *
   * @memberof KinGraphQL
   */
  public clearErroredRequests() {
    let errorCheckRefreshDate: string | null = window.localStorage.getItem(
      "kin_errors_checked"
    );
    // Check if a date has been created
    if (errorCheckRefreshDate === null) {
      window.localStorage.setItem(
        "kin_errors_checked",
        moment()
          .add(7, "days")
          .format("YYYY-MM-DD")
      );
    } else {
      // Check if date expired
      if (moment().isAfter(moment(errorCheckRefreshDate, "YYYY-MM-DD"))) {
        // Loop all 'kin_' localStorage data with errors in
        _.forEach(this.getKinStorageKeys(), key => {
          // Get value first to match
          let keyData: string = window.localStorage.getItem(key) as string;
          let keyDataObject: any;
          // If string does not start with "{" it's not a object
          try {
            // Parse JSON object
            keyDataObject = JSON.parse(keyData);
            // Check if the data is request data
            if (keyDataObject.data !== undefined) {
              // Check if the data is request error data
              if (keyDataObject.data.errors !== undefined) {
                window.localStorage.removeItem(key);
              }
            }
          } catch (e) {
            // keyData was not an object so ignore the key
          }
        });
        // Extend refresh date to 7 days in the future
        window.localStorage.setItem(
          "kin_errors_checked",
          moment()
            .add(7, "days")
            .format("YYYY-MM-DD")
        );
      }
    }
  }
}

export default KinGraphQL;
