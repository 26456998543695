import React from "react";
import LayoutSidebar from "../../hoc/layout-sidebar";
import { menuItems } from "../../common/menu";
import { history } from "../../common/history";
import {
  IPeopleStore,
  IPeopleSidebarBalance,
  getPersonId
} from "../../stores/peopleStore";
import { inject, observer } from "mobx-react";
import { computed } from "mobx";
import Segment, { SegmentEvent } from "../../common/segment";
import PersonTile from "../../components/tiles/person";
import "./people.scss";
import { IPersonStore } from "../../stores/personStore";
import PeopleLoader from "./people-loader";
import BalanceSummary from "../../components/balance-summary/balance-summary";
import { ICurrenciesStore } from "../../stores/currenciesStore";
import { SettleSVG } from "../../components/svgs/settle-empty";
import copyIcon from "../../images/copy.svg";
import Input from "../../components/form/input";
import { IProfileSettingsStore } from "../../stores/profileSettingsStore";
import { Clipboard } from "ts-clipboard";
import Notices from "../../common/snackbars";
import { ILayoutSidebarStore } from "../../stores/layoutSidebarStore";

/**
 * People props interface
 *
 * @interface IPeopleProps
 */
interface IPeopleProps {
  peopleStore?: IPeopleStore;
  personStore?: IPersonStore;
  currenciesStore: ICurrenciesStore;
  profileSettingsStore?: IProfileSettingsStore;
  layoutSidebarStore: ILayoutSidebarStore;
}

@inject(
  "peopleStore",
  "personStore",
  "currenciesStore",
  "profileSettingsStore",
  "layoutSidebarStore"
)
@observer
class People extends React.Component<IPeopleProps, {}> {
  @computed get loading(): boolean {
    return this.props.peopleStore!.loading;
  }
  @computed get error(): boolean {
    return this.props.peopleStore!.error;
  }
  @computed get peopleSidebarBalances(): IPeopleSidebarBalance[] | undefined {
    return this.props.peopleStore!.peopleSidebarBalances;
  }
  @computed get noticeState(): boolean {
    return this.props.layoutSidebarStore!.noticeState;
  }
  @computed get noticeMsg(): string {
    return this.props.layoutSidebarStore!.noticeMsg;
  }

  /**
   * Fetch the people query
   *
   * @memberof People
   */
  componentDidMount() {
    // Fetch People balances data
    this.props.peopleStore!.fetchPeople();
    // Trigger Segment page view
    new Segment().Page(SegmentEvent.PAGE_PEOPLE);
  }

  /**
   * Open a person balance
   *
   * @param {string} id
   * @memberof People
   */
  openPerson(person: IPeopleSidebarBalance) {
    // Change page
    history.push(`/home/people/person/${getPersonId(person)}`);
    // Update person object for the person page
    this.props.personStore!.setPerson({
      name: person.name,
      image: person.image,
      id: getPersonId(person),
      groupBalances: this.props.peopleStore!.fetchPersonGroupBalances(
        getPersonId(person)
      ),
      connectionId: person.connectionId,
      status: person.status,
      userId: person.userId,
      email: person.email,
      balances: person.balances
    });
  }

  /**
   * default render method
   *
   * @returns
   * @memberof People
   */
  render() {
    let balances = this.props.peopleStore!.peopleBalances?.balances;

    let owedBalances;
    let oweBalances;
    if (balances) {
      owedBalances = balances
        .filter(balance => {
          return balance.summary.totalIAmOwed.cents;
        })
        .map(balance => {
          return this.props.currenciesStore!.createAmount(
            balance.summary.totalIAmOwed
          );
        });

      oweBalances = balances
        .filter(balance => {
          return balance.summary.totalOwe.cents;
        })
        .map(balance => {
          return this.props.currenciesStore!.createAmount(
            balance.summary.totalOwe
          );
        });
    }

    const userHasConnections = this.peopleSidebarBalances!.length;
    const inviteLinkUrl = this.props.profileSettingsStore!.user.inviteLinkUrl;
    return (
      <LayoutSidebar
        showAddNew={false}
        menuItems={menuItems}
        menuActiveItem={menuItems[1].title}
        errorState={this.error}
        noticeState={this.noticeState}
        noticeMsg={this.noticeMsg}
      >
        <div className="b-people-page">
          <PeopleLoader active={this.loading} />
          {!this.loading ? (
            <>
              <div className="l-summary-cards:horizontal">
                <BalanceSummary
                  label="You owe"
                  type="danger"
                  amounts={oweBalances}
                />
                <BalanceSummary
                  label="Owed to you"
                  type="success"
                  amounts={owedBalances}
                />
              </div>
              {userHasConnections ? (
                this.peopleSidebarBalances!.map((person, index) => (
                  <PersonTile
                    key={index}
                    name={person.name}
                    image={person.image}
                    amountIOwe={person.amountIOwe.map(amount =>
                      this.props.currenciesStore!.createAmount(amount)
                    )}
                    amountOwed={person.amountOwed.map(amount =>
                      this.props.currenciesStore!.createAmount(amount)
                    )}
                    onClick={() => this.openPerson(person)}
                  />
                ))
              ) : (
                <>
                  <SettleSVG />
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "40px",
                      marginBottom: "10px",
                      fontSize: "20px"
                    }}
                  >
                    <strong>Kin is better with friends</strong>
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      marginBottom: "0"
                    }}
                  >
                    Invite people with your invite link so you can keep track of
                    expenses together
                  </p>
                  <div className="e-kin-share">
                    <Input
                      readonly={true}
                      className="e-share-field"
                      type="text"
                      value={inviteLinkUrl}
                      placeholder="Fetching share link..."
                      onChange={() => {}}
                    />
                    <img
                      className="e-kin-share-icon g-pointer"
                      src={copyIcon}
                      alt="Copy to clipboard"
                      onClick={() => {
                        Clipboard.copy(inviteLinkUrl ? inviteLinkUrl : "");
                        new Notices().GlobalTrigger(() => {
                          this.props.layoutSidebarStore!.setNotice(
                            !this.noticeState,
                            "Copied to clipboard."
                          );
                        });
                      }}
                    />
                  </div>
                </>
              )}
            </>
          ) : null}
        </div>
      </LayoutSidebar>
    );
  }
}

export default People;
