import React from "react";
import "./button.scss";
import Loader from "../loader/loader";

/**
 * Button colors as enum
 *
 * @export
 * @enum {number}
 */
export enum ButtonColors {
  Black = 0,
  Primary = 1,
  Secondary = 2,
  Future = 3,
  Google = 4,
  Facebook = 5
}

/**
 * Button props
 *
 * @interface IButtonProps
 */
interface IButtonProps {
  icon?: string;
  iconCircle?: boolean;
  link?: string;
  color?: ButtonColors;
  outline?: boolean;
  className?: string;
  style?: any;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  small?: boolean;
  inline?: boolean;
}

/**
 * Button component for displaying a standard button
 *
 * @param {*} props
 * @returns
 */
const Button: React.FC<IButtonProps> = props => {
  // Set the icon class if the button needs to display an icon
  let iconClasses: string = "e-icon";

  if (!props.iconCircle) {
    iconClasses += " m-no-circle";
  }

  // Set the button class depending on the button type/style
  let buttonClasses: string = "b-btn";

  switch (props.color) {
    case ButtonColors.Black:
      buttonClasses += " m-black";
      break;
    case ButtonColors.Primary:
      buttonClasses += " m-primary";
      break;
    case ButtonColors.Secondary:
      buttonClasses += " m-secondary";
      break;
    case ButtonColors.Future:
      buttonClasses += " m-future";
      break;
    case ButtonColors.Google:
      buttonClasses += " m-google";
      break;
    case ButtonColors.Facebook:
      buttonClasses += " m-facebook";
      break;
    default:
      buttonClasses += " m-primary";
      break;
  }

  // Add disabled class if the button is set as disabled
  if (props.disabled || props.loading) {
    buttonClasses += " m-disabled";
  }

  // Add any additional classes from the default className attribute
  if (props.className) {
    buttonClasses += ` ${props.className}`;
  }

  // Add button outline style
  if (props.outline) {
    buttonClasses += " m-outline";
  }

  if (props.small) {
    buttonClasses += " m-small";
  }

  if (props.inline) {
    buttonClasses += " m-inline";
  }

  /**
   * Remove onClick handler if the button is disabled
   *
   * @returns
   */
  const handleClick = () => {
    if (!props.disabled && !props.loading) {
      return props.onClick();
    }
  };

  return (
    <button
      className={buttonClasses}
      style={props.style}
      onClick={() => handleClick()}
    >
      {props.loading ? (
        <div className="e-label m-loading">
          <Loader active={true} white={!props.outline} />
        </div>
      ) : (
        <>
          {props.icon ? (
            <div className={iconClasses}>
              <img src={props.icon} alt="Icon" />
            </div>
          ) : null}
          <div className="e-label">{props.children}</div>
        </>
      )}
    </button>
  );
};

/**
 * Default prop state for a button
 */
Button.defaultProps = {
  iconCircle: true,
  disabled: false
};

export default Button;
