import React from "react";

import "./input.scss";
import warningIcon from "../../images/warning.svg";

/**
 * Input field props
 *
 * @interface IInputProps
 */
interface IInputProps {
  readonly?: boolean;
  type: string;
  textarea?: boolean;
  label?: string;
  placeholder?: string;
  value?: string;
  style?: any;
  onChange: (value: any) => void;
  onFocus?: () => void;
  onBlur?: (value: string) => void;
  onKeyPress?: (value: React.KeyboardEvent) => void;
  onKeyDown?: (value: React.KeyboardEvent) => void;
  minLength?: number;
  maxLength?: number;
  error?: boolean;
  errorMsg?: string;
  disabled?: boolean;
  className?: string;
  focus?: boolean;
  small?: boolean;
  noBorderStyle?: boolean;
  pattern?: string;
}

/**
 * Input component for any text fields
 *
 * @param {*} props
 * @returns
 */
const Input: React.FC<IInputProps> = props => {
  let inputClasses: string = "b-input";
  if (props.small) {
    inputClasses += " m-small";
  }
  if (props.noBorderStyle) {
    inputClasses += " m-no-border-style";
  }
  if (props.className) {
    inputClasses += ` ${props.className}`;
  }
  return (
    <div className={inputClasses}>
      {props.label !== undefined ? (
        <label className="e-label">{props.label}</label>
      ) : null}
      {props.textarea ? (
        <textarea
          readOnly={props.readonly}
          className={props.error ? "e-input m-error" : "e-input"}
          style={props.style}
          placeholder={props.placeholder}
          minLength={props.minLength}
          maxLength={props.maxLength}
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
          onFocus={() => (props.onFocus ? props.onFocus() : {})}
          onBlur={e => (props.onBlur ? props.onBlur(e.target.value) : {})}
          onKeyPress={e => (props.onKeyPress ? props.onKeyPress(e) : {})}
          onKeyDown={e => (props.onKeyDown ? props.onKeyDown(e) : {})}
          disabled={props.disabled}
          ref={input => input && props.focus && input.focus()}
        />
      ) : (
        <input
          readOnly={props.readonly}
          className={props.error ? "e-input m-error" : "e-input"}
          style={props.style}
          type={props.type}
          placeholder={props.placeholder}
          minLength={props.minLength}
          maxLength={props.maxLength}
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
          onFocus={() => (props.onFocus ? props.onFocus() : {})}
          onBlur={e => (props.onBlur ? props.onBlur(e.target.value) : {})}
          onKeyPress={e => (props.onKeyPress ? props.onKeyPress(e) : {})}
          onKeyDown={e => (props.onKeyDown ? props.onKeyDown(e) : {})}
          disabled={props.disabled}
          ref={input => input && props.focus && input.focus()}
          pattern={props.pattern}
          inputMode={props.pattern ? "numeric" : "text"}
        />
      )}
      {props.error ? (
        <span className="e-error">
          <img src={warningIcon} alt="Error" /> {props.errorMsg}
        </span>
      ) : null}
    </div>
  );
};

/**
 * Default props for Input component
 */
Input.defaultProps = {
  label: undefined,
  readonly: false
};

export default Input;
