import { IMenuItem } from "../components/sidebar/sidebar";

import kinsIcon from "../images/home.svg";
import activityIcon from "../images/bell.svg";
import activityNewIcon from "../images/bell-new.svg";
import peopleIcon from "../images/people.svg";
import exploreIcon from "../images/compass.svg";
import helpIcon from "../images/message-circle.svg";
import { SegmentEvent } from "./segment";

/**
 * Url Paths enum
 *
 * @export
 * @enum {number}
 */
export enum Paths {
  SignIn = "/sign-in",
  ZendeskSignIn = "/zendesk/sign-in",
  Invite = "/invite/:id",
  ProfileSetup = "/profile/setup/:inviteid",
  Kin = "/home/kins/group/:groupid",
  Kins = "/home/kins",
  Activity = "/home/activity",
  ActivityItem = "/home/activity/:activityId",
  People = "/home/people",
  Person = "/home/people/person/:personid",
  Explore = "/home/explore",
  Home = "/",
  NotFound = "/not-found"
}

/**
 * Menu list in the side bar
 * @type {IMenuItem[]}
 */
export let menuItems: IMenuItem[] = [
  {
    title: "My Kins",
    linkTo: Paths.Kins,
    icon: kinsIcon,
    active: false,
    trackingEvent: SegmentEvent.HOME_TAPPED
  },
  {
    title: "People",
    linkTo: Paths.People,
    icon: peopleIcon,
    active: false
  },
  {
    title: "Activity",
    linkTo: Paths.Activity,
    icon: activityIcon,
    iconNew: activityNewIcon,
    active: false
  },
  {
    title: "Explore",
    linkTo: Paths.Explore,
    icon: exploreIcon,
    active: false,
    trackingEvent: SegmentEvent.EXPLORE_TAPPED
  },
  {
    title: "Get help",
    linkTo: "",
    icon: helpIcon,
    active: false
  }
];
