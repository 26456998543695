import React from "react";

import successImg from "../../images/kin_success.png";
import { history } from "../../common/history";

import "./campaign.scss";

import { inject, observer } from "mobx-react";
import { computed } from "mobx";
import { IProfileSettingsStore } from "../../stores/profileSettingsStore";
import {
  IKinsStore,
  IGroupMembers,
  GroupMembersStatus
} from "../../stores/kinsStore";
import GroupTile from "./group";
import { queryAuthenticated } from "../../common/graphql-utils";

/**
 * Campaign tile props
 *
 * @interface ICampaignTileProps
 */
interface ICampaignTileProps {
  style?: any;
  title: string;
  ctaTitle: string;
  onClick?: any;
  profileSettingsStore?: IProfileSettingsStore;
  kinsStore?: IKinsStore;
}

interface ICampaignTileState {
  isLoggedInFromCampaign: boolean;
  hasCreatedGroup: boolean;
}

/**
 * Component to render the campaign tile
 *
 * @param {*} props
 * @returns
 */
@inject("profileSettingsStore", "kinsStore")
@observer
class CampaignTile extends React.Component<
  ICampaignTileProps,
  ICampaignTileState
> {
  @computed get userId(): string {
    return this.props.profileSettingsStore!.user.id;
  }

  constructor(props: ICampaignTileProps) {
    super(props);
    this.state = {
      isLoggedInFromCampaign: false,
      hasCreatedGroup: false
    };
  }

  componentDidMount() {
    this.setState(state => ({
      ...state,
      isLoggedInFromCampaign: this.getCampaignFlag()
    }));

    this.props
      .profileSettingsStore!.fetchUser()
      .then(() => this.createCampaignGroupIfNotCreatedBefore());
  }

  createCampaignGroupIfNotCreatedBefore = async () => {
    const keyName = "kin_created-cooking-with-kin";
    const userIsFromCampaign = this.getCampaignFlag();
    const didCreateGroup = window.localStorage.getItem(keyName) === "true";
    var groups = await this.props.kinsStore!.fetchCachedKinsOrRefresh();

    const groupExists =
      groups.filter(group => group.name === "Ultimate Holiday Fish Braai")
        .length >= 1;

    if (userIsFromCampaign && !didCreateGroup && !groupExists) {
      window.localStorage.setItem(keyName, JSON.stringify(true));
      this.createCampaignGroup();
    }
  };

  createCampaignGroup = () => {
    queryAuthenticated(groupCreateWithMembersAndTransactions, {
      transactionRecords: [
        {
          description: "Fish braai",
          responsibleMemberId: "me",
          transactionType: "Planned",
          date: null,
          amount: {
            currencyIso: "ZAR",
            cents: 0
          },
          split: {
            type: "Exact",
            portions: [
              {
                memberId: "me",
                size: 1
              }
            ]
          }
        },
        {
          description: "Snacks",
          responsibleMemberId: "me",
          transactionType: "Planned",
          date: null,
          amount: {
            currencyIso: "ZAR",
            cents: 0
          },
          split: {
            type: "Exact",
            portions: [
              {
                memberId: "me",
                size: 1
              }
            ]
          }
        },
        {
          description: "Drinks",
          responsibleMemberId: "me",
          transactionType: "Planned",
          date: null,
          amount: {
            currencyIso: "ZAR",
            cents: 0
          },
          split: {
            type: "Exact",
            portions: [
              {
                memberId: "me",
                size: 1
              }
            ]
          }
        },
        {
          description: "Dessert",
          responsibleMemberId: "me",
          transactionType: "Planned",
          date: null,
          amount: {
            currencyIso: "ZAR",
            cents: 0
          },
          split: {
            type: "Exact",
            portions: [
              {
                memberId: "me",
                size: 1
              }
            ]
          }
        }
      ]
    }).then(
      () => {
        // Update local kin settings
        this.props.kinsStore!.refreshKins();
      },
      e => {
        // Trigger error
        console.error("error fatal", e);
      }
    );
  };

  getCampaignFlag = (): boolean => {
    return window.localStorage.getItem("kin_cooking-with-kin") === "true";
  };

  /**
   * Filter out groups that are 'InActive'
   *
   * @param {IGroupMembers[]} members
   * @returns
   * @memberof Kins
   */
  filterByGroupStatus(members: IGroupMembers[]) {
    return members.filter(member => {
      return member.status !== GroupMembersStatus.InActive;
    });
  }

  /**
   * Open group component
   *
   * @memberof Kins
   */
  openGroup(groupId: string) {
    history.push(`/home/kins/group/${groupId}`);
  }

  handleClick = (campaignGroupId: string) => {
    this.openGroup(campaignGroupId);
    window.localStorage.setItem(
      "kin_has-seen-campaign-highlight",
      JSON.stringify(true)
    );
  };

  render() {
    const campaignGroup = this.props.kinsStore!.kins.find(
      group => group.name === "Ultimate Holiday Fish Braai"
    );
    const hasSeenCampaignGroup = !!window.localStorage.getItem(
      "kin_has-seen-campaign-highlight"
    );
    if (campaignGroup && !hasSeenCampaignGroup) {
      return (
        <div className="e-campaign-kin">
          <GroupTile
            key={campaignGroup.id}
            name={campaignGroup.name}
            image={campaignGroup.image}
            hidden={campaignGroup.isHidden}
            balance={campaignGroup.currentMember.summary.balance}
            members={this.filterByGroupStatus(campaignGroup.members)}
            onClick={() => this.handleClick(campaignGroup.id)}
          />
          <p>
            <img
              src={successImg}
              alt="Success"
              className="e-campaign-success"
            />
            <span className="e-campaign-emoji m-campaign-emoji-narrow">
              You’re all set to host your ultimate fish braai!
            </span>
            <br />
            <span className="e-campaign-emoji">
              Next steps... <br />
              Invite your friends <br />
              Buy the stuff. <br />
              Split the costs and get paid back. <br />
              Have a great time!
            </span>
          </p>
        </div>
      );
    } else {
      return null;
    }
  }
}
const groupCreateWithMembersAndTransactions = `
mutation($transactionRecords: [TransactionRecordCreateUpdate!]!) {
  groupCreateWithMembersAndTransactions(
    name: "Ultimate Holiday Fish Braai"
    purpose: null
    imageUrl: "http://assets.dev.kin.business.s3.amazonaws.com/groups/5def77778de4af0001a530d7/5def77778de4af0001a530d7-primary.jpg?uid=96db25b9-afb5-439b-ae63-6b0c77e01320"
    members: []
    transactionRecords: $transactionRecords
  ) {
    id
  }
}
`;

export const setCampaignFlag = (ref: string | null) => {
  if (ref === "cooking-with-kin") {
    window.localStorage.setItem("kin_cooking-with-kin", JSON.stringify(true));
  }
};

export default CampaignTile;
