/**
 * Interface for defining an Segment Experiment
 *
 * @export
 * @interface ISegmentExperiment
 */
export interface ISegmentExperiment {
  experiment_id: string;
  experiment_name: string;
  variation_id: string;
  variation_name: string;
}

/**
 * Segment user interface
 *
 * @interface ISegmentUser
 */
interface ISegmentUser {
  name: string;
  id: string;
}

/**
 * Segment window interface
 *
 * @interface SegmentWindow
 * @extends {Window}
 */
interface SegmentWindow extends Window {
  analytics: {
    identify: any;
    track: any;
    page: any;
  };
}

/**
 * Window variable to Segment to attach to
 */
declare var window: SegmentWindow;

/**
 * Segment event types enum
 *
 * @export
 * @enum {number}
 */
export enum SegmentEvent {
  PAGE_SIGN_IN = "viewed_sign_in",
  PAGE_SIGN_IN_ZENDESK = "viewed_zendesk_sign_in",
  PAGE_PROFILE_SETUP = "viewed_profile_setup",
  PAGE_INVITE = "viewed_invite",
  PAGE_MY_KINS = "viewed_my_kins",
  PAGE_ACTIVITY = "viewed_activity",
  PAGE_PEOPLE = "viewed_people",
  PAGE_PERSON = "viewed_person",
  PAGE_EXPLORE = "viewed_explore",
  PAGE_404 = "viewed_404",
  PAGE_KIN = "viewed_kin",
  SIGN_IN_GOOGLE = "sign_in_google",
  SIGN_IN_FACEBOOK = "sign_in_facebook",
  SIGN_IN_EMAIL = "sign_in_email",
  DOWNLOAD_IOS = "download_ios_app_button",
  // 👆🏼 Custom web events
  // 👇🏼 Corresponds to app event name, different variable names
  // TODO: Rename to be consistent
  SIGN_OUT = "signed_out",
  BANNER_DISMISSED = "dismissed_app_banner",
  SIGN_UP_MODAL = "sign_up_modal",
  DOWNLOAD_ANDROID = "download_android_app_button",
  TRAVEL_KIN = "curated_kin_tapped_travel",
  HOUSEHOLD_KIN = "curated_kin_tapped_household",
  EVERYDAY_KIN = "curated_kin_tapped_everyday",
  // 👆🏼 Corresponds to app event name, different variable names
  // 👇🏼 Corresponds to app event name, consistent variable names
  ADD_ESTIMATE = "add_estimate",
  EDIT_ESTIMATE = "edit_estimate",
  DELETE_ESTIMATE = "delete_estimate",
  ADD_EXPENSE = "add_expense",
  EDIT_EXPENSE = "edit_expense",
  DELETE_EXPENSE = "delete_expense",
  ADD_EXTERNAL_PAYMENT = "add_external_payment",
  DELETE_EXTERNAL_PAYMENT = "delete_external_payment",
  ADD_FRIEND_ON_KIN = "add_friend_on_kin", // TODO
  ADD_GROUP = "add_group",
  ADD_GROUP_NAME = "add_group_name",
  ADD_GROUP_PURPOSE = "add_group_purpose",
  ADD_NAME = "add_name",
  EXPENSE_QUEUE_TIME = "expense_queue_time",
  INVITE_EXPIRED = "invite_expired",
  INVITE_LINK_CLICKED = "invite_link_clicked",
  INVITE_LINK_GENERATED = "invite_link_generated",
  INVITE_VIA_EMAIL = "invite_via_email",
  EDIT_GROUP_NAME = "edit_group_name",
  EDIT_GROUP_PURPOSE = "edit_group_purpose",
  EDIT_PAYMENT_AMOUNT = "edit_payment_amount",
  ADD_BANK = "add_bank",
  GENERATE_SIGNIN_LINK = "generate_signin_link",
  HOME_TAPPED = "home_tapped",
  EXPLORE_TAPPED = "explore_tapped",
  LEAVE_GROUP = "leave_group",
  NUDGE_FRIEND = "nudge_friend",
  KIN_ACTIVITY = "kin_activity",
  KIN_SETTLE = "kin_settle",
  KIN_TRANSACTIONS = "kin_transactions",
  PROFILE_TAPPED = "profile_tapped",
  // Unassigned events 👇🏼
  LOGIN = "login", // Uses same calls for signup/login. How to differentiate?
  JOIN_GROUP = "join_group",
  // Not sure if in web yet 👇🏼
  INVITE_VIA_MORE = "invite_via_more",
  INVITE_VIA_SMS = "invite_via_sms",
  DOWNLOAD_ANDROID_APP_BUTTON_APP_BANNER = "download_android_app_button_app_banner",
  DOWNLOAD_ANDROID_APP_BUTTON_INVITE_ERROR = "download_android_app_button_invite_error",
  GROUP_ICON_TYPE_SELECTED = "group_icon_type_selected",
  INVITE_VIA_WHATSAPP = "invite_via_whatsapp",
  MAKE_APP_PAYMENT = "make_app_payment",
  OPEN_COMMUNITY = "open_community",
  PRESIGNUP_CAROUSEL_S1 = "presignup_carousel_s1",
  PRESIGNUP_CAROUSEL_S2 = "presignup_carousel_s2",
  PRESIGNUP_CAROUSEL_S3 = "presignup_carousel_s3",
  PRESIGNUP_JOIN_NOW = "presignup_join_now",
  QUEUE_ERROR = "queue_error",
  REMOVE_HOLDING_CONTACT = "remove_holding_contact",
  RESET_PIN = "reset_pin",
  SHARE_BANK_DETAILS_COPY_TAPPED = "share_bank_details_copy_tapped",
  SHARE_BANK_DETAILS_SHARE_TAPPED = "share_bank_details_share_tapped",
  SHARE_GROUP_COPY_LINK_TAPPED = "share_group_copy_link_tapped",
  SHARE_GROUP_RESET_LINK_TAPPED = "share_group_reset_link_tapped",
  SHARE_GROUP_SHARE_LINK_TAPPED = "share_group_share_link_tapped",
  SHARE_VIA_EMAIL = "share_via_email",
  SHARE_VIA_MORE = "share_via_more",
  SHARE_VIA_SMS = "share_via_sms",
  SHARE_VIA_WHATSAPP = "share_via_whatsapp",
  TELL_A_FRIEND = "tell_a_friend",
  TRANSACTION_ICON_TYPE_SELECTED = "transaction_icon_type_selected",
  USER_ICON_TYPE_SELECTED = "user_icon_type_selected"
}

/**
 * Class for managing Segment events
 *
 * @class Segment
 */
class Segment {
  getClientTimeZone = (): string => {
    return (
      Intl.DateTimeFormat &&
      Intl.DateTimeFormat().resolvedOptions &&
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
  };

  /**
   * Format the event name to remove any space and replace it with _'s
   *
   * @param {string} eventName
   * @returns
   * @memberof Segment
   */
  public FormatEventName(eventName: string) {
    return eventName.toLocaleLowerCase().replace(/ /g, "_");
  }

  /**
   * Identify segment user
   *
   * @param {ISegmentUser} user
   * @returns
   * @memberof Segment
   */
  public Identify(user: ISegmentUser) {
    return window.analytics.identify(user.id, {
      name: user.name
    });
  }

  /**
   * Track event
   *
   * @param {string} description
   * @returns
   * @memberof Segment
   */
  public Track(description: string, properties: any = null) {
    const timeZone = this.getClientTimeZone();

    if (properties === null && !timeZone) {
      return window.analytics.track(this.FormatEventName(description));
    } else {
      return window.analytics.track(this.FormatEventName(description), {
        ...(timeZone && { timeZone }),
        ...properties
      });
    }
  }

  /**
   * Track page view
   *
   * @param {string} pageName
   * @returns
   * @memberof Segment
   */
  public Page(pageName: string) {
    const timeZone = this.getClientTimeZone();
    return window.analytics.page(pageName, { ...(timeZone && { timeZone }) });
  }
}

export default Segment;
