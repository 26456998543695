import React from "react";
import LayoutSidebar from "../../hoc/layout-sidebar";
import { menuItems } from "../../common/menu";
import GroupTile from "../../components/tiles/group";
import {
  IKinsStore,
  IGroupsQuery,
  IGroupMembers,
  GroupMembersStatus,
  INewKinImages
} from "../../stores/kinsStore";
import { observer, inject } from "mobx-react";

import "./kins.scss";
import KinsLoader from "./kins-loader";
import { RouteComponentProps } from "react-router";
import NewGroupTile from "../../components/tiles/new-group";

import goingAwayUrl from "../../images/going-away-kin.jpg";
import rentUrl from "../../images/rent-kin.jpg";
import coffeeUrl from "../../images/coffee-kin.jpg";
import plusIcon from "../../images/icon-plus.svg";
import Segment, { SegmentEvent } from "../../common/segment";
import { computed } from "mobx";
import {
  ILayoutSidebarStore,
  NewKinType
} from "../../stores/layoutSidebarStore";
import Emoji from "react-emoji-render";
import Button, { ButtonColors } from "../../components/button/button";
import { history } from "../../common/history";
import successImg from "../../images/kin_success.png";

/**
 * Kin props from stores
 *
 * @interface IKinsProps
 * @extends {RouteComponentProps}
 */
interface IKinsProps extends RouteComponentProps {
  kinsStore?: IKinsStore;
  layoutSidebarStore?: ILayoutSidebarStore;
}

interface IKinsState {
  hasSeenCampaignHighlight: boolean;
}

@inject("kinsStore", "layoutSidebarStore")
@observer
/**
 * Kins class for displaying the joined groups on your dashboard
 *
 * @class Kins
 * @extends {React.Component<IKinsProps, {}>}
 */
class Kins extends React.Component<IKinsProps, IKinsState> {
  @computed get kins(): IGroupsQuery[] {
    return this.props.kinsStore!.kins;
  }
  @computed get loading(): boolean {
    return this.props.kinsStore!.loading;
  }
  @computed get error(): boolean {
    return this.props.kinsStore!.error;
  }
  @computed get noticeState(): boolean {
    return this.props.layoutSidebarStore!.noticeState;
  }
  @computed get noticeMsg(): string {
    return this.props.layoutSidebarStore!.noticeMsg;
  }
  @computed get firstAppLoad(): boolean {
    return this.props.layoutSidebarStore!.firstAppLoad;
  }
  @computed get newKinImages(): INewKinImages[] {
    return this.props.kinsStore!.newKinImages;
  }
  @computed get highlightNewKin(): boolean {
    return this.props.kinsStore!.highlightNewKin;
  }

  constructor(props: IKinsProps) {
    super(props);
    this.state = {
      hasSeenCampaignHighlight: false
    };
  }

  /**
   * Set active menu item at page level
   *
   * @memberof Kins
   */
  componentDidMount() {
    // Fetch user groups
    this.props.kinsStore!.fetchKins();
    this.props.kinsStore!.fetchGroupTypeImages();
    new Segment().Page(SegmentEvent.PAGE_MY_KINS);
  }

  /**
   * Open group component
   *
   * @memberof Kins
   */
  openGroup(groupId: string) {
    this.props.history.push(`/home/kins/group/${groupId}`);
  }

  /**
   * Filter out groups that are 'InActive'
   *
   * @param {IGroupMembers[]} members
   * @returns
   * @memberof Kins
   */
  filterByGroupStatus(members: IGroupMembers[]) {
    return members.filter(member => {
      return member.status !== GroupMembersStatus.InActive;
    });
  }

  /**
   * Return the image url based on new kin type
   *
   * @param {NewKinType} type
   * @memberof Kins
   */
  getNewKinImageType(type: NewKinType): string {
    let newKinImage: INewKinImages[] = this.newKinImages.filter(
      image => image.key === type
    );
    // Check if the type matches
    if (this.newKinImages.length !== 0) {
      return newKinImage[0].value;
    }
    return "";
  }

  /**
   * Adding a new kin
   *
   * @memberof Kins
   */
  newKin(name: string, type: NewKinType) {
    this.props.layoutSidebarStore!.setNewKin({
      ...this.props.layoutSidebarStore!.newKin,
      name: name,
      type: type,
      imageUrl: this.getNewKinImageType(type),
      currencyIsoCode: this.props.kinsStore!.user.defaultCurrency!.isoCode
    });
    this.props.layoutSidebarStore!.setNewKinModal(true);
  }

  /**
   * Adding a new travel kin
   *
   * @memberof Kins
   */
  newTravelKin() {
    new Segment().Track(SegmentEvent.TRAVEL_KIN);
    this.newKin("Trip to ...", NewKinType.Travel);
  }

  /**
   * Adding a new household kin
   *
   * @memberof Kins
   */
  newHouseholdKin() {
    new Segment().Track(SegmentEvent.HOUSEHOLD_KIN);
    this.newKin("Our Home ...", NewKinType.Household);
  }

  /**
   * Adding a new everyday kin
   *
   * @memberof Kins
   */
  newEverydayKin() {
    new Segment().Track(SegmentEvent.EVERYDAY_KIN);
    this.newKin("Our ...", NewKinType.Everyday);
  }

  renderGroupTile(group: IGroupsQuery, index: number) {
    return (
      <GroupTile
        key={index}
        name={group.name}
        image={group.image}
        hidden={group.isHidden}
        balance={group.currentMember.summary.balance}
        members={this.filterByGroupStatus(group.members)}
        onClick={() => this.openGroup(group.id)}
      />
    );
  }

  /**
   * Renders the final component markup
   *
   * @returns
   * @memberof Kins
   */
  render() {
    return (
      <LayoutSidebar
        menuItems={menuItems}
        menuActiveItem={menuItems[0].title}
        noticeState={this.noticeState}
        noticeMsg={this.noticeMsg}
        errorState={this.error}
      >
        <div className="b-kins-page">
          <KinsLoader active={this.loading} />
          {!this.loading ? (
            <>
              {this.kins.map((group, index) =>
                index === 0 &&
                this.highlightNewKin &&
                this.kins.length === 1 ? (
                  <div className="e-new-kin" key={group.id}>
                    {this.renderGroupTile(group, index)}
                    <p>
                      <img
                        src={successImg}
                        alt="Success"
                        className="e-new-success"
                      />
                      <Emoji text="You’ve created your first Kin! 👊" />
                      <br />
                      <Emoji text="Here’s to happy money relationships." />
                    </p>
                    <Button
                      className="e-expense-button"
                      onClick={() =>
                        history.push(`/home/kins/group/${group.id}?expense=add`)
                      }
                      color={ButtonColors.Black}
                      small={true}
                      inline={true}
                      icon={plusIcon}
                      iconCircle={false}
                    >
                      New expense
                    </Button>
                    <p
                      className="m-link g-a m-no-underline"
                      onClick={() =>
                        this.props.kinsStore!.setHighlightNewKin(false)
                      }
                    >
                      Close
                    </p>
                  </div>
                ) : (
                  this.renderGroupTile(group, index)
                )
              )}
              {this.kins.length < 2 ? (
                <>
                  <NewGroupTile
                    title="Going away?"
                    ctaTitle="+ new travel Kin"
                    imageUrl={goingAwayUrl}
                    onClick={() => this.newTravelKin()}
                  />
                  <NewGroupTile
                    title="Splitting rent?"
                    ctaTitle="+ new household Kin"
                    imageUrl={rentUrl}
                    onClick={() => this.newHouseholdKin()}
                  />
                  <NewGroupTile
                    title="Office coffee run?"
                    ctaTitle="+ new everyday Kin"
                    imageUrl={coffeeUrl}
                    onClick={() => this.newEverydayKin()}
                  />
                </>
              ) : null}
            </>
          ) : null}
        </div>
      </LayoutSidebar>
    );
  }
}

export default Kins;
