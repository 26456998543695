import React from 'react';
import Pill from '../pill/pill';

import './pill-list.scss';

import profile1 from '../../images/pill-avatars/profile-1.png';
import profile2 from '../../images/pill-avatars/profile-2.png';
import profile3 from '../../images/pill-avatars/profile-3.png';
import profile4 from '../../images/pill-avatars/profile-4.png';
import profile5 from '../../images/pill-avatars/profile-13.png';
import profile6 from '../../images/pill-avatars/profile-14.png';
import profile7 from '../../images/pill-avatars/profile-9.png';
import profile8 from '../../images/pill-avatars/profile-8.png';
import profile9 from '../../images/pill-avatars/profile-7.png';
import profile10 from '../../images/pill-avatars/profile-10.png';
import profile11 from '../../images/pill-avatars/profile-11.png';
import profile12 from '../../images/pill-avatars/profile-12.png';

/**
 * Pill notification list component to display a list of pill components on the invite page
 *
 * @returns Hardcoded list of sample notifaction pills
 */
const usersPillList = () => {
    return ([
      {
        users: [
          {
            size: 30,
            image: profile1,
            name: "User Name"
          }
        ],
        msg: "Accommodation",
        color: 3
      },
      {
        users: [
          {
            size: 30,
            image: profile2,
            name: "User Name"
          },
          {
            size: 30,
            image: profile3,
            name: "User Name"
          }
        ],
        msg: "Sarah paid you! 💸💸",
        color: 2
      },
      {
        users: [
          {
            size: 30,
            image: profile4,
            name: "User Name"
          }
        ],
        msg: "Veggies 🌽🌽",
        color: 2
      },
      {
        users: [
          {
            size: 30,
            image: profile5,
            name: "User Name"
          },
          {
            size: 30,
            image: profile6,
            name: "User Name"
          }
        ],
        msg: "💸💸 You paid Sharron",
        color: 1
      },
      {
        users: [
          {
            size: 30,
            image: profile7,
            name: "User Name"
          }
        ],
        msg: "Juice ⛽️",
        color: 1
      },
      {
        users: [
          {
            size: 30,
            image: profile8,
            name: "User Name"
          },
          {
            size: 30,
            image: profile9,
            name: "User Name"
          }
        ],
        msg: "Derek paid Josh! 💸💸",
        color: 3
      },
      {
        users: [
          {
            size: 30,
            image: profile10,
            name: "User Name"
          }
        ],
        msg: "Meat 🥩",
        color: 2
      },
      {
        users: [
          {
            size: 30,
            image: profile11,
            name: "User Name"
          },
          {
            size: 30,
            image: profile12,
            name: "User Name"
          }
        ],
        msg: "Sharron paid Josh! 💸💸",
        color: 2
      }
    ])
}

/**
 * Pill list component for display a list of pills
 *
 * @returns
 */
const PillList: React.FC = () => {
    return (
      <div className="b-pill-list-container">
        <div className="e-pill-list">
            { usersPillList().map((pill, index) => {
                return (
                    <Pill delay={index * 100}
                        key={index}
                        users={pill.users}
                        msg={pill.msg}
                        color={pill.color} />
                )
            }) }
        </div>
      </div>
    )
}

export default PillList;