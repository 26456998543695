import React from "react";
import "./modal.scss";

import closeIcon from "../../images/icon-close.svg";
import backIcon from "../../images/icon-back.svg";

/**
 * Modal component props
 *
 * @interface IModalProps
 */
interface IModalProps {
  visible: boolean;
  center?: boolean;
  className?: string;
  title?: string; // This is new and not used by all modals
  onClick?: () => void;
}

/**
 * Modal component to wrap child content in styled popup.
 *
 * @param {*} props
 * @returns
 */
const Modal: React.FC<IModalProps> = props => {
  // Updating classes depending on the open or close state of the modal
  let modalClasses: string = "b-modal";

  if (props.visible) {
    modalClasses += " m-open";
    document.body.classList.add("g-no-scroll");
  } else {
    document.body.classList.remove("g-no-scroll");
  }

  // Center content of modal
  if (props.center) {
    modalClasses += " m-center";
  }

  if (props.className) {
    modalClasses += ` ${props.className}`;
  }

  return (
    <div className={modalClasses}>
      <div className="e-modal-box">
        <div className="e-close" onClick={props.onClick}>
          <img src={closeIcon} alt="Close" />
        </div>
        <div className="e-back" onClick={props.onClick}>
          <img src={backIcon} alt="Back" />
        </div>
        {props.title ? <div className="e-modal-title">{props.title}</div> : null}
        {props.children}
      </div>
      <div className="e-overlay" onClick={props.onClick} />
    </div>
  );
};

export default Modal;
