import React from "react";
import playstoreBtn from "../../images/playstore_new.svg";
import Segment, { SegmentEvent } from "../../common/segment";
import DynamicLinkBuilder from "../../common/dynamic-link-builder";
import { isAndroid, isBrowser } from "react-device-detect";

interface IPlaystoreButtonProps {
  white?: boolean;
  id?: string | undefined;
  style?: any;
  eventLocation?: string;
}

/**
 * Rendering the Play store button
 *
 * @returns
 */
const PlaystoreButton: React.FC<IPlaystoreButtonProps> = props => {
  /**
   * Segment event to fire on button click
   *
   */
  const fireEvent = () => {
    // Override with custom event name if applicable
    let eventName: string = SegmentEvent.DOWNLOAD_ANDROID;
    if (props.eventLocation) {
      eventName += `_${new Segment().FormatEventName(props.eventLocation)}`;
    }
    new Segment().Track(eventName);
  };

  /**
   * Play store link
   *
   * @private
   * @type {string}
   * @memberof Invite
   */
  let playstoreLink: string = `https://play.google.com/store/apps/details?id=${process.env.REACT_APP_PACKAGE_NAME}`;

  return isAndroid || isBrowser ? (
    <a
      href={DynamicLinkBuilder.buildLink(playstoreLink)}
      onClick={() => fireEvent()}
      style={props.style}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={playstoreBtn} alt="Google Play" />
    </a>
  ) : null;
};

export default PlaystoreButton;
