import UrlHelper from './url-helper';
/**
 * class to handle zendesk integration
 *
 * @class Zendesk
 */
class Zendesk {
  /**
   * Uniquely identifies the zendesk app id as passed from external systems.
   *
   * @returns {string}
   * @memberof Zendesk
   */
  static APP_ID = 'zendesk';

  /**
   * Performs a redirect to Zendek for the currently logged in user.
   *
   * @returns {Promise<any>}
   * @memberof Zendesk
   */
  authorize(appToken: string, returnToUri: string | null, getAuthRedirectSuccessState : any, getAuthRedirectFailureState : any): Promise<any> {
    const redirectUri = !!returnToUri ? 
      UrlHelper.appendQueryString(this.getAuthorizationRedirectUri(), 'return_to', returnToUri) : 
        this.getAuthorizationRedirectUri();

    return fetch(redirectUri, { 
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${appToken}`,
      } 
    })
    .then((result) => result.json())
    .then((responseJson) => getAuthRedirectSuccessState(responseJson.url))
    .catch((ex) => getAuthRedirectFailureState(ex));
  }

  /**
   * Gets the uri to authorize and redirect to the currently logged in user.
   *
   * @returns {string}
   * @memberof Zendesk
   */
  getAuthorizationRedirectUri(): string {
    return process.env.REACT_APP_ZENDESK_AUTH_REDIRECT_PATH || '';
  }

   /**
   * determines whether a given appId matches the zendesk app id.
   *
   * @returns {boolean}
   * @memberof Zendesk
   */
  isZendeskAppId(appId: string | null): boolean {
    return !!appId ? appId === Zendesk.APP_ID : false;
  }
}

export default Zendesk;