import React from "react";

import "./checkbox.scss";

import unchecked from "../../images/checkbox_unchecked.svg";
import checkbox from "../../images/checkbox_checked_box.svg";
import checkmark from "../../images/checkbox_checked_tick.svg";

/**
 * Checkbox interface
 *
 * @interface ICheckboxProps
 */
interface ICheckboxProps {
  checked?: boolean;
  onClick: () => void;
}

/**
 * Component to render a checkbox element
 *
 * @param {ICheckboxProps} props
 * @returns
 */
const Checkbox: React.FC<ICheckboxProps> = (props: ICheckboxProps) => {
  return (
    <div className="b-checkbox" onClick={() => props.onClick()}>
      {props.checked ? <img className="e-checkmark" src={checkmark} alt="Checkmark" /> : null}
      <img className="e-box" src={props.checked ? checkbox : unchecked} alt="Checkbox" />
    </div>
  );
};

/**
 * Default checkbox props
 */
Checkbox.defaultProps = {
  checked: false
};

export default Checkbox;
