import React from "react";

import "./navigation.scss";
import menuIcon from "../../images/icon-burger.svg";
import newKinIcon from "../../images/plus-circle.svg";
import { isMobile, isTablet } from "react-device-detect";

/**
 * Navigation header props
 *
 * @interface INavigationHeaderProps
 */
interface INavigationHeaderProps {
  menuClick: () => void;
  newKinClick?: (() => void) | null;
  title: string;
}

/**
 * Navigation component to display a title and some action buttons
 *
 * @class NavigationHeader
 * @extends {React.Component<INavigationHeaderProps, {}>}
 */
class NavigationHeader extends React.Component<INavigationHeaderProps, {}> {
  render() {
    return (
      <div className="b-nav">
        <div className="e-nav-container">
          <div className="e-nav-icon m-menu" onClick={() => this.props.menuClick()}>
            <img src={menuIcon} alt="Menu" />
          </div>
          <div className="e-nav-title">{this.props.title}</div>
          <div
            className="e-nav-icon m-new-kin"
            onClick={this.props.newKinClick ? () => this.props.newKinClick!() : () => {}}
          >
            {this.props.newKinClick && isMobile && !isTablet ? (
              <img src={newKinIcon} alt="Add New Kin" />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default NavigationHeader;
