import React from "react";
import "./balance-summary.scss";
import iconSuccess from "../../images/money-received.svg";
import iconDanger from "../../images/credit-card.svg";
import { IAmount } from "../../stores/peopleStore";

interface IBalanceSummaryProps {
  amounts: IAmount[];
  label: string;
  icon?: boolean | string;
  spacing?: string;
  type?: string;
}

function BalanceSummary(props: IBalanceSummaryProps) {
  const { amounts, label, icon, spacing, type } = props;
  const iconUrl = icon ? icon : type;

  return amounts.filter(a => a.cents !== 0).length ? (
    <div
      className={`b-summary-card m-summary-card-type:${type} m-summary-card-spacing:${spacing}`}
    >
      <p className="e-summary-card-title">
        {spacing === "loose" ? (
          <img
            className="e-summary-card-icon"
            src={showIcon(iconUrl)}
            alt="Wallet"
          />
        ) : null}
        <span>{label}</span>
      </p>
      {amounts.map((amount, index) =>
        amount.cents !== 0 ? (
          <p
            className={`e-summary-card-amount:${
              spacing === "tight" || index === 0 ? "primary" : "secondary"
            }`}
            key={`${amount.currencyIso}`}
          >
            {amount.formatted}
          </p>
        ) : null
      )}
    </div>
  ) : null;
}

function showIcon(iconType: any) {
  switch (iconType) {
    case "danger":
      return iconDanger;
    case "success":
      return iconSuccess;
    default:
      return iconType;
  }
}

BalanceSummary.defaultProps = {
  amounts: [],
  label: "Owed to you",
  iconUrl: false,
  spacing: "loose",
  type: "success"
};

export default BalanceSummary;
