import { observable, action } from "mobx";
import moment from "moment";
import { isMobile, isTablet } from "react-device-detect";
import Authentication from "../common/authentication";
import KinGraphQL from "../common/graphql";
import Notices from "../common/snackbars";
import { getPlaceholderImage } from "./kinStore";
import Segment, { SegmentEvent } from "../common/segment";
import { queryAuthenticated } from "../common/graphql-utils";

/**
 * Query to create a group
 *
 * @export
 * @param {string} name
 * @param {(string | null)} [purpose=null]
 * @param {(string | null)} [imageUrl=null]
 */
export const createGroupQuery = (
  name: string,
  purpose: string | null = null,
  imageUrl: string,
  currencyIsoCode: string
) => `
  mutation {
    groupCreate(
      name: "${name}"
      purpose: "${purpose}"
      imageUrl: "${imageUrl}"
      currencyIsoCode: "${currencyIsoCode}"
    ) {
      id
    }
  }
`;

/**
 * Query to add members to a group on new kin creation
 *
 * @export
 * @param {string} groupId
 * @param {Array} members
 */
export const createAddMembersQuery = (): string => `
mutation($groupId: String!, $members: [GroupAddMembersMember!]!) {
  groupAddMembers (
    groupId: $groupId,
    members: $members
  ) {
    id
  }
}
`;

/**
 * Query for a single group, mainly used to get group invite link
 * on new kin creation
 *
 * @export
 * @param {string} id
 */
export const groupQuery = (id: string) => `
  query {
    group(id: "${id}") {
      groupInviteUrl
    }
  }
`;

export enum NewKinType {
  Everyday = "everyday",
  Household = "household",
  Travel = "travel"
}

export interface INewMember {
  name: string;
  email: string;
  shouldInviteViaEmail: boolean;
}

export interface INewKin {
  groupId: string | undefined;
  image: any;
  imageUrl: string;
  name: string;
  purpose: string;
  type: NewKinType;
  members: INewMember[];
  currencyIsoCode: string;
}

export interface ILayoutSidebarStore {
  appBanner: boolean;
  menuPanel: boolean;
  kinSettings: boolean;
  profileSettings: boolean;
  noticeState: boolean;
  noticeMsg: string;
  nonFeatureModal: boolean;
  newKinModal: boolean;
  newKinModalCurrencies: boolean;
  newKinModalAddMembers: boolean;
  newKinModalSharing: boolean;
  newKin: INewKin;
  newKinShareUrl: string | undefined;
  creatingKin: boolean;
  error: boolean;
  firstAppLoad: boolean;
  currenciesPanel: boolean;
  createKin(callback?: any): void;
  fetchInviteLink(groupId: string): void;
  addMembersToKin(): Promise<any>;
  setAppBanner(banner: boolean): void;
  setMenuPanel(menu: boolean): void;
  setKinSettings(settings: boolean): void;
  setProfileSettings(settings: boolean): void;
  setProfileSettingsDirect(state: boolean): void;
  setNotice(state: boolean, msg: string | undefined): void;
  setNonFeatureModal(state: boolean): void;
  setNewKinModal(state: boolean): void;
  setNewKinModalCurrency(state: boolean): void;
  setNewKinModalAddMembers(state: boolean): void;
  setNewKinAddMember(): void;
  setNewKinModalSharing(state: boolean): void;
  setNewKin(newKin: INewKin): void;
  setNewKinCurrency(currencyIsoCode: string): void;
  setNewKinShareUrl(url: string | undefined): void;
  setCreatingKin(state: boolean): void;
  setError(error: boolean): void;
  setFirstAppLoad(state: boolean): void;
  setCurrenciesPanel(state: boolean): void;
}

/**
 * Default values for new kin member
 */
const defaultMember: INewMember = {
  name: "",
  email: "",
  shouldInviteViaEmail: true
};

/**
 * Default values for new kin object
 */
const defaultNewKin = {
  groupId: undefined,
  image: undefined,
  imageUrl: "",
  name: "",
  purpose: "Track and share expenses",
  shareUrl: undefined,
  type: NewKinType.Travel,
  members: [defaultMember],
  currencyIsoCode: ""
};

export class LayoutSidebarStore implements ILayoutSidebarStore {
  /**
   * UI state for app banner display
   *
   * @type {boolean}
   * @memberof LayoutSidebarStore
   */
  @observable appBanner: boolean;
  /**
   * UI state for menu panel display
   *
   * @type {boolean}
   * @memberof LayoutSidebarStore
   */
  @observable menuPanel: boolean;
  /**
   * UI state for kin settings display
   *
   * @type {boolean}
   * @memberof LayoutSidebarStore
   */
  @observable kinSettings: boolean;
  /**
   * UI state for profile setting display
   *
   * @type {boolean}
   * @memberof LayoutSidebarStore
   */
  @observable profileSettings: boolean;
  /**
   * UI state for notice msg
   *
   * @type {boolean}
   * @memberof LayoutSidebarStore
   */
  @observable noticeState: boolean;
  /**
   * Notice msg
   *
   * @type {string}
   * @memberof LayoutSidebarStore
   */
  @observable noticeMsg: string;
  /**
   * UI state to display "not a web app feature" modal
   *
   * @type {boolean}
   * @memberof LayoutSidebarStore
   */
  @observable nonFeatureModal: boolean;
  /**
   * UI state to show new kin modal
   *
   * @type {boolean}
   * @memberof LayoutSidebarStore
   */
  @observable newKinModal: boolean;
  /**
   * UI state to show currencies in new kin modal
   *
   * @type {boolean}
   * @memberof LayoutSidebarStore
   */
  @observable newKinModalCurrencies: boolean;
  /**
   * UI state to show the new kin add members modal
   *
   * @type {boolean}
   * @memberof LayoutSidebarStore
   */
  @observable newKinModalAddMembers: boolean;
  /**
   * UI state for showing the new kin sharing content
   *
   * @type {boolean}
   * @memberof LayoutSidebarStore
   */
  @observable newKinModalSharing: boolean;
  /**
   * New kin object to store the captured data
   *
   * @type {INewKin}
   * @memberof LayoutSidebarStore
   */
  @observable newKin: INewKin;
  /**
   * URL used to invite other users to the new kin
   *
   * @type {(string | undefined)}
   * @memberof LayoutSidebarStore
   */
  @observable newKinShareUrl: string | undefined;
  /**
   * UI state while creating Kin request
   *
   * @type {boolean}
   * @memberof LayoutSidebarStore
   */
  @observable creatingKin: boolean;
  /**
   * UI state for when any error occurs
   *
   * @type {boolean}
   * @memberof LayoutSidebarStore
   */
  @observable error: boolean;
  /**
   * To determine logic on first load
   *
   * @type {boolean}
   * @memberof LayoutSidebarStore
   */
  @observable firstAppLoad: boolean;

  @observable currenciesPanel: boolean;

  /**
   *Creates an instance of LayoutSidebarStore.
   * @memberof LayoutSidebarStore
   */
  constructor() {
    /**
     * Setting default values
     */
    this.appBanner = true;
    this.menuPanel = false;
    this.kinSettings = false;
    this.profileSettings = false;
    this.noticeState = false;
    this.noticeMsg = "";
    this.nonFeatureModal = false;
    this.newKinModal = false;
    this.newKinModalCurrencies = false;
    this.newKinModalAddMembers = false;
    this.newKinModalSharing = false;
    this.newKin = {
      ...defaultNewKin
    };
    this.newKinShareUrl = "";
    this.creatingKin = false;
    this.error = false;
    this.firstAppLoad = true;
    this.currenciesPanel = false;
  }

  /**
   * Send request to create the kin
   *
   * @param {INewKin} kin
   * @memberof LayoutSidebarStore
   */
  createKin(callback: any = null): void {
    this.setCreatingKin(true);
    // Initialise Authentication with refreshed token
    queryAuthenticated(
      createGroupQuery(
        this.newKin.name,
        this.newKin.purpose,
        this.newKin.imageUrl.length !== 0
          ? this.newKin.imageUrl
          : getPlaceholderImage(),
        this.newKin.currencyIsoCode
      )
    ).then(
      result => {
        // Add the new Kin's id to the store
        this.setNewKin({
          ...this.newKin,
          groupId: result.data.data.groupCreate.id
        });
        // Upload Kin photo
        if (
          this.newKin.image !== undefined &&
          this.newKin.imageUrl.length !== 0
        ) {
          this.uploadNewKinPhoto(result.data.data.groupCreate.id, () =>
            callback()
          );
        } else {
          // Send ADD_GROUP event to Segment
          new Segment().Track(SegmentEvent.ADD_GROUP);
          new Segment().Track(SegmentEvent.ADD_GROUP_NAME);
          if (this.newKin.purpose !== defaultNewKin.purpose) {
            new Segment().Track(SegmentEvent.ADD_GROUP_PURPOSE);
          }
          // Reset UI for creating Kin state
          this.setCreatingKin(false);
          // Switch to sharing screen
          this.setNewKinModalAddMembers(true);
          // If the callback defined, execute it
          if (callback) {
            callback();
          }
        }
        // Get sharable link for next screen
        this.fetchInviteLink(result.data.data.groupCreate.id);
      },
      () => {
        this.setCreatingKin(false);
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
      }
    );
  }

  uploadNewKinPhoto(groupId: string, callback: any = null) {
    // Initialise Authentication with refreshed token
    new Authentication().getToken().then(token => {
      new KinGraphQL()
        .uploadKinPhoto(this.newKin.image, groupId, token)
        .subscribe({
          next: result => {
            if (result.data.errors) {
              // Trigger error
              new Notices().GlobalTrigger(() => {
                this.setError(!this.error);
              });
              // Reset UI state
              this.setCreatingKin(false);
            } else {
              // Reset UI for creating Kin state
              this.setCreatingKin(false);
              // Switch to sharing screen
              // this.setNewKinModalSharing(true);
              this.setNewKinModalAddMembers(true);
              // If the callback defined, execute it
              if (callback) {
                callback();
              }
            }
          },
          error: (err: any) => {
            // Update UI state
            this.setCreatingKin(false);
            // Trigger error
            new Notices().GlobalTrigger(() => {
              this.setError(!this.error);
            });
            console.error(err);
          }
        });
    });
  }

  /**
   * Send request to add members to the kin
   *
   * @param {INewKin} kin
   * @memberof LayoutSidebarStore
   */
  addMembersToKin(): Promise<any> {
    const members = this.newKin.members.filter(x => x.name);
    if (members.length === 0) {
      // No members to add so moving on to next modal.
      this.setCreatingKin(false);
      this.setNewKinModalAddMembers(false);
      this.setNewKinModalSharing(true);
      this.fetchInviteLink(this.newKin.groupId!);
      return Promise.resolve(true);
    }

    this.setCreatingKin(true);
    const promise = queryAuthenticated(createAddMembersQuery(), {
      groupId: this.newKin.groupId,
      members: [...members]
    });
    // Initialise Authentication with refreshed token 1
    promise.then(
      () => {
        // Send ADD_FRIEND_ON_KIN events to Segment
        this.newKin.members.forEach(() => {
          new Segment().Track(SegmentEvent.SHARE_VIA_EMAIL);
        });
        // Reset UI state
        this.setCreatingKin(false);
        this.setNewKinModal(false);
        // Get sharable link for next screen
        this.fetchInviteLink(this.newKin.groupId!);
      },
      () => {
        this.setCreatingKin(false);
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
      }
    );
    return promise;
  }

  /**
   * Fetches the invite link to invite users to a group
   *
   * @memberof LayoutSidebarStore
   */
  fetchInviteLink(groupId: string): void {
    // Initialise Authentication with refreshed token
    queryAuthenticated(groupQuery(groupId)).then(
      result => {
        new Segment().Track(SegmentEvent.INVITE_LINK_GENERATED);
        // Update Kin invite url
        this.setNewKinShareUrl(result.data.data.group.groupInviteUrl);
      },
      () => {
        this.setCreatingKin(false);
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
      }
    );
  }

  /**
   * Setting state values
   */
  @action setAppBanner(state: boolean): void {
    const bannerKey: string = "kin_banner";
    // Load dismiss expiry date from local storage if any
    let appBannerDismissExpiry: string | null = window.localStorage.getItem(
      bannerKey
    );
    // Only if trying to activate the banner
    if (state) {
      // Look for an expiry date
      if (appBannerDismissExpiry !== null) {
        // Show banner if expired and remove storage key
        if (moment().isAfter(appBannerDismissExpiry)) {
          this.appBanner = true;
          window.localStorage.removeItem(bannerKey);
          document
            .getElementsByTagName("body")[0]
            .classList.add("g-app-banner");
        } else {
          // Otherwise hide banner
          this.appBanner = false;
          document
            .getElementsByTagName("body")[0]
            .classList.remove("g-app-banner");
        }
      } else {
        // Show banner if no expiry date is set
        this.appBanner = state;
        document.getElementsByTagName("body")[0].classList.add("g-app-banner");
      }
    } else {
      // Set new expired date
      window.localStorage.setItem(
        bannerKey,
        moment()
          .add(7, "days")
          .format("YYYY-MM-DD")
      );
      this.appBanner = state;
      document.getElementsByTagName("body")[0].classList.remove("g-app-banner");
    }
  }
  @action setMenuPanel(menu: boolean): void {
    this.menuPanel = menu;
  }
  @action setProfileSettings(settings: boolean): void {
    this.profileSettings = settings;
  }
  @action setProfileSettingsDirect(state: boolean): void {
    if (isMobile && !isTablet) {
      this.menuPanel = state;
    }
    this.profileSettings = state;
  }
  @action setKinSettings(settings: boolean): void {
    this.kinSettings = settings;
  }
  @action setNotice(state: boolean, msg: string | undefined): void {
    this.noticeState = state;
    // Only set custom notice msg if there is one
    if (msg !== undefined) {
      this.noticeMsg = msg;
    }
  }
  @action setNonFeatureModal(state: boolean): void {
    this.nonFeatureModal = state;
  }
  @action setNewKinModal(state: boolean): void {
    if (!state) {
      this.newKin = {
        ...defaultNewKin
      };
      this.newKinModalSharing = false;
    }
    this.newKinModal = state;
  }
  @action setNewKinModalSharing(state: boolean): void {
    this.newKinModalSharing = state;
  }
  @action setNewKinModalCurrency(state: boolean): void {
    this.newKinModalCurrencies = state;
  }
  @action setNewKinModalAddMembers(state: boolean): void {
    this.newKinModalAddMembers = state;
  }
  @action setNewKin(newKin: INewKin): void {
    this.newKin = newKin;
  }
  @action setNewKinCurrency(currencyIsoCode: string): void {
    this.newKin = {
      ...this.newKin,
      currencyIsoCode
    };
  }
  @action setNewKinAddMember(): void {
    this.newKin = {
      ...this.newKin,
      members: [...this.newKin.members, defaultMember]
    };
  }
  @action setNewKinShareUrl(url: string | undefined): void {
    this.newKinShareUrl = url;
  }
  @action setCreatingKin(state: boolean): void {
    this.creatingKin = state;
  }
  @action setError(error: boolean): void {
    this.error = error;
  }
  @action setFirstAppLoad(state: boolean): void {
    this.firstAppLoad = state;
  }
  @action setCurrenciesPanel(state: boolean): void {
    this.currenciesPanel = state;
  }
}
