import { observable, action } from "mobx";
import {
  IPeopleConnectionGroupBalance,
  IPeopleBalanceSummaryWithIsoCodePair
} from "./peopleStore";
import { IAvatarProps } from "../components/avatar/avatar";
import _ from "lodash";
import Notices from "../common/snackbars";
import Segment, { SegmentEvent } from "../common/segment";
import { GroupMembersStatus } from "./kinsStore";
import { queryAuthenticated } from "../common/graphql-utils";

export interface ISettlementSpecContext {
  groupId: string;
  memberId: string;
  amount: IAmountInput;
}
export interface ISettlementSpec {
  description: null;
  to: ISettlementSpecContext[] | null;
}

/**
 * Query to pay a person if the user owes the person money
 *
 * @export
 * @param {ISettlementSpec} settlementSpec
 */
export const personPayQuery = (
  fromConnectionId: string | null,
  settlementSpec: ISettlementSpec
) => `
  mutation {
    settle(
      fromConnectionId: ${
        fromConnectionId ? '"' + fromConnectionId + '"' : null
      }
      settlement: ${
        settlementSpec
          ? JSON.stringify(settlementSpec).replace(/"([^(")"]+)":/g, "$1:")
          : null
      }
    ) {
      command
    }
  }
`;

export interface IAmountInput {
  currencyIso: string;
  cents: number;
}

/**
 * Send nudge to connection for total amount owed.
 *
 * @export
 * @param {string} connectionId
 * @param {(IAmountInput | null)} [amount=null]
 */
export const personConnectionNudgeQuery = (
  connectionId: string,
  amount: IAmountInput | null = null
) => `
  mutation {
    connectionNudge(
      connectionId: "${connectionId}"
      amount: ${JSON.stringify(amount).replace(/"([^(")"]+)":/g, "$1:")}
    ) {
      command
    }
  }
`;

/**
 * Person object interface
 *
 * @export
 * @interface IPerson
 */
export interface IPerson {
  name: string;
  image: string;
  id: string;
  groupBalances: IPeopleConnectionGroupBalance[];
  connectionId?: string;
  status: GroupMembersStatus;
  userId: string;
  email?: string | null;
  balances?: IPeopleBalanceSummaryWithIsoCodePair[];
}

/**
 * Person store interface
 *
 * @export
 * @interface IPersonStore
 */
export interface IPersonStore {
  loading: boolean;
  error: boolean;
  person: any | undefined;
  balance: number;
  users: IAvatarProps[];
  payNudgeLoading: boolean;
  remindPaid: boolean;
  personPay(
    fromConnectionId: string | null,
    settlementSpec: ISettlementSpec
  ): Promise<any>;
  connectionNudge(
    connectionId: string,
    amount?: IAmountInput | null
  ): Promise<any>;
  setLoading(state: boolean): void;
  setError(state: boolean): void;
  setPerson(person: IPerson | undefined): void;
  setUsers(users: IAvatarProps[]): void;
  setPayNudgeLoading(state: boolean): void;
  setRemindPaid(state: boolean): void;
}

/**
 * Person store class for defining the person object in the person view
 *
 * @export
 * @class PersonStore
 * @implements {IPersonStore}
 */
export class PersonStore implements IPersonStore {
  /**
   *  UI loading state
   *
   * @type {boolean}
   * @memberof PersonStore
   */
  @observable loading: boolean;
  /**
   *  UI error state
   *
   * @type {boolean}
   * @memberof PersonStore
   */
  @observable error: boolean;
  /**
   * Person object
   *
   * @type {(IPerson | undefined)}
   * @memberof PersonStore
   */
  @observable person: IPerson | undefined;
  /**
   * Total balance for the person
   *
   * @type {number}
   * @memberof PersonStore
   */
  @observable balance: number;
  /**
   * User object list for making payments
   *
   * @type {IAvatarProps[]}
   * @memberof PersonStore
   */
  @observable users: IAvatarProps[];
  /**
   * UI state for paying or nudging a person
   *
   * @type {boolean}
   * @memberof PersonStore
   */
  @observable payNudgeLoading: boolean;
  /**
   * Remind/Paid on person level
   *
   * @type {boolean}
   * @memberof PersonStore
   */
  @observable remindPaid: boolean;

  /**
   * Creates an instance of PersonStore.
   * @memberof PersonStore
   */
  constructor() {
    this.loading = false;
    this.error = false;
    this.person = undefined;
    this.balance = 0;
    this.users = [];
    this.payNudgeLoading = false;
    this.remindPaid = false;
  }

  /**
   * Paying a person what the users owes them.
   * The amount automatically works out the difference with owed amounts
   *
   * @param {ISettlementSpec} settlementSpec
   * @memberof PersonStore
   */
  personPay(
    fromConnectionId: string | null = null,
    settlementSpec: ISettlementSpec
  ): Promise<any> {
    // Update loading state
    this.setPayNudgeLoading(true);
    const promise = queryAuthenticated(
      personPayQuery(fromConnectionId, settlementSpec)
    );
    promise.then(
      () => {
        // Reset loading state
        this.setPayNudgeLoading(false);
        // Execute callback if defined
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        // Reset loading state
        this.setPayNudgeLoading(false);
      }
    );
    return promise;
  }

  /**
   * Nudging a connection to pay the current user
   *
   * @param {string} connectionId
   * @param {(IAmountInput | null)} [amount]
   * @memberof PersonStore
   */
  connectionNudge(
    connectionId: string,
    amount: IAmountInput | null = null
  ): Promise<any> {
    // Update loading state
    this.setPayNudgeLoading(true);
    const promise = queryAuthenticated(
      personConnectionNudgeQuery(connectionId, amount)
    );
    // Initialise Authentication with refreshed token
    promise.then(
      response => {
        new Segment().Track(SegmentEvent.NUDGE_FRIEND);
        // Reset loading state
        this.setPayNudgeLoading(false);
        return response;
      },
      () => {
        // Trigger error
        this.setError(!this.error);
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        this.setPayNudgeLoading(false);
      }
    );
    return promise;
  }

  /**
   * Setting state values
   */
  @action setLoading(state: boolean): void {
    this.loading = state;
  }
  @action setError(state: boolean): void {
    this.error = state;
  }
  @action setPerson(person: IPerson | undefined): void {
    this.person = person;
    if (person) {
      // Get total values
      let totalIAmOwed: number = _.sumBy(
        person.groupBalances,
        balance => balance.balanceSummary.totalIAmOwed.cents
      );
      let totalOwe: number = _.sumBy(
        person.groupBalances,
        balance => balance.balanceSummary.totalOwe.cents
      );
      // If the the difference is negative, the user still owes money,
      // positive means the user gets back
      this.balance = totalIAmOwed - totalOwe;
    }
  }
  @action setUsers(users: IAvatarProps[]): void {
    this.users = users;
  }
  @action setPayNudgeLoading(state: boolean): void {
    this.payNudgeLoading = state;
  }
  @action setRemindPaid(state: boolean): void {
    this.remindPaid = state;
  }
}
