import UrlHelper from "./url-helper";


/**
 * Class used to build dynamic links for play store and app store
 *
 * @class DynamicLinkBuilder
 */
class DynamicLinkBuilder {

  static buildLink(url: string): string {
    const isAndroid = url.indexOf('play.google.com') >= 0;
    
    let currentPage = window.location.href.replace("http://localhost:3000","https://web.dev.kin.me");
    //Workaround while we are moving over to kin.me (Remove this line once clients have been updated (should be after 2019/10/3))
    currentPage = currentPage.replace("kin.me","thekinapp.com");

    currentPage = UrlHelper.appendQueryString(currentPage, "anid", DynamicLinkBuilder.getSegmentAnon());
    if (isAndroid) {
      url = UrlHelper.appendQueryString(url, "url", currentPage);
    }
    else {
      url = `https://${process.env.REACT_APP_DYNAMIC_LINK_DOMAIN}/`;
      url = UrlHelper.appendQueryString(url, "link", currentPage);
      url = UrlHelper.appendQueryString(url, "apn", process.env.REACT_APP_PACKAGE_NAME as string);
      url = UrlHelper.appendQueryString(url, "ibi", process.env.REACT_APP_IOS_BUNDLE_ID as string);
      url = UrlHelper.appendQueryString(url, "isi", process.env.REACT_APP_IOS_APP_ID as string);
      url = UrlHelper.appendQueryString(url, "ofl", process.env.REACT_APP_FALLBACK_LINK as string);
      url = UrlHelper.appendQueryString(url, "efr", "1");
    }
    return url;
  }

  
  private static safeReadCookie() {
    var cookie;
    try {
      cookie = document.cookie;
    }
    catch (err) {
      if (typeof console !== "undefined" &&
        typeof console.error === "function") {
        console.error(err.stack || err);
      }
      cookie = "";
    }
    return cookie;
  }

  private static getSegmentAnon(): string | null {
    var cookie = DynamicLinkBuilder.safeReadCookie();
    const name = "ajs_anonymous_id";
    var value =
      cookie
        .split(";")
        .map(c => c.trim())
        .filter(cookie => {
          return cookie.substring(0, name.length + 1) === `${name}=`;
        })
        .map(cookie => {
          return decodeURIComponent(cookie.substring(name.length + 1));
        })
        .map(url => url.replace(/"/g, ""))[0] || null;
    return value;
  }
}

export default DynamicLinkBuilder;