import React from "react";

import "./notice.scss";

/**
 * Notice msg props
 *
 * @interface INoticeSnackbarProps
 */
interface INoticeSnackbarProps {
  msg?: string;
  show?: boolean;
}

/**
 * Creates notice snackbar component
 *
 * @param {*} props
 * @returns Notice snackbar
 */
const NoticeSnackbar: React.FC<INoticeSnackbarProps> = props => {
  let bodyClasses: string = "b-notice-snackbar";
  if (props.show) {
    bodyClasses += " m-active";
  }
  return <div className={bodyClasses}>{props.msg}</div>;
};

/**
 * Notice snackbar default props
 */
NoticeSnackbar.defaultProps = {
  msg: "Completed Successfully.",
  show: true
};

export default NoticeSnackbar;
