import React from "react";

import "./app-banner.scss";

import closeIcon from "../../images/icon-close_white.svg";
import AppstoreButton from "../stores/appstore";
import PlaystoreButton from "../stores/playstore";
import Segment, { SegmentEvent } from "../../common/segment";

/**
 * App banner props
 *
 * @interface IAppBannerProps
 */
interface IAppBannerProps {
  onClick: () => void;
}

/**
 * Component to render the app banner
 *
 * @param {*} props
 * @returns
 */
const AppBanner: React.FC<IAppBannerProps> = props => {
  const bannerClose = () => {
    new Segment().Track(SegmentEvent.BANNER_DISMISSED);
    props.onClick();
  };

  return (
    <div className="b-app-banner">
      <div className="e-description">
        <strong>Our web version currently has limited features.</strong>
        <br />
        Download the mobile app for all features.
      </div>
      <div className="e-app-btns">
        <AppstoreButton eventLocation="App Banner" />
        <PlaystoreButton eventLocation="App Banner" />
      </div>
      <div className="e-close" onClick={() => bannerClose()}>
        <img src={closeIcon} alt="Close Modal" />
      </div>
    </div>
  );
};

export default AppBanner;
