import KinGraphQL from "./graphql";
import Authentication from "./authentication";
import { Observable, Subject } from "rxjs";
import { AxiosResponse } from "axios";

export async function queryAuthenticated(
  requestQuery: string,
  variables: Object = {}
): Promise<AxiosResponse<any>> {
  const kinGraphQLService = new KinGraphQL();
  const kinAuthService = new Authentication();
  let token = await kinAuthService.getToken();
  const queryResult = await kinGraphQLService.queryWithToken(
    requestQuery,
    token,
    variables
  );
  if (queryResult.data.errors) {
    console.warn(
      "GraphQL error reported while processing [" +
        requestQuery.substring(0, 30) +
        "]: ",
      queryResult.data.errors.map((x: any) => x.message)
    );
    throw queryResult.data.errors;
  }
  return queryResult;
}

export async function queryAuthenticatedOrLoadFromCache(
  requestQuery: string,
  variables: Object = {}
): Promise<any> {
  const kinGraphQLService = new KinGraphQL();
  const kinAuthService = new Authentication();
  var cachedValue = await kinGraphQLService.fetchStoredRequest(requestQuery);
  if (cachedValue !== null) return cachedValue;
  await kinAuthService.getToken();
  return await queryAuthenticated(requestQuery, variables);
}

export function queryAuthenticatedWithCache(
  requestQuery: string,
  variables: Object = {}
): Observable<ICachedResponse<any>> {
  let subject = new Subject<any>();
  const kinGraphQLService = new KinGraphQL();

  kinGraphQLService.fetchStoredRequest(requestQuery).then(value => {
    if (value !== null) {
      subject.next({ ...value, isFromCache: true });
    }
  });

  queryAuthenticated(requestQuery, variables).then(
    result => {
      subject.next({ ...result, isFromCache: false });
      subject.complete();
    },
    error => subject.error(error)
  );
  return subject;
}

export interface ICachedFlag {
  isFromCache: boolean;
}

export interface ICachedResponse<T> extends AxiosResponse<T>, ICachedFlag {}
