/**
 * Firebase default configuration depending on the build environment (development / production)
 */
export const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

/**
 * Action code settings for authorizing a user through Firebase
 */
export const actionCodeSettings: any = {
    // URL to redirect back to. URL must be whitelisted in the Firebase Console.
    url: process.env.REACT_APP_URL,
    handleCodeInApp: true,
    iOS: {
      bundleId: process.env.REACT_APP_IOS_BUNDLE_ID
    },
    android: {
      packageName: process.env.REACT_APP_PACKAGE_NAME,
      installApp: true
    },
    //dynamicLinkDomain: process.env.REACT_APP_DYNAMIC_LINK_DOMAIN
};