import React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig } from "../firebase/firebase";
import { isMobile, isTablet } from "react-device-detect";
import { CSSTransition } from "react-transition-group";
import { Clipboard } from "ts-clipboard";

import { ILayoutProps } from "./layout";
import Sidebar, { IMenuItem } from "../components/sidebar/sidebar";
import AppBanner from "../components/app-banner/app-banner";
import ErrorSnackbar from "../components/snackbar/error";
import NoticeSnackbar from "../components/snackbar/notice";
import { inject, observer } from "mobx-react";
import { ILayoutSidebarStore, INewKin } from "../stores/layoutSidebarStore";
import { computed } from "mobx";
import KinSettings from "../components/panel/kin-settings";
import { IKinStore, IGroupMember } from "../stores/kinStore";
import Overlay from "../components/overlay/overlay";
import NavigationHeader from "../components/header/navigation";
import PlaystoreButton from "../components/stores/playstore";
import AppstoreButton from "../components/stores/appstore";
import Modal from "../components/modal/modal";
import { IPersonStore } from "../stores/personStore";
import { IActivityStore } from "../stores/activityStore";
import AvatarUpload, {
  AvatarUploadType
} from "../components/avatar-upload/avatar-upload";
import Input from "../components/form/input";
import Button, { ButtonColors } from "../components/button/button";
import GroupTile from "../components/tiles/group";
import { IProfileSettingsStore } from "../stores/profileSettingsStore";
import { GroupMembersStatus, IKinsStore } from "../stores/kinsStore";
import copyIcon from "../images/copy.svg";
import plusSquareIcon from "../images/plus-square.svg";
import Notices from "../common/snackbars";
import noGroupImage from "../images/no-group-image.svg";
import { ICurrenciesStore } from "../stores/currenciesStore";
import ProfileSettingsCurrency from "../components/panel/profile-settings-currency";

/**
 * Props for passing down the menu items and state
 *
 * @interface ILayoutSidebarProps
 * @extends {ILayoutProps}
 */
interface ILayoutSidebarProps extends ILayoutProps {
  menuItems: IMenuItem[];
  menuActiveItem: string;
  errorState: boolean;
  errorMsg?: string | undefined;
  noticeState?: boolean;
  noticeMsg?: string;
  showNavBar: boolean;
  showAddNew: boolean;
  layoutSidebarStore?: ILayoutSidebarStore;
  kinStore?: IKinStore;
  kinsStore?: IKinsStore;
  personStore?: IPersonStore;
  activityStore?: IActivityStore;
  profileSettingsStore?: IProfileSettingsStore;
  currenciesStore?: ICurrenciesStore;
}

interface ILayoutSidebarState {
  mounted: boolean;
  searchTerm: string;
}

@inject(
  "layoutSidebarStore",
  "kinStore",
  "kinsStore",
  "personStore",
  "activityStore",
  "profileSettingsStore",
  "currenciesStore"
)
@observer
/**
 * Basic Layout component with sidebar. Mostly use with signed in content
 *
 * @class LayoutSidebar
 * @extends {React.Component<ILayoutSidebarProps, ILayoutSidebarState>}
 */
class LayoutSidebar extends React.Component<
  ILayoutSidebarProps,
  ILayoutSidebarState
> {
  @computed get noticeState(): boolean {
    return this.props.layoutSidebarStore!.noticeState;
  }
  @computed get appBanner(): boolean {
    return this.props.layoutSidebarStore!.appBanner;
  }
  @computed get menuState(): boolean {
    return this.props.layoutSidebarStore!.menuPanel;
  }
  @computed get kinSettings(): boolean {
    return this.props.layoutSidebarStore!.kinSettings;
  }
  @computed get newKinModal(): boolean {
    return this.props.layoutSidebarStore!.newKinModal;
  }
  @computed get newKinModalSharing(): boolean {
    return this.props.layoutSidebarStore!.newKinModalSharing;
  }
  @computed get newKinModalAddMembers(): boolean {
    return this.props.layoutSidebarStore!.newKinModalAddMembers;
  }
  @computed get newKinModalCurrencies(): boolean {
    return this.props.layoutSidebarStore!.newKinModalCurrencies;
  }
  @computed get newKin(): INewKin {
    return this.props.layoutSidebarStore!.newKin;
  }
  @computed get newKinShareUrl(): string | undefined {
    return this.props.layoutSidebarStore!.newKinShareUrl;
  }
  @computed get kinImage(): string {
    return this.props.kinStore!.groupImage;
  }
  @computed get kinName(): string {
    return this.props.kinStore!.groupName;
  }
  @computed get kinPurpose(): string {
    return this.props.kinStore!.groupPurpose;
  }
  @computed get creatingKin(): boolean {
    return this.props.layoutSidebarStore!.creatingKin;
  }
  @computed get kinMembers(): IGroupMember[] {
    return this.props.kinStore!.members;
  }

  /**
   * Default props
   *
   * @static
   * @memberof LayoutSidebar
   */
  static defaultProps = {
    noticeState: false,
    showNavBar: true,
    showAddNew: true
  };

  /**
   * Creates an instance of LayoutSidebar.
   * @param {ILayoutSidebarProps} props
   * @memberof LayoutSidebar
   */
  constructor(props: ILayoutSidebarProps) {
    super(props);
    this.state = {
      mounted: false,
      searchTerm: ""
    };
    this.showAppBanner();
    /**
     * Initializing the Firebase config for authorizing the user.
     * Globalised as to not have to reinitialize on every page,
     * but with if check as a fallback incase the basic layout changes.
     */
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    this.props.activityStore!.checkUnseenActivities();
  }

  /**
   * Validate that new member's data is complete and has a valid email address.
   */
  hasIncompleteMember = (): boolean => {
    const validEmail = /^(\w\w)[^@^ ]+@(\w)[^ ]+\.([^.^ ]+)$/;
    return this.props.layoutSidebarStore!.newKin.members.some(member => {
      let hasValues = member.email.length !== 0 || member.name.length !== 0;
      if (hasValues) {
        if (!validEmail.test(member.email) || member.name.length === 0) {
          return true;
        }
      }
      return false;
    });
  };

  /**
   * Sets local mounted state to initiate fade in animation
   *
   * @memberof LayoutSidebar
   */
  componentDidMount() {
    this.setState({ mounted: true });
  }

  /**
   * Adds a body class when the app banner is visible
   *
   * @memberof LayoutSidebar
   */
  showAppBanner() {
    this.props.layoutSidebarStore!.setAppBanner(true);
  }

  /**
   * Removes the body class to hide the app banner and adjust the layout
   *
   * @memberof LayoutSidebar
   */
  hideAppBanner() {
    this.props.layoutSidebarStore!.setAppBanner(false);
  }

  /**
   * Close kin settings panel
   *
   * @memberof LayoutSidebar
   */
  closeKinSettingsPanel() {
    this.props.layoutSidebarStore!.setKinSettings(false);
  }

  /**
   * Close menu panel
   *
   * @memberof LayoutSidebar
   */
  closeMenuPanel() {
    this.props.layoutSidebarStore!.setProfileSettings(false);
    this.props.layoutSidebarStore!.setMenuPanel(false);
  }

  /**
   * Open menu panel
   *
   * @memberof LayoutSidebar
   */
  openMenu() {
    this.props.layoutSidebarStore!.setMenuPanel(true);
  }

  /**
   * Opening and closing the add new kin modal
   *
   * @memberof LayoutSidebar
   */
  toggleNewKinModal() {
    this.props.layoutSidebarStore!.setNewKinModal(!this.newKinModal);
    if (this.newKinModal) {
      this.props.layoutSidebarStore!.setNewKinCurrency(
        this.props.profileSettingsStore!.user.defaultCurrency.isoCode
      );
    } else {
      this.props.layoutSidebarStore!.setNewKinModalCurrency(false);
    }
  }

  toggleSharingModal(state: boolean = true) {
    this.props.layoutSidebarStore!.setNewKinModalAddMembers(!state);
    this.props.layoutSidebarStore!.setNewKinModalSharing(state);
  }

  /**
   * Prepopulate the new kin modal with type image
   * if specified.
   *
   * @memberof LayoutSidebar
   */
  getGroupImageType() {}

  handleSearch = (e: any): void => {
    this.setState({
      searchTerm: e.target.value
    });
  };

  filteredCurrencies = (searchTerm: string) => {
    let userCurrency = String(
      this.props.profileSettingsStore!.user.defaultCurrency.isoCode
    );
    let filteredCurrencies = this.props
      .currenciesStore!.currencies.filter(function searchCurrencies(currency) {
        if (searchTerm) {
          return currency.name.toLowerCase().indexOf(searchTerm) !== -1
            ? true
            : currency.isoCode.toLowerCase().indexOf(searchTerm) !== -1
            ? true
            : false;
        }
        return true;
      })
      .map(currency => {
        return (
          <React.Fragment key={currency.isoCode}>
            <ProfileSettingsCurrency
              currency={currency}
              isActive={currency.isoCode === userCurrency}
              handleClick={() => {
                this.props.layoutSidebarStore!.setNewKin({
                  ...this.props.layoutSidebarStore!.newKin,
                  currencyIsoCode: currency.isoCode
                });
                this.props.layoutSidebarStore!.setNewKinModalCurrency(false);
              }}
              className="m-settings-li-light"
            />
            <hr className="e-currencies-separator" />
          </React.Fragment>
        );
      });
    return filteredCurrencies.length ? (
      filteredCurrencies
    ) : (
      <p className="e-search-no-result">
        No results found. <br />
        Please try again.
      </p>
    );
  };

  /**
   * Render modal component
   *
   * @returns
   * @memberof Kins
   */
  renderModal() {
    return (
      <Modal
        visible={this.props.layoutSidebarStore!.nonFeatureModal}
        center={true}
        onClick={() =>
          this.props.layoutSidebarStore!.setNonFeatureModal(
            !this.props.layoutSidebarStore!.nonFeatureModal
          )
        }
      >
        <h3>Get the Kin mobile app</h3>
        <PlaystoreButton
          style={{
            display: "inline-block",
            margin: "0 12px 12px"
          }}
          eventLocation="Not a Feature Modal"
        />
        <AppstoreButton
          style={{
            display: "inline-block",
            margin: "0 12px 12px"
          }}
          eventLocation="Not a Feature Modal"
        />
        <p
          style={{
            marginBottom: "0"
          }}
        >
          Our web version currently has limited features.
        </p>
        <p>
          <strong>Download the mobile app for all features</strong>
        </p>
      </Modal>
    );
  }

  /**
   * Render the content for creating a kin screen
   *
   * @returns
   * @memberof LayoutSidebar
   */
  renderNewKinCreate() {
    const currency = this.props.currenciesStore!.currencyByIsoCode(
      this.props.layoutSidebarStore!.newKin.currencyIsoCode
    );
    return (
      <>
        <div className="e-modal-sticky-button">
          <h2>Kin helps you keep track of shared expenses with other people</h2>
          <p className="m-note">
            Use a Kin to group expenses with specific people, like a group for
            your household, a trip or an event.
          </p>
          <div className="e-kin-info">
            <AvatarUpload
              className="e-upload-input"
              editMode={false}
              size={80}
              name={this.newKin.name}
              image={
                this.newKin.imageUrl.length !== 0
                  ? this.newKin.imageUrl
                  : noGroupImage
              }
              newKinFile={(file: any) =>
                this.props.layoutSidebarStore!.setNewKin({
                  ...this.props.layoutSidebarStore!.newKin,
                  image: file,
                  imageUrl: URL.createObjectURL(file)
                })
              }
              uploadType={AvatarUploadType.NewKin}
            />
            <Input
              className="e-kin-name"
              type="text"
              value={this.newKin.name}
              placeholder="Kin name..."
              onChange={(value: string) =>
                this.props.layoutSidebarStore!.setNewKin({
                  ...this.props.layoutSidebarStore!.newKin,
                  name: value
                })
              }
            />
          </div>
          <div className="e-kin-purpose">
            <div className="e-field-label">Add a Note / purpose</div>
            <Input
              className="e-purpose-field"
              type="text"
              value={this.newKin.purpose}
              placeholder="Kin purpose..."
              onChange={(value: string) =>
                this.props.layoutSidebarStore!.setNewKin({
                  ...this.props.layoutSidebarStore!.newKin,
                  purpose: value
                })
              }
            />
          </div>

          <div className="e-kin-currency">
            <div className="e-field-label">Currency</div>
            <div className="e-kin-name b-currencies-list m-currencies-input">
              <ProfileSettingsCurrency
                currency={currency}
                handleClick={() =>
                  this.props.layoutSidebarStore!.setNewKinModalCurrency(true)
                }
                icon
              />
            </div>
          </div>
        </div>

        <Button
          loading={this.creatingKin}
          disabled={
            this.newKin.name.length === 0 || this.newKin.purpose.length === 0
          }
          small={true}
          className="e-action"
          color={ButtonColors.Black}
          onClick={() =>
            this.props.layoutSidebarStore!.createKin(() => {
              this.props.kinsStore!.refreshKins().then(() => {
                return this.props.kinsStore!.setHighlightNewKin(true);
              });
            })
          }
        >
          Save
        </Button>
      </>
    );
  }

  /**
   * Render the content for sharing the newly creted kin
   *
   * @memberof LayoutSidebar
   */
  renderNewKinModalSharing() {
    return (
      <>
        <GroupTile
          members={[
            {
              id: this.props.profileSettingsStore!.user.id,
              name: this.props.profileSettingsStore!.user.name,
              image: this.props.profileSettingsStore!.user.image,
              status: GroupMembersStatus.Active
            }
          ]}
          hidden={false}
          balance={false}
          image={
            this.newKin.imageUrl.length !== 0
              ? this.newKin.imageUrl
              : noGroupImage
          }
          name={this.newKin.name}
        />
        <h3>Kin is better with friends</h3>
        <p>
          Copy the invite link below and share it with your friends to join{" "}
          <strong>{this.newKin.name}</strong>.
        </p>
        <div className="e-kin-share">
          <Input
            readonly={true}
            className="e-share-field"
            type="text"
            value={this.newKinShareUrl}
            placeholder="Fetching share link..."
            onChange={() => {}}
          />
          <img
            className="e-kin-share-icon g-pointer"
            src={copyIcon}
            alt="Copy to clipboard"
            onClick={() => {
              Clipboard.copy(this.newKinShareUrl ? this.newKinShareUrl : "");
              new Notices().GlobalTrigger(() => {
                this.props.layoutSidebarStore!.setNotice(
                  !this.noticeState,
                  "Copied to clipboard."
                );
              });
            }}
          />
        </div>
        <div className="e-kin-share-action">
          <p>
            <span
              className="g-a"
              onClick={() => {
                this.toggleSharingModal(false);
              }}
            >
              Or add people one by one
            </span>
          </p>
        </div>
        <Button
          small={true}
          className="e-action"
          color={ButtonColors.Black}
          onClick={() => this.props.layoutSidebarStore!.setNewKinModal(false)}
        >
          Done
        </Button>
      </>
    );
  }

  /**
   * Render the content for adding members to the newly created kin
   *
   * @memberof LayoutSidebar
   */
  renderNewKinModalAddMembers() {
    return (
      <>
        <GroupTile
          members={[
            {
              id: this.props.profileSettingsStore!.user.id,
              name: this.props.profileSettingsStore!.user.name,
              image: this.props.profileSettingsStore!.user.image,
              status: GroupMembersStatus.Active
            }
          ]}
          hidden={false}
          balance={false}
          image={
            this.newKin.imageUrl.length !== 0
              ? this.newKin.imageUrl
              : noGroupImage
          }
          name={this.newKin.name}
        />
        <h3>Kin is better with friends</h3>
        <p>
          Invite your friends so you can keep track of Our Home expenses
          together
        </p>
        <div className="e-kin-share">
          {this.props.layoutSidebarStore!.newKin.members.map(
            (
              inputGroup: {
                name: string;
                email: string;
              },
              index: number
            ) => {
              return (
                <div className="b-grid g-margin-bottom" key={index}>
                  <div style={{ width: "30%" }}>
                    <Input
                      className="e-kin-name"
                      type="text"
                      value={inputGroup.name}
                      placeholder="Name"
                      onChange={(value: string) => {
                        let newMember = [];
                        newMember[index] = { ...inputGroup, name: value };
                        let newMembers = Object.assign(
                          [...this!.newKin.members],
                          newMember
                        );
                        this.props.layoutSidebarStore!.setNewKin({
                          ...this!.newKin,
                          members: newMembers
                        });
                      }}
                    />
                  </div>
                  <div className="e-col m-auto g-margin-left">
                    <Input
                      className="e-kin-email"
                      type="text"
                      value={inputGroup.email}
                      placeholder="Email address"
                      onChange={(value: string) => {
                        let newMember = [];
                        newMember[index] = { ...inputGroup, email: value };
                        let newMembers = Object.assign(
                          [...this!.newKin.members],
                          newMember
                        );
                        this.props.layoutSidebarStore!.setNewKin({
                          ...this!.newKin,
                          members: newMembers
                        });
                      }}
                    />
                  </div>
                </div>
              );
            }
          )}

          <div
            className="b-grid g-margin-top-lg"
            style={{ textAlign: "left" }}
            onClick={() => this.props.layoutSidebarStore!.setNewKinAddMember()}
          >
            <div className="e-col m-auto">
              <p>Add more friends</p>
            </div>
            <div className="e-col">
              <img src={plusSquareIcon} alt="Close" />
            </div>
          </div>
        </div>
        <div className="e-kin-share-action">
          <p>
            Lots of people to add?{" "}
            <span
              className="g-a"
              onClick={() => {
                this.toggleSharingModal(true);
              }}
            >
              Share a link instead
            </span>
            .
          </p>
        </div>
        <Button
          small={true}
          disabled={this.hasIncompleteMember()}
          className="e-action"
          color={ButtonColors.Black}
          onClick={() =>
            this.props.layoutSidebarStore!.addMembersToKin().then(() => {
              this.props.kinsStore!.refreshKins().then(() => {
                return this.props.kinsStore!.setHighlightNewKin(true);
              });
            })
          }
        >
          Save
        </Button>
      </>
    );
  }

  renderNewKinModalCurrencies() {
    const searchTerm = this.state.searchTerm;
    return (
      <div>
        <p>
          You can set your default currency in your profile. Kin currencies
          cannot be changed later.
        </p>
        <div className="b-list-search">
          <div className="e-list-search-input m-list-search-input-grey">
            <input type="text" onChange={this.handleSearch} />
          </div>
        </div>
        <div className="b-currencies-list">
          {this.filteredCurrencies(searchTerm)}
        </div>
      </div>
    );
  }

  /**
   * Modal for adding a new Kin/group
   *
   * @returns
   * @memberof LayoutSidebar
   */
  renderNewKinModal() {
    return (
      <Modal
        className="b-new-kin-modal"
        visible={this.newKinModal}
        center={true}
        title={this.newKinModalCurrencies ? "Set currency" : "New Kin"}
        onClick={() => {
          const result = this.creatingKin ? {} : this.toggleNewKinModal();
          this.props.layoutSidebarStore!.setNewKinModal(false);
          this.props.layoutSidebarStore!.setNewKinModalAddMembers(false);
          return result;
        }}
      >
        {/* This is not REASONable!!!!! */}
        {this.newKinModal
          ? !this.newKinModalAddMembers && !this.newKinModalSharing
            ? !this.newKinModalCurrencies
              ? this.renderNewKinCreate()
              : this.renderNewKinModalCurrencies()
            : this.newKinModalAddMembers
            ? this.renderNewKinModalAddMembers()
            : this.renderNewKinModalSharing()
          : null}
      </Modal>
    );
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof LayoutSidebar
   */
  render() {
    let layoutClasses: string = "e-col m-auto m-scroll";
    let containerClasses: string = "g-container m-no-max-width m-scroll-auto";
    if (this.props.className) {
      containerClasses += ` ${this.props.className}`;
    }

    let bodyClasses: string = "b-grid m-full-height m-full-width";
    if (this.appBanner) {
      layoutClasses += " m-banner-height";
    }
    if (isMobile && !isTablet) {
      bodyClasses += " m-vertical";
    }

    return (
      <div className="b-grid m-full-height m-full-width m-vertical">
        <div className="e-col m-auto">
          <div className={bodyClasses}>
            <div className="e-col">
              <Sidebar
                menuItems={this.props.menuItems}
                menuActiveItem={this.props.menuActiveItem}
              />
              <Overlay
                active={this.menuState}
                onClick={() => this.closeMenuPanel()}
              />
            </div>
            <div className="e-col m-auto m-scroll">
              <div className="b-grid m-full-height m-full-width m-vertical">
                {this.props.showNavBar ? (
                  <CSSTransition
                    in={this.state.mounted}
                    classNames="g-transition"
                    timeout={1000}
                    unmountOnExit
                  >
                    <NavigationHeader
                      menuClick={() => this.openMenu()}
                      title={this.props.menuActiveItem}
                      newKinClick={
                        this.props.showAddNew
                          ? () => this.toggleNewKinModal()
                          : null
                      }
                    />
                  </CSSTransition>
                ) : null}
                <CSSTransition
                  in={this.state.mounted}
                  classNames="g-transition"
                  timeout={1000}
                  unmountOnExit
                >
                  <div className={layoutClasses}>
                    <div className={containerClasses}>
                      <ErrorSnackbar
                        show={this.props.errorState}
                        msg={this.props.errorMsg}
                      />
                      <NoticeSnackbar
                        show={this.props.noticeState}
                        msg={this.props.noticeMsg}
                      />
                      {this.props.children}
                    </div>
                  </div>
                </CSSTransition>
                {this.appBanner ? (
                  <div className="e-col m-banner-padding">
                    <AppBanner onClick={() => this.hideAppBanner()} />
                  </div>
                ) : null}
              </div>
            </div>
            {this.kinSettings ? (
              <div className="e-col">
                <KinSettings
                  name={this.kinName}
                  image={this.kinImage}
                  purpose={this.kinPurpose}
                  members={this.kinMembers}
                />
                <Overlay
                  active={this.kinSettings}
                  onClick={() => this.closeKinSettingsPanel()}
                />
              </div>
            ) : null}
          </div>
        </div>
        {this.renderModal()}
        {this.renderNewKinModal()}
      </div>
    );
  }
}

export default LayoutSidebar;
