import React from "react";

import "./reminders.scss";
import Button, { ButtonColors } from "../button/button";
import { Clipboard } from "ts-clipboard";

import {
  IKinStore,
  IReminderUser,
  dynamicLinkQuery,
  DynamicLinkType
} from "../../stores/kinStore";
import { inject, observer } from "mobx-react";
import Notices from "../../common/snackbars";
import { computed } from "mobx";
import { ILayoutSidebarStore } from "../../stores/layoutSidebarStore";
import Segment, { SegmentEvent } from "../../common/segment";

/**
 * Reminder share interface
 *
 * @interface IReminderShare
 */
interface IReminderShare {
  user?: IReminderUser;
  kinStore?: IKinStore;
  layoutSidebarStore?: ILayoutSidebarStore;
}

/**
 * Component modal for displaying reminder sharing with a non-kin user
 *
 * @class ReminderShareGhost
 * @extends {React.Component<IReminderShare, {}>}
 */
@inject("kinStore", "layoutSidebarStore")
@observer
class ReminderShareGhost extends React.Component<IReminderShare, {}> {
  @computed get noticeState(): boolean {
    return this.props.layoutSidebarStore!.noticeState;
  }
  @computed get dynamicLink(): string | undefined {
    return this.props.kinStore!.dynamicLink;
  }

  /**
   * Message for sharing
   *
   * @private
   * @type {string}
   * @memberof ReminderShareGhost
   */
  private sharingMsg: string = "";

  /**
   * Assigning sharing msg to props
   *
   * @memberof ReminderShareGhost
   */
  componentDidMount() {
    this.props.kinStore!.fetchDynamicLink(
      dynamicLinkQuery(DynamicLinkType.Group, this.props.kinStore!.groupId),
      () => {
        this.sharingMsg =
          `Hi ${this.props.user!.name}! \n` +
          `Just a little reminder about the R${
            this.props.user!.amount.formatted
          } you owe ${this.props.user!.name} for ${
            this.props.kinStore!.groupName
          }. \n` +
          `Once you settle you’ll be all square :) You can view the balance or pay through Kin.\n` +
          `${this.dynamicLink !== undefined ? this.dynamicLink : ""}`;
      }
    );
  }

  /**
   * Open url to start whatsapp share
   *
   * @memberof ReminderShareGhost
   */
  shareToWhatsApp() {
    new Segment().Track(SegmentEvent.NUDGE_FRIEND);
    window.location.href = `whatsapp://send?text=${this.sharingMsg}`;
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof ReminderShareGhost
   */
  render() {
    return (
      <div className="b-reminder-modal">
        <div className="g-h5">Send reminder</div>
        <div className="e-reminder-title">
          Share the following with {this.props.user!.name} so they can pay you
          back
        </div>
        <div className="g-h6 e-reminder-description">
          Hi {this.props.user!.name}! Just a little reminder about the{" "}
          {this.props.user!.amount.formatted} you owe {this.props.user!.name}{" "}
          for {this.props.kinStore!.groupName}. Once you settle you’ll be all
          square :) You can view the balance or pay through Kin.
        </div>
        <Button
          loading={this.dynamicLink === undefined}
          className="e-reminder-btn"
          color={ButtonColors.Secondary}
          small={true}
          onClick={() => {
            new Segment().Track(SegmentEvent.NUDGE_FRIEND);
            Clipboard.copy(this.sharingMsg);
            new Notices().GlobalTrigger(() => {
              this.props.layoutSidebarStore!.setNotice(
                !this.noticeState,
                "Copied to clipboard."
              );
            });
          }}
        >
          Copy text
        </Button>
        <Button
          disabled={this.dynamicLink === undefined}
          className="e-reminder-btn"
          color={ButtonColors.Secondary}
          small={true}
          onClick={() => {
            new Segment().Track(SegmentEvent.NUDGE_FRIEND);
            this.shareToWhatsApp();
          }}
        >
          Share via WhatsApp
        </Button>
        <div
          onClick={() => this.props.kinStore!.setReminderModal(false)}
          className="e-reminder-cancel"
        >
          Cancel
        </div>
      </div>
    );
  }
}

export default ReminderShareGhost;
