import React from "react";
import { RouteComponentProps } from "react-router";

import PillList from "../../components/pill-list/pill-list";
import Button, { ButtonColors } from "../../components/button/button";
import {
  privacyPolicyLink,
  termsOfUseLink
} from "../../components/footer/footer";
import Modal from "../../components/modal/modal";
import Input from "../../components/form/input";
import Layout from "../../hoc/layout";

import "../../grid.scss";
import "../../global.scss";
import "../../typography.scss";
import "../../fonts/cerebri.css";
import "../invite/invite.scss";
import "../sign-in/sign-in.scss";

import googleIconRed from "../../images/icon-google-red.svg";
import googleBlackIcon from "../../images/icon-google-black.svg";
import facebookIconBlue from "../../images/icon-facebook-blue.svg";
import facebookBlackIcon from "../../images/icon-facebook-black.svg";
import emailIcon from "../../images/icon-email-black.svg";
import loaderSpinner from "../../images/loader-spinner.svg";
import check from "../../images/check.svg";
import mobileHero from "../../images/mobile-sign-in.jpg";
import animationData from "../../images/kin_success_check_lottie.json";
import kinLogo from "../../images/kin-logo.svg";

import Authentication from "../../common/authentication";
import UrlHelper from "../../common/url-helper";
import Lottie from "react-lottie";
import { updatePageTitle } from "../../common/history";
import Segment, { SegmentEvent } from "../../common/segment";
import AppstoreButton from "../../components/stores/appstore";
import PlaystoreButton from "../../components/stores/playstore";
import { inject, observer } from "mobx-react";
import { IInviteStore } from "../../stores/inviteStore";
import { computed } from "mobx";
import Emoji from "react-emoji-render";
import { isMobile, isTablet } from "react-device-detect";

import { setCampaignFlag } from "../../components/tiles/campaign";

/**
 * Type definitions of the Invite GraphQL query
 *
 * @interface IInviteProps
 */
interface ISignInProps extends RouteComponentProps {
  inviteStore?: IInviteStore;
}

/**
 * Local state for changing the button label
 *
 * @interface ISignInState
 */
interface ISignInState {
  buttonLabel: string;
  ref: string | null;
}

@inject("inviteStore")
@observer
/**
 * Class for creating the Invite page
 *
 * @class Invite
 * @extends {React.Component<ISignInProps, IqState>}
 */
class SignIn extends React.Component<ISignInProps, ISignInState> {
  /**
   * Invite Id variable
   *
   * @private
   * @type {string}
   * @memberof SignIn
   */
  private inviteId: string = "";

  /**
   * Experiment user active bucket
   *
   * @private
   * @type {number}
   * @memberof SignIn
   */
  private experimentBucket: number = 0;

  @computed get id(): string {
    return this.props.inviteStore!.id;
  }
  @computed get showModal(): boolean {
    return this.props.inviteStore!.showModal;
  }
  @computed get loading(): boolean {
    return this.props.inviteStore!.loading;
  }
  @computed get signingUpEmail(): boolean {
    return this.props.inviteStore!.signingUpEmail;
  }
  @computed get signingUpGoogle(): boolean {
    return this.props.inviteStore!.signingUpGoogle;
  }
  @computed get signingUpFacebook(): boolean {
    return this.props.inviteStore!.signingUpFacebook;
  }
  @computed get signUpRequestSent(): boolean {
    return this.props.inviteStore!.signUpRequestSent;
  }
  @computed get signUpRequestSending(): boolean {
    return this.props.inviteStore!.signUpRequestSending;
  }
  @computed get sighUpRequestResent(): boolean {
    return this.props.inviteStore!.sighUpRequestResent;
  }
  @computed get signUpEmailView(): boolean {
    return this.props.inviteStore!.signUpEmailView;
  }
  @computed get emailAddress(): string {
    return this.props.inviteStore!.emailAddress;
  }
  @computed get emailError(): boolean {
    return this.props.inviteStore!.emailError;
  }
  @computed get errorState(): boolean {
    return this.props.inviteStore!.errorState;
  }

  /**
   * Creates an instance of SignIn.
   * @param {ISignInProps} props
   * @memberof SignIn
   */
  constructor(props: ISignInProps) {
    super(props);
    this.state = {
      buttonLabel: "",
      ref: UrlHelper.getAppParametersFromCurrentUrl().Ref
    };
    // Set invite to '0' to bypass accepting invite code on profile setup
    this.inviteId = "0";
    // Update page title
    updatePageTitle("Sign In");
    // Trigger page view
    new Segment().Page(SegmentEvent.PAGE_SIGN_IN);

    // Auto redirect if already logged in
    // First check if the stored token is still valid
    // Checking locally just speeds up the sign in process
    if (new Authentication().hasTokenExpiredLocally()) {
      // If locally stored token is valid, check if the user is still signed in
      // in case Firebase auth has changed some some reason
      new Authentication().isSignedIn(() => {
        this.redirectSocialAuth();
      });
    } else {
      this.redirectSocialAuth();
    }
  }

  componentDidMount() {
    if (this.state.ref === "cooking-with-kin") {
      this.setState({
        buttonLabel: "Join Kin"
      });
      this.openModal();
      this.props.inviteStore!.setSignUpEmailView(true);
    }
  }

  /**
   * Adds an additional user avatar size to the users data for display purposes
   *
   * @param {any[]} users
   * @returns The manipulated user data
   * @memberof SignIn
   */
  usersList(users: any[]) {
    users.forEach(user => {
      user.size = 42;
    });
    return users;
  }

  /**
   * Set the state for opening the Modal component
   *
   * @memberof SignIn
   */
  openModal() {
    new Segment().Track(SegmentEvent.SIGN_UP_MODAL);
    this.props.inviteStore!.setShowModal(true);
  }

  /**
   * Set the state for closing the Modal component
   *
   * @memberof SignIn
   */
  closeModal() {
    this.props.inviteStore!.setShowModal(false);
  }

  /**
   * Method for sending the sign up link with email through Firebase
   *
   * @memberof SignIn
   */
  emailSignUp(resending: boolean = false) {
    new Segment().Track(SegmentEvent.SIGN_IN_EMAIL);
    /**
     * Get paramaters from URL helper for Zendesk
     */
    const urlParams = UrlHelper.getAppParametersFromCurrentUrl();
    new Authentication().emailSignUp(
      () => {
        // Set state first to queue loading animations
        this.props.inviteStore!.setSigningUpEmail(true);
        this.props.inviteStore!.setSignUpRequestSending(true);
        this.props.inviteStore!.setSighUpRequestResent(resending);
      },
      () => {
        // Email was sent successfully
        new Segment().Track(SegmentEvent.GENERATE_SIGNIN_LINK);
        this.props.inviteStore!.setSigningUpEmail(false);
        this.props.inviteStore!.setSignUpRequestSent(true);
        this.props.inviteStore!.setSignUpRequestSending(false);
        this.props.inviteStore!.setSighUpRequestResent(resending);
      },
      this.emailAddress,
      this.inviteId,
      urlParams.AppId,
      urlParams.Redirect,
      () => {
        // Email failed to send
        // Restore state to stop loading animations
        this.props.inviteStore!.setEmailError(true);
        this.props.inviteStore!.setSigningUpEmail(false);
        this.props.inviteStore!.setSignUpRequestSent(false);
        this.props.inviteStore!.setSignUpRequestSending(false);
      }
    );
  }

  /**
   * Method for authorizing the user with Google Auth
   *
   * @memberof SignIn
   */
  googleSignUp() {
    new Segment().Track(SegmentEvent.SIGN_IN_GOOGLE);
    new Authentication().googleSignUp(
      () => {
        // Set state first to queue loading animations
        this.props.inviteStore!.setSigningUpGoogle(true);
      },
      () => {
        this.props.inviteStore!.setSigningUpGoogle(false);
        this.props.inviteStore!.setSignUpRequestSent(false);
      },
      () => {
        this.closeModal();
        this.props.inviteStore!.setSigningUpGoogle(false);
        this.props.inviteStore!.setSignUpRequestSent(false);
        this.redirectSocialAuth();
      }
    );
  }

  /**
   * Method for authorizing the user with Facebook Auth
   *
   * @memberof SignIn
   */
  facebookSignUp() {
    new Segment().Track(SegmentEvent.SIGN_IN_FACEBOOK);
    new Authentication().facebookSignUp(
      () => {
        // Set state first to queue loading animations
        this.props.inviteStore!.setSigningUpFacebook(true);
      },
      () => {
        this.props.inviteStore!.setSigningUpFacebook(false);
        this.props.inviteStore!.setSignUpRequestSent(false);
      },
      () => {
        this.closeModal();
        this.props.inviteStore!.setSigningUpFacebook(false);
        this.props.inviteStore!.setSignUpRequestSent(false);
        this.redirectSocialAuth();
      }
    );
  }

  redirectSocialAuth() {
    // Invite ID of 0 will bypass any checks for invite accepting
    let url: string = "/profile/setup/0";

    const urlParams = UrlHelper.getAppParametersFromCurrentUrl();

    if (!!urlParams.AppId) {
      url = UrlHelper.appendQueryString(
        url,
        UrlHelper.QUERYSTRING_KEY_APP,
        urlParams.AppId
      );
    }

    if (!!urlParams.Redirect) {
      url = UrlHelper.appendQueryString(
        url,
        UrlHelper.QUERYSTRING_KEY_APP_RETURNTO,
        urlParams.Redirect
      );
    }

    this.props.history.push(url);
  }

  /**
   * Modal variation A
   *
   * @returns
   * @memberof SignIn
   */
  renderModalA() {
    return (
      <Modal
        visible={this.showModal}
        center={true}
        onClick={() => this.closeModal()}
      >
        <img className="e-kin-modal-logo" src={kinLogo} alt="Kin" />
        <h1
          style={{
            marginBottom: "17px"
          }}
        >
          {this.state.buttonLabel}
        </h1>
        <form
          className="e-modal-sticky-button"
          onSubmit={event => {
            event.preventDefault();
            this.emailSignUp();
          }}
        >
          <Input
            style={{
              maxWidth: "430px"
            }}
            type="email"
            placeholder="Email address"
            value={this.emailAddress}
            onChange={event => {
              this.props.inviteStore!.setEmailAddress(event);
              this.props.inviteStore!.setEmailError(false);
            }}
            disabled={this.signingUpEmail}
            error={this.emailError}
            errorMsg="Unable to send email, please check your email address for mistakes."
            focus={true}
          />
          <Button
            color={ButtonColors.Black}
            style={{
              marginTop: "32px",
              marginBottom: "40px",
              width: "100%",
              maxWidth: "328px"
            }}
            onClick={() => {}}
            loading={this.signingUpEmail}
            disabled={this.signingUpGoogle || this.signingUpFacebook}
          >
            {this.state.buttonLabel}
          </Button>
        </form>
        <p>or sign in with</p>
        <Button
          style={{
            width: "100%",
            maxWidth: "328px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            flex: "0 0 auto"
          }}
          onClick={() => this.googleSignUp()}
          color={ButtonColors.Google}
          outline={true}
          loading={this.signingUpGoogle}
          disabled={this.signingUpEmail || this.signingUpFacebook}
          icon={googleIconRed}
        >
          Google
        </Button>
        <Button
          style={{
            width: "100%",
            maxWidth: "328px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            flex: "0 0 auto"
          }}
          onClick={() => this.facebookSignUp()}
          color={ButtonColors.Facebook}
          className="g-margin-top"
          outline={true}
          loading={this.signingUpFacebook}
          disabled={this.signingUpEmail || this.signingUpGoogle}
          icon={facebookIconBlue}
        >
          Facebook
        </Button>
        <p
          style={{
            maxWidth: "296px",
            margin: "35px auto 0",
            lineHeight: "1.79"
          }}
        >
          By signing in, you're accepting the Kin
          <br />{" "}
          <a href={termsOfUseLink} rel="noopener noreferrer" target="_blank">
            <strong>Terms of Use</strong>
          </a>{" "}
          and{" "}
          <a href={termsOfUseLink} rel="noopener noreferrer" target="_blank">
            <strong>Privacy Policy</strong>
          </a>
        </p>
      </Modal>
    );
  }

  /**
   * Modal variation B
   *
   * @returns
   * @memberof SignIn
   */
  renderModalB() {
    return (
      <Modal
        visible={this.showModal}
        center={true}
        onClick={() => this.closeModal()}
      >
        <h1
          style={{
            marginBottom: "17px"
          }}
        >
          {this.state.buttonLabel}
        </h1>
        <Button
          style={{
            width: "100%",
            maxWidth: "393px",
            marginTop: "50px",
            marginLeft: "auto",
            marginRight: "auto"
          }}
          onClick={() => this.googleSignUp()}
          color={ButtonColors.Black}
          loading={this.signingUpGoogle}
          disabled={this.signingUpEmail || this.signingUpFacebook}
          icon={googleBlackIcon}
        >
          Google
        </Button>
        <Button
          style={{
            width: "100%",
            maxWidth: "393px",
            marginLeft: "auto",
            marginRight: "auto"
          }}
          onClick={() => this.facebookSignUp()}
          color={ButtonColors.Black}
          className="g-margin-top-lg"
          loading={this.signingUpFacebook}
          disabled={this.signingUpEmail || this.signingUpGoogle}
          icon={facebookBlackIcon}
        >
          Facebook
        </Button>
        <Button
          style={{
            width: "100%",
            maxWidth: "393px",
            marginLeft: "auto",
            marginRight: "auto"
          }}
          onClick={() => this.props.inviteStore!.setSignUpEmailView(true)}
          color={ButtonColors.Black}
          className="g-margin-top-lg"
          icon={emailIcon}
          loading={this.signingUpEmail}
          disabled={this.signingUpGoogle || this.signingUpFacebook}
        >
          Email
        </Button>
        <p
          style={{
            maxWidth: "296px",
            margin: "75px auto 0",
            lineHeight: "1.79"
          }}
        >
          By signing in, you're accepting the Kin
          <br />{" "}
          <a href={termsOfUseLink} rel="noopener noreferrer" target="_blank">
            <strong>Terms of Use</strong>
          </a>{" "}
          and{" "}
          <a href={privacyPolicyLink} rel="noopener noreferrer" target="_blank">
            <strong>Privacy Policy</strong>
          </a>
        </p>
      </Modal>
    );
  }

  /**
   * Rendering Modal content when signing up with email request has been sent
   *
   * @param {string} email
   * @returns
   * @memberof SignIn
   */
  renderModalEmail(email: string) {
    return (
      <Modal
        visible={this.showModal}
        center={true}
        onClick={() => this.closeModal()}
      >
        <div className="e-img-icon">
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            height={370}
            width={370}
          />
        </div>
        <h1
          style={{
            marginBottom: "32px"
          }}
        >
          Please check your email
        </h1>
        <p
          style={{
            marginBottom: "23px"
          }}
        >
          We've emailed a link to {email}. <br />
          Click the link to sign in.
        </p>
        <p>
          Wrong email address?{" "}
          <span
            className="g-a"
            onClick={() => this.props.inviteStore!.setSignUpRequestSent(false)}
          >
            <strong>Change it</strong>
          </span>
        </p>
        <p>
          Didn't receive an email?{" "}
          <span className="g-a" onClick={() => this.emailSignUp(true)}>
            <strong>Resend email</strong>
            {this.signUpRequestSending ? (
              <img
                src={loaderSpinner}
                className="g-spinner"
                style={{
                  verticalAlign: "middle",
                  marginLeft: "10px"
                }}
                alt="Resending email"
              />
            ) : null}
            {!this.signUpRequestSending && this.sighUpRequestResent ? (
              <img
                src={check}
                style={{
                  verticalAlign: "middle",
                  width: "27px",
                  marginLeft: "10px"
                }}
                alt="Email resent"
              />
            ) : null}
          </span>
        </p>
      </Modal>
    );
  }

  renderModalCampaignSignup = () => {};

  /**
   * Choosing what modal markup to render
   *
   * @returns Modal view
   * @memberof SignIn
   */
  renderModal() {
    setCampaignFlag(this.state.ref);
    if (this.signUpRequestSent) {
      return this.renderModalEmail(this.emailAddress);
    }
    if (this.signUpEmailView) {
      return this.renderModalA();
    }
    return this.renderModalB();
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof SignIn
   */
  render() {
    return (
      <Layout
        className="b-invite-page b-signin-page"
        errorState={this.errorState}
      >
        <div className="e-content g-fade m-in">
          {isMobile && !isTablet ? (
            <img
              className="e-hero"
              src={mobileHero}
              alt="Don't lose friends over money"
            />
          ) : null}
          <h1 className="e-title">
            Don’t lose friends over money, or money over friends
          </h1>
          <>
            <div
              style={{
                marginBottom: "54px"
              }}
              className="e-purpose g-h1 m-regular"
            >
              <strong>Join Kin</strong> to track, split and settle shared
              expenses with others.
            </div>
            <div className="e-center g-margin-bottom-lg g-padding-bottom">
              <Button
                color={ButtonColors.Primary}
                onClick={() => {
                  this.setState({
                    buttonLabel: "Join Kin"
                  });
                  this.openModal();
                  this.props.inviteStore!.setSignUpEmailView(true);
                }}
              >
                Join Kin
              </Button>
              <div className="e-invite-actions g-margin-bottom-lg">
                <div className="g-padding-bottom">
                  <p className="g-margin-top-lg g-text-center-mobile">
                    Already have an account?
                  </p>
                  <p className="g-text-center-mobile">
                    <strong>
                      <span
                        className="g-a"
                        onClick={() => {
                          this.setState({
                            buttonLabel: "Sign In"
                          });
                          this.openModal();
                        }}
                      >
                        <Emoji text="Click here to sign in" />
                      </span>
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="e-app-links">
              <AppstoreButton eventLocation={"Sign Up"} />
              <PlaystoreButton eventLocation={"Sign Up"} />
            </div>
          </>
        </div>
        {this.renderModal()}
        <PillList />
      </Layout>
    );
  }
}

export default SignIn;
