import React from "react";
import "./debugger.scss";
import ABTesting, { IExperiment } from "../../common/testing-engine";

/**
 * Debugger state for showing the dropdown or the component itself
 *
 * @interface IExperimentDebuggerState
 */
interface IExperimentDebuggerState {
  open: boolean;
  show: boolean;
}

/**
 * Experiment props for debugger
 *
 * @interface IExperimentDebuggerProps
 */
interface IExperimentDebuggerProps {
  experiment?: IExperiment;
}

/**
 * Debugger icon data URI
 */
const dataUri: string =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTYuMDU3IDE2LjA1NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYuMDU3IDE2LjA1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiBjbGFzcz0iIj48Zz48Zz4KCTxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkYiIGQ9Ik05LjE5NCw4Ljc0NGMwLjQ2MSwwLDAuODM1LDAuMzc0LDAuODM1LDAuODM1cy0wLjM3NCwwLjgzNS0wLjgzNSwwLjgzNVM4LjM1OSwxMC4wNCw4LjM1OSw5LjU3OSAgIEM4LjM1OSw5LjExOCw4LjczMyw4Ljc0NCw5LjE5NCw4Ljc0NHogTTYuODA3LDguMzE1Yy0wLjQ0OSwwLTAuODEzLDAuMzY0LTAuODEzLDAuODEzczAuMzY0LDAuODEzLDAuODEzLDAuODEzICAgYzAuNDQ5LDAsMC44MTMtMC4zNjQsMC44MTMtMC44MTNTNy4yNTYsOC4zMTUsNi44MDcsOC4zMTV6IE05LjA5OCw3LjY5NWMwLTAuMzI5LTAuMjY3LTAuNTk2LTAuNTk2LTAuNTk2UzcuOTA2LDcuMzY2LDcuOTA2LDcuNjk1ICAgYzAsMC4zMjksMC4yNjcsMC41OTYsMC41OTYsMC41OTZTOS4wOTgsOC4wMjQsOS4wOTgsNy42OTV6IE0xMy43NzEsMTYuMDU3SDIuMjg1Yy0xLjYxNiwwLTIuMTczLTEuMzgtMS42NjctMi44ODIgICBjMCwwLDEuOTkxLTMuNzEyLDUuMjM3LTkuMDRWMS4wNWgtMC4yQzUuMzY0LDEuMDUxLDUuMTI4LDAuODE1LDUuMTI4LDAuNTI2QzUuMTI5LDAuMjM3LDUuMzY0LDAuMDAxLDUuNjU1LDBsNC43NSwwLjAwMSAgIEMxMC42OTQsMCwxMC45MywwLjIzNiwxMC45MywwLjUyNmMwLDAuMjg5LTAuMjM2LDAuNTI0LTAuNTI1LDAuNTI0aC0wLjIwMXYzLjA4NmMzLjI0Niw1LjMyOCw1LjIzNiw5LjAyOCw1LjIzNiw5LjAyOCAgIEMxNS45NDUsMTQuNjY2LDE1LjM4OSwxNi4wNTcsMTMuNzcxLDE2LjA1N3ogTTEyLjkxOSwxMC45ODFjLTAuNTI3LTAuOTU0LTIuODg2LTUuMDQxLTMuNjQ4LTYuMjg5bC0wLjEyNy0wLjIxSDYuOTEzbC0wLjEyNywwLjIxICAgYy0wLjgyOCwxLjM2LTMuMzIsNS43MTQtMy42NTgsNi4yODlIMTIuOTE5eiIgZGF0YS1vcmlnaW5hbD0iIzAzMDEwNCIgY2xhc3M9IiIgZGF0YS1vbGRfY29sb3I9IiMwMzAxMDQiPjwvcGF0aD4KPC9nPjwvZz4gPC9zdmc+";

class ExperimentDebugger extends React.Component<IExperimentDebuggerProps, IExperimentDebuggerState> {
  /**
   * Debugger React ref for blur events
   *
   * @private
   * @type {*}
   * @memberof ExperimentDebugger
   */
  private debuggerRef: any;

  /**
   * Creates an instance of ExperimentDebugger.
   * @param {IExperimentDebuggerProps} props
   * @memberof ExperimentDebugger
   */
  constructor(props: IExperimentDebuggerProps) {
    super(props);
    // Set initial state
    this.state = {
      open: false,
      show: false
    };
    // Create React ref
    this.debuggerRef = React.createRef();
  }

  /**
   * Only display the component when on localhost or dev environment
   *
   * @memberof ExperimentDebugger
   */
  componentDidMount() {
    if (window.location.hostname.includes("dev") || window.location.hostname.includes("localhost")) {
      this.toggleDebuggerComponent();
    }
  }

  /**
   * Set state to render debugger component
   *
   * @memberof ExperimentDebugger
   */
  toggleDebuggerComponent() {
    this.setState({
      show: !this.state.show
    });
  }

  /**
   * Toggle experiment variation toggle dropdown
   *
   * @memberof ExperimentDebugger
   */
  toggleDebugger() {
    this.setState({
      open: !this.state.open
    });
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof ExperimentDebugger
   */
  render() {
    let panelClasses: string = "e-debugger-panel";
    if (this.state.open) {
      panelClasses += " m-open";
    }
    return (
      <>
        {this.props.experiment ? (
          this.state.show && this.props.experiment.name !== "" ? (
            <div
              className="b-debugger"
              ref={e => (this.debuggerRef = e)}
              onBlur={() => this.toggleDebugger()}
              tabIndex={-1}
            >
              <div className="e-test-icon" onClick={() => this.toggleDebugger()}>
                <img src={dataUri} alt="Experiment Debugger" />
              </div>
              <div className={panelClasses}>
                {this.state.open ? (
                  <>
                    <div className="e-variation m-label">{this.props.experiment.name}:</div>
                    {this.props.experiment.variations.map((variation, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => new ABTesting().SetBucket(this.props.experiment!.name, index)}
                          className="e-variation"
                        >
                          {variation.name}
                          {this.props.experiment!.bucket === index ? " (Active)" : null}
                        </div>
                      );
                    })}
                    <div
                      onClick={() => this.toggleDebuggerComponent()}
                      className="e-variation m-close m-label"
                    >
                      Close Debugger
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ) : null
        ) : null}
      </>
    );
  }
}

export default ExperimentDebugger;
