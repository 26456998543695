import { observable, action } from "mobx";
import KinGraphQL from "../common/graphql";
import Notices from "../common/snackbars";
import { queryAuthenticated } from "../common/graphql-utils";

/**
 * GraphQL Query to display an invite
 * @param id Invite ID
 */
export const inviteQuery = (id: string) => `
  {
    userInviteByPublicId(id:"${id}") {
      id,
      type,
      name,
      inviteToGroup {
        name,
        image,
        members {
          image,
          name
        }
      }
      isExpired
      expiredReason
      invitingUser {
        name,
        image
      }
    }
  }
`;

export const inviteLinkUrlQuery = `
  {
    me {
      inviteLinkUrl
    }
  }
`;

/**
 * Group invite details interface
 *
 * @export
 * @interface IInviteToGroup
 */
export interface IInviteToGroup {
  name: string;
  image: string;
  members: IUser[];
}

/**
 * User details interface
 *
 * @export
 * @interface IUser
 */
export interface IUser {
  name: string;
  image: string;
}

/**
 * Invite store state
 *
 * @export
 * @interface IInviteStore
 */
export interface IInviteStore {
  id: string;
  type: string;
  name: string;
  inviteToGroup: IInviteToGroup;
  isExpired: boolean;
  expiredReason: string;
  inviteUrl: string | null;
  invitingUser: IUser;
  showModal: boolean;
  loading: boolean;
  inviteError: boolean;
  signingUpEmail: boolean;
  signingUpGoogle: boolean;
  signingUpFacebook: boolean;
  signUpRequestSent: boolean;
  signUpRequestSending: boolean;
  sighUpRequestResent: boolean;
  signUpEmailView: boolean;
  emailAddress: string;
  emailError: boolean;
  errorState: boolean;
  fetchInvite(query: string, callback?: any): void;
  fetchInviteLinkUrl(): void;
  setId(id: string): void;
  setType(type: string): void;
  setName(name: string): void;
  setInviteToGroup(inviteToGroup: IInviteToGroup): void;
  setIsExpired(isExpired: boolean): void;
  setExpiredReason(expiredReason: string): void;
  setInvitingUser(invitingUser: IUser): void;
  setShowModal(showModal: boolean): void;
  setLoading(loading: boolean): void;
  setInviteError(inviteError: boolean): void;
  setSigningUpEmail(signingUpEmail: boolean): void;
  setSigningUpGoogle(signingUpGoogle: boolean): void;
  setSigningUpFacebook(signingUpFacebook: boolean): void;
  setSignUpRequestSent(signUpRequestSent: boolean): void;
  setSignUpRequestSending(signUpRequestSending: boolean): void;
  setSighUpRequestResent(sighUpRequestResent: boolean): void;
  setSignUpEmailView(signUpEmailView: boolean): void;
  setEmailAddress(emailAddress: string): void;
  setEmailError(emailError: boolean): void;
  setErrorState(errorState: boolean): void;
}

export class InviteStore implements IInviteStore {
  /**
   * Invite ID
   *
   * @type {string}
   * @memberof SignInStore
   */
  @observable id: string;
  /**
   * Invite Type
   *
   * @type {string}
   * @memberof SignInStore
   */
  @observable type: string;
  /**
   * Name of user that sent the invite
   *
   * @type {string}
   * @memberof SignInStore
   */
  @observable name: string;
  /**
   * Invite group details
   *
   * @type {IInviteToGroup}
   * @memberof SignInStore
   */
  @observable inviteToGroup: IInviteToGroup;
  /**
   * Is invite expired
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable isExpired: boolean;
  /**
   * The reason the invite is expired
   *
   * @type {string}
   * @memberof SignInStore
   */
  @observable expiredReason: string;
  /**
   * The user being invited
   *
   * @type {IUser}
   * @memberof SignInStore
   */
  @observable invitingUser: IUser;
  /**
   * UI state for showing the modal
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable showModal: boolean;
  /**
   * UI state for loading
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable loading: boolean;
  /**
   * UI state for invite errors
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable inviteError: boolean;
  /**
   * Clicked sign up/in with email
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable signingUpEmail: boolean;
  /**
   * Clicked sign up/in with Google
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable signingUpGoogle: boolean;
  /**
   * Clicked sign up/in with Facebook
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable signingUpFacebook: boolean;
  /**
   * Sign up request email sent
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable signUpRequestSent: boolean;
  /**
   * Sign up request email sending
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable signUpRequestSending: boolean;
  /**
   * Seign up request email resent
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable sighUpRequestResent: boolean;
  /**
   * Show email sign up view
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable signUpEmailView: boolean;
  /**
   * User signing up email address
   *
   * @type {string}
   * @memberof SignInStore
   */
  @observable emailAddress: string;
  /**
   * Email validation failed
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable emailError: boolean;
  /**
   * Any UI Errors
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable errorState: boolean;
  /**
   * This user's invite URL
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable inviteUrl: string | null;
  /**
   *Creates an instance of InviteStore.
   * @memberof InviteStore
   */
  constructor() {
    /**
     * Setting default values
     */
    this.id = "";
    this.type = "";
    this.name = "";
    this.inviteToGroup = {
      name: "",
      image: "",
      members: []
    };
    this.isExpired = false;
    this.expiredReason = "";
    this.invitingUser = {
      name: "",
      image: ""
    };
    this.showModal = false;
    this.loading = true;
    this.inviteError = false;
    this.signingUpEmail = false;
    this.signingUpGoogle = false;
    this.signingUpFacebook = false;
    this.signUpRequestSent = false;
    this.signUpRequestSending = false;
    this.sighUpRequestResent = false;
    this.signUpEmailView = false;
    this.emailAddress = "";
    this.emailError = false;
    this.errorState = false;
    this.inviteUrl = null;
  }

  /**
   * Update data to states
   *
   * @param {IInviteStore} invite
   * @memberof InviteStore
   */
  updateData(invite: IInviteStore) {
    this.setId(invite.id);
    this.setType(invite.type);
    this.setName(invite.name);
    this.setInviteToGroup(invite.inviteToGroup);
    this.setIsExpired(invite.isExpired);
    this.setExpiredReason(invite.expiredReason);
    this.setInvitingUser(invite.invitingUser);
    this.setLoading(false);
  }

  /**
   * Fetch invite data
   *
   * @param {string} query
   * @memberof InviteStore
   */
  fetchInvite(query: string, callback: any = null) {
    // Check for cached data first
    new KinGraphQL().fetchStoredRequest(query).then(result => {
      if (result !== null) {
        let invite: IInviteStore = result.data.data.userInviteByPublicId;
        // Update invite data
        this.updateData(invite);
        // Execute any callback
        if (callback) {
          callback();
        }
      }
    });

    // Fetch Query data
    new KinGraphQL().queryFetch(query).subscribe(result => {
      let invite: IInviteStore = result.data.data.userInviteByPublicId;
      if (invite != null) {
        // Update invite data
        this.updateData(invite);
        // Execute any callback
        if (callback) {
          callback();
        }
        // Store invite type
        window.localStorage.setItem("kin_inviteType", this.type);
      } else {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setErrorState(!this.errorState);
        });
        // Update UI states
        this.setInviteError(true);
        this.setLoading(false);
      }
    });
  }

  /**
   * Fetch this user's invite URL
   *
   * @param {string} query
   * @memberof InviteStore
   */
  fetchInviteLinkUrl() {
    queryAuthenticated(inviteLinkUrlQuery).then(
      result => {
        this.setInviteUrl(result.data.data.me.inviteLinkUrl);
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setErrorState(!this.errorState);
        });
      }
    );
  }

  /**
   * Setting state values
   */
  @action setId(id: string): void {
    this.id = id;
  }
  @action setType(type: string): void {
    this.type = type;
  }
  @action setName(name: string): void {
    this.name = name;
  }
  @action setInviteToGroup(inviteToGroup: IInviteToGroup): void {
    this.inviteToGroup = inviteToGroup;
  }
  @action setIsExpired(isExpired: boolean): void {
    this.isExpired = isExpired;
  }
  @action setExpiredReason(expiredReason: string): void {
    this.expiredReason = expiredReason;
  }
  @action setInviteUrl(inviteUrl: string): void {
    this.inviteUrl = inviteUrl;
  }
  @action setInvitingUser(invitingUser: IUser): void {
    this.invitingUser = invitingUser;
  }
  @action setShowModal(showModal: boolean): void {
    this.showModal = showModal;
  }
  @action setLoading(loading: boolean): void {
    this.loading = loading;
  }
  @action setInviteError(inviteError: boolean): void {
    this.inviteError = inviteError;
  }
  @action setSigningUpEmail(signingUpEmail: boolean): void {
    this.signingUpEmail = signingUpEmail;
  }
  @action setSigningUpGoogle(signingUpGoogle: boolean): void {
    this.signingUpGoogle = signingUpGoogle;
  }
  @action setSigningUpFacebook(signingUpFacebook: boolean): void {
    this.signingUpFacebook = signingUpFacebook;
  }
  @action setSignUpRequestSent(signUpRequestSent: boolean): void {
    this.signUpRequestSent = signUpRequestSent;
  }
  @action setSignUpRequestSending(signUpRequestSending: boolean): void {
    this.signUpRequestSending = signUpRequestSending;
  }
  @action setSighUpRequestResent(sighUpRequestResent: boolean): void {
    this.sighUpRequestResent = sighUpRequestResent;
  }
  @action setSignUpEmailView(signUpEmailView: boolean): void {
    this.signUpEmailView = signUpEmailView;
  }
  @action setEmailAddress(emailAddress: string): void {
    this.emailAddress = emailAddress;
  }
  @action setEmailError(emailError: boolean): void {
    this.emailError = emailError;
  }
  @action setErrorState(errorState: boolean): void {
    this.errorState = errorState;
  }
}
