import React from "react";
import Avatar from "../avatar/avatar";

import "./avatar-upload.scss";
import { IProfileSettingsStore } from "../../stores/profileSettingsStore";
import { IKinStore } from "../../stores/kinStore";
import { inject, observer } from "mobx-react";
import { computed } from "mobx";
import Loader from "../loader/loader";
import { IKinsStore } from "../../stores/kinsStore";

/**
 * Upload type that determines the store to access
 *
 * @export
 * @enum {number}
 */
export enum AvatarUploadType {
  User = 0,
  Kin = 1,
  NewKin = 2
}

/**
 * Avatar upload props and store
 *
 * @interface IAvatarUploadProps
 */
interface IAvatarUploadProps {
  editMode: boolean;
  size: number;
  name: string;
  image: string;
  className?: string;
  uploadType: AvatarUploadType;
  newKinFile?: (file: any) => void;
  profileSettingsStore?: IProfileSettingsStore;
  kinStore?: IKinStore;
  kinsStore?: IKinsStore;
}

/**
 * Class to render the image upload component
 *
 * @class AvatarUpload
 * @extends {React.Component<IAvatarUploadProps, {}>}
 */
@inject("profileSettingsStore", "kinStore", "kinsStore")
@observer
class AvatarUpload extends React.Component<IAvatarUploadProps, {}> {
  /**
   * Default props values
   *
   * @static
   * @memberof AvatarUpload
   */
  static defaultProps = {
    editMode: false
  };

  @computed get uploadingPhoto(): boolean {
    if (this.props.uploadType === AvatarUploadType.User) {
      return this.props.profileSettingsStore!.uploadingPhoto;
    } else {
      return this.props.kinStore!.uploadingPhoto;
    }
  }

  /**
   * Send file to upload method in store
   *
   * @param {*} files
   * @memberof AvatarUpload
   */
  handleFiles(files: any) {
    const file = files[0];
    if (
      this.props.uploadType === AvatarUploadType.NewKin &&
      this.props.newKinFile
    ) {
      this.props.newKinFile(file);
    } else {
      if (this.props.uploadType === AvatarUploadType.User) {
        this.props.profileSettingsStore!.uploadPhoto(file);
      } else {
        this.props.kinStore!.uploadKinPhoto(file, () =>
          this.props.kinsStore!.refreshKins()
        );
      }
    }
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof AvatarUpload
   */
  render() {
    let avatarUploadClasses: string = "b-avatar-upload";
    if (this.props.editMode) {
      avatarUploadClasses += " m-editing";
    }
    if (this.uploadingPhoto) {
      avatarUploadClasses += " m-uploading";
    }
    if (this.props.className) {
      avatarUploadClasses += ` ${this.props.className}`;
    }
    return (
      <div
        onClick={() =>
          this.props.editMode
            ? {}
            : this.props.profileSettingsStore!.setEditing(true)
        }
        className={avatarUploadClasses}
        style={{
          borderRadius: this.props.size
        }}
      >
        <Loader className="e-upload-loader" active={this.uploadingPhoto} />
        <input
          className="e-upload-input"
          type="file"
          onChange={event => this.handleFiles(event.target.files)}
        />
        <div className="e-upload">Change</div>
        <Avatar
          size={this.props.size}
          borderWidth={0}
          name={this.props.name}
          image={this.props.image}
        />
      </div>
    );
  }
}

export default AvatarUpload;
