const divideEquallyWithRemainder = (
  amount: number,
  divisor: number
): number[] => {
  const dividedAmount = Math.floor(amount / divisor);
  const remainder = amount - dividedAmount * divisor;

  return [dividedAmount, remainder];
};

export default divideEquallyWithRemainder;
