import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import WebFont from "webfontloader";
import { Provider } from "mobx-react";
import { stores } from "./stores";
import KinGraphQL from "./common/graphql";

/**
 * Load fonts with webfont loader for better performance
 */
WebFont.load({
  custom: {
    families: ["Cerebri Sans:n4,n6,n7,n8"]
  }
});

ReactDOM.render(
  <Provider {...stores}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

/**
 * Clear any requests that have failed from the localStorage.
 * This will only happen every 7 days for startup performance reasons.
 */
new KinGraphQL().clearErroredRequests();
