import React from "react";
import Layout from "../../hoc/layout";
import Button, { ButtonColors } from "../../components/button/button";
import Input from "../../components/form/input";
import { privacyPolicyLink, termsOfUseLink } from "../../components/footer/footer";

import "./sign-in-zendesk.scss";

import googleIcon from "../../images/icon-google.svg";
import googleBlackIcon from "../../images/icon-google-black.svg";
import facebookIcon from "../../images/icon-facebook.svg";
import facebookBlackIcon from "../../images/icon-facebook-black.svg";
import emailIcon from "../../images/icon-email-black.svg";
import loaderSpinner from "../../images/loader-spinner.svg";
import check from "../../images/check.svg";
import animationData from "../../images/kin_success_check_lottie.json";

import Authentication from "../../common/authentication";
import { RouteComponentProps } from "react-router";
import UrlHelper from "../../common/url-helper";
import { updatePageTitle } from "../../common/history";
import Segment, { SegmentEvent } from "../../common/segment";
import Lottie from "react-lottie";
import Notices from "../../common/snackbars";
import { ISignInStore } from "../../stores/signInStore";
import { inject, observer } from "mobx-react";
import { computed } from "mobx";

interface ISignInProps extends RouteComponentProps {
  signInStore?: ISignInStore;
}

@inject("signInStore")
@observer
/**
 * Clas for dispaying the sign in page component
 *
 * @class SignIn
 * @extends {React.Component<ISignInProps, {}>}
 */
class SignInZendesk extends React.Component<ISignInProps, {}> {
  /**
   * Creates an instance of SignIn.
   * @param {ISignInProps} props
   * @memberof SignIn
   */
  constructor(props: ISignInProps) {
    super(props);
    // Update page title
    updatePageTitle("Zendesk Sign In");
    // Trigger page view
    new Segment().Page(SegmentEvent.PAGE_SIGN_IN_ZENDESK);
  }

  @computed get signingUpGoogle(): boolean {
    return this.props.signInStore!.signingUpGoogle;
  }
  @computed get signingUpFacebook(): boolean {
    return this.props.signInStore!.signingUpFacebook;
  }
  @computed get signingUpEmail(): boolean {
    return this.props.signInStore!.signingUpEmail;
  }
  @computed get signUpEmailView(): boolean {
    return this.props.signInStore!.signUpEmailView;
  }
  @computed get emailAddress(): string {
    return this.props.signInStore!.emailAddress;
  }
  @computed get emailError(): boolean {
    return this.props.signInStore!.emailError;
  }
  @computed get signUpRequestSent(): boolean {
    return this.props.signInStore!.signUpRequestSent;
  }
  @computed get signUpRequestSending(): boolean {
    return this.props.signInStore!.signUpRequestSending;
  }
  @computed get sighUpRequestResent(): boolean {
    return this.props.signInStore!.sighUpRequestResent;
  }
  @computed get error(): boolean {
    return this.props.signInStore!.error;
  }

  errorTrigger() {
    new Notices().GlobalTrigger(() => {
      this.props.signInStore!.setError(!this.props.signInStore!.error);
    });
  }

  /**
   * Method for sending the sign up link with email through Firebase
   *
   * @memberof Invite
   */
  emailSignUp(resending: boolean = false) {
    new Segment().Track(SegmentEvent.SIGN_IN_EMAIL);
    const urlParams = UrlHelper.getAppParametersFromCurrentUrl();

    // With Firebase Invite ID of 0 will bypass any checks for invite accepting
    const FIREBASE_BYPASS_CHECKS_FOR_ACCEPTING_INVITE_ID = "0";

    new Authentication().emailSignUp(
      () => {
        // Set state first to queue loading animations
        this.props.signInStore!.setSigningUpEmail(true);
        this.props.signInStore!.setSignUpRequestSending(true);
        this.props.signInStore!.setSighUpRequestResent(resending);
      },
      () => {
        // Email was sent successfully
        this.props.signInStore!.setSigningUpEmail(false);
        this.props.signInStore!.setSignUpRequestSent(true);
        this.props.signInStore!.setSignUpRequestSending(false);
        this.props.signInStore!.setSighUpRequestResent(resending);
      },
      this.emailAddress,
      FIREBASE_BYPASS_CHECKS_FOR_ACCEPTING_INVITE_ID,
      urlParams.AppId,
      urlParams.Redirect,
      () => {
        // Email failed to send
        // Restore state to stop loading animations
        this.errorTrigger();
        this.props.signInStore!.setEmailError(true);
        this.props.signInStore!.setSigningUpEmail(false);
        this.props.signInStore!.setSignUpRequestSent(false);
        this.props.signInStore!.setSignUpRequestSending(false);
      }
    );
  }

  /**
   * Method for authorizing the user with Google Auth
   *
   * @memberof Invite
   */
  googleSignUp() {
    new Segment().Track(SegmentEvent.SIGN_IN_GOOGLE);
    new Authentication().googleSignUp(
      () => {
        // Set state first to queue loading animations
        this.props.signInStore!.setSigningUpGoogle(true);
      },
      () => {
        // Sign up was not successful
        this.errorTrigger();
        this.props.signInStore!.setSigningUpGoogle(false);
        this.props.signInStore!.setSignUpRequestSent(false);
      },
      () => {
        // Invite ID of 0 will bypass any checks for invite accepting
        this.redirectSocialAuth();
      }
    );
  }

  /**
   * Method for authorizing the user with Facebook Auth
   *
   * @memberof Invite
   */
  facebookSignUp() {
    new Segment().Track(SegmentEvent.SIGN_IN_FACEBOOK);
    new Authentication().facebookSignUp(
      () => {
        // Set state first to queue loading animations
        this.props.signInStore!.setSigningUpFacebook(true);
      },
      () => {
        // Sign up was not successful
        this.errorTrigger();
        this.props.signInStore!.setSigningUpFacebook(false);
        this.props.signInStore!.setSignUpRequestSent(false);
      },
      () => {
        this.redirectSocialAuth();
      }
    );
  }

  redirectSocialAuth() {
    // Invite ID of 0 will bypass any checks for invite accepting
    let url = `/profile/setup/0`;

    const urlParams = UrlHelper.getAppParametersFromCurrentUrl();

    if (!!urlParams.AppId) {
      url = UrlHelper.appendQueryString(url, UrlHelper.QUERYSTRING_KEY_APP, urlParams.AppId);
    }

    if (!!urlParams.Redirect) {
      url = UrlHelper.appendQueryString(url, UrlHelper.QUERYSTRING_KEY_APP_RETURNTO, urlParams.Redirect);
    }

    this.props.history.push(url);
  }

  /**
   * Rendering Modal content when signing up with email request has been sent
   *
   * @param {string} email
   * @returns
   * @memberof Invite
   */
  renderEmailSignedUp(email: string) {
    return (
      <>
        <div className="e-img-icon" style={{ width: "96px", margin: "0 auto 20px" }}>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            height={96}
            width={96}
          />
        </div>
        <h1
          style={{
            marginBottom: "32px"
          }}
        >
          Please check your email
        </h1>
        <p
          style={{
            marginBottom: "23px"
          }}
        >
          We've emailed a link to {email}. <br />
          Click the link to sign in.
        </p>
        <p>
          Wrong email address?{" "}
          <span className="g-a" onClick={() => this.props.signInStore!.setSignUpRequestSent(false)}>
            <strong>Change it</strong>
          </span>
        </p>
        <p>
          Didn't receive an email?{" "}
          <span className="g-a" onClick={() => this.emailSignUp(true)}>
            <strong>Resend email</strong>
            {this.signUpRequestSending ? (
              <img
                src={loaderSpinner}
                className="g-spinner"
                style={{
                  verticalAlign: "middle",
                  marginLeft: "10px"
                }}
                alt="Resending email"
              />
            ) : null}
            {!this.signUpRequestSending && this.sighUpRequestResent ? (
              <img
                src={check}
                style={{
                  verticalAlign: "middle",
                  width: "27px",
                  marginLeft: "10px"
                }}
                alt="Email resent"
              />
            ) : null}
          </span>
        </p>
      </>
    );
  }

  renderSocialSignIn() {
    return (
      <div className="b-zendesk-sign-in-page">
        <h1
          style={{
            marginBottom: "17px"
          }}
        >
          Sign In to Kin
        </h1>
        <Button
          style={{
            width: "100%",
            maxWidth: "393px",
            marginTop: "50px"
          }}
          onClick={() => this.googleSignUp()}
          color={ButtonColors.Black}
          loading={this.signingUpGoogle}
          disabled={this.signingUpEmail || this.signingUpFacebook}
          icon={googleBlackIcon}
        >
          Google
        </Button>
        <Button
          style={{
            width: "100%",
            maxWidth: "393px"
          }}
          onClick={() => this.facebookSignUp()}
          color={ButtonColors.Black}
          className="g-margin-top-lg"
          loading={this.signingUpFacebook}
          disabled={this.signingUpEmail || this.signingUpGoogle}
          icon={facebookBlackIcon}
        >
          Facebook
        </Button>
        <Button
          style={{
            width: "100%",
            maxWidth: "393px"
          }}
          onClick={() => this.props.signInStore!.setSignUpEmailView(true)}
          color={ButtonColors.Black}
          className="g-margin-top-lg"
          icon={emailIcon}
          loading={this.signingUpEmail}
          disabled={this.signingUpGoogle || this.signingUpFacebook}
        >
          Email
        </Button>
        <p
          style={{
            maxWidth: "296px",
            margin: "75px auto 0",
            lineHeight: "1.79"
          }}
        >
          By signing in, you're accepting the Kin
          <br />{" "}
          <a href={termsOfUseLink} rel="noopener noreferrer" target="_blank">
            <strong>Terms of Use</strong>
          </a>{" "}
          and{" "}
          <a href={privacyPolicyLink} rel="noopener noreferrer" target="_blank">
            <strong>Privacy Policy</strong>
          </a>
        </p>
      </div>
    );
  }

  /**
   * Render signing up with email component
   *
   * @returns
   * @memberof SignIn
   */
  renderEmailSignUp() {
    return (
      <div className="b-zendesk-sign-in-page">
        <h1
          style={{
            marginBottom: "17px"
          }}
        >
          Sign In to Kin
        </h1>
        <Input
          type="email"
          placeholder="Email address"
          value={this.emailAddress}
          onChange={event => {
            this.props.signInStore!.setEmailAddress(event);
            this.props.signInStore!.setEmailError(false);
          }}
          disabled={this.signingUpEmail}
          style={{
            maxWidth: "430px"
          }}
          error={this.emailError}
          errorMsg="Unable to send email, please check your email address for mistakes."
          focus={true}
        />
        <Button
          style={{
            marginTop: "32px",
            marginBottom: "40px",
            width: "100%",
            maxWidth: "393px"
          }}
          color={ButtonColors.Black}
          onClick={() => this.emailSignUp()}
          loading={this.signingUpEmail}
          disabled={this.signingUpGoogle || this.signingUpFacebook}
        >
          Sign In
        </Button>
        <p>or sign in with</p>
        <Button
          style={{
            width: "100%",
            maxWidth: "328px"
          }}
          onClick={() => this.googleSignUp()}
          color={ButtonColors.Black}
          outline={true}
          loading={this.signingUpGoogle}
          disabled={this.signingUpEmail || this.signingUpFacebook}
          icon={googleIcon}
        >
          Google
        </Button>
        <Button
          style={{
            width: "100%",
            maxWidth: "328px"
          }}
          onClick={() => this.facebookSignUp()}
          color={ButtonColors.Black}
          className="g-margin-top"
          outline={true}
          loading={this.signingUpFacebook}
          disabled={this.signingUpEmail || this.signingUpGoogle}
          icon={facebookIcon}
        >
          Facebook
        </Button>
        <p
          style={{
            maxWidth: "296px",
            margin: "75px auto 0",
            lineHeight: "1.79"
          }}
        >
          By signing in, you're accepting the Kin
          <br />{" "}
          <a href={termsOfUseLink} rel="noopener noreferrer" target="_blank">
            <strong>Terms of Use</strong>
          </a>{" "}
          and{" "}
          <a href={termsOfUseLink} rel="noopener noreferrer" target="_blank">
            <strong>Privacy Policy</strong>
          </a>
        </p>
      </div>
    );
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof SignIn
   */
  render() {
    return (
      <Layout className="b-zendesk-sign-in-page" errorState={this.error}>
        {this.signUpRequestSent
          ? this.renderEmailSignedUp(this.emailAddress)
          : this.signUpEmailView
          ? this.renderEmailSignUp()
          : this.renderSocialSignIn()}
      </Layout>
    );
  }
}

export default SignInZendesk;
