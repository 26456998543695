import React from "react";

import "./expense-split.scss";
import Button, { ButtonColors } from "../button/button";
import {
  IGroupMember,
  IExpenseSplitUser,
  IKinStore,
  IExpense,
  SplitType
} from "../../stores/kinStore";
import ExpenseUser from "./expense-user";
import Checkbox from "../form/checkbox";

import { observer, inject } from "mobx-react";
import { computed } from "mobx";
import { IAmount } from "../../stores/peopleStore";
import { ICurrenciesStore } from "../../stores/currenciesStore";

interface IExpenseSplitProps {
  future: boolean;
  notSure: boolean;
  buttonColor: ButtonColors;
  users: IGroupMember[];
  currentUserId: string;
  total: string;
  isSplitEqually: boolean;
  onClick: () => void;
  onUpdate: (newState: boolean, callback?: any) => void;
  kinStore?: IKinStore;
  currencySymbol: string;
  currenciesStore?: ICurrenciesStore;
}

interface IExpenseSplitState {
  futureNotSure: boolean;
  notInitialized: boolean;
}

@inject("kinStore", "currenciesStore")
@observer
class ExpenseSplit extends React.Component<
  IExpenseSplitProps,
  IExpenseSplitState
> {
  @computed get expenseSplitMembers(): IExpenseSplitUser[] | undefined {
    return this.props.kinStore!.expenseSplitMembers;
  }
  @computed get expenseRemaining(): IAmount {
    return this.props.kinStore!.expenseRemaining;
  }
  @computed get expense(): IExpense {
    return this.props.kinStore!.expense;
  }
  @computed get editingExpense(): boolean {
    return this.props.kinStore!.editingExpense;
  }
  /**
   * Creates an instance of ExpenseSplit.
   * @param {IExpenseSplitProps} props
   * @memberof ExpenseSplit
   */
  constructor(props: IExpenseSplitProps) {
    super(props);
    this.state = {
      futureNotSure: this.props.notSure ? this.props.notSure : false,
      notInitialized: true
    };

    if (!this.editingExpense && this.expenseSplitMembers === undefined) {
      this.props.kinStore!.setExpenseSplitMembers(
        this.convertUsersToSplitMembers(this.props.users),
        this.editingExpense
      );
    }
  }

  /**
   * Select all members by default
   *
   * @memberof ExpenseSplit
   */
  componentDidMount() {
    if (!this.editingExpense && !this.state.futureNotSure) {
      this.props.onUpdate(true);
    }

    if (this.props.isSplitEqually) {
      this.props.onUpdate(true);
    }
    this.assignExpenseSplit();
    this.setState(
      state => ({
        ...state,
        notInitialized: false
      }),
      () => {
        this.props.kinStore!.setExpenseSplitMembers(
          this.expenseSplitMembers,
          this.editingExpense
        );
      }
    );
  }

  /**
   * Assign expense member split before component render
   *
   * @memberof ExpenseSplit
   */
  assignExpenseSplit = (isSplitEqually?: boolean): void => {
    // Set expense split members
    if (this.expenseSplitMembers === undefined) {
      this.props.kinStore!.setExpenseSplitMembers(
        this.convertUsersToSplitMembers(this.props.users),
        this.editingExpense
      );
    } else if (this.props.isSplitEqually || isSplitEqually) {
      this.props.kinStore!.setExpenseSplitMembers(
        this.expenseSplitMembers.map(member => ({
          ...member,
          selected: this.state.futureNotSure ? false : true,
          edited: false
        })),
        this.state.notInitialized && this.editingExpense
      );
    } else if (this.editingExpense) {
      this.props.kinStore!.setExpenseSplitMembers(
        this.expenseSplitMembers.map(member => ({
          ...member,
          edited: true
        })),
        this.state.notInitialized && this.editingExpense
      );
    } else {
      this.props.kinStore!.setExpenseSplitMembers(
        this.expenseSplitMembers.map(member => ({
          ...member,
          edited: false
        }))
      );
    }
  };

  /**
   * Map to expense split user properties
   *
   * @param {IGroupMember[]} users
   * @returns {IExpenseSplitUser[]}
   * @memberof ExpenseSplit
   */
  convertUsersToSplitMembers(users: IGroupMember[]): IExpenseSplitUser[] {
    let usersFormated: IExpenseSplitUser[] = [];
    users.forEach(user => {
      let userName: string = user.name;
      // For the current user add the "You" suffix
      if (user.id === this.props.currentUserId) {
        userName += " (You)";
      }
      // Add all needed data per member
      usersFormated.push({
        id: user.id,
        name: userName,
        selected: true,
        value: 0,
        edited: false
      });
    });
    return usersFormated;
  }

  /**
   * Selecting the member for a split
   *
   * @param {number} index
   * @memberof ExpenseSplit
   */
  selectMember(selection: boolean | undefined, index: number) {
    let stateCopy: IExpenseSplitUser[] = [...this.expenseSplitMembers!];
    // Set to specific selection if selection override was given
    if (selection !== undefined) {
      stateCopy[index].selected = selection;
    } else {
      // Otherwise toggle selection
      stateCopy[index].selected = !this.expenseSplitMembers![index].selected;
    }
    // Reset member values if unselected
    if (stateCopy[index].selected === false) {
      stateCopy[index].value = 0;
      stateCopy[index].edited = false;
    }
    if (!selection) {
      this.props.onUpdate(false);
    } else {
      this.props.onUpdate(true);
    }

    // Update the remaining values
    this.props.kinStore!.setExpenseSplitMembers([...stateCopy]);
  }

  /**
   * Update the split amount for a member
   *
   * @param {number} index
   * @memberof ExpenseSplit
   */
  changeMemberValue(value: string, index: number) {
    let stateCopy: IExpenseSplitUser[] = [...this.expenseSplitMembers!];
    // Update value of member
    stateCopy[index].value = Number(value);
    // Update edited state
    stateCopy[index].edited = true;
    // If any portion's value changed, the transaction is not split equally anymore.
    this.props.onUpdate(false);
    this.props.kinStore!.setExpenseSplitMembers([...stateCopy]);
  }

  /**
   * Select all the members with the select all checkbox
   *
   * @memberof ExpenseSplit
   */
  selectAllMembers(selectAll: boolean | null = null) {
    // Copy current memberSplit state
    let membersSplit: IExpenseSplitUser[] = [...this.expenseSplitMembers!];
    // Toggle selection state - default defined in convertUsersToSplitMembers()
    membersSplit.forEach(member => {
      member.selected =
        selectAll === null ? this.props.isSplitEqually : selectAll;
    });
    // Update the remaining values
    this.props.kinStore!.setExpenseSplitMembers([...membersSplit]);
  }

  /**
   * Render each member
   *
   * @returns
   * @memberof ExpenseSplit
   */
  renderMembers() {
    return this.expenseSplitMembers
      ? this.expenseSplitMembers!.map((user, index) => {
          return (
            <ExpenseUser
              disabled={this.state.futureNotSure && this.props.future}
              key={index}
              name={user.name}
              amount={this.props.currenciesStore!.createAmount({
                cents: user.value,
                currencyIso: this.props.currencySymbol
              })}
              checked={user.selected}
              edited={user.edited}
              onSelect={selection => this.selectMember(selection, index)}
              onChange={value => this.changeMemberValue(value, index)}
              currencySymbol={this.props.currencySymbol}
            />
          );
        })
      : null;
  }

  /**
   * Render button or outstanding amount
   *
   * @returns
   * @memberof ExpenseSplit
   */
  renderButton() {
    let currentPortionTotal = this.props.kinStore!.expenseSplitMembers!.reduce(
      (acc, member) => acc + member.value,
      0
    );
    let totalAmount = Math.abs(this.expense.amount.cents);
    let remaining: IAmount = this.props.currenciesStore!.createAmount({
      cents: currentPortionTotal - totalAmount,
      currencyIso: this.props.kinStore!.currencyIsoCode
    });
    let prefix: string = "Add";
    if (remaining.cents > 0) {
      prefix = "Subtract";
    }
    if (remaining.cents === 0 || this.state.futureNotSure) {
      return (
        <Button
          className="e-expense-btn"
          color={this.props.buttonColor}
          small={true}
          onClick={() => this.props.onClick()} // Return only selected members
        >
          Done
        </Button>
      );
    } else {
      return (
        <div className="e-split-msg">
          {prefix}{" "}
          <span className="e-split-msg-amount">
            {this.props.currenciesStore!.formatAmountWithSymbol(remaining)}
          </span>
        </div>
      );
    }
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof ExpenseSplit
   */
  render() {
    return (
      <>
        <div className="e-modal-sticky-button">
          <div className="b-expense-split">
            <div className="e-split-title">Split</div>
            <div className="e-split-list">
              {this.props.future ? (
                <div className="e-split-all">
                  <div className="e-split-all-check">
                    <Checkbox
                      checked={this.state.futureNotSure}
                      onClick={() =>
                        this.setState(
                          {
                            futureNotSure: !this.state.futureNotSure
                          },
                          () => {
                            this.props.kinStore!.setExpense({
                              ...this.expense,
                              responsibleMemberId: this.state.futureNotSure
                                ? null
                                : this.props.kinStore!.currentUserId
                            });

                            if (this.state.futureNotSure)
                              this.selectAllMembers(false);
                            this.props.onUpdate(true);
                          }
                        )
                      }
                    />
                  </div>
                  <div>Not sure yet</div>
                </div>
              ) : null}
              <div className="e-split-all">
                <div className="e-split-all-check">
                  <Checkbox
                    checked={this.props.isSplitEqually}
                    onClick={() => {
                      let futureNotSureState: boolean;
                      let selectAllState: boolean;
                      // If false but about to change
                      if (!this.props.isSplitEqually) {
                        futureNotSureState = false;
                        selectAllState = true;
                      } else {
                        futureNotSureState = this.props.future
                          ? !this.state.futureNotSure
                          : false;
                        selectAllState = false;
                      }
                      this.setState(
                        {
                          futureNotSure: futureNotSureState
                        },
                        () => {
                          this.props.onUpdate(selectAllState, () => {
                            this.selectAllMembers(selectAllState);
                          });
                          this.props.kinStore!.setExpense({
                            ...this.expense,
                            split: {
                              ...this.expense.split,
                              ...this.expense.split.portions.map(portion => {
                                return {
                                  ...portion,
                                  size: this.props.isSplitEqually
                                    ? 1
                                    : portion.size
                                };
                              }),
                              type: this.props.isSplitEqually
                                ? SplitType.Ratio
                                : SplitType.Exact
                            }
                          });
                        }
                      );
                    }}
                  />
                </div>
                <div className="e-split-all-label">Equally by all</div>
              </div>
              {this.renderMembers()}
            </div>
          </div>
        </div>
        <div style={{ paddingBottom: "1.5rem" }}>{this.renderButton()}</div>
      </>
    );
  }
}

export default ExpenseSplit;
