import React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig } from "../firebase/firebase";
import queryString from "query-string";

import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import ErrorSnackbar from "../components/snackbar/error";
import { history } from "../common/history";
import Notices from "../common/snackbars";

/**
 * Basic layout props for page level class names
 * @export
 * @interface ILayoutProps
 */
export interface ILayoutProps {
  className?: string;
  errorState: boolean;
}

/**
 * Error display state
 *
 * @interface ILayoutState
 */
interface ILayoutState {
  errorState: boolean;
}

/**
 * Basic Layout component to standardise the header and footer with content. Mostly use with invites
 *
 * @class Layout
 * @extends {React.Component<ILayoutProps, {}>}
 */
class Layout extends React.Component<ILayoutProps, ILayoutState> {
  private errorMsg: string | undefined;
  /**
   * Creates an instance of Layout.
   * @param {ILayoutProps} props
   * @memberof Layout
   */
  constructor(props: ILayoutProps) {
    super(props);
    /**
     * Initializing the Firebase config for authorizing the user.
     * Globalised as to not have to reinitialize on every page,
     * but with if check as a fallback incase the basic layout changes.
     */
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    this.state = {
      errorState: this.props.errorState
    };
    this.errorMsg = undefined;
  }

  /**
   * DIsplay error from query string after mounting component
   *
   * @memberof Layout
   */
  componentDidMount() {
    // Get error query string
    const errorMsg = queryString.parse(history.location.search);
    if (errorMsg.error !== undefined) {
      this.errorMsg = errorMsg.error as string;
      new Notices().GlobalTrigger(() => {
        this.setState({
          errorState: !this.state.errorState
        });
      }, false);
    }
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof Layout
   */
  render() {
    let containerClasses: string = "g-container";
    if (this.props.className) {
      containerClasses += ` ${this.props.className}`;
    }
    return (
      <div className="b-grid m-vertical m-full-height">
        <div className="e-col m-auto">
          <Header className="m-margin-bottom" hideAccount={true} />
          <div className={containerClasses}>
            <ErrorSnackbar show={this.state.errorState} msg={this.errorMsg} />
            {this.props.children}
          </div>
        </div>
        <div className="e-col m-shrink">
          <Footer />
        </div>
      </div>
    );
  }
}

export default Layout;
