import _ from "lodash";
import Segment, { ISegmentExperiment } from "./segment";

/**
 * Experiment setup interface
 *
 * @export
 * @interface IExperimentSetup
 */
export interface IExperimentSetup {
  name: string;
  id: string;
  variations: IVariation[];
}

/**
 * Experiment interface
 *
 * @export
 * @interface IExperiment
 */
export interface IExperiment {
  name: string;
  id: string;
  variations: IVariation[];
  bucket: number;
}

/**
 * Interface for defining a experiment variation
 *
 * @interface IVariation
 */
interface IVariation {
  name: string;
  id: string;
  split: number;
}

/**
 * Default experiment state
 */
const defaultExperimentState = {
  name: "",
  id: "",
  variations: [],
  bucket: 0
};

/**
 * Class to setup and read A/B Tests
 *
 * @class ABTesting
 */
class ABTesting {
  /**
   * Experiment Data
   *
   * @private
   * @type {IExperiment}
   * @memberof ABTesting
   */
  private experiment: IExperiment;

  /**
   * Creates an instance of ABTesting.
   * @memberof ABTesting
   */
  constructor() {
    // Set default experiment value
    this.experiment = {
      ...defaultExperimentState
    };
  }

  /**
   * Initializing the AB Test experiment
   *
   * @param {IVariation[]} variations
   * @memberof ABTesting
   */
  public Setup(experimentSetup: IExperimentSetup) {
    // Set initial value
    this.experiment = {
      name: experimentSetup.name,
      id: experimentSetup.id,
      variations: experimentSetup.variations,
      bucket: this.assignBucket(experimentSetup.variations)
    };
    // Check if a stored experiment already exists
    let storedExperiment: IExperiment | null = this.getExperiment(this.experiment.name);
    if (storedExperiment !== null) {
      // Overwrite with stored experiment
      this.experiment = storedExperiment;
    } else {
      // Setup new experiment
      this.setExperiment();
    }
  }

  /**
   * Assign the user a bucket based on variation splits
   *
   * @private
   * @returns {number} Assigned bucket
   * @memberof ABTesting
   */
  private assignBucket(variations: IVariation[]): number {
    let variationSplits: number[] = [];
    // Build array with all variation splits
    variations.forEach(variation => {
      variationSplits.push(variation.split);
    });

    // Check if total is equal to 100%
    let variationSplitTotal: number = _.sum([...variationSplits]);
    if (variationSplitTotal < 100) {
      console.error(
        `Your AB test variation split does not add up to 100% - Split: ${variationSplits} = ${variationSplitTotal}`
      );
      return 0;
    }
    if (variationSplitTotal > 100) {
      console.error(
        `Your AB test variation split is more than 100% - Split: ${variationSplits} = ${variationSplitTotal}`
      );
      return 0;
    }

    // Build out an array visually displaying the odds
    // e.g [0,0,0,0,1,1,1,1,1....]
    let oddsArray: number[] = [];
    for (let i = 0; i < variationSplits.length; i++) {
      for (let y = 0; y < variationSplits[i]; y++) {
        oddsArray.push(i);
      }
    }

    // Return random assigned bucket
    return oddsArray[Math.round(Math.random() * (100 - 0) + 0)];
  }

  /**
   * Fetch existing experiment
   *
   * @private
   * @param {IExperiment} experiment
   * @returns
   * @memberof ABTesting
   */
  private getExperiment(experimentName: string): IExperiment | null {
    return JSON.parse(
      window.localStorage.getItem(`experiment_${this.experimentNameFormat(experimentName)}`)!
    );
  }

  /**
   * Save the experiment data
   *
   * @private
   * @param {IExperiment} experiment
   * @memberof ABTesting
   */
  private setExperiment(): void {
    window.localStorage.setItem(
      `experiment_${this.experimentNameFormat(this.experiment.name)}`,
      JSON.stringify(this.experiment)
    );
  }

  /**
   * Format the experiment name to not use any spaces, instead _'s
   *
   * @private
   * @param {string} name
   * @returns {string}
   * @memberof ABTesting
   */
  private experimentNameFormat(name: string): string {
    return name.replace(/ /g, "_").toLowerCase();
  }

  /**
   * Return the current active experiment
   * If the experiment can't be found it returns
   * the original variation or bucket 0
   *
   * @param {string} name
   * @memberof ABTesting
   */
  public ActivateExperiment(name: string, isDebugger: boolean = false) {
    let activeExperiment: IExperiment | null = this.getExperiment(name);
    if (activeExperiment !== null) {
      // Overwrite with stored experiment
      this.experiment = activeExperiment;
    } else {
      // Set to default experiment
      this.experiment = {
        ...defaultExperimentState
      };
    }
    if (!isDebugger) {
      let segmentExperiment: ISegmentExperiment = {
        experiment_id: `${this.experiment.id}`,
        experiment_name: `${this.experiment.name}`,
        variation_id: `${this.experiment.variations[this.experiment.bucket].id}`,
        variation_name: `${this.experiment.variations[this.experiment.bucket].name}`
      };
      new Segment().Track(this.experiment.name, {
        ...segmentExperiment
      });
    }
    return this.experiment;
  }

  /**
   * Changing the user assigned bucket
   *
   * @param {string} name
   * @param {number} bucket
   * @memberof ABTesting
   */
  public SetBucket(name: string, bucket: number) {
    this.experiment = {
      ...this.getExperiment(name)!,
      bucket: bucket
    };
    this.setExperiment();
    window.location.reload();
  }
}

export default ABTesting;
