import React from "react";
import LayoutSidebar from "../../hoc/layout-sidebar";
import { menuItems } from "../../common/menu";

import "./explore.scss";
import Segment, { SegmentEvent } from "../../common/segment";

/**
 * Explore page component
 *
 * @class Explore
 * @extends {React.Component<{}, {}>}
 */
class Explore extends React.Component<{}, {}> {
  /**
   * Trigger page view on component mount
   *
   * @memberof Explore
   */
  componentDidMount() {
    new Segment().Page(SegmentEvent.PAGE_EXPLORE);
  }

  /**
   * Renders the final component markup
   *
   * @returns
   * @memberof Explore
   */
  render() {
    return (
      <LayoutSidebar
        menuItems={menuItems}
        showAddNew={false}
        menuActiveItem={menuItems[3].title}
        errorState={false}
      >
        <div className="b-explore-page">
          <iframe
            className="e-iframe"
            src="https://www.kin.me/explore"
            title="Kin Explore"
          />
        </div>
      </LayoutSidebar>
    );
  }
}

export default Explore;
