import React from "react";
import "../../global.scss";
import LoaderContent from "../../hoc/content-loader";

/**
 * People balances loading component props for showing and hiding the loader
 *
 * @interface IPeopleLoaderProps
 */
interface IPeopleLoaderProps {
  active: boolean;
}

/**
 * People Loader component for displaying while a query is executing in the browser
 *
 * @param {*} props
 */
const PeopleLoader: React.FC<IPeopleLoaderProps> = props => (
  <LoaderContent active={props.active}>
    <div
      className="g-loader"
      style={{
        height: "35px",
        width: "150px",
        marginTop: "24px",
        marginBottom: "24px"
      }}
    ></div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
        width: "500px",
        maxWidth: "100%"
      }}
    >
      <div
        className="g-loader m-round"
        style={{
          width: "80px",
          minWidth: "80px",
          height: "80px",
          marginRight: "15px"
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div
          className="g-loader"
          style={{
            width: "250px",
            maxWidth: "100%",
            height: "15px",
            marginBottom: "10px"
          }}
        ></div>
        <div
          className="g-loader"
          style={{
            width: "80px",
            height: "15px",
            marginBottom: "10px"
          }}
        ></div>
        <div
          className="g-loader"
          style={{
            width: "130px",
            height: "15px"
          }}
        ></div>
      </div>
    </div>
  </LoaderContent>
);

/**
 * Default prop state for the loader is always false
 */
PeopleLoader.defaultProps = {
  active: false
};

export default PeopleLoader;
