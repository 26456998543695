import { observable, action } from "mobx";
import Notices from "../common/snackbars";
import { Subscription, Observable } from "rxjs";
import {
  queryAuthenticated,
  queryAuthenticatedWithCache
} from "../common/graphql-utils";

/**
 * Query to recieve the user's global activity
 *
 * @export
 * @param {(string | null)} [first=null]
 * @param {(string | null)} [after=null]
 * @param {(string[] | null)} [groupIds=null]
 * @param {(string | null)} [orderBy=null]
 */
export const activityQuery = (
  first: string | null = null,
  after: string | null = null,
  groupIds: string[] | null = null
) => `
  query {
    userActivitiesQuery(
      first: ${first !== null ? '"' + first + '"' : "null"}
      after: ${after !== null ? '"' + after + '"' : "null"}
      groupIds: ${groupIds !== null ? "[" + groupIds + "]" : "null"}
      orderBy: "RecentlyCreated"
      ) {
        count
        endCursor
        hasNextPage
        items {
          activityType
          byUser {
            id
            image
            isMe
            name
          }
          createDate
          event
          group {
            name
            image
          }
          groupId
          id
          message
          properties {
            key
            value
          }
          seen
          updateDate
        }
        markedAsRemovedIds
    }
  }
`;

/**
 * Shorter query to return unseen notifications
 *
 * @export
 * @param {(string | null)} [first=null]
 * @param {(string | null)} [after=null]
 * @param {(string[] | null)} [groupIds=null]
 * @param {(string | null)} [orderBy=null]
 */
export const activityUnseenQuery = `
  query {
    userActivitiesQuery(
      first: 1
      after: null
      groupIds: null
      orderBy: "RecentlyCreated"
      ) {
        items {
          seen
        }
    }
  }
`;

/**
 * Query to mark all global activity notifications as seen
 *
 * @export
 */
export const activityFeedMarkAsSeenQuery = `
  mutation {
    activityFeedMarkAsSeen(
      groupId: null
      cursor: null
    ) {
      command
    }
  }
`;

export interface IQueryUserActivitySpecificationResult {
  count: number;
  endCursor: string;
  hasNextPage: boolean;
  items: IUserActivity[];
}

export interface IUserActivity {
  activityType: UserActivityType;
  byUser: IUserActivityUser;
  createDate: string;
  event: string;
  group: IUserActivityGroupReference;
  groupId: string;
  id: string;
  message: string;
  properties: [
    {
      key: string;
      value: string;
    }
  ];
  seen: boolean;
  updateDate: string;
}

export interface IUserActivityUser {
  id: string;
  image: string;
  isMe: boolean;
  name: string;
}

export interface IUserActivityGroupReference {
  image: string;
  name: string;
}

export enum UserActivityType {
  REQUEST = "REQUEST",
  EXPENSE = "EXPENSE",
  PAYMENT = "PAYMENT",
  UPDATE = "UPDATE",
  SEARCH = "SEARCH" // This value only exists locally
}

export interface IActivityStore {
  loading: boolean;
  error: boolean;
  filter: UserActivityType | undefined;
  searchTerm: string;
  count: number;
  endCursor: string;
  hasNextPage: boolean;
  items: IUserActivity[];
  itemsFiltered: IUserActivity[];
  itemsSearched: IUserActivity[];
  hasNotifications: boolean;
  fetchActivities(): Observable<any>;
  checkUnseenActivities(): Promise<any>;
  filterData(filter: UserActivityType): void;
  markAsSeen(): void;
  resetData(): void;
  setLoading(state: boolean): void;
  setError(state: boolean): void;
  setFilter(filter: UserActivityType | undefined): void;
  setSearchTerm(term: string): void;
  setEndCursor(endCursor: string): void;
  setHasNextPage(state: boolean): void;
  setActivities(
    endCursor: string,
    state: boolean,
    activities: IUserActivity[]
  ): void;
  setFilteredActivities(activities: IUserActivity[]): void;
  setSearchedActivities(activities: IUserActivity[]): void;
  setHasNotifications(state: boolean): void;
}

export class ActivityStore implements IActivityStore {
  /**
   * Subscription for fetching activities
   *
   * @private
   * @type {Subscription}
   * @memberof ActivityStore
   */
  private activityFetchSubscription: Subscription;

  /**
   * UI state for loading
   *
   * @type {boolean}
   * @memberof ActivityStore
   */
  @observable loading: boolean;
  /**
   * UI error state
   *
   * @type {boolean}
   * @memberof ActivityStore
   */
  @observable error: boolean;
  /**
   * UI state for when the filtered view needs to be displayed
   *
   * @type {(UserActivityType | undefined)}
   * @memberof ActivityStore
   */
  @observable filter: UserActivityType | undefined;
  /**
   * Search term for filtering activities
   *
   * @type {string}
   * @memberof ActivityStore
   */
  @observable searchTerm: string;
  /**
   * Total acitivities count
   *
   * @type {number}
   * @memberof ActivityStore
   */
  @observable count: number;
  /**
   * Cursor passed to after argument to retrieve the next page.
   *
   * @type {string}
   * @memberof ActivityStore
   */
  @observable endCursor: string;
  /**
   * Does the list contain more items
   *
   * @type {boolean}
   * @memberof ActivityStore
   */
  @observable hasNextPage: boolean;
  /**
   * List of activities for the current user
   *
   * @type {IUserActivity[]}
   * @memberof ActivityStore
   */
  @observable items: IUserActivity[];
  /**
   * Filtered list of activities for the current user
   *
   * @type {IUserActivity[]}
   * @memberof ActivityStore
   */
  @observable.shallow itemsFiltered: IUserActivity[];
  /**
   * List of activities filtered by search term
   *
   * @type {IUserActivity[]}
   * @memberof ActivityStore
   */
  @observable.shallow itemsSearched: IUserActivity[];
  /**
   * UI state for if the user has new unread activities
   *
   * @type {boolean}
   * @memberof ActivityStore
   */
  @observable hasNotifications: boolean;

  /**
   * Creates an instance of ActivityStore.
   * @memberof ActivityStore
   */
  constructor() {
    this.loading = true;
    this.error = false;
    this.filter = undefined;
    this.searchTerm = "";
    this.count = 0;
    this.endCursor = "";
    this.hasNextPage = false;
    this.items = [];
    this.itemsFiltered = [];
    this.itemsSearched = [];
    this.hasNotifications = false;
    this.activityFetchSubscription = new Subscription();
  }

  /**
   * Fetch the users activities
   *
   * @param {string} query
   * @param {*} [callback=null]
   * @memberof ActivityStore
   */
  fetchActivities(): Observable<any> {
    this.setLoading(true);
    const observable = queryAuthenticatedWithCache(activityQuery());
    this.activityFetchSubscription = observable.subscribe({
      next: result => {
        // Update activities data to result
        this.setActivities(
          result.data.data.userActivitiesQuery.endCursor,
          result.data.data.userActivitiesQuery.hasNextPage,
          result.data.data.userActivitiesQuery.items
        );
      },
      error: () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
      }
    });
    return observable;
  }

  /**
   * Check for any unseen notifications
   *
   * @param {string} query
   * @memberof ActivityStore
   */
  checkUnseenActivities(): Promise<any> {
    const promise = queryAuthenticated(activityUnseenQuery);
    // Initialise Authentication with refreshed token
    promise.then(
      result => {
        // Update notification status
        if (result.data.data.userActivitiesQuery.items.length !== 0) {
          this.setHasNotifications(
            !result.data.data.userActivitiesQuery.items[0].seen
          );
        }
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(true);
        });
      }
    );
    return promise;
  }

  /**
   * Filter the data by selected filter
   *
   * @param {UserActivityType} filter
   * @memberof ActivityStore
   */
  filterData(filter: UserActivityType) {
    // Set filter type
    this.setFilter(filter);
    // Filter by searched results
    let items: IUserActivity[] = this.itemsSearched.filter(item => {
      return item.activityType === filter;
    });
    // Set filtered items
    this.setFilteredActivities(items);
  }

  /**
   * Mark all activities as seen
   *
   * @memberof ActivityStore
   */
  markAsSeen(): Promise<any> {
    const promise = queryAuthenticated(activityFeedMarkAsSeenQuery);
    promise.then(
      () => {
        this.setHasNotifications(false);
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
      }
    );
    return promise;
  }

  /**
   * Reset the data to it's initial
   *
   * @memberof ActivityStore
   */
  resetData() {
    this.activityFetchSubscription.unsubscribe();
    this.setActivities("", false, []);
    this.setLoading(true);
  }

  /**
   * Setting state values
   */
  @action setLoading(state: boolean): void {
    this.loading = state;
  }
  @action setError(state: boolean): void {
    this.error = state;
  }
  @action setFilter(filter: UserActivityType | undefined): void {
    this.filter = filter;
  }
  @action setSearchTerm(term: string): void {
    this.searchTerm = term;
    // Filter through items for search matches
    this.itemsSearched = this.items.filter(item => {
      // Matches message (contains person name)
      let matchesMsg: boolean = item.message
        .toLowerCase()
        .includes(this.searchTerm.toLowerCase());
      // Matches Kin name
      let matchesKin: boolean = false;
      if (item.group !== null) {
        matchesKin = item.group.name
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase());
      }
      if (matchesMsg || matchesKin) {
        return item;
      }
      return null;
    });
    // If not on a filter already
    if (this.filter === undefined) {
      this.filter = UserActivityType.SEARCH;
    } else {
      // If filter has already been selected, refilter with updated search term items
      this.filterData(this.filter);
    }
  }
  @action setEndCursor(endCursor: string): void {
    this.endCursor = endCursor;
  }
  @action setHasNextPage(state: boolean): void {
    this.hasNextPage = state;
  }
  @action setActivities(
    endCursor: string,
    state: boolean,
    activities: IUserActivity[]
  ): void {
    this.endCursor = endCursor;
    this.hasNextPage = state;
    this.items = activities;
    this.itemsSearched = activities;
    // Check if any unread notifications
    this.hasNotifications =
      activities.filter(activity => !activity.seen).length >= 1;
    this.loading = false;
  }
  @action setFilteredActivities(activities: IUserActivity[]): void {
    this.itemsFiltered = activities;
  }
  @action setSearchedActivities(activities: IUserActivity[]): void {
    this.itemsSearched = activities;
  }
  @action setHasNotifications(state: boolean): void {
    this.hasNotifications = state;
  }
}
