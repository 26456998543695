import React from "react";
import { inject, observer } from "mobx-react";
import { computed } from "mobx";
import { ICurrenciesStore } from "../../stores/currenciesStore";
import ProfileSettingsCurrency from "./profile-settings-currency";

import "./profile-settings.scss";
import { IProfileSettingsStore } from "../../stores/profileSettingsStore";
import { ILayoutSidebarStore } from "../../stores/layoutSidebarStore";
import { uniqueId } from "lodash";

/**
 * Profile settings props and stores
 *
 * @interface IProfileSettingsCurrenciesProps
 */
interface IProfileSettingsCurrenciesProps {
  active: boolean;
  currenciesStore?: ICurrenciesStore;
  profileSettingsStore?: IProfileSettingsStore;
  layoutSidebarStore?: ILayoutSidebarStore;
}

/**
 * Profile settings local bank form validation state
 *
 * @interface IProfileSettingsCurrenciesState
 */
interface IProfileSettingsCurrenciesState {
  searchTerm: string;
  loading: boolean;
}

/**
 * Component to render the profiles settings panel and the bank details modal
 *
 * @class ProfileSettingsCurrencies
 * @extends {React.Component<IProfileSettingsCurrenciesProps, IProfileSettingsCurrenciesState>}
 */
@inject("profileSettingsStore", "layoutSidebarStore", "currenciesStore")
@observer
class ProfileSettingsCurrencies extends React.Component<
  IProfileSettingsCurrenciesProps,
  IProfileSettingsCurrenciesState
> {
  @computed get currencies() {
    return this.props.currenciesStore!.currencies;
  }

  /**
   *Creates an instance of ProfileSettingsCurrencies.
   * @param {IProfileSettingsProps} props
   * @memberof ProfileSettingsCurrencies
   */
  constructor(props: IProfileSettingsCurrenciesProps) {
    super(props);
    this.state = {
      searchTerm: "",
      loading: false
    };
  }

  /**
   * Reset editing modes when settings is closed
   *
   * @memberof ProfileSettingsCurrencies
   */
  closeProfileSettingsCurrencies = () => {
    this.props.layoutSidebarStore!.setCurrenciesPanel(false);
  };

  handleSearch = (e: any): void => {
    this.setState({
      searchTerm: e.target.value
    });
  };

  handleSelect = (currency: string | null) => {
    this.setState({ loading: true });
    this.props
      .currenciesStore!.updateCurrency({
        name: this.props.profileSettingsStore!.name,
        defaultCurrencyIso: currency
      })
      .then(() => this.props.profileSettingsStore!.refreshUser())
      .then(() => this.setState({ loading: false }))
      .then(() => this.closeProfileSettingsCurrencies());
  };

  filteredCurrencies = (searchTerm: string) => {
    const filteredCurrencies = this.currencies
      .filter(function findCurrency(currency) {
        if (searchTerm) {
          return currency.name.toLowerCase().indexOf(searchTerm) !== -1
            ? true
            : currency.isoCode.toLowerCase().indexOf(searchTerm) !== -1
            ? true
            : false;
        }
        return true;
      })
      .map(currency => (
        <ProfileSettingsCurrency
          currency={currency}
          key={currency ? currency.isoCode : uniqueId()}
          handleClick={() => {
            this.handleSelect(currency ? currency.isoCode : null);
          }}
          disabled={this.state.loading}
        />
      ));

    return filteredCurrencies.length ? (
      filteredCurrencies
    ) : (
      <p className="e-search-no-result">
        No results found. <br />
        Please try again.
      </p>
    );
  };

  /**
   * Default render method
   *
   * @returns
   * @memberof ProfileSettingsCurrencies
   */
  render() {
    let settingsClasses: string =
      "b-profile-settings b-grid m-vertical m-full-height";
    if (this.props.active) {
      settingsClasses += " m-active";
    }

    const searchTerm = this.state.searchTerm.toLowerCase();

    return (
      <>
        <div className={settingsClasses}>
          <div className="e-col m-auto e-profile-setting-scroll">
            <header className="b-list-search">
              <div className="e-list-search-input">
                <input type="text" onChange={this.handleSearch} />
              </div>
              <button
                className="btn-cancel"
                onClick={this.closeProfileSettingsCurrencies}
              >
                Cancel
              </button>
            </header>
            <div className="e-profile-details">
              {this.filteredCurrencies(searchTerm)}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProfileSettingsCurrencies;
