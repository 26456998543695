import React from "react";
import Hammer from "react-hammerjs";
import {
  IGroupMember,
  IKinStore,
  updateGroupQuery,
  groupInviteExistingMember
} from "../../stores/kinStore";

import "./kin-settings.scss";
import { ILayoutSidebarStore } from "../../stores/layoutSidebarStore";
import { ICurrenciesStore } from "../../stores/currenciesStore";

import closeIcon from "../../images/icon-close.svg";
import editIcon from "../../images/edit.svg";
import signOutIcon from "../../images/log-out.svg";
import copyIcon from "../../images/copy.svg";
import trashIcon from "../../images/trash.svg";
import { inject, observer } from "mobx-react";
import Avatar from "../avatar/avatar";
import { GroupMembersStatus } from "../../stores/kinsStore";
import { computed } from "mobx";
import Input from "../form/input";
import Button from "../button/button";
import KinSettingsImage from "./kin-settings-image";
import { Clipboard } from "ts-clipboard";
import Notices from "../../common/snackbars";
import ProfileSettingsCurrency from "./profile-settings-currency";

/**
 * Kin settings props and stores
 *
 * @interface IKinSettingsProps
 */
interface IKinSettingsProps {
  image: string;
  name: string;
  purpose?: string;
  members: IGroupMember[];
  layoutSidebarStore?: ILayoutSidebarStore;
  kinStore?: IKinStore;
  currenciesStore?: ICurrenciesStore;
}

interface IKinSettingsState {
  name: string;
  purpose: string;
  menuPanOffset: number;
  menuPanStart: number;
  sharingMsg: string;
  deleteMemberId: string | boolean;
}

/**
 * Kin purpose for kins with no specified purpose
 */
const kinDefaultPurpose: string =
  "Use this kin to plan, manage, split and settle expenses with other people.";

/**
 * Component to display the kin settings panel
 *
 * @class KinSettings
 * @extends {React.Component<IKinSettingsProps, IKinSettingsState>}
 */
@inject("layoutSidebarStore", "kinStore", "currenciesStore")
@observer
class KinSettings extends React.Component<
  IKinSettingsProps,
  IKinSettingsState
> {
  @computed get editingKin(): boolean {
    return this.props.kinStore!.editingKin;
  }
  @computed get savingKin(): boolean {
    return this.props.kinStore!.savingKin;
  }
  @computed get noGhosts(): boolean {
    const hasGhosts = this.props.kinStore!.members.find(
      member =>
        member.status === GroupMembersStatus.Invited ||
        member.status === GroupMembersStatus.NotInvited
    );
    return !hasGhosts;
  }
  @computed get kinInviteUrl(): string | undefined {
    return this.props.kinStore!.groupInviteUrl;
  }
  @computed get noticeState(): boolean {
    return this.props.layoutSidebarStore!.noticeState;
  }
  @computed get removingMemberFromKin(): boolean {
    return this.props.kinStore!.removingMember;
  }
  @computed get fetchingInviteUrl(): boolean {
    return this.props.kinStore!.fetchingInviteUrl;
  }

  /**
   * Creates an instance of KinSettings.
   * @param {IKinSettingsProps} props
   * @memberof KinSettings
   */
  constructor(props: IKinSettingsProps) {
    super(props);
    this.state = {
      name: this.props.name,
      purpose: this.props.purpose ? this.props.purpose : kinDefaultPurpose,
      menuPanOffset: 0,
      menuPanStart: 0,
      sharingMsg: "",
      deleteMemberId: false
    };
  }

  /**
   * Calculate the panning position
   *
   * @param {HammerInput} event
   * @returns {number}
   * @memberof KinSettings
   */
  panCalculation(event: HammerInput): number {
    let panPositionPage: number = (event.srcEvent as any).pageX;
    return this.state.menuPanStart - panPositionPage;
  }

  /**
   * Record pan start location
   *
   * @param {HammerInput} event
   * @memberof KinSettings
   */
  panStart(event: HammerInput) {
    this.setState({
      menuPanStart: (event.srcEvent as any).pageX
    });
  }

  /**
   * Check position on pan end and close menu if needed
   *
   * @param {HammerInput} event
   * @memberof KinSettings
   */
  panEnd(event: HammerInput) {
    // Only do panning for touch events (ignores desktop mouse) and left and right direction
    if (
      event.pointerType === "touch" &&
      (event.offsetDirection === 2 || event.offsetDirection === 4)
    ) {
      let position: number = this.panCalculation(event);
      if (position < -30) {
        this.props.layoutSidebarStore!.setKinSettings(false);
        this.resetPan();
      }
    }
  }

  /**
   * Adjust the menu position while panning
   *
   * @param {HammerInput} event
   * @memberof KinSettings
   */
  panMenu(event: HammerInput) {
    // Only do panning for touch events (ignores desktop mouse) and left and right direction
    if (
      event.pointerType === "touch" &&
      (event.offsetDirection === 2 || event.offsetDirection === 4)
    ) {
      let position: number = this.panCalculation(event);
      if (position > 0) {
        position = 0;
      }
      this.setState({
        menuPanOffset: position
      });
    }
  }

  /**
   * Reset the pan position so that menu will have
   * the right position when opened again
   *
   * @memberof KinSettings
   */
  resetPan() {
    setTimeout(() => {
      this.setState({
        menuPanOffset: 0,
        menuPanStart: 0
      });
    }, 300);
  }

  /**
   * Closing the kins settings panel
   *
   * @memberof KinSettings
   */
  closePanel() {
    this.props.kinStore!.setEditingKin(false);
    this.props.layoutSidebarStore!.setKinSettings(false);
    document
      .getElementsByTagName("body")[0]
      .classList.remove("g-kin-settings-panel");
  }

  /**
   * Close panel and open up the kin leaving modal
   *
   * @memberof KinSettings
   */
  leaveKinModal() {
    this.closePanel();
    this.props.kinStore!.leaveKinModal();
  }

  renderInviteButton(memberId: string, memberName: string) {
    return (
      <div className="e-kin-reinvite">
        <button
          disabled={
            this.fetchingInviteUrl && this.state.deleteMemberId === memberId
          }
          className="m-text"
          onClick={() => {
            if (!this.fetchingInviteUrl) {
              this.setState(state => ({
                ...state,
                deleteMemberId: memberId
              }));
              this.props.kinStore!.fetchInviteUrlFromCorrelationId(
                groupInviteExistingMember(
                  this.props.kinStore!.groupId,
                  memberId
                ),
                () => {
                  let sharingMsg =
                    `Hi ${memberName}, \n` +
                    `I’d like to collaborate with you on the Kin app. Please join me.` +
                    `web Kin - Track and share expenses. \n` +
                    `Tap the link to open your invite: ${
                      this.props.kinStore!.dynamicLink
                    }`;
                  this.setState(
                    state => ({
                      ...state,
                      sharingMsg
                    }),
                    () => {
                      Clipboard.copy(
                        this.props.kinStore!.dynamicLink
                          ? this.state.sharingMsg
                          : ""
                      );
                      new Notices().GlobalTrigger(() => {
                        this.props.layoutSidebarStore!.setNotice(
                          !this.noticeState,
                          "Copied to clipboard."
                        );
                      });
                      this.props.kinStore!.setFetchingInviteUrl(false);
                      this.setState(state => ({
                        ...state,
                        deleteMemberId: false
                      }));
                    }
                  );
                }
              );
            }
          }}
        >
          Copy invite link
        </button>
        <button
          className="m-icon"
          onClick={() => {
            if (!this.props.kinStore!.removingMember) {
              this.setState(state => ({
                ...state,
                deleteMemberId: memberId
              }));
              this.props.kinStore!.removeMemberFromKin(memberId, () => {
                this.setState(state => ({
                  ...state,
                  deleteMemberId: false
                }));
              });
            }
          }}
          disabled={
            this.removingMemberFromKin || this.state.deleteMemberId === memberId
          }
        >
          <img src={trashIcon} alt="Remove member from Group" />
        </button>
      </div>
    );
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof KinSettings
   */
  render() {
    const kinCurrency = this.props.currenciesStore?.currencyByIsoCode(
      this.props.kinStore!.currencyIsoCode
    );
    return (
      <Hammer
        onPan={e => this.panMenu(e)}
        onPanStart={e => this.panStart(e)}
        onPanEnd={e => this.panEnd(e)}
      >
        <div
          className="b-grid m-full-height m-vertical b-kin-settings"
          style={{
            marginRight: this.state.menuPanOffset + "px"
          }}
        >
          <div className="e-col m-auto e-panel g-touch-scroll g-relative">
            <img
              src={closeIcon}
              className="e-close"
              onClick={() => this.closePanel()}
              alt="Close Kin Settings"
            />
            {!this.editingKin ? (
              <img
                src={editIcon}
                className="e-edit"
                onClick={() => this.props.kinStore!.setEditingKin(true)}
                alt="Edit Kin Settings"
              />
            ) : null}
            {!this.editingKin ? (
              <Avatar
                className="e-kin-avatar g-pointer"
                borderWidth={0}
                size={120}
                name={this.props.name}
                image={this.props.image}
                onClick={() => this.props.kinStore!.setEditingKin(true)}
              />
            ) : (
              <KinSettingsImage
                name={this.props.name}
                image={this.props.image}
              />
            )}
            {this.editingKin ? (
              <>
                <div className="e-divider-title">Kin Name</div>
                <Input
                  disabled={this.savingKin}
                  type="text"
                  small={true}
                  noBorderStyle={true}
                  onChange={event =>
                    this.setState({
                      name: event
                    })
                  }
                  value={this.state.name}
                />
              </>
            ) : (
              <div
                className="e-kin-title g-pointer"
                onClick={() => this.props.kinStore!.setEditingKin(true)}
              >
                {this.props.name}
              </div>
            )}
            {this.editingKin ? (
              <>
                <div className="e-divider-title">Note / Purpose</div>
                <div className="b-grid m-vertical">
                  <Input
                    textarea={true}
                    disabled={this.savingKin}
                    type="text"
                    small={true}
                    noBorderStyle={true}
                    onChange={event =>
                      this.setState({
                        purpose: event
                      })
                    }
                    value={this.state.purpose}
                  />
                  <div className="e-col e-kin-save">
                    <Button
                      loading={this.savingKin}
                      small={true}
                      inline={true}
                      onClick={() =>
                        this.props.kinStore!.updateKin(
                          updateGroupQuery(
                            this.props.kinStore!.groupId,
                            this.state.name,
                            this.state.purpose
                          ),
                          this.state.name,
                          this.state.purpose
                        )
                      }
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div
                className="e-kin-purpose g-pointer"
                onClick={() => this.props.kinStore!.setEditingKin(true)}
              >
                {this.props.purpose ? this.props.purpose : kinDefaultPurpose}
              </div>
            )}
            {this.editingKin ? null : (
              <>
                <div className="e-divider-title">People in the kin</div>
                <div className="e-people">
                  {this.props.members.map((member, index) => {
                    if (member.status !== GroupMembersStatus.InActive) {
                      return (
                        <div
                          key={index}
                          className="e-person b-grid m-align-center"
                        >
                          <div className="e-col">
                            <Avatar
                              size={40}
                              borderWidth={0}
                              name={member.name}
                              image={member.image}
                            />
                          </div>
                          <div className="e-col m-auto e-person-name">
                            <div className="g-margin-left m-truncate">
                              {member.name}
                            </div>
                          </div>
                          {member.status === GroupMembersStatus.Invited ||
                          member.status === GroupMembersStatus.NotInvited
                            ? this.renderInviteButton(member.id, member.name)
                            : null}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
                {this.noGhosts ? (
                  <div>
                    <p>
                      Copy the link below and share it with your friends so they
                      can join {this.state.name}.
                    </p>
                    <div className="e-kin-share">
                      <Input
                        readonly={true}
                        className="e-share-field"
                        type="text"
                        value={this.kinInviteUrl}
                        placeholder="Fetching share link..."
                        onChange={() => {}}
                      />
                      <img
                        className="e-kin-share-icon g-pointer"
                        src={copyIcon}
                        alt="Copy to clipboard"
                        onClick={() => {
                          Clipboard.copy(
                            this.kinInviteUrl ? this.kinInviteUrl : ""
                          );
                          new Notices().GlobalTrigger(() => {
                            this.props.layoutSidebarStore!.setNotice(
                              !this.noticeState,
                              "Copied to clipboard."
                            );
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </>
            )}
            {this.editingKin ? null : (
              <>
                <div className="e-divider-title">Kin Currency</div>
                <div className="b-currencies-list">
                  <ProfileSettingsCurrency currency={kinCurrency} disabled />
                </div>
              </>
            )}
          </div>
          <div className="e-col e-leave-kin">
            <div className="b-grid m-full-width m-space-between m-align-center">
              <div className="e-col">Leave this Kin</div>
              <img
                className="e-col e-leave-btn"
                src={signOutIcon}
                onClick={() => this.leaveKinModal()}
                alt="Leave this Kin"
              />
            </div>
          </div>
        </div>
      </Hammer>
    );
  }
}

export default KinSettings;
