import { observable, action } from "mobx";

/**
 * Sign In store state
 *
 * @export
 * @interface ISignInStore
 */
export interface ISignInStore {
  signingUpGoogle: boolean;
  signingUpFacebook: boolean;
  signingUpEmail: boolean;
  signUpEmailView: boolean;
  emailAddress: string;
  emailError: boolean;
  signUpRequestSent: boolean;
  signUpRequestSending: boolean;
  sighUpRequestResent: boolean;
  error: boolean;
  setSigningUpGoogle(state: boolean): void;
  setSigningUpFacebook(state: boolean): void;
  setSigningUpEmail(state: boolean): void;
  setSignUpEmailView(state: boolean): void;
  setEmailAddress(email: string): void;
  setEmailError(state: boolean): void;
  setSignUpRequestSent(state: boolean): void;
  setSignUpRequestSending(state: boolean): void;
  setSighUpRequestResent(state: boolean): void;
  setError(state: boolean): void;
}

/**
 * Sign In state management class
 *
 * @export
 * @class SignInStore
 * @implements {ISignInStore}
 */
export class SignInStore implements ISignInStore {
  /**
   * Clicked signing in with Google
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable signingUpGoogle: boolean;
  /**
   * Clicked signing in with Facebook
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable signingUpFacebook: boolean;
  /**
   * Clicked signing in with Email
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable signingUpEmail: boolean;
  /**
   * Viewing sign up email view
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable signUpEmailView: boolean;
  /**
   * Entered email address
   *
   * @type {string}
   * @memberof SignInStore
   */
  @observable emailAddress: string;
  /**
   * Validation failed for email address
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable emailError: boolean;
  /**
   * Email request successfully sent
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable signUpRequestSent: boolean;
  /**
   * Email request currently sending
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable signUpRequestSending: boolean;
  /**
   * Email request sent again
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable sighUpRequestResent: boolean;
  /**
   * Any UI errors
   *
   * @type {boolean}
   * @memberof SignInStore
   */
  @observable error: boolean;

  /**
   *Creates an instance of SignInStore.
   * @memberof SignInStore
   */
  constructor() {
    /**
     * Setting default values
     */
    this.signingUpGoogle = false;
    this.signingUpFacebook = false;
    this.signingUpEmail = false;
    this.signUpEmailView = false;
    this.emailAddress = "";
    this.emailError = false;
    this.signUpRequestSent = false;
    this.signUpRequestSending = false;
    this.sighUpRequestResent = false;
    this.error = false;
  }

  /**
   * Setting state values
   */
  @action setSigningUpGoogle(state: boolean): void {
    this.signingUpGoogle = state;
  }
  @action setSigningUpFacebook(state: boolean): void {
    this.signingUpFacebook = state;
  }
  @action setSigningUpEmail(state: boolean): void {
    this.signingUpEmail = state;
  }
  @action setSignUpEmailView(state: boolean): void {
    this.signUpEmailView = state;
  }
  @action setEmailAddress(email: string): void {
    this.emailAddress = email;
  }
  @action setEmailError(state: boolean): void {
    this.emailError = state;
  }
  @action setSignUpRequestSent(state: boolean): void {
    this.signUpRequestSent = state;
  }
  @action setSignUpRequestSending(state: boolean): void {
    this.signUpRequestSending = state;
  }
  @action setSighUpRequestResent(state: boolean): void {
    this.sighUpRequestResent = state;
  }
  @action setError(state: boolean): void {
    this.error = state;
  }
}
