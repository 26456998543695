import React from "react";
import { inject, observer } from "mobx-react";
import { computed } from "mobx";
import { ILayoutSidebarStore } from "../../stores/layoutSidebarStore";

import "./profile-settings.scss";
import arrowBack from "../../images/mask.svg";
import {
  IProfileSettingsStore,
  IProfileSettingsUser,
  IBankSortCodes,
  IUserBankAccountDetails
} from "../../stores/profileSettingsStore";
import ProfileSettingsImage from "./profile-settings-image";
import ProfileSettingsName from "./profile-settings-name";
import ProfileSettingsBank from "./profile-settings-bank";
import Header from "../header/header";
import Authentication from "../../common/authentication";
import Modal from "../modal/modal";
import Input from "../form/input";
import Autocomplete, { IAutocompleteList } from "../form/autocomplete";
import Button, { ButtonColors } from "../button/button";
import editIcon from "../../images/edit.svg";
import closeIcon from "../../images/x.svg";

/**
 * Profile settings props and stores
 *
 * @interface IProfileSettingsProps
 */
interface IProfileSettingsProps {
  active: boolean;
  profileSettingsStore?: IProfileSettingsStore;
  layoutSidebarStore?: ILayoutSidebarStore;
}

/**
 * Profile settings local bank form validation state
 *
 * @interface IProfileSettingsState
 */
interface IProfileSettingsState {
  accountNameInvalid: boolean;
  bankIdInvalid: boolean;
  branchCodeInvalid: boolean;
  branchCodePrepopulated: boolean;
  accountNumberInvalid: boolean;
}

/**
 * Component to render the profiles settings panel and the bank details modal
 *
 * @class ProfileSettings
 * @extends {React.Component<IProfileSettingsProps, IProfileSettingsState>}
 */
@inject("profileSettingsStore", "layoutSidebarStore")
@observer
class ProfileSettings extends React.Component<
  IProfileSettingsProps,
  IProfileSettingsState
> {
  @computed get loading(): boolean {
    return this.props.profileSettingsStore!.loading;
  }
  @computed get user(): IProfileSettingsUser {
    return this.props.profileSettingsStore!.user;
  }
  @computed get editing(): boolean {
    return this.props.profileSettingsStore!.editing;
  }
  @computed get editingBank(): boolean {
    return this.props.profileSettingsStore!.editingBank;
  }
  @computed get savingBank(): boolean {
    return this.props.profileSettingsStore!.savingBank;
  }
  @computed get bank() {
    return this.props.profileSettingsStore!.bank;
  }
  @computed get bankList() {
    return this.props.profileSettingsStore!.banks;
  }

  /**
   * Default state values
   *
   * @private
   * @memberof ProfileSettings
   */
  private defaultState = {
    accountNameInvalid: false,
    bankIdInvalid: false,
    branchCodeInvalid: false,
    branchCodePrepopulated: true,
    accountNumberInvalid: false
  };

  /**
   *Creates an instance of ProfileSettings.
   * @param {IProfileSettingsProps} props
   * @memberof ProfileSettings
   */
  constructor(props: IProfileSettingsProps) {
    super(props);
    this.state = {
      ...this.defaultState
    };
  }

  /**
   * Set the profile image to editing mode
   *
   * @param {boolean} state
   * @memberof ProfileSettings
   */
  editImage(state: boolean) {
    this.props.profileSettingsStore!.setEditing(state);
  }

  /**
   * Return the default bank from user accounts that have flag 'isDefault' = true
   *
   * @returns {IUserBankAccountDetails}
   * @memberof ProfileSettings
   */
  getDefaultBank(): IUserBankAccountDetails {
    return this.user.receivingAccounts.filter(bank => {
      return bank.isDefault === true;
    })[0];
  }

  /**
   * Fetch updated user data
   *
   * @memberof ProfileSettings
   */
  componentDidMount() {
    this.props.profileSettingsStore!.refreshUser();
  }

  /**
   * Reset editing modes when settings is closed
   *
   * @memberof ProfileSettings
   */
  closeProfileSettings() {
    this.props.layoutSidebarStore!.setProfileSettings(false);
    this.props.profileSettingsStore!.setEditing(false);
    this.props.profileSettingsStore!.setEditingImage(false);
    this.props.profileSettingsStore!.setEditingName(false);
    this.props.profileSettingsStore!.setEditingBank(false);
  }

  /**
   * Reset/update bank details and local state when bank details modal is closed
   *
   * @memberof ProfileSettings
   */
  closeBankDetails() {
    this.props.profileSettingsStore!.setEditingBank(false);
    if (this.getDefaultBank() !== undefined) {
      this.props.profileSettingsStore!.setBank(this.getDefaultBank());
    } else {
      this.props.profileSettingsStore!.setBankReset();
    }
    this.setState({
      ...this.defaultState
    });
  }

  /**
   * Create new bank details and check if not editing existing bank details
   *
   * @memberof ProfileSettings
   */
  createBankDetails() {
    let editingBankId: string = this.props.profileSettingsStore!.editingBankId;
    // If there is no bank id, you are not editing an existing bank, so create new
    if (editingBankId === "") {
      this.props.profileSettingsStore!.createUserBank(
        this.bank.accountName,
        this.bank.accountNumber,
        this.bank.sortCodeDetail.id,
        this.bank.sortCodeDetail.code,
        this.bank.sortCodeDetail.description!,
        true
      );
    } else {
      // Update existing bank details
      this.updateBankDetails(editingBankId);
    }
  }

  /**
   * Updating existing bank details
   *
   * @param {string} editingBankId
   * @memberof ProfileSettings
   */
  updateBankDetails(editingBankId: string) {
    this.props.profileSettingsStore!.updateUserBank(
      editingBankId!,
      this.bank.accountName,
      this.bank.accountNumber,
      this.bank.sortCodeDetail.id,
      this.bank.sortCodeDetail.code,
      this.bank.sortCodeDetail.description!,
      true
    );
  }

  /**
   * Remove current bank details by it's ID
   *
   * @memberof ProfileSettings
   */
  removeBankDetails() {
    this.props.profileSettingsStore!.removeUserBank(
      this.props.profileSettingsStore!.editingBankId
    );
  }

  /**
   * Find the brach code by bank id
   *
   * @param {string} id
   * @returns
   * @memberof ProfileSettings
   */
  findBranchCodeById(id: string) {
    let branchCode: string = "";
    let bankName: string = "";
    this.bankList.forEach(item => {
      if (item.id === id) {
        branchCode = item.code;
        bankName = item.description;
      }
    });
    return [branchCode, bankName];
  }

  /**
   * Format bank list data to readible format for the dropdown list
   *
   * @param {IBankSortCodes[]} banks
   * @returns
   * @memberof ProfileSettings
   */
  formatBanks(banks: IBankSortCodes[]) {
    let formattedBanks: IAutocompleteList[] = [];
    banks.forEach(bank => {
      formattedBanks.push({
        value: bank.id,
        title: bank.description
      });
    });
    return formattedBanks;
  }

  /**
   * Selecting/updating the user bank based on the dropdown value (ID) change
   *
   * @param {string} id
   * @memberof ProfileSettings
   */
  selectBank(dropdownValue: string) {
    // Fetch the bank name and branch code
    let branchCode: string = this.findBranchCodeById(dropdownValue)[0];
    let bankName: string = this.findBranchCodeById(dropdownValue)[1];
    // Update user bank to selected bank
    this.props.profileSettingsStore!.setBank({
      ...this.bank,
      sortCodeDetail: {
        code: branchCode,
        id: dropdownValue,
        description: bankName
      },
      isDefault: true
    });
    // If there was a previous branch code, the user is editing existing bank details
    if (branchCode !== "") {
      this.setState({
        branchCodePrepopulated: true
      });
      //Check branch code validations
      this.checkBranchCodeValidation();
    } else {
      this.setState({
        branchCodePrepopulated: false
      });
    }
  }

  /**
   * Validate account name form field
   *
   * @memberof ProfileSettings
   */
  checkAccountNameValidation() {
    if (this.bank.accountName === "") {
      this.setState({
        accountNameInvalid: true
      });
    } else {
      this.setState({
        accountNameInvalid: false
      });
    }
  }

  /**
   * Validate bank form field
   *
   * @memberof ProfileSettings
   */
  checkBankValidation() {
    if (
      this.bank.sortCodeDetail.id === "" &&
      !this.state.branchCodePrepopulated
    ) {
      this.setState({
        bankIdInvalid: true
      });
    } else {
      this.setState({
        bankIdInvalid: false
      });
    }
  }

  /**
   * Validate bank branch code form field
   *
   * @param {boolean} [liveCheck=false]
   * @memberof ProfileSettings
   */
  checkBranchCodeValidation(liveCheck: boolean = false) {
    // If 'liveCheck', validate as you type, usually when editing a invalid form field
    if (liveCheck) {
      if (this.bank.sortCodeDetail.code.length !== 6) {
        this.setState({
          branchCodeInvalid: true
        });
      } else {
        this.setState({
          branchCodeInvalid: false
        });
      }
    } else {
      if (
        this.bank.sortCodeDetail.code === "" ||
        this.bank.sortCodeDetail.code.length !== 6
      ) {
        this.setState({
          branchCodeInvalid: true
        });
      } else {
        this.setState({
          branchCodeInvalid: false
        });
      }
    }
  }

  /**
   *  Validate bank accout number form field
   *
   * @param {boolean} [liveCheck=false]
   * @memberof ProfileSettings
   */
  checkAccountNumberValidation(liveCheck: boolean = false) {
    // If 'liveCheck', validate as you type, usually when editing a invalid form field
    if (liveCheck) {
      if (this.bank.accountNumber.length < 8) {
        this.setState({
          accountNumberInvalid: true
        });
      } else {
        this.setState({
          accountNumberInvalid: false
        });
      }
    } else {
      if (
        this.bank.accountNumber === "" ||
        this.bank.accountNumber.length < 8
      ) {
        this.setState({
          accountNumberInvalid: true
        });
      } else {
        this.setState({
          accountNumberInvalid: false
        });
      }
    }
  }

  /**
   * Update form value only with numbers
   *
   * @param {string} value
   * @returns
   * @memberof ProfileSettings
   */
  setNumbersOnly(value: string) {
    let numberValue: string = "";
    for (let i = 0; i < value.length; i++) {
      if (!Number.isNaN(+value[i])) {
        numberValue += value[i];
      }
    }
    return numberValue;
  }

  /**
   * Check if any of the form fields are invalid to prevent form submission
   *
   * @returns
   * @memberof ProfileSettings
   */
  checkFormInvalid() {
    if (
      this.bank.accountName === "" ||
      this.bank.sortCodeDetail.id === "" ||
      !this.state.branchCodePrepopulated ||
      this.bank.sortCodeDetail.code === "" ||
      this.bank.sortCodeDetail.code.length !== 6 ||
      this.bank.accountNumber === "" ||
      this.bank.accountNumber.length < 8
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof ProfileSettings
   */
  render() {
    let settingsClasses: string =
      "b-profile-settings b-grid m-vertical m-full-height";
    if (this.props.active) {
      settingsClasses += " m-active";
    }

    if (this.editing) {
      settingsClasses += " m-editing";
    }
    return (
      <>
        <Modal
          visible={this.editingBank}
          onClick={() => this.closeBankDetails()}
          className="b-bank-details"
        >
          {this.bank !== undefined && this.editingBank ? (
            <div className="e-content">
              <h1>Bank details</h1>
              <p>
                Add your bank details here so friends can pay you back with Kin
              </p>
              <Input
                type="text"
                placeholder="Account Holder Name"
                small={true}
                noBorderStyle={true}
                className="e-input-field"
                error={this.state.accountNameInvalid}
                errorMsg={"This field is required"}
                value={this.bank.accountName}
                onBlur={() => this.checkAccountNameValidation()}
                onChange={event => {
                  this.props.profileSettingsStore!.setBank({
                    ...this.bank,
                    accountName: event
                  });
                  this.checkAccountNameValidation();
                }}
              />
              <Autocomplete
                list={this.formatBanks(this.bankList)}
                className="e-input-field"
                error={this.state.bankIdInvalid}
                errorMsg={"This field is required"}
                placeholder="Search / select a bank"
                value={this.bank.sortCodeDetail.id}
                onChange={event => {
                  this.selectBank(event);
                  this.checkBankValidation();
                }}
              />
              <Input
                type="text"
                placeholder="Branch code"
                small={true}
                maxLength={6}
                noBorderStyle={true}
                disabled={this.state.branchCodePrepopulated}
                className="e-input-field"
                error={this.state.branchCodeInvalid}
                errorMsg={"Should be 6 numbers long"}
                value={this.bank.sortCodeDetail.code}
                onBlur={() => this.checkBranchCodeValidation()}
                onChange={event => {
                  this.props.profileSettingsStore!.setBank({
                    ...this.bank,
                    sortCodeDetail: {
                      ...this.bank.sortCodeDetail,
                      code: this.setNumbersOnly(event)
                    }
                  });
                  this.checkBranchCodeValidation(true);
                }}
              />
              <Input
                type="text"
                placeholder="Account Number"
                small={true}
                minLength={8}
                maxLength={13}
                noBorderStyle={true}
                className="e-input-field"
                error={this.state.accountNumberInvalid}
                errorMsg={"Should be between 8 - 13 numbers"}
                value={this.bank.accountNumber}
                onBlur={() => this.checkAccountNumberValidation()}
                onChange={event => {
                  this.props.profileSettingsStore!.setBank({
                    ...this.bank,
                    accountNumber: this.setNumbersOnly(event)
                  });
                  this.checkAccountNumberValidation(true);
                }}
              />
              <div className="b-grid m-align-center">
                {this.props.profileSettingsStore!.editingBankId !== "" ? (
                  <div
                    className="g-a m-no-underline g-text-primary"
                    style={{
                      float: "left"
                    }}
                    onClick={() => this.removeBankDetails()}
                  >
                    Delete
                  </div>
                ) : null}
                <Button
                  iconCircle={false}
                  inline={true}
                  small={true}
                  color={ButtonColors.Black}
                  disabled={this.checkFormInvalid()}
                  loading={this.savingBank}
                  style={{
                    marginLeft: "auto"
                  }}
                  onClick={() => this.createBankDetails()}
                >
                  {this.props.profileSettingsStore!.editingBankId !== ""
                    ? "Update"
                    : "Save"}
                </Button>
              </div>
            </div>
          ) : null}
        </Modal>
        <div className={settingsClasses}>
          <div className="e-col m-auto e-profile-setting-scroll">
            <div className="e-profile-avatar">
              <div className="b-grid m-align-center g-margin-bottom">
                {this.editing ? (
                  <img
                    className="g-a e-profile-editing-back"
                    onClick={() => this.editImage(!this.editing)}
                    src={closeIcon}
                    alt="Close Profile Editing Settings"
                  />
                ) : (
                  <>
                    <img
                      className="g-a e-profile-back"
                      onClick={() => this.closeProfileSettings()}
                      src={arrowBack}
                      alt="Close Profile Settings"
                    />
                    <img
                      className="e-settings-edit"
                      onClick={() => this.editImage(!this.editing)}
                      src={editIcon}
                      alt="Edit Profile"
                    />
                  </>
                )}
              </div>
              <ProfileSettingsImage
                name={this.user.name}
                image={this.user.image}
              />
              <ProfileSettingsName name={this.user.name} />
            </div>
            <div className="e-profile-details">
              <div className="e-user-email">{this.user.email}</div>
              <ProfileSettingsBank
                className={this.editing ? "m-editing" : ""}
              />
            </div>
          </div>
          <div className="e-col b-menu">
            {this.editing ? null : (
              <div
                className="e-menu-item m-sign-out"
                onClick={() => new Authentication().signOut()}
              >
                <div className="e-menu-item-label">Sign Out</div>
              </div>
            )}
          </div>
          <Header className="m-sidebar" />
        </div>
      </>
    );
  }
}

export default ProfileSettings;
