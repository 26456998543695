import React from "react";
import Emoji from "react-emoji-render";

import "./tiles.scss";
import { TileType, IMemberPaid } from "../../stores/kinStore";

import Avatar from "../avatar/avatar";

/**
 * Settle tile props
 *
 * @interface ISettleTileProps
 */
interface ISettleTileProps {
  style?: any;
  type?: TileType;
  user: IMemberPaid;
  onClick?: () => void;
}

/**
 * Component to render a settle feed tile
 *
 * @param {*} props
 * @returns
 */
const SettleTile: React.FC<ISettleTileProps> = props => {
  let blockClasses: string = "e-tile-block m-settle";
  let amountClasses: string = "e-col e-tile-amount m-settle";
  let tileAction: string = "";
  switch (props.type) {
    case TileType.Future:
      blockClasses += " m-future";
      amountClasses += " m-future";
      break;
    case TileType.Owe:
      blockClasses += " m-owe";
      amountClasses += " m-owe";
      tileAction = "Pay";
      break;
    case TileType.Owed:
      blockClasses += " m-owed";
      amountClasses += " m-owed";
      tileAction = "Remind / Paid";
      break;
    case TileType.NotInvolved:
      blockClasses += " m-not-involved";
      amountClasses += " m-not-involved";
      break;
  }
  return (
    <div className="b-tile m-settle" style={props.style}>
      <div className={blockClasses}>
        <div className="b-grid m-full-width m-align-center m-space-between">
          <div className="e-col m-auto e-avatar">
            <Avatar
              name={props.user.name}
              borderWidth={0}
              size={props.user.size}
              image={props.user.image}
            />
            <Emoji className="e-avatar-name" text={props.user.name} />
          </div>
          <div
            className={amountClasses}
            onClick={() => (props.onClick ? props.onClick() : {})}
          >
            {props.type === TileType.NotInvolved ? (
              "Settled"
            ) : (
              <>
                {props.user.paid}
                <span className="e-settle-action">{tileAction}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettleTile;
