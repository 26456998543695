import React from "react";
import Hammer from "react-hammerjs";

import "./sidebar.scss";
import { updatePageTitle, history } from "../../common/history";
import { inject, observer } from "mobx-react";
import { groupsQuery, IUser, IKinsStore } from "../../stores/kinsStore";
import { computed } from "mobx";

import iconSettings from "../../images/icon-settings.svg";
import plusCircle from "../../images/plus-circle.svg";
import Avatar from "../avatar/avatar";
import { groupQuery, IKinStore } from "../../stores/kinStore";
import { ILayoutSidebarStore } from "../../stores/layoutSidebarStore";
import ProfileSettings from "../panel/profile-settings";
import ProfileSettingsCurrencies from "../panel/profile-settings-currencies";
import { IProfileSettingsStore } from "../../stores/profileSettingsStore";
import {
  IPeopleStore,
  IPeopleSidebarBalance,
  getPersonId,
  sumAmount
} from "../../stores/peopleStore";
import { IPersonStore, IPerson } from "../../stores/personStore";
import { IActivityStore } from "../../stores/activityStore";
import Segment, { SegmentEvent } from "../../common/segment";
import { ICurrenciesStore } from "../../stores/currenciesStore";

/**
 * Menu item interface props
 *
 * @export
 * @interface IMenuItem
 */
export interface IMenuItem {
  title: string;
  linkTo: string;
  icon: string;
  iconNew?: string;
  active: boolean;
  trackingEvent?: string;
}

/**
 * Sidebar props and stores
 *
 * @export
 * @interface ISidebarProps
 */
export interface ISidebarProps {
  menuItems: IMenuItem[];
  menuActiveItem: string;
  open?: boolean;
  activityStore?: IActivityStore;
  kinsStore?: IKinsStore;
  kinStore?: IKinStore;
  profileSettingsStore?: IProfileSettingsStore;
  layoutSidebarStore?: ILayoutSidebarStore;
  peopleStore?: IPeopleStore;
  personStore?: IPersonStore;
  currenciesStore?: ICurrenciesStore;
}

interface ISidebarState {
  menuPanOffset: number;
  menuPanStart: number;
}

/**
 * Component to create the side bar that contains the menu and profile settings
 *
 * @class Sidebar
 * @extends {React.Component<ISidebarProps, ISidebarState>}
 */
@inject(
  "activityStore",
  "kinsStore",
  "kinStore",
  "layoutSidebarStore",
  "profileSettingsStore",
  "peopleStore",
  "personStore",
  "currenciesStore"
)
@observer
class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  /**
   * Variable to store the updated active menu state in
   *
   * @private
   * @type {IMenuItem[]}
   * @memberof Sidebar
   */
  private updatedMenuItems: IMenuItem[] = [...this.props.menuItems];

  constructor(props: ISidebarProps) {
    super(props);
    this.state = {
      menuPanOffset: 0,
      menuPanStart: 0
    };
    this.updateData();
  }

  /**
   * Fetch the kins, update page title and menu items active state
   *
   * @memberof Sidebar
   */
  updateData() {
    // Fetch stored kins on kin page, so the kins are visible
    // in the sidebar when directly accessing the kin url.
    // Fetching stored also avoids double requests on Kins page.
    this.props.kinsStore!.fetchStoredKins(groupsQuery());
    // Update page title to menu item
    updatePageTitle(this.props.menuActiveItem);
    // Update menu items to correct active state
    this.updatedMenuItems.forEach(item => {
      if (item.title === this.props.menuActiveItem) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
  }

  /**
   * Open profile settings panel
   *
   * @memberof Sidebar
   */
  openProfileSettings() {
    this.props.layoutSidebarStore!.setProfileSettings(true);
  }

  /**
   * Open a kin directly from the sidebar
   *
   * @param {string} kinId
   * @memberof Sidebar
   */
  openKin(kinId: string) {
    // Close the menu if it's open (mobile)
    this.props.layoutSidebarStore!.setMenuPanel(false);
    // Change page
    history.push(`/home/kins/group/${kinId}`);
    // Update data for the page (in case already mounted)
    this.props.kinStore!.fetchKinData(groupQuery(kinId));
  }

  /**
   * Open a person balance from the sidebar
   *
   * @param {string} id
   * @memberof Sidebar
   */
  openPerson(person: IPeopleSidebarBalance) {
    // Close the menu if it's open (mobile)
    this.props.layoutSidebarStore!.setMenuPanel(false);
    // Change page
    history.push(`/home/people/person/${getPersonId(person)}`);

    // Update person object for the person page
    this.props.personStore!.setPerson({
      name: person.name,
      image: person.image,
      id: getPersonId(person),
      groupBalances: this.props.peopleStore!.fetchPersonGroupBalances(
        getPersonId(person)
      ),
      connectionId: person.connectionId,
      status: person.status,
      userId: person.userId,
      email: person.email,
      balances: person.balances
    });
  }

  @computed get menuState(): boolean {
    return this.props.layoutSidebarStore!.menuPanel;
  }
  @computed get profilePanel(): boolean {
    return this.props.layoutSidebarStore!.profileSettings;
  }
  @computed get user(): IUser {
    return this.props.profileSettingsStore!.user;
  }
  @computed get kins() {
    return this.props.kinsStore!.kins;
  }
  @computed get groupId() {
    return this.props.kinStore!.settleData.id;
  }
  @computed get peopleBalances(): IPeopleSidebarBalance[] {
    return this.props.peopleStore!.peopleSidebarBalances;
  }
  @computed get person(): IPerson {
    return this.props.personStore!.person;
  }
  @computed get hasActivityNotifications(): boolean {
    return this.props.activityStore!.hasNotifications;
  }
  @computed get currenciesPanel(): boolean {
    return !!this.props.layoutSidebarStore?.currenciesPanel;
  }

  /**
   * Rendering the kins list
   *
   * @returns
   * @memberof Sidebar
   */
  renderKins() {
    return this.kins.map((kin, index) => {
      return (
        <div
          onClick={() => this.openKin(kin.id)}
          key={index}
          className={
            this.groupId === kin.id
              ? "e-menu-list-item m-active"
              : "e-menu-list-item"
          }
        >
          <div className="e-menu-list-thumb">
            <Avatar
              size={44}
              name={kin.name}
              image={kin.image}
              borderWidth={0}
            />
          </div>
          <div className="e-menu-list-name">{kin.name}</div>
        </div>
      );
    });
  }

  renderPeopleBalanceAmount(person: IPeopleSidebarBalance) {
    let balance: string | null = null;
    let amount: number =
      sumAmount(person.amountOwed) - sumAmount(person.amountIOwe);
    let outstandingBalances: number =
      person.amountIOwe.filter(amount => amount.cents !== 0).length +
      person.amountOwed.filter(amount => amount.cents !== 0).length;
    if (outstandingBalances > 1) {
      return <>{outstandingBalances} Outstanding balances</>;
    } else {
      if (amount > 0) {
        const currencySymbol = this.props.currenciesStore!.currencySybmolByIsoCode(
          person.amountOwed.find(amount => amount.cents !== 0)!.currencyIso
        );
        return (
          <>
            <span className="m-owed">
              {currencySymbol}
              {(amount / 100).toFixed(2)}
            </span>{" "}
            owed to you
          </>
        );
      } else if (amount < 0) {
        const currencySymbol = this.props.currenciesStore!.currencySybmolByIsoCode(
          person.amountIOwe.find(amount => amount.cents !== 0)!.currencyIso
        );
        return (
          <>
            you owe{" "}
            <span className="m-owe">
              {currencySymbol}
              {(Math.abs(amount) / 100).toFixed(2)}
            </span>
          </>
        );
      } else {
        return balance;
      }
    }
  }

  renderPeopleBalances() {
    return this.peopleBalances.map((person, index) => {
      return (
        <div
          onClick={() => this.openPerson(person)}
          key={index}
          className={
            this.person.id === getPersonId(person)
              ? "e-menu-list-item m-active"
              : "e-menu-list-item"
          }
        >
          <div className="e-menu-list-thumb">
            <Avatar
              size={44}
              name={person.name}
              image={person.image}
              borderWidth={0}
            />
          </div>
          <div className="e-menu-list-name m-person">
            <div>{person.name}</div>
            <div className="e-menu-list-amount">
              {this.renderPeopleBalanceAmount(person)}
            </div>
          </div>
        </div>
      );
    });
  }

  /**
   * Calculate the panning position
   *
   * @param {HammerInput} event
   * @returns {number}
   * @memberof Sidebar
   */
  panCalculation(event: HammerInput): number {
    let panPosition: number = (event.srcEvent as any).pageX + 10; // 10 is for a more accurate calculation
    return panPosition - this.state.menuPanStart;
  }

  /**
   * Record pan start location
   *
   * @param {HammerInput} event
   * @memberof Sidebar
   */
  panStart(event: HammerInput) {
    this.setState({
      menuPanStart: (event.srcEvent as any).pageX
    });
  }

  /**
   * Check position on pan end and close menu if needed
   *
   * @param {HammerInput} event
   * @memberof Sidebar
   */
  panEnd(event: HammerInput) {
    // Only do panning for touch events (ignores desktop mouse) and left and right direction
    if (
      event.pointerType === "touch" &&
      (event.offsetDirection === 2 || event.offsetDirection === 4)
    ) {
      let position: number = this.panCalculation(event);
      if (position < -30) {
        this.props.layoutSidebarStore!.setMenuPanel(false);
        this.props.layoutSidebarStore!.setProfileSettings(false);
        this.resetPan();
      }
    }
  }

  /**
   * Adjust the menu position while panning
   *
   * @param {HammerInput} event
   * @memberof Sidebar
   */
  panMenu(event: HammerInput) {
    // Only do panning for touch events (ignores desktop mouse) and left and right direction
    if (
      event.pointerType === "touch" &&
      (event.offsetDirection === 2 || event.offsetDirection === 4)
    ) {
      let position: number = this.panCalculation(event);
      if (position > 0) {
        position = 0;
      }
      this.setState({
        menuPanOffset: position
      });
    }
  }

  /**
   * Reset the pan position so that menu will have
   * the right position when opened again
   *
   * @memberof Sidebar
   */
  resetPan() {
    setTimeout(() => {
      this.setState({
        menuPanOffset: 0,
        menuPanStart: 0
      });
    }, 300);
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof Sidebar
   */
  render() {
    let siderbarClasses: string = "b-sidebar b-grid m-vertical m-full-height";
    if (this.menuState) {
      siderbarClasses += " m-open";
    }
    return (
      <Hammer
        onPan={e => this.panMenu(e)}
        onPanStart={e => this.panStart(e)}
        onPanEnd={e => this.panEnd(e)}
      >
        <div
          className={siderbarClasses}
          style={{
            marginLeft: this.state.menuPanOffset + "px"
          }}
        >
          <ProfileSettings active={this.profilePanel} />
          <ProfileSettingsCurrencies active={this.currenciesPanel} />
          <div
            className="b-profile-card b-grid"
            onClick={() => {
              new Segment().Track(SegmentEvent.PROFILE_TAPPED);
              this.openProfileSettings();
            }}
          >
            <Avatar
              size={48}
              borderWidth={2}
              name={this.user.name}
              image={this.user.image}
            />
            <img
              src={iconSettings}
              className="e-profile-settings"
              alt="Profile Settings"
            />
          </div>
          <div className="e-sidebar-menu-top">
            <div className="e-col">
              <div className="b-menu b-grid">
                <div
                  onClick={() => {
                    if (this.props.menuItems[0].trackingEvent) {
                      new Segment().Track(
                        this.props.menuItems[0].trackingEvent
                      );
                    }
                    this.props.layoutSidebarStore!.setMenuPanel(false);
                    history.push(this.props.menuItems[0].linkTo);
                    this.props.layoutSidebarStore!.setKinSettings(false);
                    this.props.kinStore!.resetData();
                  }}
                  className={
                    this.props.menuItems[0].active
                      ? "e-col e-menu-item m-active"
                      : "e-col e-menu-item"
                  }
                >
                  <img
                    src={this.props.menuItems[0].icon}
                    alt={this.props.menuItems[0].title}
                  />{" "}
                  <span className="e-menu-item-label">
                    {this.props.menuItems[0].title}
                  </span>
                  <div
                    className="e-menu-item-new"
                    onClick={() => {
                      this.props.layoutSidebarStore!.setNewKinModal(
                        !this.props.layoutSidebarStore!.newKinModal
                      );

                      this.props.layoutSidebarStore!.setNewKinCurrency(
                        this.props.profileSettingsStore!.user.defaultCurrency
                          .isoCode
                      );
                    }}
                  >
                    <Avatar
                      borderWidth={0}
                      transparent={true}
                      size={24}
                      name="New Kin"
                      image={plusCircle}
                    />
                  </div>
                </div>
                {this.props.kinStore!.groupId !== "" &&
                this.props.personStore!.person === undefined ? (
                  <div className="e-menu-list e-col m-scroll-auto m-auto">
                    {this.renderKins()}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="e-col b-menu">
              <div
                onClick={() => {
                  this.props.layoutSidebarStore!.setMenuPanel(false);
                  history.push(this.props.menuItems[1].linkTo);
                  this.props.kinStore!.resetData();
                }}
                className={
                  this.props.menuItems[1].active
                    ? "e-menu-item m-active"
                    : "e-menu-item"
                }
              >
                <img
                  src={this.props.menuItems[1].icon}
                  alt={this.props.menuItems[1].title}
                />{" "}
                <span className="e-menu-item-label">
                  {this.props.menuItems[1].title}
                </span>
              </div>
              {this.props.personStore!.person !== undefined ? (
                <div className="e-menu-list e-col m-scroll-auto m-auto">
                  {this.renderPeopleBalances()}
                </div>
              ) : null}
            </div>
            <div className="e-col b-menu">
              <div
                onClick={() => {
                  this.props.layoutSidebarStore!.setMenuPanel(false);
                  history.push(this.props.menuItems[2].linkTo);
                  this.props.kinStore!.resetData();
                }}
                className={
                  this.props.menuItems[2].active
                    ? "e-menu-item m-active"
                    : "e-menu-item"
                }
              >
                <img
                  src={
                    this.hasActivityNotifications
                      ? this.props.menuItems[2].iconNew
                      : this.props.menuItems[2].icon
                  }
                  alt={this.props.menuItems[2].title}
                />{" "}
                {this.hasActivityNotifications ? (
                  <span className="e-notification-pulse"></span>
                ) : null}
                <span className="e-menu-item-label">
                  {this.props.menuItems[2].title}
                </span>
              </div>
            </div>
            <div className="e-col b-menu">
              <div
                onClick={() => {
                  if (this.props.menuItems[3].trackingEvent) {
                    new Segment().Track(this.props.menuItems[3].trackingEvent);
                  }
                  this.props.layoutSidebarStore!.setMenuPanel(false);
                  history.push(this.props.menuItems[3].linkTo);
                  this.props.kinStore!.resetData();
                }}
                className={
                  this.props.menuItems[3].active
                    ? "e-menu-item m-active"
                    : "e-menu-item"
                }
              >
                <img
                  src={this.props.menuItems[3].icon}
                  alt={this.props.menuItems[3].title}
                />{" "}
                <span className="e-menu-item-label">
                  {this.props.menuItems[3].title}
                </span>
              </div>
            </div>
          </div>

          <div className="e-sidebar-menu-bottom">
            <div className="e-col b-menu">
              <div
                className="e-menu-item"
                onClick={function() {
                  // @ts-ignore
                  if (window && window.zE) {
                    // @ts-ignore
                    window.zE(function() {
                      // @ts-ignore
                      window.zE.showIPMWidget();
                    });
                  }
                }}
              >
                <img
                  src={this.props.menuItems[4].icon}
                  alt={this.props.menuItems[4].title}
                />
                <span className="e-menu-item-label">
                  {this.props.menuItems[4].title}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Hammer>
    );
  }
}

export default Sidebar;
