import React from "react";

import "./reminders.scss";
import Button, { ButtonColors } from "../button/button";

import {
  IKinStore,
  IReminderUser,
  IGroupMember,
  IMemberPaid,
  IPaymentSettlement,
  paymentQuery
} from "../../stores/kinStore";
import { inject, observer } from "mobx-react";
import { KinHelper } from "../../pages/home/kin";
import { IAvatarProps } from "../avatar/avatar";
import { computed } from "mobx";
import moment from "moment";
import { SettlementType } from "./payment";
import { IPersonStore } from "../../stores/personStore";
import {
  IPeopleStore,
  IPeopleConnectionGroupBalance,
  IAmount,
  matchOnConnectionIdOrUserId
} from "../../stores/peopleStore";

/**
 * Reminder options interface
 *
 * @interface IReminderOptions
 */
interface IReminderOptions {
  users?: IAvatarProps[]; // If user passed in from outside a kin
  user?: IReminderUser;
  kinStore?: IKinStore;
  personStore?: IPersonStore;
  peopleStore?: IPeopleStore;
  personReminder?: boolean;
  payPerson?: () => void;
}

/**
 * Component modal for displaying reminder options
 *
 * @class ReminderOptions
 * @extends {React.Component<IReminderOptions, {}>}
 */
@inject("kinStore", "personStore", "peopleStore")
@observer
class ReminderOptions extends React.Component<IReminderOptions, {}> {
  /**
   * Define default settlement type
   *
   * @private
   * @type {SettlementType}
   * @memberof ReminderOptions
   */
  private settlementType: SettlementType;

  /**
   * Creates an instance of PaymentModalContent.
   * @param {IPaymentModalProps} props
   * @memberof PaymentModalContent
   */
  constructor(props: IReminderOptions) {
    super(props);
    this.settlementType = SettlementType.Settled;
  }

  @computed get members(): IGroupMember[] {
    return this.props.kinStore!.members;
  }
  @computed get currentUserId(): string {
    return this.props.kinStore!.currentUserId;
  }
  @computed get groupName(): string {
    const groupName = this.props.kinStore!.payment?.transaction?.group;
    return groupName ? groupName : "";
  }
  @computed get sendingPayment(): boolean {
    return this.props.kinStore!.sendingPayment;
  }

  /**
   * Get the payment data for the payment modal
   *
   * @param {string} memberId
   * @param {number} amount
   * @param {boolean} currentUserOwes
   * @memberof Kin
   */
  setPayment(memberId: string, amount: IAmount, updateUI: boolean = true) {
    let currentUser:
      | IGroupMember
      | IMemberPaid = new KinHelper().findGroupMemberByUserId(
      this.members,
      this.currentUserId
    );
    let otherMember:
      | IGroupMember
      | IMemberPaid = new KinHelper().findGroupMemberByUserId(
      this.members,
      memberId
    );
    let users: IAvatarProps[];
    if (this.props.users) {
      users = this.props.users;
    } else {
      users = [
        {
          name: otherMember.name,
          image: otherMember.image,
          size: 32
        },
        {
          name: currentUser.name,
          image: currentUser.image,
          size: 32
        }
      ];
    }
    this.props.kinStore!.setPayment({
      currentUserId: this.props.kinStore!.payment
        ? this.props.kinStore!.payment!.currentUserId
        : (currentUser as IGroupMember).id,
      users: users,
      transaction: {
        amount: amount,
        repayAmount: amount,
        group: this.groupName,
        user: otherMember.name,
        userId: memberId,
        paying: false
      }
    });
    if (updateUI) {
      this.props.kinStore!.setReminderModal(false);
      this.props.kinStore!.setPaymentModal(true);
    }
  }

  /**
   * Settle the payment
   *
   * @memberof PaymentModalContent
   */
  settlePayment() {
    this.setPayment(this.props.user!.id, this.props.user!.amount, false);
    this.props.kinStore!.setSendingPayment(true);
    let fromMemberId: string;
    let toMemberId: string;
    // Check if the current user is paying or being paid
    if (this.props.kinStore!.payment!.transaction!.paying) {
      // If coming form people balances user assigned ID instead
      if (this.props.kinStore!.payment!.currentUserId) {
        fromMemberId = this.props.kinStore!.payment!.currentUserId;
      } else {
        fromMemberId = this.props.kinStore!.currentUserId;
      }
      toMemberId = this.props.kinStore!.payment!.transaction!.userId!;
    } else {
      fromMemberId = this.props.kinStore!.payment!.transaction!.userId!;
      // If coming form people balances user assigned ID instead
      if (this.props.kinStore!.payment!.currentUserId) {
        toMemberId = this.props.kinStore!.payment!.currentUserId;
      } else {
        toMemberId = this.props.kinStore!.currentUserId;
      }
    }
    // Build settlement data object
    let settlementData: IPaymentSettlement = {
      fromMemberId: fromMemberId,
      description: this.settlementType,
      date: moment().toISOString(true),
      to: [
        {
          memberId: toMemberId,
          amount: {
            currencyIso: this.props.kinStore!.payment!.transaction!.repayAmount!
              .currencyIso,
            cents: this.props.kinStore!.payment!.transaction!.repayAmount!.cents
          }
        }
      ]
    };
    // Send request
    this.props.kinStore!.settlePayment(
      paymentQuery(this.props.kinStore!.groupId, settlementData),
      () => {
        const person = this.props.personStore!.person;
        // When actioned from person balance
        if (person !== undefined) {
          // Fetch people balance data
          this.props.peopleStore!.refreshPeople().then(() => {
            let personId: string = this.props.personStore!.person.id;
            let groupBalances: IPeopleConnectionGroupBalance[] = this.props.peopleStore!.fetchPersonGroupBalances(
              personId
            );
            const personFromStore: any = this.props.peopleStore!.peopleBalances!.connectionBalances.find(
              personBalance => {
                return matchOnConnectionIdOrUserId(personBalance, personId);
              }
            );
            // Update person so that page rerenders data update
            this.props.personStore!.setPerson({
              name: person.name,
              image: person.image,
              id: personId,
              groupBalances: groupBalances,
              status: person.status,
              userId: person.userId,
              email: person.email,
              balances: personFromStore.balances
            });
          });
        }
      }
    );
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof ReminderOptions
   */
  render() {
    return (
      <div className="b-reminder-modal">
        <div className="g-h5">Get settled</div>
        <div className="g-h6 e-reminder-description">
          Want {this.props.user!.name} to pay you? Send a reminder. Or if it’s
          already settled up, add a payment to reflect this.
        </div>
        <Button
          disabled={this.sendingPayment}
          className="e-reminder-btn"
          color={ButtonColors.Secondary}
          small={true}
          onClick={() => {
            this.props.user!.ghost
              ? this.props.kinStore!.setReminderShareGhostModal(true)
              : this.props.kinStore!.setReminderShareUserModal(true);
          }}
        >
          Remind {this.props.user!.name}
        </Button>
        <Button
          loading={this.sendingPayment}
          className="e-reminder-btn"
          color={ButtonColors.Secondary}
          small={true}
          onClick={() =>
            this.props.personReminder
              ? this.props.payPerson!()
              : this.settlePayment()
          }
        >
          {this.props.user!.name} paid me {this.props.user!.amount.formatted}
        </Button>
        {this.props.personReminder ? null : (
          <Button
            disabled={this.sendingPayment}
            className="e-reminder-btn"
            color={ButtonColors.Secondary}
            small={true}
            onClick={() =>
              this.setPayment(this.props.user!.id, this.props.user!.amount)
            }
          >
            {this.props.user!.name} paid me a different amount
          </Button>
        )}
        <div
          onClick={() => this.props.kinStore!.setReminderModal(false)}
          className="e-reminder-cancel"
        >
          Cancel
        </div>
      </div>
    );
  }
}

export default ReminderOptions;
