import React from "react";
import "../../global.scss";
import LoaderContent from "../../hoc/content-loader";

/**
 * Activity loading component props for showing and hiding the loader
 *
 * @interface IActivityLoaderProps
 */
interface IActivityLoaderProps {
  active: boolean;
  style?: any;
}

/**
 * Activity Loader component for displaying while a query is executing in the browser
 *
 * @param {*} props
 */
const ActivityLoader: React.FC<IActivityLoaderProps> = props => (
  <LoaderContent style={props.style} active={props.active}>
    <div
      className="g-loader"
      style={{
        height: "25px",
        width: "180px",
        margin: "24px 0 40px"
      }}
    ></div>
    <div
      className="g-loader"
      style={{
        height: "25px",
        width: "100%",
        marginBottom: "15px"
      }}
    ></div>
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: "30px",
        width: "500px",
        maxWidth: "100%"
      }}
    >
      <div
        className="g-loader"
        style={{
          marginRight: "10px",
          width: "15%",
          height: "20px"
        }}
      ></div>
      <div
        className="g-loader"
        style={{
          marginRight: "10px",
          width: "15%",
          height: "20px"
        }}
      ></div>
      <div
        className="g-loader"
        style={{
          marginRight: "10px",
          width: "15%",
          height: "20px"
        }}
      ></div>
      <div
        className="g-loader"
        style={{
          width: "15%",
          height: "20px"
        }}
      ></div>
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
        width: "500px",
        maxWidth: "100%"
      }}
    >
      <div
        className="g-loader m-round"
        style={{
          width: "48px",
          minWidth: "48px",
          height: "48px",
          marginRight: "15px"
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div
          className="g-loader"
          style={{
            width: "80px",
            height: "15px",
            marginBottom: "10px"
          }}
        ></div>
        <div
          className="g-loader"
          style={{
            width: "130px",
            height: "15px"
          }}
        ></div>
      </div>
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
        width: "500px",
        maxWidth: "100%"
      }}
    >
      <div
        className="g-loader m-round"
        style={{
          width: "48px",
          minWidth: "48px",
          height: "48px",
          marginRight: "15px"
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div
          className="g-loader"
          style={{
            width: "130px",
            height: "15px",
            marginBottom: "10px"
          }}
        ></div>
        <div
          className="g-loader"
          style={{
            width: "180px",
            height: "15px"
          }}
        ></div>
      </div>
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
        width: "500px",
        maxWidth: "100%"
      }}
    >
      <div
        className="g-loader m-round"
        style={{
          width: "48px",
          minWidth: "48px",
          height: "48px",
          marginRight: "15px"
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div
          className="g-loader"
          style={{
            width: "80px",
            height: "15px",
            marginBottom: "10px"
          }}
        ></div>
        <div
          className="g-loader"
          style={{
            width: "130px",
            height: "15px"
          }}
        ></div>
      </div>
    </div>
  </LoaderContent>
);

/**
 * Default prop state for the loader is always false
 */
ActivityLoader.defaultProps = {
  active: false
};

export default ActivityLoader;
