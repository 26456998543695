import React from "react";

import "./expense.scss";
import Button, { ButtonColors } from "../button/button";
import Input from "../form/input";

import {
  IKinStore,
  IExpense,
  IGroupMember,
  TransactionRecordCreateUpdatePortion,
  SplitType,
  IExpenseSplitUser
} from "../../stores/kinStore";
import { ICurrenciesStore } from "../../stores/currenciesStore";
import { inject, observer } from "mobx-react";
import Toggle from "../toggle/toggle";

import arrowRightIcon from "../../images/chevron-right.svg";
import calenderIcon from "../../images/calendar.svg";
import { computed } from "mobx";
import moment from "moment";
import Calendar from "react-calendar";
import "../calendar/calendar.scss";
import Dropdown, { IDropdownItem } from "../form/dropdown";
import { KinHelper } from "../../pages/home/kin";
import ExpenseSplit from "../expense-split/expense-split";
import { GroupMembersStatus } from "../../stores/kinsStore";

/**
 * Payment modal props
 *
 * @interface IExpenseModalProps
 * @extends {IPaymentProps}
 */
interface IExpenseModalProps {
  kinStore?: IKinStore;
  currenciesStore?: ICurrenciesStore;
}

interface IExpenseState {
  activeItem: string;
  future: boolean;
  showCalender: boolean;
  originalAmount: number | boolean;
  isSplitEqually: boolean;
  currencySymbol: string;
}

/**
 * Options available in the toggle
 */
const toggleOptions = ["Future", "Paid"];

/**
 * Copy change depending on expense type
 *
 * @enum {number}
 */
enum PaidByLabel {
  Future = "To be paid by",
  NotFuture = "Paid by"
}

/**
 * Not sure yet label for consistency
 */
const NotSureYetLabel = "Not sure yet";

/**
 * Component modal for displaying and editing a payment
 *
 * @class ExpenseModalContent
 * @extends {React.Component<IExpenseModalProps, IExpenseState>}
 */
@inject("kinStore", "currenciesStore")
@observer
class ExpenseModalContent extends React.Component<
  IExpenseModalProps,
  IExpenseState
> {
  @computed get currentUserId(): string {
    return this.props.kinStore!.currentUserId;
  }
  @computed get expense(): IExpense {
    return this.props.kinStore!.expense;
  }
  @computed get members(): IGroupMember[] {
    return this.props.kinStore!.members;
  }
  @computed get expenseSplitMode(): boolean {
    return this.props.kinStore!.expenseSplitMode;
  }
  @computed get expenseSplitMembers(): IExpenseSplitUser[] | undefined {
    return this.props.kinStore!.expenseSplitMembers;
  }
  @computed get addingExpense(): boolean {
    return this.props.kinStore!.addingExpense;
  }
  @computed get editingExpense(): boolean {
    return this.props.kinStore!.editingExpense;
  }
  /**
   * Creates an instance of ExpenseModalContent.
   * @param {IExpenseModalProps} props
   * @memberof ExpenseModalContent
   */
  constructor(props: IExpenseModalProps) {
    super(props);
    this.state = {
      activeItem: this.expense.future ? toggleOptions[0] : toggleOptions[1],
      future: this.expense.future,
      showCalender: false,
      originalAmount: this.editingExpense ? this.expense.amount.cents : false,
      isSplitEqually: this.isKinSplitEqually(),
      currencySymbol: this.getCurrencySymbol()
    };
    this.assignExpense();
  }

  getCurrencySymbol() {
    return this.props.currenciesStore!.currencySybmolByIsoCode(
      this.props.kinStore!.expense.currencyIso
    );
  }

  /**
   * Assign the expense to update the state before render
   *
   * @memberof ExpenseModalContent
   */
  assignExpense() {
    this.props.kinStore!.setExpense({
      ...this.expense,
      responsibleMemberId: this.editingExpense
        ? this.expense.responsibleMemberId
        : this.currentUserId
    });
  }

  updateAmount(amount: string) {
    const formattedCents = this.props.currenciesStore!.formattedStringToCents(
      amount
    );
    let expenseCopy: IExpense = {
      ...this.expense,
      amount: this.props.currenciesStore!.createAmount({
        cents: formattedCents,
        currencyIso: this.expense.currencyIso
      })
    };

    this.setState(state => ({
      ...state,
      originalAmount: formattedCents
    }));
    this.props.kinStore!.setExpense(expenseCopy);
  }

  /**
   * Update the description of the expense
   *
   * @param {string} value
   * @memberof ExpenseModalContent
   */
  updateDescription(value: string) {
    let expenseCopy: IExpense = {
      ...this.expense
    };
    expenseCopy.description = value;
    this.props.kinStore!.setExpense({
      ...expenseCopy
    });
  }

  /**
   * Change the expense type and update the visuals
   *
   * @param {string} item
   * @memberof ExpenseModalContent
   */
  changeExpenseType(item: string) {
    let isFutureExpense: boolean = item === toggleOptions[0];
    // Set expense to null by default
    let expenseDate: string | null = null;
    // Set expense to whatever it was before.
    let paidByUserId: string | null = this.expense.responsibleMemberId;
    // If not a future expense set to today
    if (!isFutureExpense) {
      expenseDate = moment.utc().format();
      // Reset paid by to current user if user switches back to paid with "not sure yet" selected
      if (this.expense!.responsibleMemberId === null) {
        paidByUserId = this.currentUserId;
      }
    }
    // Update local state
    this.setState({
      activeItem: item,
      future: isFutureExpense
    });
    // Update expense state object
    this.props.kinStore!.setExpense({
      ...this.expense,
      date: expenseDate,
      future: isFutureExpense,
      responsibleMemberId: paidByUserId
    });
  }

  /**
   * Check that all required information has been completed before
   * allowing adding an expense
   *
   * @memberof ExpenseModalContent
   */
  checkValidExpenseData(): boolean {
    if (
      this.expense.amount.cents === 0 ||
      this.expense.description.length === 0
    ) {
      return true;
    }
    return false;
  }

  /**
   * Check that the updated information is correct
   * allowing to update an expense
   *
   * @memberof ExpenseModalContent
   */
  checkValidUpdatedExpenseData(): boolean {
    const amountChanged =
      this.state.originalAmount !== this.expense.amount.cents;
    if (!this.editingExpense) {
      return false;
    }
    if (this.state.originalAmount && !amountChanged) {
      return false;
    }
    if (this.state.isSplitEqually) {
      return false;
    }
    if (this.expenseSplitMembers) {
      const portionsTotal = this.expenseSplitMembers.reduce(
        (total, portion) => {
          const size = Number(portion.value);
          return Number(total) + size;
        },
        0
      );
      if (portionsTotal !== Number(this.expense.amount)) {
        return true;
      }
      return false;
    }
    return true;
  }

  /**
   * Calculate the split data
   *
   * @returns {TransactionRecordCreateUpdatePortion[]}
   * @memberof ExpenseModalContent
   */
  getSplitData(): TransactionRecordCreateUpdatePortion[] {
    let portions: TransactionRecordCreateUpdatePortion[] = [];
    // If editing, get expense split as extracted from kinStore.expenseSplitMembers
    if (this.expenseSplitMembers !== undefined) {
      this.expenseSplitMembers
        .filter(member => member.selected)
        .forEach((member: IExpenseSplitUser) => {
          portions.push({
            memberId: member.id,
            size: member.value
          });
        });
    } else {
      // Otherwise, just use the kin's members.
      let members: IGroupMember[] = this.members.filter(
        member => member.status !== GroupMembersStatus.InActive
      );
      members.forEach((member: IGroupMember) => {
        let amount: number = Math.floor(
          this.expense.amount.cents / members.length
        );
        portions.push({
          memberId: member.id,
          size: amount
        });
      });
    }
    return portions;
  }

  setSplitData() {
    let splitType: SplitType = this.isKinSplitEqually()
      ? this.expense.split.type
      : SplitType.Ratio;
    let portions: TransactionRecordCreateUpdatePortion[] = this.getSplitData();
    this.props.kinStore!.setExpense({
      ...this.expense,
      split: {
        type: splitType,
        portions: portions.map(portion => {
          return {
            ...portion,
            size: portion.size
          };
        })
      }
    });
  }

  isKinSplitEqually(): boolean {
    if (this.expenseSplitMembers) {
      const portionCount = this.expense.split.portions.length;
      const memberCount = this.expenseSplitMembers!.length;

      if (portionCount < memberCount) return false;
    }

    let samplePortionSize = 0;
    if (this.expense.split.portions.length) {
      let hasAssignedPortion = this.expense.split.portions.find(
        p => p.size !== 0
      );
      samplePortionSize = hasAssignedPortion ? hasAssignedPortion.size : 0;
    }
    const hasEqualPortions =
      this.expense.split.portions!.filter(
        portion => portion.size !== samplePortionSize
      ).length === 0;

    return hasEqualPortions;
  }

  setKinSplitState = (newState: boolean, callback?: any): void => {
    if (callback) {
      this.setState(state => {
        return {
          ...state,
          isSplitEqually: newState
        };
      }, callback());
    } else {
      this.setState(state => {
        return {
          ...state,
          isSplitEqually: newState
        };
      });
    }
  };

  /**
   * Submitting the request to
   *
   * @memberof ExpenseModalContent
   */
  addExpense() {
    this.setSplitData();
    this.props.kinStore!.addUpdateExpense(this.editingExpense);
  }

  /**
   * Formatted members list for the expense split dropdown
   *
   * @memberof ExpenseModalContent
   */
  membersList(isFuture: boolean = false) {
    let users: IDropdownItem[] = [];
    if (isFuture) {
      users.push({
        value: NotSureYetLabel,
        id: null
      });
    }
    this.members.forEach(member => {
      if (member.status !== GroupMembersStatus.InActive) {
        users.push({
          value: member.name,
          id: member.id
        });
      }
    });
    return users;
  }

  /**
   * Display dropdown label and change to "You" if current user
   *
   * @returns {string}
   * @memberof ExpenseModalContent
   */
  expenseSplitUserName(): string {
    let userName: string = new KinHelper().findGroupMemberByUserId(
      this.members,
      this.expense!.responsibleMemberId!
    ).name;
    if (this.expense!.responsibleMemberId === null && this.expense!.future) {
      userName = NotSureYetLabel;
    }
    if (this.expense!.responsibleMemberId === this.currentUserId) {
      userName = "You";
    }
    return userName;
  }

  expenseSplitType() {
    if (this.expenseSplitMembers) {
      const selectedUsers: number = this.expenseSplitMembers!.filter(
        member => member.selected
      ).length;
      const groupSize: number = this.members.filter(
        member => member.status !== GroupMembersStatus.InActive
      ).length;
      if (selectedUsers !== 0) {
        return (
          <>
            <strong>{this.state.isSplitEqually ? "equally" : "custom"}</strong>{" "}
            by{" "}
            <strong>{selectedUsers < groupSize ? selectedUsers : "all"}</strong>
            .
          </>
        );
      } else {
        return (
          <>
            <strong>{NotSureYetLabel.toLowerCase()}</strong>
          </>
        );
      }
    }
    return (
      <>
        <strong>equally</strong> by <strong>all</strong>
      </>
    );
  }

  /**
   * Render calendar component with future or paid logic
   *
   * @returns
   * @memberof ExpenseModalContent
   */
  renderCalendar() {
    let dateString: string;
    let dateMoment: string = moment(this.expense.date!).format("YYYY-MM-DD");
    let dateToday: string = moment().format("YYYY-MM-DD");
    // If it's a future expense display a certain string
    if (this.expense.date === null && this.expense.future) {
      dateString = "Someday";
    } else {
      // If the date is today
      if (moment(dateMoment).isSame(dateToday)) {
        dateString = "Today";
      } else {
        // If not today just display the actual date
        dateString = dateMoment;
      }
    }
    let calendarClasses: string = "e-expense-calendar";
    if (this.state.showCalender) {
      calendarClasses += " m-open";
    }
    return (
      <div className="g-relative">
        <div
          className={
            this.checkValidExpenseData()
              ? "e-expense-details m-disabled"
              : "e-expense-details"
          }
          onClick={() =>
            this.setState({
              showCalender: !this.state.showCalender
            })
          }
        >
          <div className="e-detail-label">{dateString}</div>
          <div className="e-detail-icon">
            <img src={calenderIcon} alt="Expense Split" />
          </div>
        </div>
        <Calendar
          className={calendarClasses}
          minDate={this.state.future ? new Date() : undefined}
          onChange={event => {
            this.setState({
              showCalender: !this.state.showCalender
            });
            this.props.kinStore!.setExpense({
              ...this.expense,
              date: moment.utc(event as Date).format()
            });
          }}
        />
      </div>
    );
  }

  /**
   * Render all the default fields
   *
   * @param {string} paymentFieldClasses
   * @param {string} paymentCurrencyClasses
   * @returns
   * @memberof ExpenseModalContent
   */
  renderAllFields() {
    let paymentCurrencyClasses: string = "e-expense-currency";

    let paymentFieldClasses: string = "e-expense-field";

    if (this.props.kinStore!.editingPayment) {
      paymentFieldClasses += " m-editing";
    }

    if (this.state.future) {
      paymentFieldClasses += " m-future";
      paymentCurrencyClasses += " m-future";
    }
    return (
      <>
        <div className="e-modal-sticky-button">
          <Toggle
            className="e-expense-toggle"
            onChange={activeItem => this.changeExpenseType(activeItem)}
            options={toggleOptions}
            active={this.state.activeItem}
            future={this.state.future}
          />
          <div className={paymentFieldClasses}>
            <span className={paymentCurrencyClasses}>
              {this.state.currencySymbol}
            </span>
            <Input
              focus={this.props.kinStore!.editingPayment}
              small={true}
              className="e-expense-input"
              noBorderStyle={true}
              type="text"
              onBlur={() => this.setSplitData()}
              onFocus={() => {}}
              onChange={event => this.updateAmount(event)}
              value={this.props.currenciesStore!.formatAmount(
                this.expense.amount
              )}
              pattern="[0-9]*"
              maxLength={10}
            />
          </div>
          <div className={paymentFieldClasses}>
            <Input
              focus={this.props.kinStore!.editingPayment}
              small={true}
              className="e-expense-input m-description"
              noBorderStyle={true}
              type="text"
              onBlur={() => {}}
              onFocus={() => {}}
              onChange={event => this.updateDescription(event)}
              placeholder="for e.g. dinner"
              value={this.expense.description}
            />
          </div>
          <div
            className={`e-expense-details ${
              this.checkValidExpenseData() ? "m-disabled" : ""
            } ${this.checkValidUpdatedExpenseData() ? "m-error" : ""}`}
            onClick={() => this.props.kinStore!.setExpenseSplitMode(true)}
          >
            <div className="e-detail-label">
              {this.expense.future ? PaidByLabel.Future : PaidByLabel.NotFuture}{" "}
              <strong>{this.expenseSplitUserName()}</strong> and split{" "}
              {this.expenseSplitType()}
            </div>
            <div className="e-detail-icon">
              <img src={arrowRightIcon} alt="Expense Split" />
            </div>
          </div>
          {this.renderCalendar()}
        </div>
        {this.checkValidUpdatedExpenseData() ? (
          <div className="e-expense-msg g-margin-top-lg">
            <span className="e-expense-msg-amount">
              Custom split needs to add up to{" "}
              {this.props.currenciesStore!.formatAmountWithSymbol(
                this.expense.amount
              )}
              .
            </span>
          </div>
        ) : (
          <div style={{ paddingBottom: "1.5rem" }}>
            <Button
              disabled={
                this.checkValidExpenseData() ||
                this.checkValidUpdatedExpenseData()
              }
              className="e-expense-btn"
              loading={this.addingExpense}
              color={
                this.state.future ? ButtonColors.Future : ButtonColors.Primary
              }
              small={true}
              onClick={() => this.addExpense()}
            >
              {this.editingExpense ? "Update expense" : "Add expense"}
            </Button>
          </div>
        )}
      </>
    );
  }

  /**
   * Render the split mode UI components
   *
   * @memberof ExpenseModalContent
   */
  renderSplitModeFields() {
    // If no users has been selected for the payment.
    // Applies to future expenses.
    let notSureSelection: boolean = false;

    if (this.expenseSplitMembers !== undefined) {
      notSureSelection =
        this.expenseSplitMembers!.filter(member => member.selected).length ===
        0;
    }
    return (
      <>
        <Dropdown
          className="e-expense-split-user"
          value={this.expense!.responsibleMemberId! as string}
          displayValue={this.expenseSplitUserName()}
          prefix={
            this.expense.future ? PaidByLabel.Future : PaidByLabel.NotFuture
          }
          list={this.membersList(this.expense!.future)}
          onChange={userId =>
            this.props.kinStore!.setExpense({
              ...this.expense,
              responsibleMemberId: userId
            })
          }
        />
        <ExpenseSplit
          future={this.props.kinStore!.expense.future}
          notSure={notSureSelection}
          buttonColor={
            this.state.future ? ButtonColors.Future : ButtonColors.Primary
          }
          users={this.members.filter(
            member => member.status !== GroupMembersStatus.InActive
          )}
          currentUserId={this.currentUserId}
          total={this.props.currenciesStore!.formatAmountWithSymbol(
            this.props.kinStore!.expense!.amount
          )}
          isSplitEqually={this.state.isSplitEqually}
          onClick={() => {
            this.props.kinStore!.setExpenseSplitMode(false);
          }}
          onUpdate={this.setKinSplitState}
          currencySymbol={this.state.currencySymbol}
        />
      </>
    );
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof ExpenseModalContent
   */
  render() {
    return (
      <div className="b-expense-modal">
        <span className="g-h5">
          {this.editingExpense ? "Update expense" : "New expense"}
        </span>
        {this.expenseSplitMode
          ? this.renderSplitModeFields()
          : this.renderAllFields()}
      </div>
    );
  }
}

export default ExpenseModalContent;
