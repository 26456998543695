import React from "react";
import "./balance-summary-person.scss";
import Button, { ButtonColors } from "../button/button";
import {
  IAmount,
  IPeopleBalanceSummaryWithIsoCodePair
} from "../../stores/peopleStore";
import BalanceSummary from "../balance-summary/balance-summary";
import { GroupMembersStatus } from "../../stores/kinsStore";
import { stores } from "../../stores";

interface IBalanceSummaryPersonProps {
  groupBalances: IPeopleBalanceSummaryWithIsoCodePair[];
  groupBalanceCount: number;
  name: string;
  status: GroupMembersStatus;
  loading?: boolean;
  onSettleClick?: any;
  onRemindClick?: any;
}

function BalanceSummaryPerson(props: IBalanceSummaryPersonProps) {
  const { groupBalances, status } = props;

  const { owedBalances, oweBalances } = filterForList(groupBalances);
  const hasMultipleBalances: boolean = checkIfMulipleCurrencyBalances(props);
  const hasOneGroupBalance: boolean = props.groupBalanceCount === 1;
  const isSettled: boolean =
    owedBalances.length === 0 && oweBalances.length === 0;

  const isDebt: boolean = oweBalances.length ? true : false;
  const isGhostUser =
    status === GroupMembersStatus.Invited ||
    status === GroupMembersStatus.NotInvited;

  const theme: number = isDebt ? ButtonColors.Primary : ButtonColors.Secondary;

  if (isSettled) {
    return (
      <p
        style={{
          maxWidth: "calc(100% - 120px)"
        }}
      >
        You're all square with {props.name}
      </p>
    );
  } else if (!hasMultipleBalances) {
    return (
      <div
        className={`b-balance-summary-person m-balance-summary-person:${
          isDebt ? "danger" : "success"
        }`}
      >
        <div className="l-balance-summary-message">
          <p className="e-balance-summary-person-title">
            {isDebt ? `You owe ${props.name}` : `${props.name} owes you`}
          </p>
          <p className="e-balance-summary-person-amount">
            {isDebt
              ? oweBalances?.map(balance => {
                  return balance.formatted;
                })
              : owedBalances?.map(balance => {
                  return balance.formatted;
                })}
          </p>
        </div>
        <div className="l-balance-summary-amount">
          {!isDebt && !isGhostUser && hasOneGroupBalance && (
            <Button
              color={theme}
              inline
              outline
              onClick={() => {
                props.onRemindClick();
              }}
              style={{
                minWidth: "110px",
                textAlign: "center",
                justifyContent: "center"
              }}
              disabled={props.loading}
            >
              Remind
            </Button>
          )}
          <Button
            color={theme}
            inline
            onClick={() =>
              isDebt ? props.onSettleClick(false) : props.onSettleClick(true)
            }
            style={{
              minWidth: "110px",
              textAlign: "center",
              marginLeft: "1rem",
              justifyContent: "center"
            }}
            disabled={props.loading}
            loading={props.loading}
          >
            Settle
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 170px",
          gridTemplateRows: "minmax(3rem, auto) auto",
          gridTemplateAreas: `
            "message ${isGhostUser ? "message" : "whiteSpace"}"
            "balances balances" 
          `,
          marginBottom: "1rem"
        }}
      >
        <div style={{ gridArea: "message" }}>
          <p>You have balances in multiple currencies with {props.name}</p>
        </div>
        <div
          className="l-summary-cards:horizontal"
          style={{ gridArea: "balances" }}
        >
          <BalanceSummary
            amounts={oweBalances}
            type="danger"
            spacing="tight"
            label="You owe"
          />
          <BalanceSummary
            amounts={owedBalances}
            type="success"
            spacing="tight"
          />
        </div>
      </div>
    );
  }
}

function checkIfMulipleCurrencyBalances(
  props: IBalanceSummaryPersonProps
): boolean {
  const sortedBalancesByCurrencyIso: any = props.groupBalances.reduce<any>(
    (sortedGroupBalances, groupBalance) => {
      const currencyIso: string = groupBalance.currencyIso;
      const hasCategory: boolean = sortedGroupBalances[currencyIso]
        ? true
        : false;
      hasCategory
        ? sortedGroupBalances[groupBalance.currencyIso].push(groupBalance)
        : groupBalance.summary.balance.cents &&
          (sortedGroupBalances[groupBalance.currencyIso] = [groupBalance]);
      return sortedGroupBalances;
    },
    {}
  );
  const hasMultipleBalances: boolean =
    Object.keys(sortedBalancesByCurrencyIso).length > 1;
  return hasMultipleBalances;
}

function filterForList(groupBalances: IPeopleBalanceSummaryWithIsoCodePair[]) {
  let owedBalances: IAmount[] = [];
  let oweBalances: IAmount[] = [];

  owedBalances = groupBalances
    .filter(balance => {
      return balance.summary.balance.cents > 0;
    })
    .map(balance => {
      return {
        ...balance.summary.balance,
        formatted: stores.currenciesStore.formatAmountWithSymbol(
          balance.summary.balance
        )
      };
    });
  oweBalances = groupBalances
    .filter(balance => {
      return balance.summary.balance.cents < 0;
    })
    .map(balance => {
      return {
        ...balance.summary.balance,
        formatted: stores.currenciesStore.formatAmountWithSymbol(
          balance.summary.balance
        )
      };
    });

  return { owedBalances, oweBalances };
}

export default BalanceSummaryPerson;
