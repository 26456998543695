import { observable, action } from "mobx";
import KinGraphQL from "../common/graphql";
import Authentication from "../common/authentication";
import Notices from "../common/snackbars";
import { updatePageTitle, history } from "../common/history";
import { GroupMembersStatus } from "./kinsStore";
import { IAvatarProps } from "../components/avatar/avatar";
import _ from "lodash";
import moment from "moment";
import { Subscription } from "rxjs";
import { Paths } from "../common/menu";

import Segment, { SegmentEvent } from "../common/segment";
import { queryAuthenticated } from "../common/graphql-utils";
import { IAmount } from "./peopleStore";

import SplitCalculator from "../modules/split-calculator";

/**
 * Query to fetch group data
 *
 * @export
 */
export const groupQuery = (id: string) => `
    fragment amountData on Amount {
        cents
        currencyIso
        formatted
    }
    fragment userActivityData on UserActivity {
        id
        event
        message
        groupId
        group {
            name
        }
        properties {
            key,
            value
        }
        seen
        seenDateTime
        createDate
        updateDate
    }
    fragment transactionData on TransactionRecord {
        id
        date
        type
        description
        amount {
            ...amountData
        }
        responsibleMemberId
        isInAppSettlement
        images {
            date
            key
            thumbnailUrl
            url
        }
        split {
        type
        portions {
            memberId
            size
            amount {
                ...amountData
            }
            responsible {
                ...amountData
            }
        }
        }
    }
    query {
        transactionRecords(groups: ["${id}"]) {
            ...transactionData
        }
        userActivitiesRecent(groupIds: ["${id}"]) {
            items {
                ...userActivityData
            }
        }
        group(id: "${id}") {
            id
            name
            image
            purpose
            currencyIsoCode
            inviteLink {
              url
            }
            currentMember {
                id
                name
            }
            memberToMemberBalance {
                fromMemberId
                toMemberId
                amount {
                    ...amountData
                }
            }
            members {
                id
                userId
                name
                image
                status
            }
        }
    }
`;

/**
 * Query to leave a group
 *
 * @export
 * @param {string} groupId
 */
export const groupLeaveQuery = (groupId: string) => `
    mutation {
        groupMemberLeave(id: "${groupId}") {
            id
        }
    }
`;

/**
 * Query to remove a member from a group
 *
 * @export
 * @param {string} groupId
 */
export const groupRemoveMemberQuery = (groupId: string, memberId: string) => `
    mutation {
        groupMemberRemove(
          groupId: "${groupId}"
          memberId: "${memberId}"
        ) {
            command
        }
    }
`;

/**
 *  Mutation query for settling a payment
 *
 * @export
 * @param {string} groupId
 * @param {IPaymentSettlement} settlement
 */
export const paymentQuery = (
  groupId: string,
  settlement: IPaymentSettlement
) => `
    fragment commandData on Command {
        id
        command
        correlationId
        parameters {
            key
            value
        }
    }
    mutation {
        transactionRecordCreateSettlement(
            groupId: "${groupId}"
            settlement: {
              fromMemberId: "${settlement.fromMemberId}",
              description: "${settlement.description}",
              date: "${settlement.date}",
              to: [
                {
                  memberId: "${settlement.to[0].memberId}",
                  amount: {
                    currencyIso: "${settlement.to[0].amount.currencyIso}",
                    cents: ${settlement.to[0].amount.cents}
                  }
                }
              ]
            }
        ) {
            ...commandData
        }
    }
`;

/**
 * Query to remind a kin user to pay another kin user
 *
 * @param {string} groupId
 * @param {string} memberId
 */
export const reminderQuery = (groupId: string, memberId: string) => `
    fragment commandData on Command {
        id
        command
        correlationId
        parameters {
            key
            value
        }
    }
    mutation {
        groupMemberNudge(groupId: "${groupId}", memberId: "${memberId}") {
            ...commandData
        }
    }
`;

/**
 * Query to create a transaction/expense
 *
 * @export
 * @param {string} groupId
 * @param {ITransactionRecordCreateUpdate} transactionRecord
 */
export const transactionCreateQuery = () => `
mutation transActionRecordCreate(
  $groupId: String!
  $transactionRecord: TransactionRecordCreateUpdate!
  $syncId: String = null
) {
  transactionRecordCreate(
    groupId: $groupId,
    transactionRecord: $transactionRecord,
    syncId: $syncId
  ) {
    command
  }
}
`;

/**
 * Query to update a transaction/expense
 *
 * @export
 * @param {string} transactionRecordId
 * @param {ITransactionRecordCreateUpdate} transactionRecord
 */
export const transactionUpdateQuery = () => `
mutation transactionRecordUpdate(
  $transactionRecordId: String!
  $transactionRecord: TransactionRecordCreateUpdate!
  $syncId: String = null
) {
  transactionRecordUpdate(
    transactionRecordId: $transactionRecordId
    transactionRecord: $transactionRecord
    syncId: $syncId
  ) {
    command
  }
}
`;

/**
 * Query to remove a transaction/expense
 *
 * @export
 * @param {string} transactionId
 */
export const transactionRemoveQuery = (transactionId: string) => `
  mutation {
    transactionRecordRemove(transactionRecordId: "${transactionId}") {
      command
    }
  }
`;

/**
 * Query to generate an dynamic invite link
 *
 * @export
 * @param {DynamicLinkType} inviteType
 * @param {string} groupId
 * @param {boolean} [shortenLink=true]
 */
export const dynamicLinkQuery = (
  inviteType: DynamicLinkType,
  groupId: string,
  shortenLink: boolean = true
) => `
  query{
    miscellaneous {
      buildDynamicLink(slug: "${inviteType}", id:"${groupId}", shortenLink: ${shortenLink})
    }
  }
`;

/**
 *  Invite an existing member to a group, or to get a `correlationId` to use when generating a dynamic link.
 *
 * @param {string} groupId
 * @param {string} memberId
 */
export const groupInviteExistingMember = (
  groupId: string,
  memberId: string
) => `
  mutation {
    groupInviteExistingMember(
      groupId: "${groupId}"
      memberId: "${memberId}"
    ) {
      correlationId
      id
    }
  }
`;

/**
 * List of placeholder images for kins
 *
 * @export
 */
export const placeholderKinImages: string[] = [
  "https://admin.kin.business/assets/group.icons/kin_group_im_1@1,5x.jpg",
  "https://admin.kin.business/assets/group.icons/kin_group_im_2@1,5x.jpg",
  "https://admin.kin.business/assets/group.icons/kin_group_im_3@1,5x.jpg",
  "https://admin.kin.business/assets/group.icons/kin_group_im_4@1,5x.jpg",
  "https://admin.kin.business/assets/group.icons/kin_group_im_5@1,5x.jpg",
  "https://admin.kin.business/assets/group.icons/kin_group_im_6@1,5x.jpg",
  "https://admin.kin.business/assets/group.icons/kin_group_im_7@1,5x.jpg",
  "https://admin.kin.business/assets/group.icons/kin_group_im_8@1,5x.jpg",
  "https://admin.kin.business/assets/group.icons/kin_group_im_9@1,5x.jpg"
];

/**
 * Return a random image form the placeholder image list
 *
 * @export
 * @returns
 */
export const getPlaceholderImage = () => {
  let randomImage: number = Math.floor(
    Math.random() * placeholderKinImages.length
  );
  return placeholderKinImages[randomImage];
};

/**
 * Mutation query to remove the kin picture
 * @export
 */
export const queryRemoveKinImage = (groupId: string) => `
    mutation {
      groupUpdateImage(id: "${groupId}", imageUrl: "${getPlaceholderImage()}") {
        command
      }
    }
`;

/**
 * Query to update the kin details
 *
 * @export
 * @param {string} groupId
 * @param {string} name
 * @param {string} purpose
 */
export const updateGroupQuery = (
  groupId: string,
  name: string,
  purpose: string
) => `
  fragment commandData on Command {
    id
    command
    correlationId
    parameters {
        key
        value
    }
  }
  mutation {
    groupUpdate(id: "${groupId}", name: "${name}", purpose: "${purpose}") {
      ...commandData
    }
  }
`;

/**
 * Interface for creating and updating a transaction on a kin
 *
 * @export
 * @interface ITransactionRecordCreateUpdate
 */
export interface ITransactionRecordCreateUpdate {
  description: string;
  responsibleMemberId: string | null;
  transactionType: TransactionType;
  date: string | null;
  amount: AmountInput;
  split: ITransactionRecordCreateUpdateSplit;
}

/**
 * Transaction split interface
 *
 * @export
 * @interface ITransactionRecordCreateUpdateSplit
 */
export interface ITransactionRecordCreateUpdateSplit {
  type: SplitType;
  portions: TransactionRecordCreateUpdatePortion[];
}

/**
 * Transaction split portions interface
 *
 * @export
 * @interface TransactionRecordCreateUpdatePortion
 */
export interface TransactionRecordCreateUpdatePortion {
  memberId: string;
  size: number;
}

/**
 * Payment settlement interface
 *
 * @export
 * @interface IPaymentSettlement
 */
export interface IPaymentSettlement {
  fromMemberId: string;
  description: string;
  date: string;
  to: IPaymentSettlementTo[];
}

/**
 * Payment settlement to interface
 *
 * @interface IPaymentSettlementTo
 */
interface IPaymentSettlementTo {
  memberId: string;
  amount: AmountInput;
}

/**
 * Payment settlement amount interface
 *
 * @interface AmountInput
 */
interface AmountInput {
  currencyIso: string;
  cents: number;
}

/**
 * Simplified avatar props with only relevant info
 *
 * @export
 * @interface IGroupMember
 */
export interface IGroupMember {
  id: string;
  name: string;
  image: string;
  status?: GroupMembersStatus;
  size?: number;
  userId?: string;
}

/**
 * User that paid a portion in transaction tile
 *
 * @export
 * @interface IMemberPaid
 */
export interface IMemberPaid {
  name: string;
  image: string;
  size: number;
  initialSize?: number;
  paid?: number | string;
}

/**
 * Group amount interface
 *
 * @export
 * @interface IGroupQueryAmount
 */
export interface IGroupQueryAmount {
  cents: number;
  currencyIso: string;
  formatted: string;
}

/**
 * Group Activity data
 *
 * @export
 * @interface IGroupQueryActivityData
 */
export interface IGroupQueryActivityData {
  id: string;
  event: string;
  message: string;
  groupId: string;
  group: {
    name: string;
  };
  properties: [
    {
      key: string;
      value: string;
    }
  ];
  seen: string;
  seenDateTime: string;
  createDate: string;
  updateDate: string;
}

/**
 * Transaction split portion interface
 *
 * @export
 * @interface IGroupSplitPortion
 */
export interface IGroupSplitPortion {
  memberId: string;
  size: number;
  amount: IGroupQueryAmount;
  responsible: IGroupQueryAmount;
}

/**
 * Group transaction data
 *
 * @export
 * @interface IGroupQueryTransactionData
 */
export interface IGroupQueryTransactionData {
  id: string;
  dateLabel?: string;
  dateLabelPrefix?: string;
  monthTotal?: number;
  sectionTotal?: number;
  date: string;
  type: string;
  description: string;
  amount: IGroupQueryAmount;
  responsibleMemberId: string;
  isInAppSettlement: boolean;
  currencyIsoCode: string;
  images: [
    {
      date: string;
      key: string;
      thumbnailUrl: string;
      url: string;
    }
  ];
  split: {
    type: SplitType;
    portions: IGroupSplitPortion[];
  };
}

/**
 * Member to member balance interface
 *
 * @export
 * @interface IGroupMemberToMemberBalance
 */
export interface IGroupMemberToMemberBalance {
  fromMemberId: string;
  toMemberId: string;
  amount: IGroupQueryAmount;
}

/**
 * Group settle data
 *
 * @export
 * @interface IGroupQuerySettleData
 */
export interface IGroupQuerySettleData {
  id: string;
  name: string;
  image: string;
  purpose: string;
  currentMember: {
    id: string;
    name: string;
  };
  memberToMemberBalance: IGroupMemberToMemberBalance[];
  members: IGroupMember[];
}

/**
 * Group root interface
 *
 * @export
 * @interface IGroupQuery
 */
export interface IGroupQuery {
  transactionRecords: IGroupQueryTransactionData[];
  userActivitiesRecent: {
    items: IGroupQueryActivityData[];
  };
  group: IGroupQuerySettleData;
}

/**
 * Combined data interface for Kin data request
 *
 * @export
 * @interface IGroupData
 */
export interface IGroupData {
  groupName: string;
  groupId: string;
  groupImage: string;
  groupPurpose: string;
  activityData: IGroupQueryActivityData[];
  transactionData: IGroupQueryTransactionData[];
  settleData: IGroupQuerySettleData;
  currentUserId: string;
  members: IGroupMember[];
  groupInviteUrl?: string | undefined;
  currencyIsoCode: string;
}

/**
 * Transaction details interface
 * Transaction user can be either the user paying or being paid
 *
 * @interface IPaymentTransaction
 */
export interface IPaymentTransaction {
  amount?: IAmount;
  repayAmount?: IAmount;
  group?: string;
  user?: string;
  userId?: string;
  paying?: boolean;
}

/**
 *
 *
 * @interface IPaymentProps
 */
export interface IPaymentProps {
  currentUserId?: string;
  users?: IAvatarProps[];
  transaction?: IPaymentTransaction;
}

/**
 * Interface for the user that the current user is reminding
 *
 * @export
 * @interface IReminderUser
 */
export interface IReminderUser {
  currentUser: string;
  name: string;
  id: string;
  amount: IAmount;
  ghost: boolean;
}

/**
 * Expense modal interface for defining all the details of the expense
 *
 * @export
 * @interface IExpense
 */
export interface IExpense {
  future: boolean;
  amount: IAmount;
  description: string;
  currencyIso: string;
  split: ITransactionRecordCreateUpdateSplit;
  responsibleMemberId: string | null;
  date: string | null;
}

/**
 * Expense split UI state interface
 *
 * @export
 * @interface IExpenseSplitUser
 */
export interface IExpenseSplitUser {
  id: string;
  name: string;
  selected: boolean;
  value: number;
  edited: boolean;
}

/**
 * Split type enum for transactions
 *
 * @export
 * @enum {number}
 */
export enum SplitType {
  Ratio = "Ratio",
  Percent = "Percent",
  Exact = "Exact"
}

/**
 * Define the Tile types by transaction type.
 * This is used to change styling and components
 * based on transaction type.
 *
 * @export
 * @enum {number}
 */
export enum TileType {
  Future = 0,
  Owe = 1,
  Owed = 2,
  NotInvolved = 3
}

/**
 * Define transaction types.
 * This is used to change styling and components
 * based on transaction type.
 *
 * @export
 * @enum {number}
 */
export enum TransactionType {
  Paid = "Paid",
  Planned = "Planned",
  Settle = "Settle"
}

/**
 * Settle type strings enum
 *
 * @export
 * @enum {number}
 */
export enum SettleType {
  Owe = "You owe",
  Owed = "Owed to you",
  Settled = "All settled"
}

/**
 * Date types string enum
 *
 * @export
 * @enum {number}
 */
export enum DateTypes {
  Upcoming = "Upcoming",
  Today = "Today"
}

/**
 * Various transaction types enum
 *
 * @export
 * @enum {number}
 */
export enum ActivityEventTypes {
  AcceptGroupInvite = "AcceptGroupInvite",
  GroupMemberAdded = "GroupMemberAdded",
  GroupInvitedExistingMember = "GroupInvitedExistingMember",
  TransactionRecordCreated = "TransactionRecordCreated",
  TransactionRecordUpdated = "TransactionRecordUpdated",
  TransactionRecordRemoved = "TransactionRecordRemoved",
  UserUpdated = "UserUpdated"
}

/**
 * Dynamic link generation type
 *
 * @export
 * @enum {number}
 */
export enum DynamicLinkType {
  Invite = "Invite",
  Group = "Group",
  Connection = "Connection"
}

/**
 * Define default/reset value for an expense
 */
const defaultExpense = {
  future: false,
  // TODO next
  amount: {
    cents: 0,
    currencyIso: "",
    formatted: ""
  },
  description: "",
  currencyIso: "",
  split: {
    type: SplitType.Ratio,
    portions: []
  },
  responsibleMemberId: "",
  date: moment.utc().format()
};

/**
 * Kin store state
 *
 * @export
 * @interface IKinStore
 */
export interface IKinStore {
  loading: boolean;
  error: boolean;
  errorMsg: string | undefined;
  expenseModal: boolean;
  expense: IExpense;
  expenseSplitMode: boolean;
  expenseSplitMembers: IExpenseSplitUser[] | undefined;
  expenseRemaining: IAmount;
  addingExpense: boolean;
  editingExpense: boolean;
  removingExpense: boolean;
  dynamicLink: string | undefined;
  correlationId: string;
  paymentModal: boolean;
  payment: IPaymentProps | undefined;
  reminderModal: boolean;
  reminderOptionsModal: boolean;
  reminderShareGhostModal: boolean;
  reminderShareUserModal: boolean;
  reminderUser: IReminderUser;
  editingPayment: boolean;
  sendingPayment: boolean;
  sendingReminder: boolean;
  confirmedPayment: boolean;
  confirmedReminder: boolean;
  groupName: string;
  groupId: string;
  groupInviteUrl: string | undefined;
  currencyIsoCode: string;
  groupImage: string;
  groupPurpose: string;
  transactionRecordId: string;
  activityData: IGroupQueryActivityData[];
  transactionData: IGroupQueryTransactionData[];
  settleData: IGroupQuerySettleData;
  currentUserId: string;
  members: IGroupMember[];
  uploadingPhoto: boolean;
  editingKin: boolean;
  savingKin: boolean;
  leaveOutstandingBalance: boolean;
  leaveOnlyMember: boolean;
  leaveOkay: boolean;
  leaving: boolean;
  removingMember: boolean;
  fetchingInviteUrl: boolean;
  removePhoto(): void;
  uploadKinPhoto(file: any, callback: any): void;
  fetchKinData(query: string): Promise<IGroupQuery>;
  settlePayment(query: string, callback?: any): void;
  remindPayment(query: string): void;
  updateKin(query: string, name: string, purpose: string): void;
  calculateTotalRemaining(totalAmount: IAmount): IAmount;
  addUpdateExpense(update?: boolean): void;
  removeExpense(transactionId: string, planned: boolean): void;
  fetchDynamicLink(query: string, callback: any): void;
  fetchCorrelationId(query: string, callback: any): void;
  fetchInviteUrlFromCorrelationId(query: string, callback: any): void;
  leaveKinModal(): void;
  leaveKin(): void;
  removeMemberFromKin(memberId: string, callback: any, groupId?: string): void;
  setExpenseModal(state: boolean, currencyIso: string): void;
  setExpense(expense: IExpense): void;
  setExpenseRemaining(): void;
  /**
   * Show Expense Split view.
   * Pass true to show, false to hide.
   *
   * @param {boolean} state
   * @memberof KinStore
   */
  setExpenseSplitMode(state: boolean): void;
  setExpenseSplitMembers(
    expenseSplitMembers: IExpenseSplitUser[] | undefined,
    isUpdate?: boolean
  ): void;
  setEditingExpense(state: boolean): void;
  setAddingExpense(state: boolean): void;
  setRemovingExpense(state: boolean): void;
  setDynamicLink(link: string): void;
  setInviteCorrelationId(correlationId: string): void;
  setPaymentModal(state: boolean): void;
  setPayment(payment: IPaymentProps): void;
  setEditingPayment(state: boolean): void;
  setSendingPayment(state: boolean): void;
  setSendingReminder(state: boolean): void;
  setConfirmedPayment(state: boolean): void;
  setConfirmedReminder(state: boolean): void;
  setReminderModal(state: boolean): void;
  setReminderOptionsModal(state: boolean): void;
  setReminderShareGhostModal(state: boolean): void;
  setReminderShareUserModal(state: boolean): void;
  setReminderUser(user: IReminderUser): void;
  setGroupSettings(name: string, purpose: string): void;
  setGroupData(groupData: IGroupData): void;
  setGroupId(id: string): void;
  setTransactionRecordId(id: string): void;
  setCurrentUserId(id: string): void;
  setUploadingPhoto(state: boolean): void;
  setEditingKin(state: boolean): void;
  setSavingKin(state: boolean): void;
  setLeaveOkay(state: boolean): void;
  setLeaveOnlyMember(state: boolean): void;
  setLeaveOutstandingBalance(state: boolean): void;
  setLoading(loading: boolean): void;
  setError(error: boolean, errorMsg?: string | undefined): void;
  resetData(): void;
  setRemovingMember(state: boolean): void;
  setFetchingInviteUrl(state: boolean): void;
}

export class KinStore implements IKinStore {
  /**
   * Subscription for fetching new kin data
   *
   * @private
   * @type {Subscription}
   * @memberof KinStore
   */
  private kinFetchSubscription: Subscription;
  /**
   * UI loading state
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable loading: boolean;
  /**
   * UI error state
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable error: boolean;
  /**
   * Error message.
   *
   * @type {string | undefined}
   * @memberof KinStore
   */
  @observable errorMsg: string | undefined;
  /**
   * UI state for notice msg
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable noticeState: boolean;
  /**
   * Notice msg
   *
   * @type {string}
   * @memberof KinStore
   */
  @observable noticeMsg: string;
  /**
   * UI state for showing the adding expense modal
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable expenseModal: boolean;
  /**
   * Expense object used to store adding expense data
   *
   * @type {IExpense}
   * @memberof KinStore
   */
  @observable expense: IExpense;
  /**
   * Expense split generated by the expense split UI to keep track of the split accross screens
   *
   * @type {IExpenseSplitUser[]}
   * @memberof KinStore
   */
  @observable expenseSplitMembers: IExpenseSplitUser[] | undefined;
  /**
   * Track remaining amount for members expense split
   *
   * @type {IAmount}
   * @memberof KinStore
   */
  @observable expenseRemaining: IAmount;
  /**
   * UI State for when editing the expense split
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable expenseSplitMode: boolean;
  /**
   * UI state if editing instead of adding an expense
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable editingExpense: boolean;
  /**
   * Dynamic link for invites
   *
   * @type {string}
   * @memberof KinStore
   */
  @observable dynamicLink: string | undefined;
  /**
   * CorrelationId for generating invites
   *
   * @type {string}
   * @memberof KinStore
   */
  @observable correlationId: string;
  /**
   * UI state for adding expense
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable addingExpense: boolean;
  /**
   * UI state for removing expense
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable removingExpense: boolean;
  /**
   * UI state for showing a payment modal
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable paymentModal: boolean;
  /**
   * Payment transaction info for making or settling payments
   *
   * @type {IPaymentProps}
   * @memberof KinStore
   */
  @observable payment: IPaymentProps | undefined;
  /**
   * UI state when editing the payment amount
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable editingPayment: boolean;
  /**
   * UI state when sending the payment
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable sendingPayment: boolean;
  /**
   * UI state when sending a reminder
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable sendingReminder: boolean;
  /**
   * UI state for a confirmed payment
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable confirmedPayment: boolean;
  /**
   * UI state for a confirmed reminder
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable confirmedReminder: boolean;
  /**
   * Reminder Modal frame
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable reminderModal: boolean;
  /**
   * Reminder options selection modal content
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable reminderOptionsModal: boolean;
  /**
   * Reminder sharing modal content for users not on Kin
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable reminderShareGhostModal: boolean;
  /**
   * Reminder sharing modal content for kin member
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable reminderShareUserModal: boolean;
  /**
   * User being reminded
   *
   * @type {IReminderUser}
   * @memberof KinStore
   */
  @observable reminderUser: IReminderUser;
  /**
   * Kin name
   *
   * @type {string}
   * @memberof KinStore
   */
  @observable groupName: string;
  /**
   * Kin ID
   *
   * @type {string}
   * @memberof KinStore
   */
  @observable groupId: string;
  /**
   * Kin Group Invite URL
   *
   * @type {string}
   * @memberof KinStore
   */
  @observable groupInviteUrl: string | undefined;
  /**
   * Kin Group Currency Iso Code
   *
   * @type {string}
   * @memberof KinStore
   */
  @observable currencyIsoCode: string;
  /**
   * Kin image
   *
   * @type {string}
   * @memberof KinStore
   */
  @observable groupImage: string;
  /**
   * Kin purpose
   *
   * @type {string}
   * @memberof KinStore
   */
  @observable groupPurpose: string;
  /**
   * Transaction record id, needed when updating an expense
   *
   * @type {string}
   * @memberof KinStore
   */
  @observable transactionRecordId: string;
  /**
   * Kin activity data
   *
   * @type {IGroupQueryActivityData[]}
   * @memberof KinStore
   */
  @observable.shallow activityData: IGroupQueryActivityData[];
  /**
   * Kin transaction data
   *
   * @type {IGroupQueryTransactionData[]}
   * @memberof KinStore
   */
  @observable.shallow transactionData: IGroupQueryTransactionData[];
  /**
   * Kin settle data
   *
   * @type {IGroupQuerySettleData}
   * @memberof KinStore
   */
  @observable.shallow settleData: IGroupQuerySettleData;
  /**
   * Current logged in user ID for matching transactions
   *
   * @type {string}
   * @memberof KinStore
   */
  @observable currentUserId: string;
  /**
   * List of Kin memebers (Active only)
   *
   * @type {IGroupMember[]}
   * @memberof KinStore
   */
  @observable.shallow members: IGroupMember[];
  /**
   * UI state for uploading an kin image
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable uploadingPhoto: boolean;
  /**
   * UI state when editing the Kin settings
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable editingKin: boolean;
  /**
   * UI state when saving the new kin details
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable savingKin: boolean;
  /**
   * UI state for oustanding balance
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable leaveOutstandingBalance: boolean;
  /**
   * UI state for only memeber
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable leaveOnlyMember: boolean;
  /**
   * UI state if leaving a kin is okay
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable leaveOkay: boolean;
  /**
   * UI state while leaving a kin
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable leaving: boolean;

  /**
   * UI state while removing a member from a kin
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable removingMember: boolean;

  /**
   * UI state while fetching an inviteURL
   *
   * @type {boolean}
   * @memberof KinStore
   */
  @observable fetchingInviteUrl: boolean;

  /**
   *Creates an instance of KinStore.
   * @memberof KinStore
   */
  constructor() {
    /**
     * Setting default values
     */
    this.loading = false;
    this.error = false;
    this.errorMsg = "";
    this.noticeState = false;
    this.noticeMsg = "";
    this.expenseModal = false;
    this.expense = {
      ...defaultExpense
    };
    this.expenseSplitMode = false;
    this.expenseSplitMembers = undefined;
    // TODO next
    this.expenseRemaining = {
      cents: 0,
      currencyIso: "",
      formatted: ""
    };
    this.editingExpense = false;
    this.addingExpense = false;
    this.removingExpense = false;
    this.dynamicLink = undefined;
    this.correlationId = "";
    this.paymentModal = false;
    this.payment = undefined;
    this.editingPayment = false;
    this.sendingPayment = false;
    this.sendingReminder = false;
    this.confirmedPayment = false;
    this.confirmedReminder = false;
    this.reminderModal = false;
    this.reminderOptionsModal = false;
    this.reminderShareGhostModal = false;
    this.reminderShareUserModal = false;
    this.reminderUser = {
      currentUser: "",
      name: "",
      id: "",
      amount: {
        cents: 0,
        currencyIso: "ZAR",
        formatted: "R0.00"
      },
      ghost: false
    };
    this.groupName = "";
    this.groupId = "";
    this.groupInviteUrl = "";
    this.groupImage = "";
    this.groupPurpose = "";
    this.transactionRecordId = "";
    this.activityData = [];
    this.transactionData = [];
    this.settleData = {
      id: "",
      name: "",
      image: "",
      purpose: "",
      currentMember: {
        id: "",
        name: ""
      },
      memberToMemberBalance: [],
      members: []
    };
    this.currentUserId = "";
    this.members = [];
    this.uploadingPhoto = false;
    this.editingKin = false;
    this.savingKin = false;
    this.leaveOutstandingBalance = false;
    this.leaveOnlyMember = false;
    this.leaveOkay = false;
    this.leaving = false;
    this.kinFetchSubscription = new Subscription();
    this.removingMember = false;
    this.fetchingInviteUrl = false;
    this.currencyIsoCode = "";
  }

  /**
   * Removing current kin picture
   *
   * @memberof ProfileSettinsStore
   */
  removePhoto(): void {
    // Update UI state
    this.setUploadingPhoto(true);
    queryAuthenticated(queryRemoveKinImage(this.groupId)).then(
      () => {
        // Fetch updated user data to show new profile picture
        this.fetchKinData(groupQuery(this.groupId)).then(() => {
          // Update UI state
          this.setUploadingPhoto(false);
          // Update UI state
          this.setEditingKin(false);
        });
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        this.setUploadingPhoto(false);
      }
    );
  }

  /**
   * Uploading new kin picture
   *
   * @param {*} file
   * @memberof KinStore
   */
  uploadKinPhoto(file: any, callback: any = null): void {
    // Update UI state
    this.setUploadingPhoto(true);
    new Authentication().getToken().then(token => {
      new KinGraphQL().uploadKinPhoto(file, this.groupId, token).subscribe({
        next: result => {
          if (result.data.errors) {
            // Trigger error
            new Notices().GlobalTrigger(() => {
              this.setError(!this.error);
            });
            // Reset UI state
            this.setUploadingPhoto(false);
          } else {
            // Fetch updated user data to show new profile picture
            this.fetchKinData(groupQuery(this.groupId)).then(() => {
              // Update UI state
              this.setUploadingPhoto(false);
              // Update UI state
              this.setEditingKin(false);
              // Execute a callback if defined
              if (callback) {
                callback();
              }
            });
          }
        },
        error: (err: any) => {
          // Update UI state
          this.setUploadingPhoto(false);
          // Trigger error
          new Notices().GlobalTrigger(() => {
            this.setError(!this.error);
          });
          console.error(err);
        }
      });
    });
  }

  /**
   * Split out planned transactions and add a "Upcoming" label
   *
   * @param {IGroupQueryTransactionData[]} transactionData
   * @returns {IGroupQueryTransactionData[]}
   * @memberof KinStore
   */
  plannedTransactions(
    transactionData: IGroupQueryTransactionData[]
  ): IGroupQueryTransactionData[] {
    // Filter to only planned transactions
    let transactionPlannedData: IGroupQueryTransactionData[] = transactionData.filter(
      entry => {
        return entry.type === TransactionType.Planned;
      }
    );
    if (transactionPlannedData.length !== 0) {
      // Add appropriate labels to transactions
      transactionPlannedData = this.addPlannedDateLabels(
        transactionPlannedData
      );
      return transactionPlannedData;
    } else {
      return [];
    }
  }

  /**
   * Add properly formatted date labels to planned transactions.
   * The passed in data is mutated so void is returned
   *
   * @param {IGroupQueryTransactionData[]} transactionData
   * @memberof KinStore
   */
  addPlannedDateLabels(transactionData: IGroupQueryTransactionData[]) {
    // Split data with and without dates.
    let upcomingTransactions: IGroupQueryTransactionData[] = transactionData.filter(
      entry => entry.date === null
    );
    let upcomingDatedTransactions: IGroupQueryTransactionData[] = transactionData.filter(
      entry => entry.date !== null
    );
    // Add "Upcoming" label to first item of null dates if any
    if (upcomingTransactions.length !== 0) {
      upcomingTransactions[0].dateLabel = DateTypes.Upcoming;
      upcomingTransactions[0].monthTotal = Math.abs(
        _.sum(_.map(_.filter(upcomingTransactions, "amount"), "amount.cents"))
      );
    }
    // Sort dated planned transactions by most recent first
    upcomingDatedTransactions = _.sortBy(upcomingDatedTransactions, ["date"]);
    //_.reverse(upcomingDatedTransactions);
    // Add date labels to first transaction
    this.addDateLabels(upcomingDatedTransactions, true);
    return this.addFutureAndPaidTotals([
      ...upcomingTransactions,
      ...upcomingDatedTransactions
    ]);
  }

  /**
   * Mutate transaction data and add total value for Future/Paid sections
   *
   * @memberof KinStore
   */
  addFutureAndPaidTotals(
    transactionData: IGroupQueryTransactionData[]
  ): IGroupQueryTransactionData[] {
    if (transactionData.length !== 0) {
      transactionData[0].sectionTotal = _.sum(
        _.map(_.filter(transactionData, "monthTotal"), "monthTotal")
      );
    }
    return transactionData;
  }

  /**
   * Split out dated (transaction not flagged as planned) transactions,
   * add the date label and reverse order latest first
   *
   * @param {IGroupQueryTransactionData[]} transactionData
   * @returns {IGroupQueryTransactionData[]}
   * @memberof KinStore
   */
  datedTransactions(
    transactionData: IGroupQueryTransactionData[]
  ): IGroupQueryTransactionData[] {
    // Filter to only dated transactions
    let transactionDatedData: IGroupQueryTransactionData[] = transactionData.filter(
      entry => {
        return entry.type !== TransactionType.Planned;
      }
    );
    // Put latest transactions first
    transactionDatedData = _.sortBy(transactionDatedData, ["date"]);
    _.reverse(transactionDatedData);
    if (transactionData.length !== 0) {
      // Add date labels to transaction object
      this.addDateLabels(transactionDatedData);
      return this.addFutureAndPaidTotals(transactionDatedData);
    } else {
      return [];
    }
  }

  /**
   * Small helper function to either return date or "today"
   *
   * @param {string} todayDate
   * @param {string} entryDate
   * @returns
   * @memberof KinStore
   */
  todayOrDateHelper(date: string, isPlanned: boolean = false): string[] {
    // Today's date string
    let todayDate: string = moment().format("DD MMMM");
    // Entry date string
    let entryDate: string = moment(date).format("DD MMMM");
    if (todayDate === entryDate) {
      // Preffix with "Due" if planned and today
      if (isPlanned) {
        return ["Due", "Today"];
      }
      return ["", "Today"];
    } else {
      // Check if planned transaction and if the date has passed already
      if (isPlanned === moment().isAfter(moment(date))) {
        return ["Was Due", entryDate];
      }
      return ["", entryDate];
    }
  }

  /**
   * Add properly formatted date labels to dated transactions.
   * Add month totals to transactions as well.
   * The passed in data is mutated so void is returned
   *
   * @param {IGroupQueryTransactionData[]} transactionData
   * @memberof KinStore
   */
  addDateLabels(
    transactionData: IGroupQueryTransactionData[],
    isPlannedTransaction: boolean = false
  ) {
    // Create temp index reference for month totals
    let monthIndex: number = 0;

    // Loop through transactions
    transactionData.forEach((entry, index) => {
      let dateLabels: string[] = this.todayOrDateHelper(
        entry.date,
        isPlannedTransaction
      );

      // Automatically date stamp the first item
      if (index === 0) {
        // Set month total to current entry amount
        if (entry.type !== TransactionType.Settle) {
          transactionData[monthIndex].monthTotal = Math.abs(entry.amount.cents);
        } else {
          transactionData[monthIndex].monthTotal = 0;
        }

        // Add date label
        entry.dateLabelPrefix = dateLabels[0];
        entry.dateLabel = dateLabels[1];
      } else {
        // Get current and previous date
        let entryDate: string = moment(entry.date).format("YYYY-MM-DD");
        let previousEntryDate: string = moment(
          transactionData[index - 1].date
        ).format("YYYY-MM-DD");
        // Only add date property to items who have a different date
        // compared to the previous transaction. This calculation will
        // only add it to the first item in a date set
        if (isPlannedTransaction) {
          // isAfter is used because the transactions are planne transactions
          if (moment(entryDate).isAfter(moment(previousEntryDate))) {
            // Add date label
            entry.dateLabelPrefix = dateLabels[0];
            entry.dateLabel = dateLabels[1];
          }
        } else {
          // isBefore is used because the transactions are listed most recent to oldest
          if (moment(entryDate).isBefore(moment(previousEntryDate))) {
            // Add date label
            entry.dateLabelPrefix = dateLabels[0];
            entry.dateLabel = dateLabels[1];
          }
        }
        // Update the month index only if it's a different month
        let updatedMonthIndex: number = this.updateMonthIndex(
          entryDate,
          previousEntryDate,
          index,
          monthIndex
        );
        // Check to see if the months changed
        if (monthIndex !== updatedMonthIndex) {
          // If months changed set month total to current amount
          monthIndex = updatedMonthIndex;
          if (entry.type !== TransactionType.Settle) {
            transactionData[monthIndex].monthTotal = Math.abs(
              entry.amount.cents
            );
          } else {
            transactionData[monthIndex].monthTotal = 0;
          }
        } else {
          // If it's the same month, keep adding to month total
          if (entry.type !== TransactionType.Settle) {
            transactionData[monthIndex].monthTotal! += Math.abs(
              entry.amount.cents
            );
          }
        }
      }
    });
  }

  /**
   * Update the month index of where the total is stored
   *
   * @param {string} currentDate
   * @param {(string | undefined)} previousDate
   * @param {number} currentIndex
   * @param {number} monthIndex
   * @memberof KinStore
   */
  updateMonthIndex(
    currentDate: string,
    previousDate: string | undefined,
    currentIndex: number,
    monthIndex: number
  ) {
    // Safety check incase previous date is null
    let validPreviousDate: string = "";
    if (previousDate !== undefined) {
      validPreviousDate = moment(previousDate).format("MMMM");
    }
    // Check if it's the not same month (doesn't care for month order)
    if (
      moment(currentDate).format("MMMM") !== validPreviousDate ||
      currentIndex === 0
    ) {
      // Store month index
      monthIndex = currentIndex;
    }
    return monthIndex;
  }

  /**
   * Combine planned and dated transactions into a single list
   *
   * @param {IGroupQueryTransactionData[]} transactionData
   * @returns
   * @memberof KinStore
   */
  buildTransactionData(transactionData: IGroupQueryTransactionData[]) {
    // Split transaction data into planned and dated data
    let plannedTransactions: IGroupQueryTransactionData[] = this.plannedTransactions(
      transactionData
    );
    let datedTransactions: IGroupQueryTransactionData[] = this.datedTransactions(
      transactionData
    );
    // Recombine lists so that planned data is first
    return [...plannedTransactions, ...datedTransactions];
  }

  /**
   * Update data of kin
   *
   * @param {*} results
   * @param {*} group
   * @memberof KinStore
   */
  updateData(results: any, group: any) {
    let activityData: IGroupQueryActivityData[] =
      group.userActivitiesRecent.items;
    let transactionData: IGroupQueryTransactionData[] = this.buildTransactionData(
      group.transactionRecords
    );
    let settleData: IGroupQuerySettleData = group.group;
    let currentUserId: string = group.group.currentMember.id;
    let groupName: string = group.group.name;
    let groupId: string = group.group.id;
    let groupImage: string = group.group.image;
    let groupPurpose: string = group.group.purpose;
    let members: IGroupMember[] = group.group.members;
    let groupInviteUrl: string =
      group.group.inviteLink && group.group.inviteLink.url;
    let groupCurrencyIso: string = group.group.currencyIsoCode;
    if (results !== null) {
      this.setGroupData({
        groupName: groupName,
        groupId: groupId,
        groupImage: groupImage,
        groupPurpose: groupPurpose,
        activityData: activityData,
        transactionData: transactionData,
        settleData: settleData,
        currentUserId: currentUserId,
        members: members,
        groupInviteUrl,
        currencyIsoCode: groupCurrencyIso
      });
    }
    // Update page title to Group name
    updatePageTitle(groupName);
  }

  /**
   * Fetching kin data from server
   *
   * @param {string} query
   * @memberof KinStore
   */
  fetchKinData(query: string): Promise<IGroupQuery> {
    // Initialise Authentication with refreshed token
    return queryAuthenticated(query).then(
      result => {
        let group: IGroupQuery = result.data.data;
        // Update kin data to result
        this.updateData(result, group);
        return result.data.data;
      },
      errors => {
        const errorMessage = errors[0].message;
        if (
          errorMessage === "Error trying to resolve group." ||
          errorMessage.includes("is not a valid 24 digit hex string.")
        ) {
          history.push(Paths.NotFound);
        } else {
          // Trigger error
          new Notices().GlobalTrigger(() => {
            this.setError(!this.error);
          });
        }
        throw errors;
      }
    );
  }

  /**
   * Settle a payment on a kin
   *
   * @param {string} query
   * @memberof KinStore
   */
  settlePayment(query: string, callback: any = null) {
    // Update UI state
    this.setSendingPayment(true);
    // Initialise Authentication with refreshed token
    queryAuthenticated(query).then(
      () => {
        // Log payment to segment
        new Segment().Track(SegmentEvent.ADD_EXTERNAL_PAYMENT);
        // Fetch new kin data to display updated information
        this.fetchKinData(groupQuery(this.groupId));
        // Execute any callbacks
        if (callback) {
          callback();
        }
        // Reset UI state
        this.setSendingPayment(false);
        this.setConfirmedPayment(true);
        this.setReminderModal(false);
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        this.setSendingPayment(false);
      }
    );
  }

  /**
   * Remind a kin user to pay the user that kin owes
   *
   * @param {string} query
   * @memberof KinStore
   */
  remindPayment(query: string) {
    // Update UI state
    this.setSendingReminder(true);
    // Initialise Authentication with refreshed token
    queryAuthenticated(query).then(
      () => {
        new Segment().Track(SegmentEvent.NUDGE_FRIEND);
        // Reset UI state
        this.setSendingReminder(false);
        this.setConfirmedReminder(true);
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        this.setSendingReminder(false);
      }
    );
  }

  /**
   * Updating the details of a kin
   *
   * @param {string} query
   * @memberof KinStore
   */
  updateKin(query: string, name: string, purpose: string): void {
    // Update UI state
    this.setSavingKin(true);
    // Initialise Authentication with refreshed token
    queryAuthenticated(query).then(
      () => {
        if (this.groupName !== name) {
          new Segment().Track(SegmentEvent.EDIT_GROUP_NAME);
        }
        if (this.groupPurpose !== purpose) {
          new Segment().Track(SegmentEvent.EDIT_GROUP_PURPOSE);
        }
        // Reset UI state
        this.setSavingKin(false);
        this.setEditingKin(false);
        // Update local kin settings
        this.fetchKinData(groupQuery(this.groupId));
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        this.setSavingKin(false);
      }
    );
  }

  /**
   * Adding or updating an expense to a kin
   *
   * @memberof KinStore
   */
  addUpdateExpense(update: boolean = false): void {
    // Set UI state to loading
    this.setAddingExpense(true);

    const queryStart = moment();
    let queryType: string;
    let segmentEventType: string;
    let queryVars: any;
    if (update) {
      queryType = transactionUpdateQuery();
      queryVars = {
        transactionRecordId: this.transactionRecordId,
        transactionRecord: {
          description: this.expense.description,
          responsibleMemberId: this.expense.responsibleMemberId,
          transactionType: this.expense.future
            ? TransactionType.Planned
            : TransactionType.Paid,
          date: this.expense.date as string,
          amount: {
            currencyIso: this.expense.amount.currencyIso,
            cents: Math.abs(this.expense.amount.cents) * -1
          },
          split: this.expense.split
        },
        syncId: null
      };

      segmentEventType = this.expense.future
        ? SegmentEvent.EDIT_ESTIMATE
        : SegmentEvent.EDIT_EXPENSE;
    } else {
      queryType = transactionCreateQuery();
      queryVars = {
        groupId: this.groupId,
        transactionRecord: {
          description: this.expense.description,
          responsibleMemberId: this.expense.responsibleMemberId,
          transactionType: this.expense.future
            ? TransactionType.Planned
            : TransactionType.Paid,
          date: this.expense.date as string,
          amount: {
            currencyIso: this.expense.amount.currencyIso,
            cents: Math.abs(this.expense.amount.cents) * -1
          },
          split: {
            ...this.expense.split,
            portions: this.expense.split.portions.length
              ? this.expense.split.portions
              : null
          }
        },
        syncId: null
      };

      segmentEventType = this.expense.future
        ? SegmentEvent.ADD_ESTIMATE
        : SegmentEvent.ADD_EXPENSE;
    }

    // Initialise Authentication with refreshed token
    queryAuthenticated(queryType, queryVars).then(
      result => {
        // Log query response time to Segment
        const queryEnd = moment();
        const queryDuration = queryEnd.diff(queryStart, "milliseconds") / 1000;
        new Segment().Track(SegmentEvent.EXPENSE_QUEUE_TIME, {
          time: queryDuration
        });

        // Reset UI state
        this.setAddingExpense(false);
        this.setExpenseModal(false, this.currencyIsoCode);
        // Update local kin settings
        this.fetchKinData(groupQuery(this.groupId));
        // Segment: log succesful edit or update event
        new Segment().Track(segmentEventType);
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        this.setAddingExpense(false);
      }
    );
  }

  /**
   * Removing an expense from a kin
   *
   * @param {string} transactionId
   * @param {boolean} planned
   * @memberof KinStore
   */
  removeExpense(transactionId: string, planned: boolean): void {
    // Set UI state
    this.setRemovingExpense(true);
    // Initialise Authentication with refreshed token
    queryAuthenticated(transactionRemoveQuery(transactionId)).then(
      result => {
        // Reset UI state
        this.setRemovingExpense(false);
        // Update local kin settings
        this.fetchKinData(groupQuery(this.groupId));
        // Log delete event to segment
        if (planned) {
          new Segment().Track(SegmentEvent.DELETE_ESTIMATE);
        } else {
          new Segment().Track(SegmentEvent.DELETE_EXPENSE);
        }
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        this.setRemovingExpense(false);
      }
    );
  }

  fetchInviteUrlFromCorrelationId(query: string, callback: any = null) {
    this.setFetchingInviteUrl(true);
    this.fetchCorrelationId(query, () =>
      this.fetchDynamicLink(
        dynamicLinkQuery(DynamicLinkType.Invite, this.correlationId),
        callback
      )
    );
  }

  fetchCorrelationId(query: string, callback: any = null) {
    queryAuthenticated(query).then(
      result => {
        this.setInviteCorrelationId(
          result.data.data.groupInviteExistingMember.correlationId
        );
        // Execute callback if defined
        if (callback) {
          callback();
        }
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        this.setInviteCorrelationId("");
      }
    );
  }

  /**
   * Fetch dynamic invite link
   *
   * @param {string} query
   * @memberof KinStore
   */
  fetchDynamicLink(query: string, callback: any = null) {
    // Initialise Authentication with refreshed token
    queryAuthenticated(query).then(
      result => {
        // Update dynamic link
        this.setDynamicLink(result.data.data.miscellaneous.buildDynamicLink);
        // Execute callback if defined
        if (callback) {
          callback();
        }
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        this.setDynamicLink(undefined);
      }
    );
  }

  /**
   * Check if an user is the only member of that kin
   *
   * @returns
   * @memberof KinStore
   */
  checkIfUserIsOnlyUser() {
    let isOnlyMember: boolean = false;
    // Filter out InActive users
    let filteredMembers: IGroupMember[] = this.members.filter(member => {
      return member.status === GroupMembersStatus.Active;
    });
    // If list is only 1 member long
    if (filteredMembers.length === 1) {
      filteredMembers.forEach(member => {
        if (member.id === this.currentUserId) {
          isOnlyMember = true;
        }
      });
    }
    return isOnlyMember;
  }

  /**
   * Check if the user has any outstanding balances
   * Includes when a user is also still owed money
   *
   * @returns
   * @memberof KinStore
   */
  checkIfUserHasOutstandingBalance() {
    let hasOutstanding: boolean = false;
    this.settleData.memberToMemberBalance.forEach(balance => {
      if (balance.fromMemberId === this.currentUserId) {
        if (balance.amount.cents !== 0) {
          hasOutstanding = true;
        }
      }
    });
    return hasOutstanding;
  }

  /**
   * Show a modal depending on user kin state
   *
   * @memberof KinStore
   */
  leaveKinModal() {
    switch (true) {
      case this.checkIfUserIsOnlyUser():
        // Update UI state
        this.setLeaveOnlyMember(true);
        break;
      case this.checkIfUserHasOutstandingBalance():
        // Update UI state
        this.setLeaveOutstandingBalance(true);
        break;
      default:
        // Update UI state
        this.setLeaveOkay(true);
        break;
    }
  }

  /**
   * Leave a kin
   *
   * @memberof KinStore
   */
  leaveKin() {
    // Update UI state
    this.setLeaving(true);
    queryAuthenticated(groupLeaveQuery(this.groupId)).then(
      result => {
        // Update UI state
        this.setLeaving(false);
        // Navigate to kins list page
        history.push("/home/kins");
        new Segment().Track(SegmentEvent.LEAVE_GROUP);
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
      }
    );
  }

  /**
   * Remove a member from a group.
   *
   * @param {string} memberId
   * @param {string} [groupId=this.groupId]
   * @memberof KinStore
   */
  removeMemberFromKin(
    memberId: string,
    callback: any = null,
    groupId: string = this.groupId
  ): void {
    this.setRemovingMember(true);
    queryAuthenticated(groupRemoveMemberQuery(groupId, memberId)).then(
      result => {
        // Update local kin settings
        this.fetchKinData(groupQuery(this.groupId));
        this.setRemovingMember(false);
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        this.setRemovingMember(false);
      }
    );
  }

  /**
   * Calculate total remaining amount.
   * Can be positive or negative
   *
   * @param {number} totalAmount
   * @returns
   * @memberof KinStore
   */
  calculateTotalRemaining(totalAmount: IAmount) {
    // Add all the value of each selected member
    let totalMemberAmounts: number = _.sumBy(
      this.expenseSplitMembers!.filter(member => member.selected === true),
      "value"
    );
    return {
      ...totalAmount,
      cents: Math.abs(totalAmount.cents) - totalMemberAmounts
      // cents: totalAmount.cents - totalMemberAmounts
    };
  }

  /**
   * Setting state values
   */
  @action setExpenseModal(state: boolean, currencyIso: string): void {
    this.expenseModal = state;
    if (!state) {
      this.expenseSplitMembers = undefined;
      this.expenseSplitMode = false;
      this.editingExpense = false;
      this.expense = {
        ...defaultExpense,
        currencyIso
      };
    } else {
      this.expense.currencyIso = currencyIso;
    }
  }
  @action setExpense(expense: IExpense): void {
    this.expense = expense;
  }
  @action setExpenseSplitMode(state: boolean): void {
    this.expenseSplitMode = state;
  }
  @action setExpenseSplitMembers(
    expenseSplitMembers: IExpenseSplitUser[] | undefined,
    isUpdate: boolean = false
  ): void {
    if (expenseSplitMembers) {
      const prevSplitAmounts = expenseSplitMembers.map(member => {
        if (member.selected) {
          return {
            cents: Math.abs(member.value),
            constant: isUpdate ? true : member.edited,
            currencyIso: this.currencyIsoCode,
            formatted: ""
          };
        } else {
          return {
            cents: 0,
            constant: true,
            currencyIso: this.currencyIsoCode,
            formatted: ""
          };
        }
      });

      const nextSplitAmounts = SplitCalculator.split(
        Math.abs(this.expense.amount.cents),
        prevSplitAmounts
      );
      this.expenseSplitMembers = expenseSplitMembers.map((member, index) => ({
        ...member,
        value: nextSplitAmounts[index].cents,
        edited: isUpdate ? false : member.edited
      }));
    }
  }
  @action setExpenseRemaining(): void {
    this.expenseRemaining = this.calculateTotalRemaining(this.expense.amount);
  }
  @action setEditingExpense(state: boolean): void {
    this.editingExpense = state;
  }
  @action setAddingExpense(state: boolean): void {
    this.addingExpense = state;

    // When closing the add expense modal,
    // reset the global state that keeps track of
    // the current expense.
    if (!state) {
      this.expense = {
        ...defaultExpense
      };
    }
  }
  @action setRemovingExpense(state: boolean): void {
    this.removingExpense = state;
  }
  @action setDynamicLink(link: string | undefined): void {
    this.dynamicLink = link;
  }
  @action setInviteCorrelationId(correlationId: string): void {
    this.correlationId = correlationId;
  }
  @action setPaymentModal(state: boolean): void {
    this.reminderModal = false;
    this.confirmedPayment = false;
    this.paymentModal = state;
  }
  @action setPayment(payment: IPaymentProps): void {
    this.payment = payment;
  }
  @action setEditingPayment(state: boolean): void {
    this.editingPayment = state;
  }
  @action setSendingPayment(state: boolean): void {
    this.sendingPayment = state;
  }
  @action setSendingReminder(state: boolean): void {
    this.sendingReminder = state;
  }
  @action setConfirmedPayment(state: boolean): void {
    this.confirmedPayment = state;
  }
  @action setConfirmedReminder(state: boolean): void {
    this.reminderShareUserModal = false;
    this.confirmedReminder = state;
  }
  @action setReminderModal(state: boolean): void {
    this.paymentModal = false;
    this.reminderModal = state;
    this.reminderOptionsModal = state;
    this.reminderShareGhostModal = false;
    this.reminderShareUserModal = false;
    this.confirmedReminder = false;
    if (!state) {
      this.sendingReminder = false;
      this.sendingPayment = false;
    }
  }
  @action setReminderOptionsModal(state: boolean): void {
    this.reminderShareGhostModal = false;
    this.reminderShareUserModal = false;
    this.reminderOptionsModal = state;
  }
  @action setReminderShareGhostModal(state: boolean): void {
    this.reminderOptionsModal = false;
    this.reminderShareUserModal = false;
    this.reminderShareGhostModal = state;
  }
  @action setReminderShareUserModal(state: boolean): void {
    this.reminderOptionsModal = false;
    this.reminderShareGhostModal = false;
    this.reminderShareUserModal = state;
  }
  @action setReminderUser(user: IReminderUser): void {
    this.reminderUser = user;
  }
  @action setLoading(loading: boolean): void {
    this.loading = loading;
  }
  @action setError(error: boolean, errorMsg?: string | undefined): void {
    this.error = error;
    this.errorMsg = error ? errorMsg : "";
  }
  @action setGroupSettings(name: string, purpose: string): void {
    this.groupName = name;
    this.groupPurpose = purpose;
  }
  @action setGroupData(groupData: IGroupData): void {
    this.groupName = groupData.groupName;
    this.groupId = groupData.groupId;
    this.groupImage = groupData.groupImage;
    this.groupPurpose = groupData.groupPurpose;
    this.activityData = groupData.activityData;
    this.transactionData = groupData.transactionData;
    this.settleData = groupData.settleData;
    this.currentUserId = groupData.currentUserId;
    this.members = groupData.members;
    this.loading = false;
    this.groupInviteUrl = groupData.groupInviteUrl;
    this.currencyIsoCode = groupData.currencyIsoCode;
  }
  @action setGroupId(id: string): void {
    this.groupId = id;
  }
  @action setTransactionRecordId(id: string): void {
    this.transactionRecordId = id;
  }
  @action setCurrentUserId(id: string): void {
    this.currentUserId = id;
  }
  @action setUploadingPhoto(state: boolean): void {
    this.uploadingPhoto = state;
  }
  @action setEditingKin(state: boolean): void {
    this.editingKin = state;
  }
  @action setSavingKin(state: boolean): void {
    this.savingKin = state;
  }
  @action setLeaveOutstandingBalance(state: boolean): void {
    this.leaveOutstandingBalance = state;
  }
  @action setLeaveOnlyMember(state: boolean): void {
    this.leaveOnlyMember = state;
  }
  @action setLeaveOkay(state: boolean): void {
    this.leaveOkay = state;
  }
  @action setLeaving(state: boolean): void {
    this.leaving = state;
  }
  @action resetData() {
    this.kinFetchSubscription.unsubscribe();
    this.editingExpense = false;
    this.setGroupData({
      groupName: "",
      groupId: "",
      groupImage: "",
      groupPurpose: "",
      activityData: [],
      transactionData: [],
      settleData: {
        id: "",
        name: "",
        image: "",
        purpose: "",
        currentMember: {
          id: "",
          name: ""
        },
        memberToMemberBalance: [],
        members: []
      },
      currentUserId: "",
      members: [],
      currencyIsoCode: ""
    });
    this.transactionRecordId = "";
    this.leaveOutstandingBalance = false;
    this.leaveOnlyMember = false;
    this.leaveOkay = false;
    this.leaving = false;
    this.loading = true;
  }
  @action setRemovingMember(state: boolean): void {
    this.removingMember = state;
  }
  @action setFetchingInviteUrl(state: boolean): void {
    this.fetchingInviteUrl = state;
  }
}
