import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { history } from "./common/history";

import Invite from "./pages/invite/invite";
import ProfileSetup from "./pages/profile/profile-setup";
import Kins from "./pages/home/kins";
import Kin from "./pages/home/kin";
import Explore from "./pages/home/explore";

import SignIn from "./pages/sign-in/sign-in";
import SignInZendesk from "./pages/sign-in/sign-in-zendesk";
import Authentication from "./common/authentication";
import Experiments from "./common/experiments";
import ExperimentDebugger from "./components/experiment/debugger";
import { Paths } from "./common/menu";
import Activity from "./pages/home/activity";
import People from "./pages/home/people";
import Person from "./pages/home/person";
import NotFound from "./pages/errors/404";

const App: React.FC = () => {
  // Initiate any experiments
  new Experiments();

  // Check if signed out then go back to sign-in page
  // Only applies to pages under /home/*
  if (history.location.pathname.includes("/home")) {
    if (new Authentication().hasTokenExpiredLocally()) {
      new Authentication().isSignedOut();
    }
  }

  return (
    <Router history={history}>
      <ExperimentDebugger />
      <Switch>
        <Route path={Paths.SignIn} component={SignIn} />
        <Route path={Paths.ZendeskSignIn} component={SignInZendesk} />
        <Route path={Paths.Invite} component={Invite} />
        <Route path={Paths.ProfileSetup} component={ProfileSetup} />
        <Route path={Paths.Kin} exact component={Kin} />
        <Route path={Paths.Kins} exact component={Kins} />
        <Route path={Paths.Activity || Paths.ActivityItem} component={Activity} />
        <Route path={Paths.People} exact component={People} />
        <Route path={Paths.Person} exact component={Person} />
        <Route path={Paths.Explore} component={Explore} />
        <Route path={Paths.Home} exact component={SignIn} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
