import React from "react";

import "./overlay.scss";

/**
 * Overlay props
 *
 * @interface IOverlay
 */
interface IOverlay {
  active?: boolean;
  onClick?: () => void;
  zIndex?: number;
}

/**
 * Overlay component when a panel is opened to mask content below and register on click events
 *
 * @param {*} props
 * @returns
 */
const Overlay: React.FC<IOverlay> = props => {
  return (
    <>
      {props.active ? (
        <div
          className="b-overlay"
          style={
            props.zIndex
              ? {
                  zIndex: props.zIndex
                }
              : {}
          }
          onClick={() => (props.onClick ? props.onClick() : {})}
        />
      ) : null}
    </>
  );
};

/**
 * Default props for Overlay component
 */
Overlay.defaultProps = {
  active: false
};

export default Overlay;
