import { observable, action } from "mobx";
import KinGraphQL from "../common/graphql";
import Segment from "../common/segment";
import Notices from "../common/snackbars";
import { NewKinType } from "./layoutSidebarStore";
import { queryAuthenticatedWithCache } from "../common/graphql-utils";
import { Observable } from "rxjs";
import { map, first, last } from "rxjs/operators";

/**
 * Query to fetch joined groups
 *
 */
export const groupsQuery = () => `
    query {
        me {
            id,
            name,
            image,
            email,
            defaultCurrency {
              isoCode
            }
        }
        groups {
            id
            name
            image
            currencyIsoCode
            currentMember {
                summary {
                balance {
                    cents
                    currencyIso
                    formatted
                }
                }
                updateDate
            }
            isHidden
            members {
                id,name,image, status
            }
        }
    }
`;
/**
 * Query to fetch new kin create images
 *
 * @export
 */
export const groupTypeImagesQuery = `
    query {
      groupIconsByType {
        key
        value
      }
    }
`;

/**
 * Group member status enum
 *
 * @export
 * @enum {number}
 */
export enum GroupMembersStatus {
  Active = "Active",
  InActive = "InActive",
  NotInvited = "NotInvited",
  Invited = "Invited"
}

/**
 * Group member details interface
 *
 * @export
 * @interface IGroupMembers
 */
export interface IGroupMembers {
  id: string;
  name: string;
  image: string;
  status: GroupMembersStatus;
}

/**
 * Group details interface
 *
 * @export
 * @interface IGroupsQuery
 */
export interface IGroupsQuery {
  me: IUser;
  id: string;
  name: string;
  image: string;
  currentMember: {
    summary: {
      balance: {
        cents: number;
        currencyIso: string;
        formatted: string;
      };
    };
    updateDate: string;
  };
  isHidden: boolean;
  members: IGroupMembers[];
}

interface IDefaultCurrency {
  isoCode: string;
}

/**
 * User interface
 *
 * @export
 * @interface IUser
 */
export interface IUser {
  name: string;
  id: string;
  image: string;
  hasSignedUp: boolean;
  email: string;
  inviteLinkUrl?: string;
  defaultCurrency?: IDefaultCurrency;
}

/**
 * Interface for users and groups/kins data
 *
 * @export
 * @interface IKinsData
 */
export interface IKinsData {
  user: IUser;
  kins: IGroupsQuery[];
}

/**
 * Interface for new kin image object array
 *
 * @export
 * @interface INewKinImages
 */
export interface INewKinImages {
  key: NewKinType;
  value: string;
}

/**
 * Kin store state
 *
 * @export
 * @interface IKinsStore
 */
export interface IKinsStore {
  loading: boolean;
  error: boolean;
  user: IUser;
  kins: IGroupsQuery[];
  newKinImages: INewKinImages[];
  highlightNewKin: boolean;
  fetchCachedKinsOrRefresh(): Promise<IGroupsQuery[]>;
  refreshKins(): Promise<IGroupsQuery[]>;
  fetchStoredKins(callback?: any): void;
  fetchKins(): Observable<IGroupsQuery[]>;
  fetchGroupTypeImages(): void;
  setKinsData(kinsData: IKinsData): void;
  setNewKinImages(images: INewKinImages[]): void;
  setLoading(loading: boolean): void;
  setError(error: boolean): void;
  setHighlightNewKin(state: boolean): void;
}

export class KinsStore implements IKinsStore {
  /**
   * UI state when loading
   *
   * @type {boolean}
   * @memberof KinsStore
   */
  @observable loading: boolean;
  /**
   * UI state for errors
   *
   * @type {boolean}
   * @memberof KinsStore
   */
  @observable error: boolean;
  /**
   * User data
   *
   * @type {IUser}
   * @memberof KinsStore
   */
  @observable user: IUser;
  /**
   * Kins data
   *
   * @type {IGroupsQuery[]}
   * @memberof KinsStore
   */
  @observable.shallow kins: IGroupsQuery[];
  /**
   * New Kin placeholder images
   *
   * @type {any[]}
   * @memberof KinsStore
   */
  @observable newKinImages: INewKinImages[];
  /**
   * UI state for highlighting a newly created Kin
   *
   * @type {boolean}
   * @memberof KinsStore
   */
  @observable highlightNewKin: boolean;

  /**
   *Creates an instance of KinsStore.
   * @memberof KinsStore
   */
  constructor() {
    /**
     * Setting default values
     */
    this.loading = true;
    this.error = false;
    this.user = {
      name: "",
      id: "",
      hasSignedUp: false,
      image: "",
      email: ""
    };
    this.kins = [];
    this.newKinImages = [];
    this.highlightNewKin = false;
  }

  /**
   * Fetch kins list from stored data
   *
   * @param {string} query
   * @memberof KinsStore
   */
  fetchStoredKins(query: string, callback: any = null): void {
    // Check for cached data first
    new KinGraphQL().fetchStoredRequest(query).then(value => {
      if (value !== null) {
        // Set kins data
        this.setKinsData({
          user: value.data.data.me,
          kins: value.data.data.groups
        });
        //Set Segment User identity
        new Segment().Identify(this.user);
        // Execute defined callback
        if (callback) {
          callback();
        }
      } else {
        // Update UI state
        this.setLoading(true);
      }
    });
  }

  /**
   * Fetch kins list from server
   *
   * @param {string} query
   * @memberof KinsStore
   */
  fetchKins(): Observable<IGroupsQuery[]> {
    const observable = queryAuthenticatedWithCache(groupsQuery());
    // Initialise Authentication with refreshed token
    observable.subscribe({
      next: result => {
        let groups: IGroupsQuery[] = result.data.data.groups;
        // Set kins data
        this.setKinsData({
          user: result.data.data.me,
          kins: groups
        });
        //Set Segment User identity
        new Segment().Identify(this.user);
      },
      error: () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
      }
    });
    return observable.pipe(
      map(x => {
        return x.data.data.groups;
      })
    );
  }

  /**
   * Fetch kins list from server
   *
   * @memberof KinsStore
   */
  async fetchCachedKinsOrRefresh(): Promise<IGroupsQuery[]> {
    const graphQl = new KinGraphQL();
    const storedKins = await graphQl.fetchStoredRequest(groupsQuery());
    if (storedKins) {
      return storedKins.data.data.groups;
    }
    return await this.fetchKins()
      .pipe(first())
      .toPromise();
  }

  refreshKins(): Promise<IGroupsQuery[]> {
    const obs = this.fetchKins().pipe(last());
    return obs.toPromise();
  }

  /**
   * Fetching the images for new group types
   *
   * @param {string} query
   * @memberof KinsStore
   */
  fetchGroupTypeImages(): void {
    //only need to load images once not on every call
    if (this.newKinImages.length > 0) {
      return;
    }
    // Initialise Authentication with refreshed token
    queryAuthenticatedWithCache(groupTypeImagesQuery).subscribe({
      next: result => {
        this.setNewKinImages(result.data.data.groupIconsByType);
      },
      error: () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
      }
    });
  }

  /**
   * Setting state values
   */
  @action setKinsData(kinsData: IKinsData): void {
    this.user = kinsData.user;
    this.kins = kinsData.kins;
    this.loading = false;
  }
  @action setNewKinImages(images: INewKinImages[]): void {
    this.newKinImages = images;
  }
  @action setLoading(loading: boolean) {
    this.loading = loading;
  }
  @action setError(error: boolean) {
    this.error = error;
  }
  @action setHighlightNewKin(state: boolean): void {
    this.highlightNewKin = state;
  }
}
