import React from 'react';

import './loader.scss';

/**
 * Loader component props
 *
 * @interface ILoaderProps
 */
interface ILoaderProps {
    active: boolean;
    white?: boolean;
    style?: any;
    className?: string;
}

/**
 * Loader component for displaying an animated loading icon
 *
 * @param {*} props
 * @returns
 */
const Loader: React.FC<ILoaderProps> = (props) => {
    let loaderClasses: string = "b-loader";
    let dotClasses: string = "e-dot";

    if(props.className) {
        loaderClasses += ` ${props.className}`;
    }

    if(props.white) {
        dotClasses += ' m-white';
    }

    return (
        <>
            {
                props.active ?
                    <div className={loaderClasses} style={props.style}>
                        <div className={dotClasses}></div>
                        <div className={dotClasses}></div>
                    </div> : null
            }
        </>
    );
}

/**
 * Default loader active state is always false
 */
Loader.defaultProps = {
    active: false
}

export default Loader;