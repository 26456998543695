import React from "react";

import "./payment.scss";
import AvatarList from "../avatar-list/avatar-list";
import Button, { ButtonColors } from "../button/button";
import Emoji from "react-emoji-render";
import Input from "../form/input";

import editIcon from "../../images/edit.svg";
import checkIcon from "../../images/check_small.svg";
import {
  IPaymentProps,
  IKinStore,
  paymentQuery,
  IPaymentSettlement
} from "../../stores/kinStore";
import { inject, observer } from "mobx-react";
import moment from "moment";
import Utilities from "../../common/utilities";
import {
  IPeopleStore,
  IPeopleConnectionGroupBalance,
  getPersonId,
  matchOnConnectionIdOrUserId
} from "../../stores/peopleStore";
import { IPersonStore } from "../../stores/personStore";
import { ICurrenciesStore } from "../../stores/currenciesStore";
import Segment, { SegmentEvent } from "../../common/segment";

/**
 * Settlement type enum for better description of settlement
 *
 * @enum {number}
 */
export enum SettlementType {
  Partial = "Partial Settlement",
  Settled = "Settled"
}

/**
 * Payment modal props
 *
 * @interface IPaymentModalProps
 * @extends {IPaymentProps}
 */
interface IPaymentModalProps extends IPaymentProps {
  kinStore?: IKinStore;
  personStore?: IPersonStore;
  peopleStore?: IPeopleStore;
  currenciesStore?: ICurrenciesStore;
}

/**
 * Component modal for displaying and editing a payment
 *
 * @class PaymentModalContent
 * @extends {React.Component<IPaymentModalProps, {}>}
 */
@inject("kinStore", "personStore", "peopleStore", "currenciesStore")
@observer
class PaymentModalContent extends React.Component<IPaymentModalProps, {}> {
  /**
   * Define default settlement type
   *
   * @private
   * @type {SettlementType}
   * @memberof PaymentModalContent
   */
  private settlementType: SettlementType;

  /**
   * Creates an instance of PaymentModalContent.
   * @param {IPaymentModalProps} props
   * @memberof PaymentModalContent
   */
  constructor(props: IPaymentModalProps) {
    super(props);
    this.settlementType = SettlementType.Settled;
  }

  /**
   * Update input amount
   *
   * @param {string} value
   * @memberof PaymentModalContent
   */
  updateAmount(value: string) {
    let payment: IPaymentProps = {
      ...this.props.kinStore!.payment
    };
    payment.transaction!.repayAmount!.cents = Number(
      // TODO: next
      new Utilities().FormatToCentsValue(value)
    );
    this.props.kinStore!.setPayment({
      ...payment
    });
  }

  /**
   * Settle the payment
   *
   * @memberof PaymentModalContent
   */
  settlePayment() {
    let fromMemberId: string;
    let toMemberId: string;
    // Check if the current user is paying or being paid
    if (this.props.kinStore!.payment!.transaction!.paying) {
      // If coming from people balances user assigned ID instead
      if (this.props.kinStore!.payment!.currentUserId) {
        fromMemberId = this.props.kinStore!.payment!.currentUserId;
      } else {
        fromMemberId = this.props.kinStore!.currentUserId;
      }
      toMemberId = this.props.kinStore!.payment!.transaction!.userId!;
    } else {
      fromMemberId = this.props.kinStore!.payment!.transaction!.userId!;
      // If coming from people balances user assigned ID instead
      if (this.props.kinStore!.payment!.currentUserId) {
        toMemberId = this.props.kinStore!.payment!.currentUserId;
      } else {
        toMemberId = this.props.kinStore!.currentUserId;
      }
    }
    // Build settlement data object
    let settlementData: IPaymentSettlement = {
      fromMemberId: fromMemberId,
      description: this.settlementType,
      date: moment().toISOString(true),
      to: [
        {
          memberId: toMemberId,
          amount: {
            currencyIso: this.props.kinStore!.payment!.transaction!.repayAmount!
              .currencyIso,
            cents: this.props.kinStore!.payment!.transaction!.repayAmount!.cents
          }
        }
      ]
    };
    // Send request
    this.props.kinStore!.settlePayment(
      paymentQuery(this.props.kinStore!.groupId, settlementData),
      () => {
        const payment = this.props.kinStore!.payment;

        if (
          payment!.transaction!.amount !== payment!.transaction!.repayAmount
        ) {
          new Segment().Track(SegmentEvent.EDIT_PAYMENT_AMOUNT);
        }

        const person = this.props.personStore!.person;

        // When actioned from person balance
        if (person !== undefined) {
          // Fetch people balance data
          this.props.peopleStore!.refreshPeople().then(() => {
            let personId: string = this.props.personStore!.person.id;
            let groupBalances: IPeopleConnectionGroupBalance[] = this.props.peopleStore!.fetchPersonGroupBalances(
              personId
            );
            const personFromStore: any = this.props.peopleStore!.peopleBalances!.connectionBalances.find(
              personBalance => {
                return matchOnConnectionIdOrUserId(personBalance, person.id);
              }
            );

            // Update person so that page rerenders data update
            this.props.personStore!.setPerson({
              name: person.name,
              image: person.image,
              id: getPersonId(person),
              groupBalances: groupBalances,
              status: person.status,
              userId: person.userId,
              email: personFromStore.email,
              balances: personFromStore.balances
            });
          });
        }
      }
    );
  }

  /**
   * Render the difference of payment with current added payment amount
   *
   * @param {string} userName
   * @returns
   * @memberof PaymentModalContent
   */
  renderSquareAmount(userName: string) {
    let paying: number = this.props.kinStore!.payment!.transaction!.repayAmount!
      .cents;
    let needed: number = this.props.kinStore!.payment!.transaction!.amount!
      .cents;
    let difference: number = needed - paying;
    if (paying < needed) {
      this.settlementType = SettlementType.Partial;
      if (this.props.transaction!.paying) {
        return `You'll OWE R${(difference / 100).toFixed(2)} to ${userName}`;
      } else {
        return `${userName} will OWE YOU R${(difference / 100).toFixed(2)}`;
      }
    }
    if (paying > needed) {
      this.settlementType = SettlementType.Partial;
      if (this.props.transaction!.paying) {
        return `${userName} will OWE YOU R${(
          Math.abs(difference) / 100
        ).toFixed(2)}`;
      } else {
        return `You'll OWE R${Math.abs(difference / 100).toFixed(
          2
        )} to ${userName}`;
      }
    }
    return `ALL SQUARE with ${userName}`;
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof PaymentModalContent
   */
  render() {
    let paymentCurrentClasses: string = "e-payment-currency";

    if (!this.props.transaction!.paying) {
      paymentCurrentClasses += " m-paid";
    }

    let paymentFieldClasses: string = "e-payment-field";

    if (this.props.kinStore!.editingPayment) {
      paymentFieldClasses += " m-editing";

      if (!this.props.transaction!.paying) {
        paymentFieldClasses += " m-paid";
      }
    }
    return (
      <div className="b-payment-modal">
        <span className="g-h5">Settling up</span>
        <div className="e-payment-users">
          <AvatarList borderWidth={1} users={this.props.users!} />
        </div>
        {this.props.transaction!.paying ? (
          <span className="g-h5 m-regular">
            I paid <strong>{this.props.transaction!.user}</strong>
          </span>
        ) : (
          <span className="g-h5 m-regular">
            <strong>{this.props.transaction!.user}</strong> paid me
          </span>
        )}
        <div className={paymentFieldClasses}>
          <span className={paymentCurrentClasses}>
            {this.props.currenciesStore!.currencySybmolByIsoCode(
              this.props.transaction!.amount!.currencyIso
            )}
          </span>
          <Input
            focus={this.props.kinStore!.editingPayment}
            small={true}
            className="e-payment-input"
            noBorderStyle={true}
            type="text"
            onBlur={() => {
              this.props.kinStore!.setEditingPayment(false);
            }}
            onFocus={() => {
              this.props.kinStore!.setEditingPayment(true);
            }}
            onChange={event => this.updateAmount(event)}
            value={this.props.currenciesStore!.formatAmount(
              this.props.transaction!.repayAmount!
            )}
          />
          {this.props.kinStore!.editingPayment ? (
            <img
              className="e-payment-edit m-confirm"
              src={checkIcon}
              alt="Confirm Payment"
            />
          ) : (
            <img className="e-payment-edit" src={editIcon} alt="Edit Payment" />
          )}
        </div>
        <span
          style={{
            display: "block",
            marginBottom: "24px"
          }}
          className="g-h5"
        >
          for <strong>{this.props.transaction!.group}</strong>
        </span>
        <p
          style={{
            marginBottom: "24px"
          }}
        >
          After payment
          <br />
          <strong>
            {this.renderSquareAmount(this.props.transaction!.user!)}
          </strong>
        </p>
        <Button
          className="e-payment-btn"
          loading={this.props.kinStore!.sendingPayment}
          color={
            this.props.transaction!.paying
              ? ButtonColors.Primary
              : ButtonColors.Secondary
          }
          small={true}
          onClick={() => this.settlePayment()}
        >
          Add payment
        </Button>
        <div className="e-payment-fyi">
          <p
            style={{
              margin: "34px auto 11px"
            }}
          >
            <strong>
              <Emoji text="☝️Just so you know…" />
            </strong>
          </p>

          {this.props.transaction!.paying ? (
            <p>
              …this is a record of a cash payment only, no money will be sent to{" "}
              {this.props.transaction!.user}.{" "}
              <span className="g-a">
                <strong>Download the Kin mobile app</strong>
              </span>{" "}
              to use your bank card to make payments
            </p>
          ) : (
            <p>
              …this is a record of a cash payment only, no money will be sent to
              you by Kin.{" "}
              <span className="g-a">
                <strong>Add your bank details</strong>
              </span>{" "}
              so people in your Kins can pay you back in the app
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default PaymentModalContent;
