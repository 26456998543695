import React from "react";

import "./activity.scss";
import {
  IActivityStore,
  IUserActivity,
  UserActivityType,
  IUserActivityUser
} from "../../stores/activityStore";
import { inject, observer } from "mobx-react";
import LayoutSidebar from "../../hoc/layout-sidebar";
import { menuItems } from "../../common/menu";
import { computed } from "mobx";
import ActivityLoader from "./activity-loader";
import Segment, { SegmentEvent } from "../../common/segment";
import Avatar from "../../components/avatar/avatar";
import moment from "moment";
import ReactList from "react-list";
import Emoji from "react-emoji-render";
import searchIcon from "../../images/search.svg";
import clearIcon from "../../images/icon-close.svg";
import Button, { ButtonColors } from "../../components/button/button";
import { history } from "../../common/history";

import moneySent from "../../images/money-sent.svg";
import moneyReceived from "../../images/money-received.svg";
import payment from "../../images/payment.svg";

import expenseFuture from "../../images/estimate.svg";
import expenseOwed from "../../images/expense-green.svg";
import expenseEdited from "../../images/expense-grey.svg";
import expenseOwe from "../../images/expense-pink.svg";
import { ILayoutSidebarStore } from "../../stores/layoutSidebarStore";
import { tabNames } from "./kin";
import { last } from "rxjs/operators";

/**
 * Activity component props
 *
 * @interface IActivityProps
 */
interface IActivityProps {
  activityStore?: IActivityStore;
  layoutSidebarStore?: ILayoutSidebarStore;
}

/**
 * Transaction Event type
 *
 * @enum {number}
 */
enum TransactionEventType {
  Created = "TransactionRecordCreated",
  Updated = "TransactionRecordUpdated",
  Removed = "TransactionRecordRemoved",
  BankDetails = "RequestBankDetails"
}

/**
 * Activity component for showing the current user's activities over all their Kin's
 *
 * @class Activity
 * @extends {React.Component<IActivityProps, {}>}
 */
@inject("activityStore", "layoutSidebarStore")
@observer
class Activity extends React.Component<IActivityProps, {}> {
  @computed get error(): boolean {
    return this.props.activityStore!.error;
  }
  @computed get loading(): boolean {
    return this.props.activityStore!.loading;
  }
  @computed get filter(): UserActivityType | undefined {
    return this.props.activityStore!.filter;
  }
  @computed get searchTerm(): string {
    return this.props.activityStore!.searchTerm;
  }
  @computed get activities(): IUserActivity[] {
    return this.props.activityStore!.items;
  }
  @computed get activitiesFiltered(): IUserActivity[] {
    return this.props.activityStore!.itemsFiltered;
  }
  @computed get activitiesSearched(): IUserActivity[] {
    return this.props.activityStore!.itemsSearched;
  }
  @computed get hasNotifications(): boolean {
    return this.props.activityStore!.hasNotifications;
  }

  /**
   * Fetch user activities and trigger page view
   *
   * @memberof Activity
   */
  componentDidMount() {
    this.props
      .activityStore!.fetchActivities()
      .pipe(last())
      .toPromise()
      .then(() => this.markAsSeen());
    new Segment().Page(SegmentEvent.PAGE_ACTIVITY);
  }

  /**
   * Reset data on unmount
   *
   * @memberof Activity
   */
  componentWillUnmount() {
    this.props.activityStore!.resetData();
  }

  /**
   * Mark activities as read after 2 seconds of viewing page
   *
   * @memberof Activity
   */
  markAsSeen() {
    setTimeout(() => {
      // Check if any notifications are marked as unseen
      if (this.hasNotifications) {
        this.props.activityStore!.markAsSeen();
      }
    }, 2000);
  }

  /**
   * Check if the activity should link to a transaction
   *
   * @param {IUserActivity} activity
   * @returns {boolean}
   * @memberof Activity
   */
  linkToTransaction(activity: IUserActivity): boolean {
    return (
      (activity.activityType === UserActivityType.EXPENSE &&
        !activity.message.includes("removed")) ||
      (activity.activityType === UserActivityType.PAYMENT &&
        activity.message.includes("paid"))
    );
  }

  /**
   * Check if the activity should link to the transactions tab
   *
   * @param {IUserActivity} activity
   * @returns {boolean}
   * @memberof Activity
   */
  linkToGroup(activity: IUserActivity): boolean {
    return (
      (activity.activityType === UserActivityType.PAYMENT &&
        activity.message.includes("paid")) ||
      (activity.activityType === UserActivityType.UPDATE &&
        activity.message.includes("created")) ||
      (activity.activityType === UserActivityType.EXPENSE &&
        activity.message.includes("is due"))
    );
  }

  /**
   * Link to Kin Settings panel
   *
   * @param {IUserActivity} activity
   * @returns {boolean}
   * @memberof Activity
   */
  linkToGroupSettings(activity: IUserActivity): boolean {
    return (
      activity.activityType === UserActivityType.UPDATE &&
      (activity.message.includes("joined") ||
        activity.message.includes("added") ||
        activity.message.includes(""))
    );
  }

  /**
   * Link to the settle tab on a group/Kin
   *
   * @param {IUserActivity} activity
   * @returns {boolean}
   * @memberof Activity
   */
  linkToGroupSettlements(activity: IUserActivity): boolean {
    return (
      activity.activityType === UserActivityType.PAYMENT &&
      activity.message.includes("removed a")
    );
  }

  clickHandler(activity: IUserActivity) {
    // Click Handlers for an expense
    if (this.linkToTransaction(activity)) {
      let transactionId: string = activity.properties.filter(
        (property: any) => property.key === "transactionRecordId"
      )[0].value;
      history.push(
        `/home/kins/group/${activity.groupId}?transaction=${transactionId}`
      );
    } else if (this.linkToGroupSettlements(activity)) {
      history.push(`/home/kins/group/${activity.groupId}?tab=${tabNames[2]}`);
    } else if (this.linkToGroup(activity)) {
      if (activity.groupId !== null) {
        history.push(`/home/kins/group/${activity.groupId}`);
      } else {
        history.push(`/not-found`);
      }
    } else if (this.linkToGroupSettings(activity)) {
      history.push(`/home/kins/group/${activity.groupId}?settings=true`);
    }
    return {};
  }

  /**
   * Render activity msg with bold string
   *
   * @param {string} msg
   * @param {string} userName
   * @returns
   * @memberof Activity
   */
  renderActivityMessage(msg: string, user: IUserActivityUser | null) {
    // List of keywords to help find the first name index in string.
    // Update any new string here if the match fails or are outdated.
    // If this becomes too long, implement a server side solution with
    // the activity properties. This would be best case scenario.
    let keywordMatch: string[] = [
      "added",
      "removed",
      "paid",
      "changed",
      "commented",
      "joined",
      "invited",
      "left",
      "edited"
    ];
    // When it's the current user "You" is used instead of the user's name
    let youUser: string = "You";
    let firstWord: string;
    let remainingWords: string;
    let boldTextLength: number = 0;

    if (user !== null && msg.indexOf(user.name) === 0) {
      // If the user name is first in msg
      boldTextLength = user.name.length;
    } else if (msg.indexOf(youUser) === 0) {
      // If the "You" keyword is first
      boldTextLength = youUser.length;
    } else {
      // If none of the above match try to find the first
      // word by doing the manual keyword match
      for (let i = 0; i <= keywordMatch.length; i++) {
        if (msg.indexOf(keywordMatch[i]) !== -1) {
          boldTextLength = msg.indexOf(keywordMatch[i]);
        } else {
          boldTextLength = msg.indexOf(" ");
        }
      }
    }
    // Split string into first words and remaining
    firstWord = msg.substring(0, boldTextLength);
    remainingWords = msg.substring(boldTextLength, msg.length);

    return (
      <>
        <strong>{firstWord}</strong>
        {remainingWords}
      </>
    );
  }

  /**
   * Render the timestamp
   *
   * @param {IUserActivity} activity
   * @returns
   * @memberof Activity
   */
  renderActivityTimestamp(activity: IUserActivity) {
    // Activity date
    let activityDate: moment.Moment = moment(activity.createDate);
    // Month ago from now date
    let monthFromDate: moment.Moment = moment(activity.createDate).subtract(
      1,
      "month"
    );
    // If not a month ago yet
    if (activityDate.isSameOrAfter(monthFromDate)) {
      return activityDate.fromNow();
    } else {
      // Add year if not this year
      if (moment().isSame(activityDate, "year")) {
        return activityDate.format("DD MMMM");
      } else {
        return activityDate.format("DD MMMM YYYY");
      }
    }
  }

  /**
   *
   *
   * @returns
   * @memberof Activity
   */
  renderActivityAction(activity: IUserActivity) {
    let userId: string = activity.byUser.id;
    if (activity.byUser.isMe) {
      if (activity.group) {
        userId = activity.properties.filter(
          property => property.key === "requestFromMemberId"
        )[0].value;
      } else {
        userId = activity.properties.filter(
          property => property.key === "requestFromConnectionId"
        )[0].value;
      }
    }
    return (
      <div className="e-activity-action">
        {activity.event === TransactionEventType.BankDetails &&
        activity.message.includes("bank details") ? (
          <Button
            className="e-action-btn"
            small={true}
            color={ButtonColors.Black}
            onClick={() => {
              this.props.layoutSidebarStore!.setProfileSettingsDirect(true);
            }}
          >
            Go to profile
          </Button>
        ) : (
          <Button
            className="e-action-btn"
            small={true}
            color={ButtonColors.Black}
            onClick={() => {
              console.log(
                "5d2ed9669891070002356b2f",
                userId,
                activity.byUser.id,
                activity,
                activity.message
              );
              history.push(`/home/people/person/${userId}`);
            }}
          >
            View Balances
          </Button>
        )}
      </div>
    );
  }

  renderActivityExpenseIcon(activity: IUserActivity) {
    let icon: string = expenseEdited;
    if (activity.event === TransactionEventType.Created) {
      if (activity.message.includes("future expense")) {
        icon = expenseFuture;
      }
    }
    if (activity.event === TransactionEventType.Updated) {
      icon = expenseEdited;
    }
    let expenseTransactionProperty: any = activity.properties.filter(
      (property: any) => property.key === "flow"
    )[0];
    if (expenseTransactionProperty !== undefined) {
      if (expenseTransactionProperty.value === "expense") {
        icon = expenseOwe;
      } else {
        icon = expenseOwed;
      }
    }
    // Don't show icons for removed events
    if (activity.event !== TransactionEventType.Removed) {
      return (
        <img src={icon} alt="Expense" className="e-activity-action-icon" />
      );
    }
    return null;
  }

  renderActivityPaymentIcon(activity: IUserActivity) {
    let icon: string = "";
    let youStr: string = "you";
    let message: string = activity.message.toLowerCase();
    // Check if payment was by current user
    if (message.includes(youStr)) {
      // If current user paid
      if (message.indexOf(youStr) === 0) {
        icon = moneySent;
      } else {
        // If current user received money
        icon = moneyReceived;
      }
    } else {
      // Current user is not involved
      icon = payment;
    }

    if (activity.event !== TransactionEventType.Removed) {
      return (
        <img src={icon} alt="Payment" className="e-activity-action-icon" />
      );
    }
    return null;
  }

  /**
   * Render method for each activity
   *
   * @param {number} index
   * @returns
   * @memberof Activity
   */
  renderActivity(activities: IUserActivity[], index: number) {
    let activityClasses: string = "b-activity";
    if (!activities[index].seen) {
      activityClasses += " m-unread";
    }
    if (
      this.linkToTransaction(activities[index]) ||
      this.linkToGroup(activities[index]) ||
      this.linkToGroupSettings(activities[index]) ||
      this.linkToGroupSettlements(activities[index])
    ) {
      activityClasses += " m-clickable";
    }
    return (
      <div
        className={activityClasses}
        key={index}
        onClick={() => this.clickHandler(activities[index])}
      >
        <div className="e-activity-avatar">
          {activities[index].byUser !== null ? (
            <Avatar
              size={48}
              image={activities[index].byUser.image}
              name={activities[index].byUser.name}
            />
          ) : (
            <Avatar
              size={48}
              image={activities[index].group.image}
              name={activities[index].group.name}
            />
          )}
        </div>
        <div className="e-activity-content">
          {activities[index].group !== null ? (
            <div className="e-activity-group">
              <Emoji text={activities[index].group.name} />
            </div>
          ) : null}
          <div className="e-activity-msg">
            {this.renderActivityMessage(
              activities[index].message,
              activities[index].byUser
            )}
          </div>
          <div className="e-activity-timestamp">
            {this.renderActivityTimestamp(activities[index])}
          </div>
        </div>
        {activities[index].activityType === UserActivityType.REQUEST
          ? this.renderActivityAction(activities[index])
          : null}
        {activities[index].activityType === UserActivityType.EXPENSE
          ? this.renderActivityExpenseIcon(activities[index])
          : null}
        {activities[index].activityType === UserActivityType.PAYMENT
          ? this.renderActivityPaymentIcon(activities[index])
          : null}
      </div>
    );
  }

  renderList(activities: IUserActivity[], overrideSearched: boolean = false) {
    // Only render if there are results
    if (this.activitiesSearched.length !== 0 && activities.length !== 0) {
      // Assign values based on search term
      let list: IUserActivity[] =
        this.searchTerm.length !== 0 ? this.activitiesSearched : activities;
      let listLength: number =
        this.searchTerm.length !== 0
          ? this.activitiesSearched.length
          : activities.length;

      // Override values for filtered results
      if (overrideSearched) {
        list = activities;
        listLength = activities.length;
      }

      // Render activities
      return (
        <ReactList
          itemRenderer={index => this.renderActivity(list, index)}
          length={listLength}
          type="simple"
          threshold={0}
          useTranslate3d={true}
        />
      );
    } else {
      // Render no results
      return (
        <div className="e-no-results">
          <div className="e-no-results-title">
            <Emoji text="No matching results 😬" />
          </div>
          <div className="e-no-results-description">
            Unfortunately we can’t find anything matching that. Please try
            searching for the name of a Kin, a person or an expense.
          </div>
        </div>
      );
    }
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof Activity
   */
  render() {
    return (
      <LayoutSidebar
        showNavBar={true}
        showAddNew={false}
        menuItems={menuItems}
        menuActiveItem={menuItems[2].title}
        errorState={this.error}
      >
        <div className="b-activity-page">
          {this.loading ? (
            <ActivityLoader
              style={{
                maxWidth: "500px",
                margin: "24px auto",
                padding: "0 24px"
              }}
              active={this.loading}
            />
          ) : (
            <>
              <div className="e-container">
                <div className="e-search-bar">
                  <img
                    src={searchIcon}
                    alt="Search"
                    className="e-search-icon"
                  />
                  <input
                    onClick={() =>
                      new Segment().Track(
                        `activity_${new Segment().FormatEventName("search")}`
                      )
                    }
                    type="text"
                    onChange={e =>
                      this.props.activityStore!.setSearchTerm(
                        e.currentTarget.value
                      )
                    }
                    value={this.searchTerm}
                    placeholder="Search for a person or Kin"
                    className="e-search-field"
                  />
                  {this.searchTerm.length !== 0 ? (
                    <img
                      src={clearIcon}
                      alt="Clear Search"
                      className="e-search-clear"
                      onClick={() =>
                        this.props.activityStore!.setSearchTerm("")
                      }
                    />
                  ) : null}
                </div>
              </div>
              <div className="e-filters">
                <div className="e-filter-wrapper">
                  <div
                    onClick={() => {
                      this.props.activityStore!.setFilter(undefined);
                      new Segment().Track(
                        `activity_${new Segment().FormatEventName("All")}`
                      );
                    }}
                    className={
                      this.filter === undefined ||
                      this.filter === UserActivityType.SEARCH
                        ? "e-filter m-active"
                        : "e-filter"
                    }
                  >
                    All
                  </div>
                  <div
                    onClick={() => {
                      new Segment().Track(
                        `activity_${new Segment().FormatEventName("Updates")}`
                      );
                      this.props.activityStore!.filterData(
                        UserActivityType.UPDATE
                      );
                    }}
                    className={
                      this.filter === UserActivityType.UPDATE
                        ? "e-filter m-active"
                        : "e-filter"
                    }
                  >
                    Updates
                  </div>
                  <div
                    onClick={() => {
                      new Segment().Track(
                        `activity_${new Segment().FormatEventName("Requests")}`
                      );
                      this.props.activityStore!.filterData(
                        UserActivityType.REQUEST
                      );
                    }}
                    className={
                      this.filter === UserActivityType.REQUEST
                        ? "e-filter m-active"
                        : "e-filter"
                    }
                  >
                    Requests
                  </div>
                  <div
                    onClick={() => {
                      new Segment().Track(
                        `activity_${new Segment().FormatEventName("Payments")}`
                      );
                      this.props.activityStore!.filterData(
                        UserActivityType.PAYMENT
                      );
                    }}
                    className={
                      this.filter === UserActivityType.PAYMENT
                        ? "e-filter m-active"
                        : "e-filter"
                    }
                  >
                    Payments
                  </div>
                  <div
                    onClick={() => {
                      new Segment().Track(
                        `activity_${new Segment().FormatEventName("Expenses")}`
                      );
                      this.props.activityStore!.filterData(
                        UserActivityType.EXPENSE
                      );
                    }}
                    className={
                      this.filter === UserActivityType.EXPENSE
                        ? "e-filter m-active"
                        : "e-filter"
                    }
                  >
                    Expenses
                  </div>
                </div>
              </div>
              <div className="e-container">
                {this.filter !== undefined &&
                this.filter !== UserActivityType.SEARCH
                  ? this.renderList(this.activitiesFiltered, true)
                  : this.renderList(this.activities)}
              </div>
            </>
          )}
        </div>
      </LayoutSidebar>
    );
  }
}

export default Activity;
