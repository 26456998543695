import { observable, action } from "mobx";
import { IUser } from "./kinsStore";
import Authentication from "../common/authentication";
import KinGraphQL from "../common/graphql";
import Notices from "../common/snackbars";
import Segment, { SegmentEvent } from "../common/segment";
import {
  queryAuthenticatedWithCache,
  queryAuthenticated
} from "../common/graphql-utils";

/**
 * GraphQL Query to check signup status
 * @export
 */
export const queryProfile = `
    query {
      me {
        id,
        name,
        hasSignedUp,
        image,
        email,
        inviteLinkUrl,
        receivingAccounts {
          id,
          accountName,
          accountNumber,
          sortCodeDetail {
            code,
            description,
            id
          }
          isDefault
        }
        defaultCurrency {
          isoCode
        }
      }
    }
`;

/**
 * GraphQL query to update the user name
 * @export
 * @param {string} name
 */
export const queryUpdateName = (name: string) => `
    mutation {
      userUpdate(name: "${name}") {
        command
      }
    }
`;

/**
 * Query to return a list of banks
 * @export
 */
export const querySortCodes = `
    query {
      sortCodes {
        id
        code
        description
      }
    }
`;

/**
 * Mutation query to remove a profile picture from the user
 * @export
 */
export const queryRemoveProfileImage = `
    mutation {
      userUpdateImage(imageUrl: null) {
        command
      }
    }
`;

/**
 *  Query to break new bank details for the user
 *
 * @export
 * @param {string} accountName
 * @param {string} accountNumber
 * @param {string} sortCodeId
 * @param {string} code
 * @param {boolean} isDefault
 */
export const queryCreateBank = (
  accountName: string,
  accountNumber: string,
  sortCodeId: string,
  code: string,
  isDefault: boolean
) => `
    mutation {
      bankDetailsCreate(
          accountName: "${accountName}"
          accountNumber: "${accountNumber}"
          sortCodeId: "${sortCodeId}"
          code: "${code}"
          isDefault: ${isDefault}
        ) {
        command
      }
    }
`;

/**
 * Query to update existing bank details by ID
 *
 * @export
 * @param {string} id
 * @param {string} accountName
 * @param {string} accountNumber
 * @param {string} sortCodeId
 * @param {string} code
 * @param {boolean} isDefault
 */
export const queryUpdateBank = (
  id: string,
  accountName: string,
  accountNumber: string,
  sortCodeId: string,
  code: string,
  isDefault: boolean
) => `
    mutation {
      bankDetailsUpdate(
          id:  "${id}"
          accountName: "${accountName}"
          accountNumber: "${accountNumber}"
          sortCodeId: "${sortCodeId}"
          code: "${code}"
          isDefault: ${isDefault}
        ) {
        command
      }
    }
`;

/**
 * Query to remove a bank entry by ID
 *
 * @export
 * @param {string} bankId
 */
export const queryRemoveBank = (bankId: string) => `
    mutation {
      bankDetailsRemove(id: "${bankId}") {
        command
      }
    }
`;

/**
 * Interface for bank list
 *
 * @export
 * @interface IBankSortCodes
 */
export interface IBankSortCodes {
  id: string;
  code: string;
  description: string;
}

/**
 * Interface for creating a bank
 *
 * @export
 * @interface IBankCreate
 */
export interface IBankCreate {
  accountName: string;
  accountNumber: string;
  sortCodeId: string;
  code: string;
  isDefault: boolean;
}

/**
 * Interface for updating a bank
 *
 * @export
 * @interface IBankUpdate
 * @extends {IBankCreate}
 */
export interface IBankUpdate extends IBankCreate {
  id: string;
}

/**
 * Interface bank details when returning a user's bank details
 *
 * @export
 * @interface IBankAccountCode
 */
export interface IBankAccountCode {
  id: string;
  code: string;
  description?: string;
}

/**
 * User bank account details interface
 *
 * @export
 * @interface IUserBankAccountDetails
 */
export interface IUserBankAccountDetails {
  id?: string;
  accountName: string;
  accountNumber: string;
  sortCodeDetail: IBankAccountCode;
  isDefault: boolean;
}

export interface IDefaultCurrency {
  isoCode: string;
}

/**
 * User bank account details list
 *
 * @export
 * @interface IProfileSettingsUser
 * @extends {IUser}
 */
export interface IProfileSettingsUser extends IUser {
  receivingAccounts: IUserBankAccountDetails[];
  defaultCurrency: IDefaultCurrency;
}

/**
 * Profile settings store state
 *
 * @export
 * @interface IProfileSettingsStore
 */
export interface IProfileSettingsStore {
  uploadingPhoto: boolean;
  user: IProfileSettingsUser;
  editing: boolean;
  editingImage: boolean;
  savingImage: boolean;
  name: string;
  editingName: boolean;
  savingName: boolean;
  bank: IUserBankAccountDetails;
  banks: IBankSortCodes[];
  editingBank: boolean;
  editingBankId: string;
  savingBank: boolean;
  banksLoading: boolean;
  loading: boolean;
  error: boolean;
  removePhoto(): void;
  uploadPhoto(file: any): void;
  fetchUser(): Promise<IProfileSettingsUser>;
  refreshUser(): Promise<IProfileSettingsUser>;
  fetchBankList(): void;
  updateUserName(name: string): void;
  createUserBank(
    accountName: string,
    accountNumber: string,
    sortCodeId: string,
    code: string,
    description: string,

    isDefault: boolean
  ): void;
  updateUserBank(
    id: string,
    accountName: string,
    accountNumber: string,
    sortCodeId: string,
    code: string,
    description: string,
    isDefault: boolean
  ): void;
  removeUserBank(id: string): void;
  setUser(user: IProfileSettingsUser): void;
  setBanksLoading(state: boolean): void;
  setLoading(state: boolean): void;
  setError(state: boolean): void;
  setEditing(state: boolean): void;
  setEditingImage(editing: boolean): void;
  setSavingImage(saving: boolean): void;
  setName(name: string): void;
  setEditingName(editing: boolean): void;
  setSavingName(saving: boolean): void;
  setBank(bank: IUserBankAccountDetails): void;
  setBankReset(): void;
  setBanks(banks: IBankSortCodes[]): void;
  setEditingBank(editing: boolean): void;
  setEditingBankId(id: string): void;
  setSavingBank(saving: boolean): void;
}

const defaultBankState = {
  accountName: "",
  accountNumber: "",
  sortCodeDetail: {
    code: "",
    id: ""
  },
  isDefault: false
};

export class ProfileSettingsStore implements IProfileSettingsStore {
  /**
   * UI state for uploading a photo
   *
   * @type {boolean}
   * @memberof ProfileSettinsStore
   */
  @observable uploadingPhoto: boolean;
  /**
   * User details
   *
   * @type {IProfileSettingsUser}
   * @memberof ProfileSettinsStore
   */
  @observable.shallow user: IProfileSettingsUser;
  /**
   * UI state for editing settings
   *
   * @type {boolean}
   * @memberof ProfileSettinsStore
   */
  @observable editing: boolean;
  /**
   * UI state for editing an image
   *
   * @type {boolean}
   * @memberof ProfileSettinsStore
   */
  @observable editingImage: boolean;
  /**
   * UI state for uploading an image
   *
   * @type {boolean}
   * @memberof ProfileSettinsStore
   */
  @observable savingImage: boolean;
  /**
   * User name
   *
   * @type {string}
   * @memberof ProfileSettinsStore
   */
  @observable name: string;
  /**
   * UI state for editing a name
   *
   * @type {boolean}
   * @memberof ProfileSettinsStore
   */
  @observable editingName: boolean;
  /**
   * UI state for updating/saving a name
   *
   * @type {boolean}
   * @memberof ProfileSettinsStore
   */
  @observable savingName: boolean;
  /**
   * User active bank
   *
   * @type {IUserBankAccountDetails}
   * @memberof ProfileSettinsStore
   */
  @observable bank: IUserBankAccountDetails;
  /**
   * Downloaded bank list
   *
   * @type {IBankSortCodes[]}
   * @memberof ProfileSettinsStore
   */
  @observable banks: IBankSortCodes[];
  /**
   * UI state for editing a bank
   *
   * @type {boolean}
   * @memberof ProfileSettinsStore
   */
  @observable editingBank: boolean;
  /**
   * Currently editing bank's ID
   *
   * @type {string}
   * @memberof ProfileSettinsStore
   */
  @observable editingBankId: string;
  /**
   * UI state for saving bank details
   *
   * @type {boolean}
   * @memberof ProfileSettinsStore
   */
  @observable savingBank: boolean;
  /**
   * UI state for downloading the latest bank list
   *
   * @type {boolean}
   * @memberof ProfileSettinsStore
   */
  @observable banksLoading: boolean;
  /**
   * UI state for loading
   *
   * @type {boolean}
   * @memberof ProfileSettinsStore
   */
  @observable loading: boolean;
  /**
   * UI state for errors
   *
   * @type {boolean}
   * @memberof ProfileSettinsStore
   */
  @observable error: boolean;

  private _userPromiseStore: Promise<IProfileSettingsUser> | null;

  /**
   *Creates an instance of ProfileSettinsStore.
   * @memberof ProfileSettinsStore
   */
  constructor() {
    /**
     * Setting default values
     */
    this.uploadingPhoto = false;
    this.editing = false;
    this.editingImage = false;
    this.savingImage = false;
    this.editingName = false;
    this.name = "";
    this.savingName = false;
    this.bank = {
      ...defaultBankState
    };
    this.banks = [];
    this.editingBank = false;
    this.editingBankId = "";
    this.savingBank = false;
    this.banksLoading = false;
    this.loading = true;
    this.error = false;
    this.user = {
      name: "",
      id: "",
      image: "",
      email: "",
      hasSignedUp: false,
      receivingAccounts: [],
      inviteLinkUrl: "",
      defaultCurrency: {
        isoCode: ""
      }
    };
    this._userPromiseStore = null;
  }

  /**
   * Uploading new profile picture
   *
   * @param {*} file
   * @memberof ProfileSettinsStore
   */
  uploadPhoto(file: any): void {
    // Update UI state
    this.setUploadingPhoto(true);
    new Authentication().getToken().then(token => {
      new KinGraphQL().uploadUserPhoto(file, this.user.id, token).subscribe({
        next: result => {
          if (result.data.errors) {
            // Trigger error
            new Notices().GlobalTrigger(() => {
              this.setError(!this.error);
            });
            // Reset UI state
            this.setUploadingPhoto(false);
          } else {
            // Fetch updated user data to show new profile picture

            this.refreshUser().then(() => {
              // Update UI state
              this.setUploadingPhoto(false);
              // Update UI state
              this.setEditingImage(false);
            });
          }
        },
        error: (err: any) => {
          // Update UI state
          this.setUploadingPhoto(false);
          // Trigger error
          new Notices().GlobalTrigger(() => {
            this.setError(!this.error);
          });
          console.error(err);
        }
      });
    });
  }

  /**
   * Removing current profile picture
   *
   * @memberof ProfileSettinsStore
   */
  removePhoto(): void {
    // Update UI state
    this.setUploadingPhoto(true);
    queryAuthenticated(queryRemoveProfileImage).then(
      () => {
        // Fetch updated user data to show new profile picture
        this.refreshUser().then(() => {
          // Update UI state
          this.setUploadingPhoto(false);
          // Update UI state
          this.setEditingImage(false);
        });
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        this.setUploadingPhoto(false);
      }
    );
  }

  /**
   * Fetch user data from server
   *
   * @returns Promise<IProfileSettingsUser>
   * @memberof ProfileSettinsStore
   *
   */

  fetchUser(): Promise<IProfileSettingsUser> {
    if (this._userPromiseStore !== null) return this._userPromiseStore;
    this._userPromiseStore = new Promise((resolve, error) => {
      this.setLoading(true);
      const observable = queryAuthenticatedWithCache(queryProfile);
      observable.subscribe({
        next: result => {
          // Set user data to results
          this.setUser(result.data.data.me);
          resolve(result.data.data.me);
          // Update UI state
          this.setLoading(false);
        },
        error: () => {
          // Trigger error
          new Notices().GlobalTrigger(() => {
            this.setError(!this.error);
          });
          error(this.error);
        }
      });
    });
    return this._userPromiseStore;
  }

  refreshUser(): Promise<IProfileSettingsUser> {
    this._userPromiseStore = null;
    return this.fetchUser();
  }

  /**
   * Update user's name
   *
   * @param {string} name
   * @memberof ProfileSettinsStore
   */
  updateUserName(name: string): void {
    // Update UI state
    this.setSavingName(true);
    queryAuthenticated(queryUpdateName(name)).then(
      () => {
        // Update UI state
        this.setEditingName(false);
        this.setSavingName(false);
        // Update user state data with new name
        this.setUser({
          ...this.user,
          name: name
        });
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        // Update UI state
        this.setEditingName(false);
        this.setSavingName(false);
      }
    );
  }

  /**
   * Fetch stored list of banks
   *
   * @memberof ProfileSettinsStore
   */
  fetchStoredBankList(): void {
    // Update UI state
    this.setBanksLoading(true);
    // Check for cached data first
    new KinGraphQL().fetchStoredRequest(querySortCodes).then(value => {
      if (value !== null) {
        // Update UI state
        this.setBanksLoading(false);
        // Update bank list to stored data
        this.setBanks(value.data.data.sortCodes);
      }
    });
  }

  /**
   * Get a list of banks from the server
   *
   * @memberof ProfileSettinsStore
   */
  fetchBankList() {
    // Update UI state
    this.setBanksLoading(true);
    new KinGraphQL().queryFetch(querySortCodes).subscribe({
      next: result => {
        // Update UI state
        this.setBanksLoading(false);
        // Update bank list to latest request data
        this.setBanks(result.data.data.sortCodes);
      },
      error: () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
      }
    });
  }

  /**
   * Create new bank details entry for user
   *
   * @param {string} accountName
   * @param {string} accountNumber
   * @param {string} sortCodeId
   * @param {string} code
   * @param {string} description
   * @param {boolean} isDefault
   * @memberof ProfileSettinsStore
   */
  createUserBank(
    accountName: string,
    accountNumber: string,
    sortCodeId: string,
    code: string,
    description: string,
    isDefault: boolean
  ): void {
    // Update UI state
    this.setSavingBank(true);
    queryAuthenticated(
      queryCreateBank(accountName, accountNumber, sortCodeId, code, isDefault)
    ).then(
      () => {
        // If the result was successful but contains an error
        new Segment().Track(SegmentEvent.ADD_BANK);
        // Set bank details to local variable for re-usability
        const bank = {
          accountName: accountName,
          accountNumber: accountNumber,
          sortCodeDetail: {
            code: code,
            id: sortCodeId,
            description: description
          },
          isDefault: isDefault
        };

        // Update UI state
        this.setEditingBank(false);
        // Update UI state
        this.setSavingBank(false);
        // Return array of all banks that are not the previously active bank
        let receivingAccounts: IUserBankAccountDetails[] = this.user.receivingAccounts.filter(
          bank => {
            return !bank.isDefault;
          }
        );
        // Add new default bank to the list
        receivingAccounts.push(bank);
        // Set user with updated bank list
        this.setUser({
          ...this.user,
          receivingAccounts: receivingAccounts
        });
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        this.setEditingBank(false);
        this.setSavingBank(false);
      }
    );
  }

  /**
   * Update user bank details
   *
   * @param {string} id
   * @param {string} accountName
   * @param {string} accountNumber
   * @param {string} sortCodeId
   * @param {string} code
   * @param {string} description
   * @param {boolean} isDefault
   * @memberof ProfileSettinsStore
   */
  updateUserBank(
    id: string,
    accountName: string,
    accountNumber: string,
    sortCodeId: string,
    code: string,
    description: string,
    isDefault: boolean
  ): void {
    // Update UI state
    this.setSavingBank(true);
    queryAuthenticated(
      queryUpdateBank(
        id,
        accountName,
        accountNumber,
        sortCodeId,
        code,
        isDefault
      )
    ).then(
      () => {
        // If the result was successful but contains an error

        // Set default bank details
        let bank: IUserBankAccountDetails = {
          id: id,
          accountName: accountName,
          accountNumber: accountNumber,
          sortCodeDetail: {
            code: code,
            id: sortCodeId,
            description: description
          },
          isDefault: isDefault
        };

        // Update UI state
        this.setEditingBank(false);
        // Update UI state
        this.setSavingBank(false);
        // Look for existing bank details to update locally by ID
        let receivingAccount: IUserBankAccountDetails[] = this.user.receivingAccounts.map(
          account => {
            if (account.id === id) {
              account = bank;
            }
            return account;
          }
        );
        // Set user with updated bank list
        this.setUser({
          ...this.user,
          receivingAccounts: [...receivingAccount]
        });
      },
      () => {
        // Trigger error
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
        this.setSavingBank(false);
        this.setEditingBank(false);
      }
    );
  }

  /**
   * Request to remove a bank from user
   *
   * @param {string} id
   * @memberof ProfileSettinsStore
   */
  removeUserBank(id: string): void {
    this.setSavingBank(true);
    queryAuthenticated(queryRemoveBank(id)).then(
      () => {
        this.setEditingBank(false);
        this.refreshUser().then(() => {
          this.setSavingBank(false);
        });
      },
      () => {
        this.setEditingBank(false);
        this.setSavingBank(false);
        new Notices().GlobalTrigger(() => {
          this.setError(!this.error);
        });
      }
    );
  }

  /**
   * Setting state values
   */
  @action setUploadingPhoto(state: boolean): void {
    this.uploadingPhoto = state;
  }
  @action setEditing(editing: boolean): void {
    this.editing = editing;
  }
  @action setEditingImage(editing: boolean): void {
    // Flag global editing state as well
    this.setEditing(editing);
    this.editingImage = editing;
  }
  @action setSavingImage(saving: boolean): void {
    this.savingImage = saving;
  }
  @action setName(name: string): void {
    this.name = name;
  }
  @action setEditingName(editing: boolean): void {
    // Flag global editing state as well
    this.setEditing(editing);
    this.editingName = editing;
  }
  @action setSavingName(saving: boolean): void {
    this.savingName = saving;
  }
  @action setBank(bank: IUserBankAccountDetails): void {
    this.bank = bank;
  }
  @action setBankReset(): void {
    this.bank = defaultBankState;
  }
  @action setBanks(banks: IBankSortCodes[]): void {
    this.banks = banks;
  }
  @action setEditingBank(editing: boolean): void {
    // Flag global editing state as well
    this.setEditing(editing);
    this.editingBank = editing;
  }
  @action setEditingBankId(id: string): void {
    this.editingBankId = id;
  }
  @action setSavingBank(saving: boolean): void {
    this.savingBank = saving;
  }
  @action setBanksLoading(loading: boolean): void {
    this.banksLoading = loading;
  }
  @action setLoading(loading: boolean): void {
    this.loading = loading;
  }
  @action setError(error: boolean): void {
    this.error = error;
  }
  @action setUser(user: IProfileSettingsUser): void {
    this.setName(user.name);
    // Update active bank with results from user request
    let banks: IUserBankAccountDetails[] = user.receivingAccounts.filter(
      bank => bank.isDefault
    );
    if (banks.length === 0) {
      // Set bank to default bank incase there last bank was just removed
      this.setBank({ ...defaultBankState });
    } else {
      // Set to result with 'isDefault' flag
      this.setBank({ ...banks[0] });
    }
    this.user = user;
  }
}
