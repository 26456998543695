import * as firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig, actionCodeSettings } from "../firebase/firebase";
import { history } from "./history";
import UrlHelper from "./url-helper";
import KinGraphQL from "./graphql";
import Segment, { SegmentEvent } from "./segment";
import jwtDecode from "jwt-decode";
import moment from "moment";

interface FirebaseToken {
  aud: string; //Audience
  auth_time: number; //Authentication time
  email: string;
  email_verified: boolean;
  exp: number; //Expiration date
  firebase: any;
  iat: number; //Issued-at time
  iss: string; //Issuer
  name: string;
  picture: string;
  sub: string; //Subject
  user_id: string;
}

interface FirebaseLocallyStoredToken {
  issued: number;
  expires: number;
}

/**
 * Authentication class to handle the signed In state and refreshing tokens
 *
 * @class Authentication
 */
class Authentication {
  /**
   * Firebase token variable
   *
   * @private
   * @type {(string | null)}
   * @memberof Authentication
   */
  private token: string | null;
  /**
   * User signed in state variable
   *
   * @private
   * @type {boolean}
   * @memberof Authentication
   */
  private isAuthed: boolean;

  /**
   * Creates an instance of Authentication.
   * @memberof Authentication
   */
  constructor() {
    this.token = "";
    this.isAuthed = false;
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  /**
   * Store firebase token object
   *
   * @param {string} token
   * @memberof Authentication
   */
  storeTokenExpiry(token: string): void {
    let tokenObject: FirebaseToken = jwtDecode(token);
    let tokenStoredObject: FirebaseLocallyStoredToken = {
      issued: tokenObject.iat,
      expires: tokenObject.exp
    };
    this.storeTokenInCookie(token);
    window.localStorage.setItem("kin_token", JSON.stringify(tokenStoredObject));
  }

  /**
   * Since the local storage can be accessed by anyone,
   * the token is stored as a cookie to prevent XSS attackes.
   * It's more secure because only the Kin domain can access it.
   *
   * @memberof Authentication
   */
  storeTokenInCookie(token: string): void {
    const date = new Date();
    // Expire in 1 hour
    date.setTime(date.getTime() + 1 * 60 * 60 * 1000);
    document.cookie = `kin_token=${token}; expires=${date.toUTCString()}; path=/;`;
  }

  /**
   * Remove the token cookie
   *
   * @memberof Authentication
   */
  removeTokenInCookie(): void {
    document.cookie = `kin_token=; Max-Age=-99999999; path=/;`;
  }

  /**
   * Fetch the kin token in cookies
   *
   * @returns
   * @memberof Authentication
   */
  fetchTokenInCookie() {
    const name = "kin_token=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
  }

  /**
   * Check if the stored token expiry date has expired
   *
   * @returns {boolean}
   * @memberof Authentication
   */
  hasTokenExpiredLocally(): boolean {
    let storedToken: string | null = window.localStorage.getItem("kin_token");
    let tokenStoredObject: FirebaseLocallyStoredToken;
    if (storedToken !== null) {
      tokenStoredObject = JSON.parse(storedToken);
      let hasExpired: boolean = moment
        .unix(tokenStoredObject!.expires)
        .isBefore(moment());
      if (hasExpired) {
        this.removeTokenInCookie();
      }
      return hasExpired;
    }
    this.removeTokenInCookie();
    return true;
  }

  /**
   * Check if still signed in with Firebase
   *
   * @param {*} signedIn
   * @param {*} [signedOut=null]
   * @memberof Authentication
   */
  isSignedIn(signedInCallback: any) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        signedInCallback(user);
      } else {
        this.isSignedOut();
      }
    });
  }

  /**
   * Check if still signed in with Firebase
   *
   * @memberof Authentication
   */
  isSignedOut() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        return false;
      } else {
        this.signOut();
      }
    });
  }

  /**
   * Fetches the token from Firebase and makes sure it is refreshed
   * Also sets the is authenticated variable flag
   *
   * @returns {Promise<any>}
   * @memberof Authentication
   */
  fetchToken(): Promise<any> {
    return new Promise(resolve => {
      this.isSignedIn((user: firebase.User) => {
        user.getIdToken(/* forceRefresh */ true).then((idToken: any) => {
          if (idToken !== null) {
            this.storeTokenExpiry(idToken);
            this.isAuthed = true;
          } else {
            this.isAuthed = false;
          }
          resolve(idToken);
        });
      });
    });
  }

  /**
   * Returns the latest token
   *
   * @returns {Promise<any>}
   * @memberof Authentication
   */
  public getToken(): Promise<any> {
    if (this.hasTokenExpiredLocally()) {
      return this.fetchToken().then(token => {
        this.token = token;
        return this.token;
      });
    } else {
      return new Promise(resolve => {
        resolve(this.fetchTokenInCookie());
      });
    }
  }

  /**
   * Returns Is Authenticated status
   *
   * @returns
   * @memberof Authentication
   */
  public getIsAuthed() {
    return this.isAuthed;
  }

  /**
   * Method for sending the sign up link with email through Firebase
   *
   * @memberof Authentication
   */
  emailSignUp(
    signUpState: any,
    signUpSuccessState: any,
    email: string,
    inviteId: string,
    appId: string | null,
    postAuthRedirectUri: string | null,
    errorState: any
  ) {
    // Set state first to queue loading animations
    signUpState();
    // Initiate Firebase email link auth
    // Clone settings and update redirect URL to include invite ID
    const actionCodeSettingsInviteID = {
      ...actionCodeSettings,
      url: `${actionCodeSettings.url}/${inviteId}`
    };

    if (!!appId) {
      actionCodeSettingsInviteID.url = UrlHelper.appendQueryString(
        actionCodeSettingsInviteID.url,
        UrlHelper.QUERYSTRING_KEY_APP,
        appId
      );
    }

    if (!!postAuthRedirectUri) {
      actionCodeSettingsInviteID.url = UrlHelper.appendQueryString(
        actionCodeSettingsInviteID.url,
        UrlHelper.QUERYSTRING_KEY_APP_RETURNTO,
        postAuthRedirectUri
      );
    }

    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettingsInviteID)
      .then(() => {
        // Email was sent successfully
        signUpSuccessState();
        // Store the email for later use by Firebase
        window.localStorage.setItem("kin_emailForSignIn", email);
      })
      .catch((error: any) => {
        // Email failed to send
        // Restore state to stop loading animations
        errorState();
        console.error("Unable to send email", error);
      });
  }

  /**
   * Method for authorizing the user with Google Auth
   *
   * @memberof Authentication
   */
  googleSignUp(signUpState: any, errorState: any, redirect: any) {
    // Set state first to queue loading animations
    signUpState();

    // Initiate Google Auth provider
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result: any) => {
        this.socialAuthProviderSignUp(result, redirect);
      })
      .catch((error: any) => {
        // Provider failed to authorize
        // Restore state to stop loading animations
        this.failedProviderAuth(errorState, error);
      });
  }

  /**
   * Method for authorizing the user with Facebook Auth
   *
   * @memberof Authentication
   */
  facebookSignUp(signUpState: any, errorState: any, redirect: any) {
    // Set state first to queue loading animations
    signUpState();

    // Initiate Facebook Auth provider
    let provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result: any) => {
        this.socialAuthProviderSignUp(result, redirect);
      })
      .catch((error: any) => {
        // Provider failed to authorize
        // Restore state to stop loading animations
        this.failedProviderAuth(errorState, error);
      });
  }

  /**
   * Handling errors from failes provider authentication
   *
   * @param {*} error
   * @memberof Authentication
   */
  failedProviderAuth(stateChange: any, error: any) {
    // For updating the state
    stateChange();
    console.error("Unable to authorize user", error);
  }

  /**
   * Handling successful provider authentication
   *
   * @param {*} result
   * @memberof Authentication
   */
  socialAuthProviderSignUp(result: any, redirect: any) {
    if (result.user !== null) {
      result.user
        .getIdToken(/* forceRefresh */ true)
        .then((tokenId: string) => {
          // The signed-in user info.
          let name = result.user.displayName;
          // Store name
          window.localStorage.setItem("kin_name", name);
          redirect();
        })
        .catch((error: any) => {
          // Handle error
          console.error(error);
        });
    }
  }

  /**
   * Signing out a user and clearing their data from localStorage
   *
   * @memberof Authentication
   */
  signOut(signOutActioned: boolean = false) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Clear user storage data
        new KinGraphQL().clearCache();

        //add the app id and return-to if the user was redirected.
        let url = "/";
        const urlParams = UrlHelper.getAppParametersFromCurrentUrl();

        if (!!urlParams.AppId) {
          url = UrlHelper.appendQueryString(
            url,
            UrlHelper.QUERYSTRING_KEY_APP,
            urlParams.AppId
          );
        }

        if (!!urlParams.Redirect) {
          url = UrlHelper.appendQueryString(
            url,
            UrlHelper.QUERYSTRING_KEY_APP_RETURNTO,
            urlParams.Redirect
          );
        }

        // Only fire signout event when button was clicked
        if (signOutActioned) {
          // Fire sign out event
          new Segment().Track(SegmentEvent.SIGN_OUT);
        }

        if (history.location.search !== undefined) {
          // Sign-out successful with params.
          history.push(url + history.location.search);
        } else {
          // Sign-out successful.
          history.push(url);
        }
      })
      .catch(error => {
        // An error happened.
        console.error("Unable to sign out");
      });
  }
}

export default Authentication;
