import React from "react";
import { isMobile, isTablet } from "react-device-detect";

import "./page-modal.scss";
import menuIcon from "../images/icon-burger.svg";
import iconSettings from "../images/icon-settings.svg";
import Avatar, { IAvatarProps } from "../components/avatar/avatar";
import { inject, observer } from "mobx-react";
import { ILayoutSidebarStore } from "../stores/layoutSidebarStore";
import { computed } from "mobx";

import { IKinStore } from "../stores/kinStore";
import AvatarList from "../components/avatar-list/avatar-list";
import Button, { ButtonColors } from "../components/button/button";
import { IProfileSettingsStore } from "../stores/profileSettingsStore";
import Notices from "../common/snackbars";
import { Clipboard } from "ts-clipboard";
import {
  IPeopleBalanceSummaryWithIsoCodePair,
  IAmount
} from "../stores/peopleStore";
import { ICurrenciesStore } from "../stores/currenciesStore";

interface IPageModalState {
  active: boolean;
}

interface IPageModalProps {
  kinStore?: IKinStore;
}
/**
 * Page modal class that turns a page into a modal for mobile devices
 *
 * @class PageModal
 * @extends {React.Component<IPageModalState, {}>}
 */

@inject("kinStore")
class PageModal extends React.Component<IPageModalProps, IPageModalState> {
  /**
   *Creates an instance of PageModal.
   * @param {{}} props
   * @memberof PageModal
   */
  constructor(props: {}) {
    super(props);
    // Set initial state
    this.state = {
      active: isMobile && !isTablet
    };
  }
  /**
   * Set active when mobile device
   *
   * @memberof PageModal
   */
  componentDidMount() {
    if (isMobile) {
      document.getElementsByTagName("body")[0].classList.add("g-hide-widget");
    }
    this.setState({
      active: isMobile && !isTablet
    });
    this.fixWindowSize();
  }

  componentDidUpdate() {
    this.fixWindowSize();
  }
  /**
   * Set inactive when unmounting component
   *
   * @memberof PageModal
   */
  componentWillUnmount() {
    if (isMobile) {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("g-hide-widget");
    }
    this.setState({
      active: false
    });
    this.fixWindowSize();
  }

  fixWindowSize = () => {
    if (this.props.kinStore!.expenseModal) {
      document.getElementsByTagName("html")[0].style.overflowY = "hidden";
      document.getElementsByTagName("body")[0].style.overflowY = "hidden";
      document.getElementById("root")!.style.overflowY = "hidden";
    } else {
      document.getElementsByTagName("html")[0].style.overflowY = "initial";
      document.getElementsByTagName("body")[0].style.overflowY = "initial";
      document.getElementById("root")!.style.overflowY = "initial";
    }
  };

  /**
   * Default render method
   *
   * @returns
   * @memberof PageModal
   */
  render() {
    let modalClasses: string = "b-page-modal";
    if (this.state.active) {
      modalClasses += " m-open";
    }
    return <div className={modalClasses}>{this.props.children}</div>;
  }
}

/**
 * Data object passed to header to determine person actions
 *
 * @export
 * @interface IPageModalHeadPersonAction
 */
export interface IPageModalHeadPersonAction {
  balances: IPeopleBalanceSummaryWithIsoCodePair;
  payClick: () => void;
  nudgeClick: () => void;
  loading: boolean;
}

export interface IPageModalHeadPersonActionAndProfile
  extends IPageModalHeadPersonAction {
  copyInviteLink: () => void;
  status: string;
  image: string;
  name: string;
}

/**
 * Page modal header props for displaying the title
 *
 * @interface IPageModalHeadProps
 */
interface IPageModalHeadProps {
  title: string;
  image?: string | null;
  membersList?: IAvatarProps[];
  layoutSidebarStore?: ILayoutSidebarStore;
  profileSettingsStore?: IProfileSettingsStore;
  kinStore?: IKinStore;
  showSettings: boolean;
  personAction?: IPageModalHeadPersonActionAndProfile;
  className?: string;
  currenciesStore?: ICurrenciesStore;
}

@inject(
  "layoutSidebarStore",
  "kinStore",
  "profileSettingsStore",
  "currenciesStore"
)
@observer
/**
 * Page modal header component
 *
 * @class PageModalHead
 * @extends {React.Component<IPageModalHeadProps, {}>}
 */
class PageModalHead extends React.Component<IPageModalHeadProps, {}> {
  @computed get kinSettings(): boolean {
    return this.props.layoutSidebarStore!.kinSettings;
  }
  @computed get groupId(): string {
    return this.props.kinStore!.groupId;
  }
  @computed get noticeState(): boolean {
    return this.props.layoutSidebarStore!.noticeState;
  }
  /**
   * Default props
   *
   * @static
   * @memberof PageModalHead
   */
  static defaultProps = {
    showSettings: true
  };

  /**
   * Adds a back button to close the modal
   *
   * @memberof PageModalHead
   */
  openMenu() {
    this.props.layoutSidebarStore!.setMenuPanel(true);
  }

  /**
   * Toggle settings panel for a kin
   *
   * @memberof PageModalHead
   */
  toggleKinSettings() {
    this.props.layoutSidebarStore!.setKinSettings(!this.kinSettings);
    document
      .getElementsByTagName("body")[0]
      .classList.toggle("g-kin-settings-panel");
  }

  /**
   * Render balance amount for person
   *
   * @param {number} personBalance
   * @memberof PageModalHead
   */
  renderPersonBalance(personBalance: IAmount) {
    if (personBalance.cents < 0) {
      return (
        <div className="e-head-title-balance">
          you owe{" "}
          <span className="m-owe">
            {this.props.currenciesStore!.formatAmountWithSymbol(personBalance)}
          </span>
        </div>
      );
    }
    if (personBalance.cents === 0) {
      return null;
    }
    return (
      <div className="e-head-title-balance">
        <span className="m-owed">
          {this.props.currenciesStore!.formatAmountWithSymbol(personBalance)}
        </span>{" "}
        is owed to you
      </div>
    );
  }

  renderPersonAction(personAction: IPageModalHeadPersonActionAndProfile) {
    const user = this.props.profileSettingsStore!.user;
    if (
      personAction.status === "Invited" ||
      personAction.status === "NotInvited"
    ) {
      return (
        <div
          className={`e-col g-padding-left ${
            isMobile ? "g-padding-right" : ""
          }`}
        >
          <Button
            outline={true}
            loading={false}
            disabled={false}
            small={true}
            className="e-invite-card-cta-action"
            color={ButtonColors.Black}
            onClick={() => {
              Clipboard.copy(`${user.inviteLinkUrl}`);
              new Notices().GlobalTrigger(() => {
                this.props.layoutSidebarStore!.setNotice(
                  !this.props.layoutSidebarStore!.noticeState,
                  "Copied to clipboard."
                );
              }, true);
            }}
          >
            Reinvite
          </Button>
        </div>
      );
    } else if (this.props.personAction?.image) {
      return (
        <div className="e-col g-padding-left e-balance-summary-person-avatar-container">
          <div className="e-balance-summary-person-avatar">
            <Avatar
              size={120}
              name={this.props.personAction!.name}
              initial={this.props.personAction!.name.substr(0, 1)}
              image={this.props.personAction!.image}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof PageModalHead
   */
  render() {
    let headClasses: string = "e-head g-box-shadow";
    if (this.props.className) {
      headClasses += ` ${this.props.className}`;
    }
    return (
      <div className={headClasses}>
        <div className="e-title e-container">
          {isMobile && !isTablet ? (
            <img
              onClick={() => this.openMenu()}
              className="e-menu-icon"
              src={menuIcon}
              alt="Open Menu"
            />
          ) : null}
          {!isMobile || isTablet ? (
            <div className="e-kin b-grid m-align-center e-col m-auto">
              {!this.props.personAction && this.props.image !== null ? (
                <div className="e-col">
                  <Avatar
                    size={48}
                    borderWidth={0}
                    name={this.props.title}
                    image={this.props.image}
                  />
                </div>
              ) : null}
              <div
                className={`e-col m-auto e-head-title ${
                  this.props.personAction ? "" : "g-padding-left"
                }`}
              >
                {this.props.title}
              </div>
              {this.props.personAction
                ? this.renderPersonAction(this.props.personAction)
                : null}
            </div>
          ) : (
            <div className="e-kin b-grid m-align-center">
              <div className="e-col e-kin-flex">
                {!this.props.personAction && this.props.image !== null ? (
                  <Avatar
                    size={25}
                    borderWidth={0}
                    name={this.props.title}
                    image={this.props.image}
                  />
                ) : null}
                <div className="e-col e-kin-title">{this.props.title}</div>
                {this.props.personAction
                  ? this.renderPersonAction(this.props.personAction)
                  : null}
              </div>
            </div>
          )}
          {this.props.membersList || this.props.showSettings ? (
            <div className="b-grid">
              {this.props.membersList ? (
                <div className="e-avatar-list">
                  <AvatarList
                    maxAvatars={4}
                    spaced={true}
                    initialSize={12}
                    borderWidth={0}
                    users={this.props.membersList}
                  />
                </div>
              ) : null}
              <img
                className={
                  this.props.showSettings
                    ? "e-settings-icon"
                    : "e-settings-icon m-inactive"
                }
                onClick={() =>
                  this.props.showSettings ? this.toggleKinSettings() : {}
                }
                src={iconSettings}
                alt={`${this.props.title} Settings`}
              />
            </div>
          ) : null}
        </div>
        {this.props.children}
      </div>
    );
  }
}

/**
 * Page modal body props for adding classes
 *
 * @interface IPageModalBodyProps
 */
interface IPageModalBodyProps {
  id?: string;
  className?: string;
}

/**
 * Page modal body component with auto scroll
 *
 * @class PageModalBody
 * @extends {React.Component<IPageModalBodyProps, {}>}
 */
class PageModalBody extends React.Component<IPageModalBodyProps, {}> {
  /**
   * Default render method
   *
   * @returns
   * @memberof PageModalBody
   */
  render() {
    let bodyClasses: string = "e-body";
    if (this.props.className) {
      bodyClasses += ` ${this.props.className}`;
    }
    return (
      <div id={this.props.id} className={bodyClasses}>
        {this.props.children}
      </div>
    );
  }
}

export { PageModal, PageModalHead, PageModalBody };
