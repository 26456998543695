import { observable, action } from "mobx";

/**
 * GraphQL Query to check signup status
 * @export
 */
export const queryHasSignedUp = () => `
    query {
        me {
            hasSignedUp,
            name,
            image,
            email
        }
    }
`;

/**
 * GraphQL Query to sign up user
 * @export
 * @param {string} id Invite ID
 * @param {string} name User name to sign up with
 */
export const queryToSignUp = (id: string, name: string) => `
    mutation {
        signUpAuthenticatedUser(inviteId: "${id}", name: "${name}") {
            id
        }
    }
`;

/**
 * GraphQL Query to accept connection invite
 * @export
 * @param {string} id Invite ID
 */
export const queryConnectionInvite = (id: string) => `
    mutation {
        acceptConnectionInvite(inviteId: "${id}") {
            id
        }
    }
`;

/**
 * GraphQL Query to accept group invite
 * @export
 * @param {string} id Invite ID
 */
export const queryGroupInvite = (id: string) => `
    mutation {
        acceptGroupInvite(inviteId: "${id}") {
            id
        }
    }
`;

/**
 * Profile setup state
 *
 * @export
 * @interface IProfileSetupStore
 */
export interface IProfileSetupStore {
  loading: boolean;
  name: string;
  inviteId: string;
  token?: string;
  signingUpUser: boolean;
  signingUpError: boolean;
  bypassInvite: boolean;
  errorState: boolean;
  setLoading(loading: boolean): void;
  setName(name: string): void;
  setInviteId(inviteId: string): void;
  setToken(token?: string): void;
  setSigningUpUser(signingUpUser: boolean): void;
  setSigningUpError(signingUpError: boolean): void;
  setBypassInvite(bypassInvite: boolean): void;
  setErrorState(errorState: boolean): void;
}

export class ProfileSetupStore implements IProfileSetupStore {
  /**
   * UI loading state
   *
   * @type {boolean}
   * @memberof ProfileSetupStore
   */
  @observable loading: boolean;
  /**
   * User name
   *
   * @type {string}
   * @memberof ProfileSetupStore
   */
  @observable name: string;
  /**
   * Invite ID used to sign up/in
   *
   * @type {string}
   * @memberof ProfileSetupStore
   */
  @observable inviteId: string;
  /**
   * User access firebase token
   *
   * @type {string}
   * @memberof ProfileSetupStore
   */
  @observable token?: string;
  /**
   * State when code is busy signing up user
   *
   * @type {boolean}
   * @memberof ProfileSetupStore
   */
  @observable signingUpUser: boolean;
  /**
   * UI state for any errors signing up a user
   *
   * @type {boolean}
   * @memberof ProfileSetupStore
   */
  @observable signingUpError: boolean;
  /**
   * If a invite sign up should be bypassed (when only signing in not accepting an invite)
   *
   * @type {boolean}
   * @memberof ProfileSetupStore
   */
  @observable bypassInvite: boolean;
  /**
   * UI error state
   *
   * @type {boolean}
   * @memberof ProfileSetupStore
   */
  @observable errorState: boolean;

  /**
   *Creates an instance of ProfileSetupStore.
   * @memberof ProfileSetupStore
   */
  constructor() {
    /**
     * Setting default values
     */
    this.loading = true;
    this.name = "";
    this.inviteId = "";
    this.signingUpUser = false;
    this.signingUpError = false;
    this.bypassInvite = false;
    this.errorState = false;
  }

  /**
   * Setting state values
   */
  @action setLoading(loading: boolean): void {
    this.loading = loading;
  }
  @action setName(name: string): void {
    this.name = name;
  }
  @action setInviteId(inviteId: string): void {
    this.inviteId = inviteId;
  }
  @action setToken(token?: string): void {
    this.token = token;
  }
  @action setSigningUpUser(signingUpUser: boolean): void {
    this.signingUpUser = signingUpUser;
  }
  @action setSigningUpError(signingUpError: boolean): void {
    this.signingUpError = signingUpError;
  }
  @action setBypassInvite(bypassInvite: boolean): void {
    this.bypassInvite = bypassInvite;
  }
  @action setErrorState(errorState: boolean): void {
    this.errorState = errorState;
  }
}
