import React from "react";

import "./toggle.scss";

interface IToggleProps {
  style?: any | {};
  className?: string;
  options: string[];
  active?: string;
  future: boolean;
  onChange: (active: string) => void;
}

class Toggle extends React.Component<IToggleProps, {}> {
  render() {
    let toggleClasses: string = "b-toggle";
    let optionClasses: string = "e-option";
    let optionFutureClasses: string = "";
    if (this.props.className) {
      toggleClasses += ` ${this.props.className}`;
    }
    if (this.props.future) {
      optionFutureClasses = " m-future";
    }
    return (
      <div className={toggleClasses} style={this.props.style}>
        {this.props.options.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => this.props.onChange(item)}
              className={
                this.props.active === item
                  ? `${optionClasses} ${optionFutureClasses} m-active`
                  : optionClasses
              }
            >
              {item}
            </div>
          );
        })}
      </div>
    );
  }
}

export default Toggle;
