import React from "react";
import "./avatar.scss";

/**
 * Avatar props
 *
 * @export
 * @interface IAvatarProps
 */
export interface IAvatarProps {
  size: number;
  initial?: string;
  initialSize?: number;
  image?: string;
  name: string;
  moreCount?: number;
  isMore?: boolean;
  borderWidth?: number;
  className?: string;
  transparent?: boolean;
  onClick?: () => void;
}

/**
 * Avatar component for display the circular avatar icon for profiles
 *
 * @param {*} props
 * @returns
 */
const Avatar: React.FC<IAvatarProps> = props => {
  // Changing the classes depending on the avatar properties.
  let initialLetter: string = "";
  let avatarClasses: string = "b-avatar";

  if (props.className) {
    avatarClasses += ` ${props.className}`;
  }

  if (props.transparent) {
    avatarClasses += " m-transparent";
  }

  // If an avatar has no image url it will default back to an initial letter instead
  if (
    (props.initial && !props.isMore) ||
    (props.image === "" && !props.isMore) ||
    (props.image === null && !props.isMore)
  ) {
    if (props.image === "" || props.image === null) {
      initialLetter = props.name.substring(0, 1);
    } else {
      initialLetter = (props.initial as string).substring(0, 1);
    }
    avatarClasses += " m-initial";
  }

  // Display "+X" instead to avoid showing more avatars
  if (props.isMore) {
    avatarClasses += " m-more";
  }

  return (
    <div
      onClick={() => (props.onClick ? props.onClick() : {})}
      className={avatarClasses}
      style={{
        maxWidth: props.isMore ? "100%" : props.size + "px",
        minWidth: props.size + "px",
        height: props.size + "px",
        borderWidth: props.borderWidth + "px"
      }}
    >
      {props.image !== undefined && props.image !== "" && props.image !== null ? (
        <img className="e-img" width={props.size} height={props.size} src={props.image} alt={props.name} />
      ) : (
        <span
          className="e-initial"
          style={{
            fontSize: props.initialSize + "px"
          }}
        >
          {props.isMore ? "+" + props.moreCount : initialLetter}
        </span>
      )}
    </div>
  );
};

/**
 * Default prop state for the avatar:
 * - Size and borderWidth need to always be set
 */
Avatar.defaultProps = {
  size: 32,
  initial: undefined,
  initialSize: 24,
  image: undefined,
  name: undefined,
  borderWidth: 2,
  transparent: false
};

export default Avatar;
