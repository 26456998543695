import React from "react";
import { RouteComponentProps } from "react-router";
import LayoutSidebar from "../../hoc/layout-sidebar";
import { menuItems, Paths } from "../../common/menu";
import { inject, observer } from "mobx-react";
import { computed } from "mobx";
import Segment, { SegmentEvent } from "../../common/segment";
import {
  IPersonStore,
  IPerson,
  ISettlementSpecContext
} from "../../stores/personStore";
import { PageModal, PageModalHead, PageModalBody } from "../../hoc/page-modal";
import {
  IPeopleStore,
  IPeopleConnectionGroupBalance,
  matchOnConnectionIdOrUserId,
  IAmount,
  IPeopleBalanceSummaryWithIsoCodePair,
  IPeopleSidebarBalance
} from "../../stores/peopleStore";
import "./person.scss";
import SettleTile from "../../components/tiles/settle";
import {
  TileType,
  IGroupMember,
  IKinStore,
  IPaymentProps,
  IReminderUser
} from "../../stores/kinStore";
import { IProfileSettingsStore } from "../../stores/profileSettingsStore";
import Modal from "../../components/modal/modal";
import PaymentModalContent from "../../components/modal/payment";
import PaymentAdded from "../../components/modal/payment-added";
import ReminderOptions from "../../components/modal/reminder-options";
import ReminderShareGhost from "../../components/modal/reminder-share-ghost";
import ReminderShare from "../../components/modal/reminder-share";
import ReminderSent from "../../components/modal/reminder-sent";
import { GroupMembersStatus } from "../../stores/kinsStore";
import PersonLoader from "./person-loader";
import { history } from "../../common/history";
import Button, { ButtonColors } from "../../components/button/button";
import { isMobile, isTablet } from "react-device-detect";
import { IInviteStore } from "../../stores/inviteStore";
import { Clipboard } from "ts-clipboard";
import Notices from "../../common/snackbars";
import { ILayoutSidebarStore } from "../../stores/layoutSidebarStore";
import BalanceSummaryPerson from "../../components/balance-summary-person/balance-summary-person";
import { ICurrenciesStore } from "../../stores/currenciesStore";

/**
 * Person props interface
 *
 * @interface IPersonProps
 * @extends {RouteComponentProps}
 */
interface IPersonProps extends RouteComponentProps {
  peopleStore?: IPeopleStore;
  personStore?: IPersonStore;
  kinStore?: IKinStore;
  profileSettingsStore?: IProfileSettingsStore;
  inviteStore?: IInviteStore;
  layoutSidebarStore: ILayoutSidebarStore;
  currenciesStore: ICurrenciesStore;
}

/**
 * Component for rendering the Person page
 *
 * @class Person
 * @extends {React.Component<IPersonProps, {}>}
 */
@inject(
  "peopleStore",
  "personStore",
  "kinStore",
  "profileSettingsStore",
  "inviteStore",
  "layoutSidebarStore",
  "currenciesStore"
)
@observer
class Person extends React.Component<IPersonProps, {}> {
  @computed get loading(): boolean {
    return this.props.peopleStore!.loading;
  }
  @computed get person(): IPerson {
    return this.props.personStore!.person;
  }
  @computed get personBalances(): IPeopleBalanceSummaryWithIsoCodePair {
    return this.props.personStore!.person.balances[0];
  }
  @computed get currentUserId(): string {
    return this.props.kinStore!.currentUserId;
  }
  @computed get members(): IGroupMember[] {
    return this.props.kinStore!.members;
  }
  @computed get paymentModal(): boolean {
    return this.props.kinStore!.paymentModal;
  }
  @computed get payment(): IPaymentProps | undefined {
    return this.props.kinStore!.payment;
  }
  @computed get confirmedPayment(): boolean {
    return this.props.kinStore!.confirmedPayment;
  }
  @computed get confirmedReminder(): boolean {
    return this.props.kinStore!.confirmedReminder;
  }
  @computed get reminderModal(): boolean {
    return this.props.kinStore!.reminderModal;
  }
  @computed get reminderOptionsModal(): boolean {
    return this.props.kinStore!.reminderOptionsModal;
  }
  @computed get reminderShareGhostModal(): boolean {
    return this.props.kinStore!.reminderShareGhostModal;
  }
  @computed get reminderShareUserModal(): boolean {
    return this.props.kinStore!.reminderShareUserModal;
  }
  @computed get reminderUser(): IReminderUser {
    return this.props.kinStore!.reminderUser;
  }
  @computed get payNudgeLoading(): boolean {
    return this.props.personStore!.payNudgeLoading;
  }
  @computed get remindPaid(): boolean {
    return this.props.personStore!.remindPaid;
  }
  @computed get inviteUrl(): string | undefined {
    return this.props.profileSettingsStore!.user.inviteLinkUrl;
  }
  @computed get peopleSidebarBalances(): IPeopleSidebarBalance[] | undefined {
    return this.props.peopleStore!.peopleSidebarBalances;
  }

  /**
   * Trigger Segment page view event
   *
   * @memberof Person
   */
  componentDidMount() {
    // Fetch People data if coming directly to person page
    if (!this.props.personStore!.person) {
      let personId: string = (this.props.match.params as any).personid;
      // Fetch people data first
      this.props.peopleStore!.refreshPeople().then(() => {
        let groupBalances: IPeopleConnectionGroupBalance[] = this.props.peopleStore!.fetchPersonGroupBalances(
          personId
        );
        const person: any = this.props.peopleStore!.peopleBalances!.connectionBalances.find(
          personBalance => {
            return matchOnConnectionIdOrUserId(personBalance, personId);
          }
        );
        // If the user does not exist in data (direct url or 404)
        if (groupBalances.length === 0) {
          history.push(Paths.NotFound);
        } else {
          this.props.personStore!.setPerson({
            name: groupBalances[0].member.name,
            image: groupBalances[0].member.image,
            id: personId,
            groupBalances: groupBalances,
            connectionId: groupBalances[0].member.connectionId,
            status: groupBalances[0].member.status,
            userId: groupBalances[0].member.userId,
            email: person.email,
            balances: person.balances
          });
        }
      });
    }
    new Segment().Page(SegmentEvent.PAGE_PERSON);
  }

  /**
   * Clean update data
   *
   * @memberof Person
   */
  componentWillUnmount() {
    this.props.personStore!.setPerson(undefined);
  }

  /**
   * Get the payment data for the payment modal
   *
   * @param {string} memberId The member to settle with
   * @param {number} amount In cents
   * @memberof Person
   */
  setPayment(
    member: IGroupMember,
    myMemberId: string,
    groupName: string,
    groupId: string,
    amount: IAmount
  ) {
    this.props.personStore!.setUsers([
      {
        name: member.name,
        image: member.image,
        size: 32
      },
      {
        name: this.props.profileSettingsStore!.user.name,
        image: this.props.profileSettingsStore!.user.image,
        size: 32
      }
    ]);
    this.props.kinStore!.setPayment({
      currentUserId: myMemberId,
      users: this.props.personStore!.users,
      transaction: {
        amount: amount,
        repayAmount: amount,
        group: groupName,
        user: member.name,
        userId: member.id,
        paying: true
      }
    });
    // Set group ID so that payment query has all the needed data
    this.props.kinStore!.setGroupId(groupId);
    this.props.kinStore!.setCurrentUserId(
      this.props.profileSettingsStore!.user!.id
    );
    this.props.kinStore!.setPaymentModal(true);
  }

  /**
   * Get reminder data for reminder modal
   *
   * @param {string} memberId
   * @param {number} amount
   * @memberof Person
   */
  remindPayment(
    member: IGroupMember,
    myMemberId: string,
    groupName: string,
    groupId: string,
    amount: IAmount
  ) {
    // Set payment details incase user want to settle payment
    // This adds required data not provided form the person component
    // unlike the kin component.
    this.props.personStore!.setUsers([
      {
        name: member.name,
        image: member.image,
        size: 32
      },
      {
        name: this.props.profileSettingsStore!.user.name,
        image: this.props.profileSettingsStore!.user.image,
        size: 32
      }
    ]);
    this.props.kinStore!.setPayment({
      currentUserId: myMemberId,
      users: this.props.personStore!.users,
      transaction: {
        amount: amount,
        repayAmount: amount,
        group: groupName,
        user: member.name,
        userId: member.id,
        paying: true
      }
    });
    // Set group ID so that payment query has all the needed data
    this.props.kinStore!.setGroupId(groupId);
    this.props.kinStore!.setCurrentUserId(
      this.props.profileSettingsStore!.user!.id
    );

    // Update reminder data
    let ghost: boolean = false;
    if (
      member.status === GroupMembersStatus.InActive ||
      member.status === GroupMembersStatus.Invited ||
      member.status === GroupMembersStatus.NotInvited
    ) {
      ghost = true;
    }
    this.props.kinStore!.setReminderUser({
      currentUser: this.props.profileSettingsStore!.user.name,
      name: member.name,
      id: member.id,
      amount: amount,
      ghost: ghost
    });
    this.props.kinStore!.setReminderModal(true);
    this.props.kinStore!.setReminderOptionsModal(true);
  }

  /**
   * Settle a person
   * @param {boolean} [paid=false] If the current user was being paid
   * @memberof Person
   */

  personPayClick(paid: boolean = false) {
    // UI state update
    this.props.kinStore!.setSendingPayment(true);
    let settlementSpecContext: ISettlementSpecContext[] = [];

    // Check if user is a ghost then user connectionID instead
    let fromUserId: string | null;
    if (
      this.person.status === GroupMembersStatus.InActive ||
      this.person.status === GroupMembersStatus.Invited ||
      this.person.status === GroupMembersStatus.NotInvited
    ) {
      fromUserId = this.person.connectionId!;
    } else {
      // User member ID if not a ghost
      fromUserId = this.person.userId;
    }
    if (!paid) {
      fromUserId = null;
    }

    // Loop through balances with the person and build up settlementSpecContext data
    this.person.groupBalances.forEach(balance => {
      let amount: number;

      // The direction of the transaction.
      // If the current user is paying or is logging it as being paid by someone else
      if (balance.balanceSummary.totalIAmOwed.cents !== 0) {
        amount = balance.balanceSummary.totalIAmOwed.cents;
        if (!paid) {
          amount = amount * -1;
        }
      } else {
        amount = balance.balanceSummary.totalOwe.cents;
        if (paid) {
          amount = amount * -1;
        }
      }

      if (amount !== 0) {
        settlementSpecContext.push({
          groupId: balance.group.id,
          memberId: fromUserId ? balance.myMemberId : balance.member.id, // If from connection ID use current user ID
          amount: {
            currencyIso: balance.balanceSummary.currencyIso,
            cents: amount
          }
        });
      }
    });

    // Execute function to pay a person
    this.props
      .personStore!.personPay(fromUserId, {
        description: null,
        to: [...settlementSpecContext]
      })
      .then(() => {
        this.props.kinStore!.setSendingPayment(false);
        this.props.kinStore!.setReminderModal(false);
        this.props.peopleStore!.refreshPeople().then(() => {
          let groupBalances: IPeopleConnectionGroupBalance[] = this.props.peopleStore!.fetchPersonGroupBalances(
            this.person.id
          );
          const person = this.peopleSidebarBalances!.find(
            p => p.userId === this.person.id
          );
          this.props.personStore!.setPerson({
            name: this.person.name,
            image: this.person.image,
            id: this.person.id,
            groupBalances: groupBalances,
            connectionId: this.person.connectionId,
            status: this.person.status,
            userId: this.person.userId,
            email: this.person.email,
            balances: person ? person.balances : []
          });
        });
      });
  }

  /**
   * Remind paid a person modal options
   *
   * @memberof Person
   */
  personRemindNudge() {
    // Set remind paid to person level
    this.props.personStore!.setRemindPaid(true);
    // Set payment details incase user want to settle payment
    // This adds required data not provided form the person component
    // unlike the kin component.
    this.props.personStore!.setUsers([
      {
        name: this.person.name,
        image: this.person.image,
        size: 32
      },
      {
        name: this.props.profileSettingsStore!.user.name,
        image: this.props.profileSettingsStore!.user.image,
        size: 32
      }
    ]);
    // Set group ID so that payment query has all the needed data
    this.props.kinStore!.setCurrentUserId(
      this.props.profileSettingsStore!.user!.id
    );

    // Update reminder data
    let ghost: boolean = false;
    if (
      this.person.status === GroupMembersStatus.InActive ||
      this.person.status === GroupMembersStatus.Invited ||
      this.person.status === GroupMembersStatus.NotInvited
    ) {
      ghost = true;
    }
    this.props.kinStore!.setReminderUser({
      currentUser: this.props.profileSettingsStore!.user.name,
      name: this.person.name,
      id: this.person.id,
      amount: this.person.balances![0].summary.balance,
      ghost: ghost
    });
    this.props.kinStore!.setReminderModal(true);
    this.props.kinStore!.setReminderOptionsModal(true);
  }

  /**
   * Remind or nudge a person
   *
   * @memberof Person
   */
  personRemind() {
    // If no connection ID just assign the user ID
    let connectionId: string = this.person.connectionId
      ? this.person.connectionId
      : this.person.id;
    this.props.kinStore!.setSendingPayment(true);
    this.props
      .personStore!.connectionNudge(connectionId, {
        currencyIso: this.personBalances.currencyIso,
        cents: this.personBalances.summary.balance.cents
      })
      .then(
        () => {
          new Notices().GlobalTrigger(
            this.props.layoutSidebarStore!.setNotice(
              !this.props.layoutSidebarStore!.noticeState,
              "Invite sent!"
            )
          );
        },
        () => {
          new Notices().GlobalTrigger(
            this.props.layoutSidebarStore!.setError(
              !this.props.layoutSidebarStore!.error
            )
          );
        }
      )
      .then(() => this.props.kinStore!.setReminderModal(false))
      .then(() => this.props.peopleStore!.fetchPeople());
  }

  /**
   * Render person balance action and component
   *
   * @returns
   * @memberof Person
   */
  renderPersonAction() {
    const personBalance = this.personBalances.summary.balance;
    if (!isMobile || isTablet || personBalance.cents === 0) {
      return null;
    }
    let actionLabel: string = "Total you are owed";
    let amountClasses: string = "e-col e-action-amount";

    if (personBalance.cents < 0) {
      actionLabel = "Total you owe";
      amountClasses += " m-owe";
    } else {
      amountClasses += " m-owed";
    }
    return (
      <div className="e-balance-action">
        <div className="b-grid m-align-center">
          <div className="e-col m-auto e-action-label">{actionLabel}</div>
          <div className={amountClasses}>
            {this.props.currenciesStore!.formatAmountWithSymbol(personBalance)}
          </div>
        </div>
        {personBalance.cents < 0 ? (
          <Button
            loading={this.payNudgeLoading}
            className="e-action-btn"
            color={ButtonColors.Primary}
            onClick={() => this.personPayClick()}
          >
            Pay
          </Button>
        ) : (
          <Button
            loading={this.payNudgeLoading}
            className="e-action-btn"
            color={ButtonColors.Secondary}
            onClick={() => this.personRemindNudge()}
          >
            Remind / Paid
          </Button>
        )}
      </div>
    );
  }

  renderReinviteCard() {
    if (
      this.person.status === GroupMembersStatus.Invited ||
      this.person.status === GroupMembersStatus.NotInvited
    ) {
      // This is on hold for a little while.
      // Uncomment when 'resend' is available on the API.
      // if (!this.person.email) {
      return (
        <div className="e-invite-card g-text-center-mobile">
          <p className="e-invite-card-message">
            {this.person.name} hasn't joined Kin yet, send them this link to
            connect to kin.
          </p>
          <div className="e-invite-card-cta">
            <Button
              loading={false}
              disabled={false}
              small={true}
              className="e-invite-card-cta-action"
              color={ButtonColors.Black}
              onClick={() => {
                Clipboard.copy(`${this.inviteUrl}`);
                new Notices().GlobalTrigger(() => {
                  this.props.layoutSidebarStore!.setNotice(
                    !this.props.layoutSidebarStore!.noticeState,
                    "Copied to clipboard."
                  );
                });
              }}
            >
              Copy invite link
            </Button>
          </div>
        </div>
      );
      // This is on hold for a little while.
      // Uncomment when 'resend' is available on the API.
      // } else {
      //   return (
      //     <div className="e-invite-card g-text-center-mobile">
      //       <p className="e-invite-card-message">
      //         {this.person.name} hasn't joined Kin yet, click to resend their invite email.
      //       </p>
      //       <div className="e-invite-card-cta">
      //         <Button
      //           loading={false}
      //           disabled={false}
      //           small={true}
      //           className="e-invite-card-cta-action"
      //           color={ButtonColors.Black}
      //           onClick={() => {
      //             console.log("Whoop! lets send an email");
      //           }}
      //         >
      //           Resend invite link
      //         </Button>
      //       </div>
      //     </div>
      //   );
      // }
    } else {
      return null;
    }
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof Person
   */
  render() {
    let owedBalances: IPeopleConnectionGroupBalance[] = [];
    let oweBalances: IPeopleConnectionGroupBalance[] = [];
    let settledBalances: IPeopleConnectionGroupBalance[] = [];

    if (this.person !== undefined) {
      owedBalances = this.person.groupBalances.filter(
        balance => balance.balanceSummary.totalIAmOwed.cents !== 0
      );
      oweBalances = this.person.groupBalances.filter(
        balance => balance.balanceSummary.totalOwe.cents !== 0
      );
      settledBalances = this.person.groupBalances.filter(
        balance =>
          balance.balanceSummary.totalIAmOwed.cents === 0 &&
          balance.balanceSummary.totalOwe.cents === 0
      );
    }

    return (
      <LayoutSidebar
        showNavBar={false}
        menuItems={menuItems}
        menuActiveItem={menuItems[1].title}
        errorState={this.props.layoutSidebarStore!.error}
        errorMsg="Something went wrong!"
        noticeState={this.props.layoutSidebarStore!.noticeState}
        noticeMsg={this.props.layoutSidebarStore!.noticeMsg}
      >
        <div className="b-person-page">
          {this.loading ? (
            <div className="e-container">
              <PersonLoader style={{ width: "100%" }} active={this.loading} />
            </div>
          ) : null}
          {!this.loading ? (
            <>
              <Modal
                center={true}
                onClick={() => this.props.kinStore!.setPaymentModal(false)}
                visible={this.paymentModal}
              >
                {this.payment !== undefined && !this.confirmedPayment ? (
                  <PaymentModalContent
                    users={this.payment.users}
                    transaction={this.payment.transaction}
                  />
                ) : null}
                {this.confirmedPayment ? (
                  <PaymentAdded paying={this.payment!.transaction!.paying} />
                ) : null}
              </Modal>
              <Modal
                center={true}
                onClick={() => {
                  this.props.kinStore!.setReminderModal(false);
                  this.props.personStore!.setRemindPaid(false);
                }}
                visible={this.reminderModal}
              >
                {this.reminderOptionsModal ? (
                  <ReminderOptions
                    users={this.props.personStore!.users}
                    user={this.reminderUser}
                    personReminder={this.remindPaid}
                    payPerson={() => this.personPayClick(this.remindPaid)}
                  />
                ) : null}
                {this.reminderShareGhostModal ? (
                  <ReminderShareGhost user={this.reminderUser} />
                ) : null}
                {this.reminderShareUserModal ? (
                  <ReminderShare
                    user={this.reminderUser}
                    remindPerson={() => this.personRemind()}
                  />
                ) : null}
                {this.confirmedReminder ? (
                  <ReminderSent name={this.reminderUser.name} />
                ) : null}
              </Modal>
              {this.person ? (
                <PageModal>
                  <PageModalHead
                    className="m-condensed-header m-person"
                    image={this.person.image}
                    title={this.person.name}
                    showSettings={false}
                    personAction={{
                      balances: this.personBalances,
                      payClick: () => this.personPayClick(),
                      nudgeClick: () => this.personRemindNudge(),
                      copyInviteLink: () => this.personPayClick(),
                      loading: this.payNudgeLoading,
                      status: this.person.status,
                      image: this.person.image,
                      name: this.person.name
                    }}
                  ></PageModalHead>
                  <PageModalBody className="m-condensed-header">
                    <div className="e-container">
                      <BalanceSummaryPerson
                        groupBalances={
                          this.person.balances ? this.person.balances : []
                        }
                        groupBalanceCount={
                          this.person.groupBalances.filter(
                            group => group.balanceSummary.balance.cents !== 0
                          ).length
                        }
                        name={this.person.name}
                        status={this.person.status}
                        onSettleClick={this.personPayClick.bind(this)}
                        onRemindClick={() => {
                          const hasOnePersonBalance =
                            this.person.balances!.length >= 1;
                          const personBalance = this.person.groupBalances.find(
                            group => group.balanceSummary.balance.cents
                          );

                          if (hasOnePersonBalance && personBalance) {
                            this.remindPayment(
                              {
                                id: this.person.id,
                                name: this.person.name,
                                image: this.person.image,
                                status: this.person.status
                              },
                              personBalance.myMemberId,
                              personBalance.group.name,
                              personBalance.group.id,
                              personBalance.balanceSummary.totalIAmOwed
                            );
                            this.reminderUser.ghost
                              ? this.props.kinStore!.setReminderShareGhostModal(
                                  true
                                )
                              : this.props.kinStore!.setReminderShareUserModal(
                                  true
                                );
                          } else {
                            new Notices().GlobalTrigger(
                              this.props.layoutSidebarStore!.setError(
                                !this.props.layoutSidebarStore!.error
                              )
                            );
                          }
                        }}
                        loading={this.props.kinStore!.sendingPayment}
                      />
                      {owedBalances.length !== 0 ? (
                        <>
                          <div className="b-tile-divider m-totals">
                            Owed to you
                          </div>
                          {owedBalances.map((balance, index) => {
                            return (
                              <SettleTile
                                key={`${balance.group.name}${index}`}
                                type={TileType.Owed}
                                user={{
                                  name: balance.group.name,
                                  image: balance.group.image,
                                  size: 44,
                                  paid: this.props.currenciesStore!.createAmount(
                                    balance.balanceSummary.totalIAmOwed
                                  ).formatted
                                }}
                                onClick={() => {
                                  this.remindPayment(
                                    {
                                      id: balance.member.id,
                                      name: balance.member.name,
                                      image: balance.member.image,
                                      status: balance.member.status
                                    },
                                    balance.myMemberId,
                                    balance.group.name,
                                    balance.group.id,
                                    balance.balanceSummary.totalIAmOwed
                                  );
                                }}
                              />
                            );
                          })}
                        </>
                      ) : null}
                      {oweBalances.length !== 0 ? (
                        <>
                          <div className="b-tile-divider m-totals">You owe</div>
                          {oweBalances.map((balance, index) => {
                            return (
                              <SettleTile
                                key={`${balance.group.name}${index}`}
                                type={TileType.Owe}
                                user={{
                                  name: balance.group.name,
                                  image: balance.group.image,
                                  size: 44,
                                  paid: this.props.currenciesStore!.createAmount(
                                    balance.balanceSummary.totalOwe
                                  ).formatted
                                }}
                                onClick={() => {
                                  this.setPayment(
                                    {
                                      name: balance.member.name,
                                      image: balance.member.image,
                                      id: balance.member.id
                                    },
                                    balance.myMemberId,
                                    balance.group.name,
                                    balance.group.id,
                                    balance.balanceSummary.totalOwe
                                  );
                                }}
                              />
                            );
                          })}
                        </>
                      ) : null}
                      {settledBalances.length !== 0 ? (
                        <>
                          <div className="b-tile-divider">Settled</div>
                          {settledBalances.map((balance, index) => {
                            return (
                              <SettleTile
                                key={`${balance.group.name}${index}`}
                                type={TileType.NotInvolved}
                                user={{
                                  name: balance.group.name,
                                  image: balance.group.image,
                                  size: 44,
                                  paid: "Settled"
                                }}
                              />
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                  </PageModalBody>
                </PageModal>
              ) : null}
            </>
          ) : null}
        </div>
      </LayoutSidebar>
    );
  }
}

export default Person;
