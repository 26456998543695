import { observable, computed } from "mobx";
import { computedFn } from "mobx-utils";
import KinGraphQL from "../common/graphql";
import { queryAuthenticated } from "../common/graphql-utils";
import { IAmount, IUIAmount } from "./peopleStore";

export const currencyQuery = `
query {
  currencies {
    currencySymbol
    isoCode
    name
  }
}
`;

export const updateCurrencyQuery = `
mutation(
  $defaultCurrencyIso: String,
	$name: String!
) {
  userUpdate(
    defaultCurrencyIso: $defaultCurrencyIso,
    name: $name
  ) {
    id,
    correlationId
  }
}
`;

/**
 * Describes the shape of a currency entity
 *
 * @interface ICurrency
 */
export interface ICurrency {
  currencySymbol: string;
  isoCode: string;
  name: string;
}

interface ICreateAmount {
  cents: number;
  currencyIso: string;
}

interface ICreateUIAmount extends ICreateAmount {
  constant?: boolean;
}

/**
 * Describes the shape of a list of currencies
 *
 * @interface ICurrencies
 */
interface ICurrencies extends Array<ICurrency> {}

/**
 * Describes the shape of the currencies store
 *
 * @interface ICurrenciesStore
 */
export interface ICurrenciesStore {
  currencies: ICurrencies;
  fetchCurrencies(query?: string): void;
  updateCurrency(variables: any, query?: string): Promise<any>;
  currencyByIsoCode(isoCode: string): ICurrency | false;
  currencySybmolByIsoCode(isoCode: string): string;
  createAmount(value: ICreateAmount): IAmount;
  formatAmount(amount: IAmount): string;
  formatAmountWithSymbol(amount: IAmount): string;
  formattedStringToCents(string: string): number;
}

class currenciesStore implements ICurrenciesStore {
  /**
   * All currencies available in Kin.
   *
   * @type {ICurrencies}
   * @memberof currenciesStore
   */
  @observable currencies: ICurrencies;

  constructor() {
    this.currencies = [];
    this.fetchCurrencies();
  }

  fetchCurrencies() {
    // Initialise Authentication with refreshed token
    new KinGraphQL().queryFetch(currencyQuery).subscribe({
      next: result => {
        // Update notification status
        const currencies = result.data.data.currencies;
        this.currencies = currencies;
      }
    });
  }

  updateCurrency(variables: any): Promise<any> {
    return queryAuthenticated(updateCurrencyQuery, variables);
  }

  @computed get allCurrencies(): ICurrencies {
    return this.currencies;
  }

  currencyByIsoCode = computedFn((isoCode: string): ICurrency | false => {
    const currency =
      this.currencies.length !== 0
        ? this.currencies.find(currency => currency.isoCode === isoCode)
        : false;
    return currency ? currency : false;
  });

  currencySybmolByIsoCode = computedFn((isoCode: string): string => {
    const currency =
      this.currencies.length !== 0
        ? this.currencies.find(currency => currency.isoCode === isoCode)
        : false;
    if (currency) {
      return currency.currencySymbol;
    } else {
      return isoCode;
    }
  });

  createAmount = (values: ICreateAmount): IAmount => {
    let newAmount: IAmount = {
      cents: values.cents,
      currencyIso: values.currencyIso,
      formatted: ""
    };

    return { ...newAmount, formatted: this.formatAmountWithSymbol(newAmount) };
  };

  createUIAmount = (params: ICreateUIAmount): IUIAmount => {
    return {
      ...this.createAmount(params),
      constant: params.constant ? true : false
    };
  };

  formatAmount = (balance: IAmount): string => {
    const [units, fraction] = Math.abs(balance.cents / 100)
      .toFixed(2)
      .split(".");

    const formattedUnits = units.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return [formattedUnits, fraction].join(".").toString();
  };

  formatAmountWithSymbol = (balance: IAmount): string => {
    const currencySymbol = this.currencySybmolByIsoCode(balance.currencyIso);

    const formattedAmount = this.formatAmount(balance);
    return (currencySymbol + formattedAmount).toString();
  };

  formattedStringToCents = (string: string): number => {
    if (
      string === "NaN" ||
      // @ts-ignore
      (typeof string === "number" && isNaN(string)) ||
      !string.length ||
      Number(string) === 0
    ) {
      return 0;
    }
    let cleanString = this.removeSymbol(string);
    let cents = this.removeDecimal(cleanString);

    return cents;
  };

  removeSymbol = (string: string) => {
    let cleanString = string;
    while (cleanString && !Number(cleanString.charAt(0))) {
      cleanString = cleanString.slice(1);
    }
    return cleanString;
  };

  removeDecimal = (string: string) => {
    let cents: number;
    if (string.indexOf(".")) {
      cents = Number(string.replace(".", "").replace(",", ""));
    } else {
      cents = Number(string.replace(",", "")) * 10;
    }
    return Number(cents);
  };
}

export default currenciesStore;
