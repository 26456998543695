import { createBrowserHistory } from "history";

/**
 * Initializes globally accessible history object
 *
 * @export
 */
export const history = createBrowserHistory();

/**
 * Update page title attribute
 *
 * @export
 * @param {string} pageTitle
 */
export const updatePageTitle = (pageTitle: string) => {
  document.getElementsByTagName("title")[0].innerText = `${pageTitle} - ${process.env.REACT_APP_TITLE}`;
};
