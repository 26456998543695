/**
 * Class for triggering global ui notices
 *
 * @class Notice
 */
class Notices {
  /**
   * Default notice delay before it hides itself
   *
   * @private
   * @type {number}
   * @memberof Notices
   */
  private noticeDelay: number = 6000;

  /**
   * Triggers a global generic error promise that automatically toggles itself off after the delay
   *
   * @param {*} stateFunction
   * @returns {Promise<any>} A promise to reset the UI of necessary
   * @memberof Notices
   */
  public GlobalTrigger(
    stateFunction: any = null,
    autoDismiss: boolean = true
  ): Promise<any> {
    // First toggle the state in
    if (stateFunction !== null) {
      stateFunction();
    }
    return new Promise(resolve => {
      setTimeout(() => {
        // Toggle the state again to reset it
        if (stateFunction !== null && autoDismiss) {
          resolve(stateFunction());
        } else {
          resolve(true);
        }
      }, this.noticeDelay);
    });
  }
}

export default Notices;
