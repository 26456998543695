import React from "react";
import "../../global.scss";
import LoaderContent from "../../hoc/content-loader";

/**
 * Person loading component props for showing and hiding the loader
 *
 * @interface IPersonLoaderProps
 */
interface IPersonLoaderProps {
  active: boolean;
  style?: any;
}

/**
 * Person Loader component for displaying while a query is executing in the browser
 *
 * @param {*} props
 */
const PersonLoader: React.FC<IPersonLoaderProps> = props => (
  <LoaderContent style={props.style} active={props.active}>
    <div
      className="g-loader"
      style={{
        height: "25px",
        width: "50px",
        margin: "24px 0 40px"
      }}
    ></div>
    <div
      className="g-loader"
      style={{
        height: "55px",
        width: "700px",
        maxWidth: "100%",
        margin: "24px 0"
      }}
    ></div>
    <div
      className="g-loader"
      style={{
        height: "55px",
        width: "700px",
        maxWidth: "100%",
        margin: "24px 0"
      }}
    ></div>
  </LoaderContent>
);

/**
 * Default prop state for the loader is always false
 */
PersonLoader.defaultProps = {
  active: false
};

export default PersonLoader;
