import React from "react";
import Button, { ButtonColors } from "../button/button";

import "./profile-settings-bank.scss";

import bankIcon from "../../images/bank-details.svg";
import bankCopy from "../../images/copy.svg";
import bankEdit from "../../images/edit.svg";

import { IProfileSettingsStore } from "../../stores/profileSettingsStore";
import { inject, observer } from "mobx-react";
import { computed } from "mobx";
import { Clipboard } from "ts-clipboard";
import { ILayoutSidebarStore } from "../../stores/layoutSidebarStore";
import { ICurrenciesStore } from "../../stores/currenciesStore";
import Notices from "../../common/snackbars";
import ProfileSettingsCurrency from "./profile-settings-currency";

/**
 * Profile bank props and stores
 *
 * @interface IProfileSettingsBankProps
 */
interface IProfileSettingsBankProps {
  className?: string;
  profileSettingsStore?: IProfileSettingsStore;
  layoutSidebarStore?: ILayoutSidebarStore;
  currenciesStore?: ICurrenciesStore;
}

/**
 * Component to render the user bank details or button to add bank details
 *
 * @class ProfileSettingsBank
 * @extends {React.Component<IProfileSettingsBankProps, {}>}
 */
@inject("profileSettingsStore", "layoutSidebarStore", "currenciesStore")
@observer
class ProfileSettingsBank extends React.Component<
  IProfileSettingsBankProps,
  {}
> {
  @computed get bank() {
    return this.props.profileSettingsStore!.user.receivingAccounts;
  }
  @computed get noticeState() {
    return this.props.layoutSidebarStore!.noticeState;
  }

  /**
   * Adding new bank details
   *
   * @memberof ProfileSettingsBank
   */
  addBankDetails() {
    this.props.profileSettingsStore!.setEditingBank(true);
    this.props.profileSettingsStore!.setEditingBankId("");
    this.props.profileSettingsStore!.fetchBankList();
  }

  /**
   * Editing existing bank details
   *
   * @param {string} id
   * @memberof ProfileSettingsBank
   */
  editBankDetails(id: string) {
    this.props.profileSettingsStore!.setEditingBank(true);
    this.props.profileSettingsStore!.fetchBankList();
    this.props.profileSettingsStore!.setEditingBankId(id!);
  }

  renderAddBank() {
    if (this.bank.length === 0) {
      return (
        <Button
          icon={bankIcon}
          iconCircle={false}
          inline={false}
          small={true}
          color={ButtonColors.Black}
          onClick={() => this.addBankDetails()}
        >
          Add bank details
        </Button>
      );
    } else {
      return (
        <>
          {
            // Display default bank
          }
          <p>You’re receiving payments into the following account:</p>
          {this.bank.map((bank, index) => {
            if (bank.isDefault) {
              return (
                <div key={index} className="e-settings-bank">
                  <div className="e-bank-detail">{bank.accountName}</div>
                  <div className="e-bank-detail">
                    {bank.sortCodeDetail.description}
                  </div>
                  <div className="e-bank-detail">
                    {bank.sortCodeDetail.code}
                  </div>
                  <div className="e-bank-detail">{bank.accountNumber}</div>
                  <img
                    className="e-bank-actions m-copy"
                    onClick={() => {
                      Clipboard.copy(
                        `${bank.accountName}\n${bank.sortCodeDetail.description}\n${bank.sortCodeDetail.code}\n${bank.accountNumber}`
                      );
                      new Notices().GlobalTrigger(() => {
                        this.props.layoutSidebarStore!.setNotice(
                          !this.noticeState,
                          "Copied to clipboard."
                        );
                      });
                    }}
                    src={bankCopy}
                    alt="Copy to clipboard"
                  />
                  <img
                    className="e-bank-actions m-edit"
                    onClick={() => this.editBankDetails(bank.id!)}
                    src={bankEdit}
                    alt="Edit Banking Details"
                  />
                </div>
              );
            }
            return null;
          })}
        </>
      );
    }
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof ProfileSettingsBank
   */
  render() {
    let profileBankSettingsClasses: string = "b-profile-settings-bank";
    const defaultCurrencyIso = this.props.profileSettingsStore!.user
      .defaultCurrency.isoCode;
    const defaultCurrency = this.props.currenciesStore?.currencyByIsoCode(
      defaultCurrencyIso
    );
    if (this.props.className) {
      profileBankSettingsClasses += ` ${this.props.className}`;
    }
    return (
      <div className={profileBankSettingsClasses}>
        <div className="e-settings-title">Default Currency</div>
        <div className="e-settings-currency">
          <ProfileSettingsCurrency
            currency={defaultCurrency}
            handleClick={() =>
              this.props.layoutSidebarStore!.setCurrenciesPanel(true)
            }
            icon
          />
        </div>
        {defaultCurrencyIso === "ZAR" ? (
          <>
            <div className="e-settings-title">Receive Payments</div>
            {this.renderAddBank()}
          </>
        ) : null}
      </div>
    );
  }
}

export default ProfileSettingsBank;
