import React from "react";

import PillList from "../../components/pill-list/pill-list";
import Layout from "../../hoc/layout";

import "../../grid.scss";
import "../../global.scss";
import "../../typography.scss";
import "../../fonts/cerebri.css";
import "./404.scss";

import { updatePageTitle, history } from "../../common/history";
import Segment, { SegmentEvent } from "../../common/segment";
import Button, { ButtonColors } from "../../components/button/button";
import { Paths } from "../../common/menu";

/**
 * Class for creating the 404 error page
 *
 * @class NotFound
 * @extends {React.Component<{}, {}>}
 */
class NotFound extends React.Component<{}, {}> {
  /**
   * Triggers the page view event
   *
   * @memberof NotFound
   */
  componentDidMount() {
    // Update page title
    updatePageTitle("Page Not Found");
    new Segment().Page(SegmentEvent.PAGE_404);
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof NotFound
   */
  render() {
    return (
      <Layout className="b-404-error" errorState={false}>
        <div className="e-content g-fade m-in">
          <h1>Oops, we can’t find that page</h1>
          <div className="e-purpose g-h1 m-regular">
            The link you followed may be broken, or the page may have been removed.
          </div>
          <Button
            className="e-center m-block"
            color={ButtonColors.Black}
            onClick={() => history.push(Paths.Kins)}
          >
            Take me home
          </Button>
        </div>
        <PillList />
      </Layout>
    );
  }
}

export default NotFound;
