import React from "react";

import "./profile-settings-image.scss";
import { IProfileSettingsStore } from "../../stores/profileSettingsStore";
import { observer, inject } from "mobx-react";
import AvatarUpload, { AvatarUploadType } from "../avatar-upload/avatar-upload";
import { computed } from "mobx";

import deleteIcon from "../../images/trash-2.svg";

/**
 * Profile image props and store
 *
 * @interface IProfileSettingsImageProps
 */
interface IProfileSettingsImageProps {
  name: string;
  image: string;
  profileSettingsStore?: IProfileSettingsStore;
}

/**
 * Component to render the image uploading component or editing an existing image
 *
 * @class ProfileSettingsImage
 * @extends {React.Component<IProfileSettingsImageProps, {}>}
 */
@inject("profileSettingsStore")
@observer
class ProfileSettingsImage extends React.Component<IProfileSettingsImageProps, {}> {
  @computed get editing() {
    return this.props.profileSettingsStore!.editing;
  }

  /**
   * Default render method
   *
   * @returns
   * @memberof ProfileSettingsImage
   */
  render() {
    return (
      <div className="b-profile-settings-image">
        <AvatarUpload
          className="e-avatar"
          uploadType={AvatarUploadType.User}
          editMode={this.editing}
          size={120}
          name={this.props.name}
          image={this.props.image}
        />
        <div className="e-col m-auto">
          {this.editing ? (
            <div onClick={() => this.props.profileSettingsStore!.removePhoto()} className="e-remove-image">
              Remove image <img src={deleteIcon} alt="Remove Avatar" />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ProfileSettingsImage;
