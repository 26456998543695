import React from 'react';
import '../../global.scss';
import LoaderContent from '../../hoc/content-loader';

/**
 * Kins loading component props for showing and hiding the loader
 *
 * @interface IGroupLoaderProps
 */
interface IGroupLoaderProps {
    active: boolean;
    style?: any;
}

/**
 * Kins Loader component for displaying while a query is executing in the browser
 *
 * @param {*} props
 */
const KinLoader: React.FC<IGroupLoaderProps> = (props) => (
    <LoaderContent style={props.style} active={props.active}>
        <div className="g-loader" style={{
                    height: "25px",
                    width: "180px",
                    margin: "24px auto 40px"
                }}></div>
            <div style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    marginBottom: "30px",
                    width: "500px",
                    maxWidth: "100%"
                }}>
                    <div className="g-loader" style={{
                            width: "15%",
                            height: "20px",
                        }}></div>
                    <div className="g-loader" style={{
                            width: "15%",
                            height: "20px",
                        }}></div>
                    <div className="g-loader" style={{
                            width: "15%",
                            height: "20px",
                        }}></div>
            </div>
            <div className="g-loader" style={{
                    height: "55px",
                    width: "100%",
                    margin: "24px 0"
                }}></div>
            <div className="g-loader" style={{
                    height: "55px",
                    width: "100%",
                    margin: "24px 0"
                }}></div>
    </LoaderContent>
);

/**
 * Default prop state for the loader is always false
 */
KinLoader.defaultProps = {
    active: false
}

export default KinLoader;