import React from "react";
import { ICurrency } from "../../stores/currenciesStore";
import arrowRightIcon from "../../images/chevron-right.svg";
import "./profile-settings-currency.scss";

type TProfileSettingsCurrencyProps = {
  currency: false | ICurrency | undefined;
  handleClick?: any;
  icon?: any;
  isActive?: boolean;
  disabled?: boolean;
  className?: string;
};

function ProfileSettingsCurrency(props: TProfileSettingsCurrencyProps) {
  function renderCurrencyItem(currency: false | ICurrency | undefined) {
    return (
      <div
        className={`e-settings-li ${
          props.disabled ? "m-settings-li-disabled" : ""
        } ${props.className}`}
        onClick={props.handleClick}
      >
        <div className="e-settings-li-content">
          <div className="e-settings-li-label">
            <div className="e-settings-li-label-iso-code">
              {currency ? currency.isoCode : null}{" "}
              {currency ? `• ${currency.currencySymbol}` : null}
            </div>
            <span className="e-settings-li-label-name">
              {currency ? currency.name : null}
            </span>
          </div>
          {props.icon ? (
            <div className="e-settings-li-action">
              <img src={arrowRightIcon} alt="Choose default currency" />
            </div>
          ) : null}
          {props.isActive ? (
            <div className="e-settings-li-active">current</div>
          ) : null}
        </div>
      </div>
    );
  }
  return renderCurrencyItem(props.currency);
}

ProfileSettingsCurrency.defaultProps = {
  disabled: false
};

export default ProfileSettingsCurrency;
