interface IUtilitiesThousandSeparatorOptions {
  centsAmount: number;
  convertToRands?: boolean;
  separator?: string;
}

/**
 * Common helper utilities
 *
 * @class Utilities
 */
class Utilities {
  public FormatToCurrencyValue(
    currencySymbol: string,
    value: string | undefined,
    includeCurrency: boolean = false
  ): string {
    let formattedValue: string;
    // Get number value but always displayed as positive value
    let valueAsNumber: number = Math.abs(parseFloat(value!));
    if (isNaN(valueAsNumber) || valueAsNumber === 0) {
      formattedValue = "0.00";
    } else {
      formattedValue = (valueAsNumber / 100).toFixed(2).toString();
    }
    if (includeCurrency) {
      return `${currencySymbol + formattedValue}`;
    }
    return formattedValue;
  }

  /**
   * Return a value converted to cents
   *
   * @param {string} value
   * @returns {string} String value in cents
   * @memberof Utilities
   */
  public FormatToCentsValue(value: string): string {
    // Remove currency symbol if added in front
    if (value.charAt(0) === "R") {
      value = value.slice(1, value.length);
    }
    let valueAsNumber: number = parseFloat(value) * 10;
    let storedValue: string = valueAsNumber.toString();
    // if a trailing zero, remove decimal point to avoid
    // parseFloat removing trailing zero's or multiplying
    // with strings
    if (value.lastIndexOf("0") === value.length - 1) {
      storedValue = value.replace(".", "");
    }
    // Include decimal values if there are any
    if (Number(valueAsNumber) === valueAsNumber && valueAsNumber % 1 !== 0) {
      storedValue = Math.round(valueAsNumber * 100).toString();
    }
    return storedValue;
  }

  /**
   * Take cents value, format to rand and add thousand separator
   *
   * @param {number} cents
   * @returns {string}
   * @memberof Kin
   */
  public ThousandSeparator(
    options: IUtilitiesThousandSeparatorOptions
  ): string {
    let convertToRands: boolean = options.convertToRands || true;
    let centsAmount: number = options.centsAmount;
    let separator: string = options.separator || " ";

    if (convertToRands || true) {
      centsAmount = centsAmount / 100;
    }

    let randValue: string = centsAmount.toFixed(2);
    randValue = randValue.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

    // Remove unnecessary decimals if .00
    let randValueLength: number = randValue.length;
    if (randValue.substring(randValueLength - 3, randValueLength) === ".00") {
      randValue = randValue.substring(0, randValueLength - 3);
    }

    return randValue;
  }
}

export default Utilities;
